import { SRC_BUCKET, DEST_BUCKET } from '../../config';
import Theme from './Theme';

class Job {
  constructor(job) {
    if (job.specs === undefined) {
      job.specs = {};
    }
    // eslint-disable-next-line
    this.id = job._id;
    this.ownerId = job.owner;
    this.ownerName = job.owner_name;
    this.guid = job.guid;
    this.episodeInfo = job.episodeInfo;
    this.isAutomated = job.isAutomated;
    this.isFullLength = job.isFullLength; // automated video with no summarization
    this.extraDurationCharged = job.extraDurationCharged || 0;
    this.dateAdded = new Date(job.date_added);
    this.status = job.status;
    if (job.date_completed) {
      this.dateCompleted = new Date(job.date_completed);
    }
    if (job.date_edited) {
      this.dateEdited = new Date(job.date_edited);
    }
    this.isLegacy = this.dateAdded < new Date(2021, 9, 4); // Oct 4 2021
    this.detailedStatus = null; // used for when job creation is currently pending
    this.title = job.title;
    this.tags = job.tags || [];
    this.editCount = job.editCount || 0;
    this.subtitles = Object.assign(
      {},
      {
        started: false,
        finished: false,
        progress: 0,
        ref: null,
      },
      job.subtitles
    );
    this.transcript = Object.assign(
      {},
      {
        started: false,
        finished: false,
      },
      job.transcript
    );
    this.cardType = job.specs ? job.specs.cardType : '';
    this.start = job.specs ? job.specs.start : null;
    this.end = job.specs ? job.specs.end : null;
    this.width = job.specs ? job.specs.width : null;
    this.height = job.specs ? job.specs.height : null;
    this.designId = job.specs ? job.specs._id : null;

    this.stingerVisible = false;

    if (this.width && this.height) {
      if (this.width === this.height) {
        this.cardType = 'square';
      } else {
        this.cardType = this.width > this.height ? 'horizontal' : 'vertical';
      }
    }

    this.archived = job.archived || false;
    this.deleted = job.deleted || false;
    this.inputUrl = `https://${SRC_BUCKET}.s3.amazonaws.com/tmp/${job.guid}/audiofile`;
    if (this.dateCompleted || this.status === 'complete') {
      this.url = `https://s3.amazonaws.com/${DEST_BUCKET}/${job.guid}.mp4`;
      this.stingerUrl = `https://s3.amazonaws.com/${DEST_BUCKET}/${job.guid}-stinger.mp4`;
    } else {
      this.url = null;
      this.stingerUrl = null;
    }
    if (job.specs.backgroundImage) {
      this.backgroundImageKey = job.specs.backgroundImage;
      this.backgroundImage = `https://s3-us-west-2.amazonaws.com/wavvegram/background/${job.specs.backgroundImage}`;
    } else {
      this.backgroundImageKey = null;
      this.backgroundImage = null;
    }
    if (this.subtitles.ref) {
      this.captionUrl = `https://s3.amazonaws.com/subtitles-out/${this.subtitles.ref}`;
      this.captionStingerUrl = `https://s3.amazonaws.com/subtitles-out/${this.subtitles.stingerRef}`;
    } else if (this.transcript.started && this.transcript.finished && this.subtitles.started && this.subtitles.finished) {
      this.captionUrl = `https://s3.amazonaws.com/${DEST_BUCKET}/${job.guid}_captions.mp4`
      this.captionStingerUrl = null;
    } else {
      this.captionUrl = null;
      this.captionStingerUrl = null;
    }
    // eslint-disable-next-line
    this.themeId = job.specs ? job.specs._id : -1;

    if (job.specs && job.specs.duration) {
      this.duration = +job.specs.duration;
    } else if (job.specs.start !== undefined && job.specs.end !== undefined) {
      this.duration = +job.specs.end - +job.specs.start;
    } else {
      this.duration = 0;
    }

    if (job.specs) {
      this.specs = job.specs;
      this.theme = new Theme(job.specs);
      this.specs.previewImage = job.specs.previewImage != "" && job.specs.previewImage != undefined && job.specs.previewImage != null
        ? `https://s3-us-west-2.amazonaws.com/wavvegram/background/${job.specs.previewImage}` : null;
    }

    // saved specs (edited but not yet created a video with edited specs)
    this.editedSpecs = job.editedSpecs;
    this.editedStart = job.editedSpecs ? job.editedSpecs.start : null;
    this.editedEnd = job.editedSpecs ? job.editedSpecs.end : null;
    this.editedThemeId = job.editedSpecs ? job.editedSpecs.themeId : null;
  }
}

export default Job;
