<template>
  <div>
    <section class="section has-no-padding-bottom has-small-padding-top">
      <div class="container">
        <br>
        <h2 class="is-size-2 has-text-weight-bold has-large-margin-top">My Designs</h2>
        <br />
        <br>
        <br>
        <router-link class="createBtnColor" :to="{ path: '/editor' }">
          <el-button class="createBtn" type="primary" round icon="el-icon-folder-add"> Create a New Design</el-button>
        </router-link>
        <!--       <el-dropdown>
          <el-button type="text" style="margin: 0 0 0 20px">Filters Designs<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
        </el-dropdown> -->
      </div>
    </section>
    <br>
    <br>
    <br>
    <div style="text-align:center">
      <div v-for="design in designs" :key="design.id" style=display:inline-block>
        <KeepAlive :max="100">
          <div shadow="hover" class="design-card">
            <div class="design-thumb">
              <img :src="design.backgroundImage" :alt="design.name" />
              <!-- <p class="design-size">{{ design.height }}x{{ design.width }}</p> -->
            </div>
            <div class="has-text-left">
              <div class="description_title">Design Name</div>
              <div class="description_value">{{ design.name }}</div>
              <div class="description_title">Dimensions</div>
              <div class="description_value">{{ design.height }}x{{ design.width }}</div>

              <div class="description_title">Created </div>
              <div class="description_value"> {{ dateFormatter(design.dateAdded) }}</div>
              <!-- <div class="description_value"> You</div> -->
              <div class="toolbar">
                <!-- <el-button  round plain     size="mini"  type="secondary">
                     Edit
              </el-button>  -->
                <el-dropdown v-loading="design.isLoading" round plain size="mini" split-button type="secondary"
                  :key="design.id" @click="create(design.id)">
                  <i class="fas fa-play-circle" style="margin-right: 10px"></i> Create
                  <el-dropdown-menu slot="dropdown">
                    <!-- <a @click="alert('/editor/'+design.id) "> -->
                    <a @click="edit(design.id)">
                      <el-dropdown-item size="small"> <i class="fas fa-pencil"></i>Edit</el-dropdown-item>
                    </a>
                    <a @click="clone(design.id)">
                      <el-dropdown-item> <i class="fal fa-copy"></i>Clone</el-dropdown-item>
                    </a>
                    <!--      <a>
    <el-dropdown-item>  <i class="fal fa-magic" ></i>Expand</el-dropdown-item>
    </a> -->

                    <!-- <a 
    v-if="scope.row.docType != 'summary'"
    @click="handleSummaryClick(scope.row._id, scope.row.title)">
      <el-dropdown-item>Create Summary</el-dropdown-item></a> -->
                    <a @click="softDelete(design.id)">
                      <el-dropdown-item icon=el-icon-delete>Delete</el-dropdown-item>
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>

            </div>
          </div>
        </KeepAlive>
      </div>
    </div>
  </div>
</div></template>

<script>
import DesignAnimatedPreview from '@/components/Create/DesignAnimatedPreview.vue';
import Theme from '@/store/models/Theme';
import ThemeService from '@/api/theme.service';

import { demoThemes } from '@/config/default-themes';
import { DELETE_THEME, SET_SELECTED_THEME, SYNC_USER_TO_DB } from '@/store/action-types';
import { SET_THEME_LOADING } from '@/store/mutation-types';


export default {
  name: 'Designs',
  components: {
    DesignAnimatedPreview,
  },
  data() {
    return {};
  },
  computed: {
    noUserThemes() { return this.$store.state.user.themes.length === 0 },
    designs() {
      if (this.noUserThemes) {
        return demoThemes.map(theme => new Theme(theme));
      }
      return this.$store.state.user.themes;
    },
  },
  async created() {
  },
  async mounted() {
  },
  watch: {
  },
  methods: {
    async edit(id) {
      this.$router.push('/editor/' + id);
    },
    async create(id) {
      this.$router.push('/create?design=' + id);
    },
    async clone(id) {
      try {
        this.$store.commit(SET_THEME_LOADING, { id: id, value: true });
        await ThemeService.clone({ id });
        await this.$store.dispatch(SYNC_USER_TO_DB);

        this.$notify.success({ title: 'Success', message: 'Design Cloned' });
      } catch (err) {
        console.log('err', err);
        this.$notify.error({ title: 'Error', message: 'Clone Design Failed' });
      }
    },
    async softDelete(id) {
      try {
        await this.$confirm(`Are you want to delete this design?`);
        await this.$store.dispatch(DELETE_THEME, id);
        this.$notify.success({ title: 'Success', message: 'Design Removed' });
      } catch (err) {
        if (err !== 'cancel') {
          this.$notify.error({ title: 'Error', message: 'Design Delete Failed' });
        }
      }
    },
    getMonthName(monthIndex) {
      let monthsArray = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
      return monthsArray[monthIndex];
    },
    dateFormatter(date) {
      if ((new Date(date)).toDateString() == (new Date).toDateString()) {
        return "Today"
      };
      if (date && new Date(date).getYear() != 70) {
        let d = (new Date(date)).getDate()
        let m = this.getMonthName(new Date(date).getMonth())
        return `${m} ${d}`
        // ${row.dateAdded.split('T')[1].split('.')[0]}
        // return `<div style=font-size:90%>${row.dateAdded.split('T')[0].replace('2023', '23')}\n${row.dateAdded.split('T')[1].split('.')[0]}</div>`;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../style/main.scss';

.design-card {
  position: relative;
  margin-left: 10px;
  margin-right: 10px;
  display: inline-block;
  // max-width:450px;
  min-width: 450px;
  width: 30%;
  // background: red;
  // margin-top:100px;
  // margin-right:100px;
  margin-bottom: 30px;
  padding: 10px;
  height: 155px;
  box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2)
}

// .el-card__body {
//   padding:0px !important;
//   margin: 0px;
// }
.design-container {
  padding: 0px;
  margin: 0px;
}

.design-thumb {
  text-align: center;
  width: 140px;
  // height:128px;
  border-radius: 5px;
  border: 2px solid white;
  overflow: hidden;
  float: left;
  margin-top: 0px;
  margin-bottom: 5px;
  margin-right: 20px;

  img {
    height: 130px;
  }

  .design-size {

    width: 100%;
    text-align: center;
    font-size: 10px;
  }
}

.description_title {
  font-weight: 600;
  font-size: 80%;
  margin-top: 1px;
  margin-bottom: -3px;
}

.description_value {
  margin-bottom: 8px;
  font-size: 85%;
}

.toolbar {
  position: absolute;
  right: 10px;
  bottom: 10px;
}

.el-dropdown-menu__item {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 15px !important;
  height: 35px !important;

  i {
    margin-right: 10px;
  }
}

i {
  color: 555;
}

.el-dropdown-menu {
  width: 150px;


}
</style>
