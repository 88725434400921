/* eslint-disable */
import * as d3 from 'd3';
import scale from './d3-scale-radial';
const { scaleRadial } = scale;

function movingAvg(arr, size) {
  var win,
    i,
    newarr = [];
  for (i = size - 1; i <= arr.length; i++) {
    win = arr.slice(i - size, i);
    if (win.length === size) {
      var mean = win.reduce((sum, num) => sum + num) / win.length;
      newarr.push(mean);
    }
  }
  return newarr;
}

function blob() {
  return function(context, data, options) {
    context.fillStyle = options.waveColor;
    data = movingAvg(
      data.map(function(d) {
        return d[0];
      }),
      2
    );
    data.splice(0, 2);
    data = data.filter((d, i) => i % 3 !== 0);
    var waveHeight = Math.abs(options.waveBottom - options.waveTop);
    var waveWidth = Math.abs(options.waveRight - options.waveLeft);
    var outerRadius = Math.min(waveHeight, waveWidth) / 2;
    var innerRadius = 0.78 * outerRadius;
    var cx = (options.waveLeft + options.waveRight) / 2;
    var cy = (options.waveTop + options.waveBottom) / 2;
    var theta = d3
      .scaleBand()
      .domain(d3.range(data.length))
      .range([0, 2 * Math.PI])
      .align(0);
    var r = scaleRadial().range([innerRadius, outerRadius]);
    var radialArea = d3
      .areaRadial()
      .curve(d3.curveCardinalClosed.tension(0))
      .angle(function(d, i) {
        return theta(i);
      })
      .innerRadius(function(d) {
        return innerRadius;
      })
      .outerRadius(r)
      .context(context);
    context.translate(cx, cy);
    context.beginPath();
    radialArea(data);
    context.fill();
    context.closePath();
    context.translate(-cx, -cy);
  };
}

function vinyl() {
  return (context, data, options) => {
    data = movingAvg(
      data.map(d => d[0]),
      4
    );
    data.splice(0, 4);
    context.fillStyle = options.waveColor;
    const height = Math.abs(options.waveBottom - options.waveTop);
    const width = Math.abs(options.waveRight - options.waveLeft);
    const cx = (options.waveLeft + options.waveRight) / 2;
    const cy = (options.waveTop + options.waveBottom) / 2;
    context.translate(cx, cy);
    const r = Math.min(height, width) / 2; // radius of the entire animation
    const n = 20; // number of levels
    const rr = r / n / 2; // max radius of each drawn circle
    // to help out with the wagon-wheel effect
    context.shadowColor = options.waveColor;
    context.shadowBlur = rr;

    for (let i = 7; i < n; i++) {
      const circumference = (Math.PI * r * i) / n;
      const m = Math.floor(circumference / rr);
      for (let j = 0; j < m; j++) {
        // polar coordinates
        const polT = (2 * Math.PI * j) / m;
        const polR = (r * i) / n;
        const x = Math.sin(polT) * polR;
        const y = Math.cos(polT) * polR;
        const matchingIndex = Math.floor((j / m) * (data.length - 1));
        // amplitude on scale of 0 to 1
        const amp = 0.1 + data[matchingIndex] * 0.9;
        context.beginPath();
        context.arc(x, y, rr * amp, 0, 2 * Math.PI, false);
        context.fill();
        context.closePath();
      }
    }
    context.setTransform(1, 0, 0, 1, 0, 0);
  };
}

function siri() {
  return (context, data, options) => {
    const top = Math.min(options.waveTop, options.waveBottom);
    const bottom = Math.max(options.waveTop, options.waveBottom);
    const center = (top + bottom) / 2;
    const bcenter = center - 0.1; // just below the center to make shapes convex
    const left = Math.min(options.waveLeft, options.waveRight);
    const right = Math.max(options.waveLeft, options.waveRight);
    const height = bottom - top;
    const width = right - left;
    data = movingAvg(
      data.map(d => d[0]),
      5
    );
    data.splice(0, 5);
    const numSegments = 3;
    const numPerSegment = Math.floor(data.length / numSegments);
    context.fillStyle = options.waveColor;
    context.strokeStyle = options.waveColor;
    context.shadowColor = options.waveColor;
    context.lineWidth = 0.5;
    context.shadowBlur = 10;
    // context.globalCompositeOperation = "screen";

    const xGrid = d3.range(16).map(d => left + (d * width) / 15);
    for (let i = 0; i < numSegments; i++) {
      const x = xGrid.slice(i, 14 + i);
      const smallPeakRatio = Math.min(1, data[numPerSegment * i]);
      const largePeakRatio = Math.min(1, data[numPerSegment * i + 2] * 1.5);
      const smallPeakSize = height * smallPeakRatio;
      const largePeakSize = height * largePeakRatio;
      const topPoint = center - largePeakSize;
      const topMidPoint = center - smallPeakSize;
      const bottomPoint = center + largePeakSize;
      const bottomMidPoint = center + smallPeakSize;
      // offset the three waves
      context.beginPath();
      context.moveTo(left, center);
      context.lineTo(x[1], center); // start
      context.bezierCurveTo(x[2], topMidPoint, x[2], center, x[4], center); // first peak
      context.lineTo(x[5], center);
      context.bezierCurveTo(x[6], topPoint, x[6], center, x[8], center); // second peak
      context.lineTo(x[9], center);
      context.bezierCurveTo(x[10], topMidPoint, x[10], center, x[12], center); // third peak
      context.lineTo(x[13], center); // go out to the end
      // and come back
      context.lineTo(x[12], bcenter);
      context.bezierCurveTo(x[10], bcenter, x[10], bottomMidPoint, x[9], bcenter); // third peak
      context.lineTo(x[8], bcenter);
      context.bezierCurveTo(x[6], bcenter, x[6], bottomPoint, x[5], bcenter); // second peak
      context.lineTo(x[4], bcenter);
      context.bezierCurveTo(x[2], bcenter, x[2], bottomMidPoint, x[1], bcenter); // first peak
      context.closePath();
      context.globalAlpha = 0.6;
      context.fill();
      context.globalAlpha = 1;
      context.stroke();
    }
  };
}

function seaUrchin() {
  return (context, rawData, options) => {
    context.fillStyle = options.waveColor;
    let data = movingAvg(
      rawData.map(d => d[0]),
      3
    );
    data.splice(0, 3);
    data = data.filter((d, i) => i % 3 !== 0);
    const paddingFactor = 0.055;
    const cornerFactor = 1 / 30;
    const waveHeight = Math.abs(options.waveBottom - options.waveTop);
    const waveWidth = Math.abs(options.waveRight - options.waveLeft);
    const outerRadius = Math.min(waveHeight, waveWidth) / 2;
    const innerRadius = 0.61 * outerRadius;
    const cx = (options.waveLeft + options.waveRight) / 2;
    const cy = (options.waveTop + options.waveBottom) / 2;
    const x = d3
      .scaleBand()
      .domain(d3.range(data.length))
      .range([0, 2 * Math.PI])
      .align(0);
    const y = scaleRadial().range([innerRadius, outerRadius]);
    const arc = d3
      .arc()
      .innerRadius(y(0))
      .outerRadius(y)
      .startAngle((d, i) => x(i))
      .endAngle((d, i) => x(i) + x.bandwidth())
      .padAngle(paddingFactor)
      .padRadius(innerRadius)
      .cornerRadius((outerRadius - innerRadius) * cornerFactor)
      .context(context);
    context.translate(cx, cy);
    context.beginPath();
    data.forEach((d, i) => {
      arc(d, i);
    });
    context.fill();
    context.closePath();
    context.translate(-cx, -cy);
  };
}

function rectangleBorder() {
  return (context, data, options) => {
    data = movingAvg(
      data.map(function(d) {
        return d[0];
      }),
      4
    );
    data.splice(0, 4);
    var waves = [options.waveColor, options.waveColor, options.waveColor]; // "yellow", "red", "blue ", "green"];
    var n = Math.floor(data.length / waves.length);
    var waveHeight = Math.abs(options.waveBottom - options.waveTop);
    var waveWidth = Math.abs(options.waveRight - options.waveLeft);
    var verticalMovement = waveHeight * 0.1;
    var horizontalMovement = waveWidth * 0.1;
    var innerY = Math.min(options.waveTop, options.waveBottom) + verticalMovement;
    var innerX = options.waveLeft + horizontalMovement;
    context.globalAlpha = 0.7;
    context.lineWidth = Math.min(waveHeight, waveWidth) / 150;
    waves.forEach(function(color, index) {
      var segment = data.slice(index * n, (index + 1) * n).map(function(d) {
        return (d * (index + 1)) / n;
      });
      var fillCapacityRatio = Math.min(1, Math.pow(d3.max(segment, d => d) * 10, 1 / 3));
      var leftOffset = horizontalMovement * fillCapacityRatio;
      var topOffset = verticalMovement * fillCapacityRatio;
      context.strokeStyle = color;
      context.strokeRect(
        innerX - leftOffset,
        innerY - topOffset,
        waveWidth - (horizontalMovement - leftOffset) * 2,
        waveHeight - (verticalMovement - topOffset) * 2
      );
    });
    context.globalAlpha = 1;
  };
}

function circleWaves() {
  return (context, data, options) => {
    data = movingAvg(
      data.map(function(d) {
        return d[0];
      }),
      4
    );
    data.splice(0, 4);
    var waves = [options.waveColor, options.waveColor, options.waveColor]; // "yellow", "red", "blue ", "green"];
    var n = Math.floor(data.length / waves.length);
    var waveHeight = Math.abs(options.waveBottom - options.waveTop);
    var waveWidth = Math.abs(options.waveRight - options.waveLeft);
    var maxRadius = Math.min(waveHeight, waveWidth) / 2;
    var cx = (options.waveLeft + options.waveRight) / 2;
    var cy = (options.waveTop + options.waveBottom) / 2;
    var r = d3
      .scalePow()
      .exponent(0.22)
      .domain([0, 1])
      .range([maxRadius * 0.7, maxRadius]);
    var angles = d3.range(0, 2 * Math.PI, ((2 * Math.PI) / data.length) * waves.length);
    var radialLines = waves.map(function(wave, index) {
      return d3
        .radialLine()
        .curve(d3.curveCardinalClosed.tension(0))
        .angle(function(d, i) {
          return angles[i];
        })
        .radius(function(d) {
          return r(d / (index * 5 + 1));
        })
        .context(context);
    });
    context.globalAlpha = 0.7;
    context.lineWidth = maxRadius / 100;
    context.translate(cx, cy);
    waves.forEach(function(color, index) {
      context.strokeStyle = color;
      context.beginPath();
      radialLines[index](
        data.slice(index * n, (index + 1) * n).map(function(d) {
          return (d * (index + 1)) / n;
        })
      );
      context.stroke();
      context.closePath();
    });
    context.translate(-cx, -cy);
    context.globalAlpha = 1;
  };
}

function circlePulse() {
  return (context, data, options) => {
    context.fillStyle = options.waveColor;
    context.strokeStyle = options.waveColor;

    var waveHeight = options.waveBottom - options.waveTop;
    var baseline = options.waveTop + waveHeight / 2;
    var barX = d3
      .scaleBand()
      .paddingInner(0.2)
      .paddingOuter(0.01)
      .domain(d3.range(data.length))
      .range([options.waveLeft, options.waveRight]);

    var r = d3
      .scaleSqrt()
      .domain([0, 1])
      .range([barX.bandwidth() / 10, barX.bandwidth() * 2]);

    data.forEach(function(val, i) {
      if (i % 3 === 0) {
        var centerX = barX(i);
        var centerY = baseline;
        var radius = r(val[0]);
        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
        context.fill();
        context.closePath();
      }
    });
  };
}

function filledPath(interpolator) {
  return (context, data, options) => {
    context.fillStyle = options.waveColor;
    context.strokeStyle = options.waveColor;
    context.lineWidth = 3;

    var line = d3.line().context(context);

    if (interpolator) {
      line.curve(interpolator);
    }

    var waveHeight = options.waveBottom - options.waveTop;

    var baseline = options.waveTop + waveHeight / 2;

    var x = d3
      .scalePoint()
      .padding(0)
      .domain(d3.range(data.length))
      .range([options.waveLeft, options.waveRight]);

    var height = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, waveHeight / 2]);

    var top = data.map(function(d, i) {
      return [x(i), baseline - height(d[0])];
    });

    var bottom = data
      .map(function(d, i) {
        return [x(i), baseline + height(d[0])];
      })
      .reverse();

    top.unshift([options.waveLeft, baseline]);
    top.push([options.waveRight, baseline]);

    // Fill waveform
    context.beginPath();
    line(top.concat(bottom));
    context.fill();

    // Stroke waveform edges / ensure baseline
    [top, bottom].forEach(function(path) {
      context.beginPath();
      line(path);
      context.stroke();
    });
  };
}

function bars(round) {
  return (context, data, options) => {
    context.fillStyle = options.waveColor;

    var waveHeight = options.waveBottom - options.waveTop;

    var baseline = options.waveTop + waveHeight / 2;

    var barX = d3
      .scaleBand()
      .paddingInner(0.5)
      .paddingOuter(0)
      .domain(d3.range(data.length))
      .range([options.waveLeft, options.waveRight]);

    var height = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, waveHeight / 2]);

    var barWidth = barX.bandwidth();

    data.forEach(function(val, i) {
      var h = height(val[0]) * 2,
        x = barX(i),
        y = baseline - height(val[0]);

      context.fillRect(x, y, barWidth, h);

      if (round) {
        context.beginPath();
        context.arc(x + barWidth / 2, y, barWidth / 2, 0, 2 * Math.PI);
        context.moveTo(x + barWidth / 2, y + h);
        context.arc(x + barWidth / 2, y + h, barWidth / 2, 0, 2 * Math.PI);
        context.fill();
      }
    });
  };
}

function bricks(rainbow) {
  return function(context, data, options) {
    data = movingAvg(
      data.map(function(d) {
        return Math.sqrt(d[0]);
      }),
      3
    );
    data.splice(0, 3);
    data = data.filter((d, i) => i % 6 === 0);

    context.fillStyle = options.waveColor;
    context.shadowColor = options.waveColor;
    context.shadowBlur = 2;

    var waveHeight = options.waveBottom - options.waveTop;

    var barX = d3
      .scaleBand()
      .paddingInner(0.1)
      .paddingOuter(0.01)
      .domain(d3.range(data.length))
      .range([options.waveLeft, options.waveRight]);

    var height = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, waveHeight]);

    var barWidth = barX.bandwidth(),
      brickHeight = 10,
      brickGap = 3,
      maxBricks = Math.max(1, Math.floor(waveHeight / (brickHeight + brickGap)));

    data.forEach(function(val, i) {
      function valueMapping(input) {
        return 1 - Math.pow(1 - input, 1);
      }
      var bricks = Math.max(1, Math.floor(height(valueMapping(val)) / (brickHeight + brickGap))),
        x = barX(i);

      d3.range(bricks).forEach(function(b) {
        if (rainbow) {
          context.fillStyle = d3.interpolateWarm(1 - (b + 1) / maxBricks);
        }
        const bHeightRatio = Math.max(0.6, 1 - b * (1 / maxBricks) * 0.8);
        context.globalAlpha = bHeightRatio;
        context.fillRect(x, options.waveBottom - brickHeight * (b + 1) - brickGap * b, barWidth, brickHeight * bHeightRatio);
        context.globalAlpha = 1;
      });
    });
  };
}

function strokedPath(interpolator) {
  return (context, data, options) => {
    context.fillStyle = options.waveColor;
    context.strokeStyle = options.waveColor;
    context.lineWidth = 5;

    var line = d3.line().context(context);

    if (interpolator) {
      line.curve(interpolator);
    }

    var x = d3
      .scalePoint()
      .padding(0.1)
      .domain(d3.range(data.length))
      .range([options.waveLeft, options.waveRight]);

    var y = d3
      .scaleLinear()
      .domain([-1, 1])
      .range([options.waveBottom, options.waveTop]);

    var points = data.map(function(d, i) {
      return [x(i), y(d[1])];
    });

    // Fill waveform
    context.beginPath();
    line(points);
    context.stroke();
  };
}

function spacedBars(round) {
  return (context, data, options) => {
    // IF two colors, use gradient; else just single color
    if (options.waveColor2) {
      var gradient = context.createLinearGradient(0, 0, options.width, 0);
      gradient.addColorStop(0, options.waveColor);
      gradient.addColorStop(1, options.waveColor2);
      context.fillStyle = gradient;
    } else {
      context.fillStyle = options.waveColor;
    }
    var waveHeight = options.waveBottom - options.waveTop;
    var baseline = options.waveTop + waveHeight / 2;
    var barX = d3
      .scaleBand()
      .paddingInner(0.2)
      .paddingOuter(0.01)
      .domain(d3.range(data.length))
      .range([options.waveLeft, options.waveRight]);
    var height = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, waveHeight / 2]);
    var barWidth = barX.bandwidth();
    data.forEach(function(val, i) {
      if (i % 3 === 0) {
        var h = height(val[0]) * 2,
          x = barX(i),
          y = baseline - height(val[0]);
        context.fillRect(x, y, barWidth, h);
        if (round) {
          context.beginPath();
          context.arc(x + barWidth / 2, y, barWidth / 2, 0, 2 * Math.PI);
          context.moveTo(x + barWidth / 2, y + h);
          context.arc(x + barWidth / 2, y + h, barWidth / 2, 0, 2 * Math.PI);
          context.fill();
        }
      }
    });
  };
}

function downwardBars() {
  return (context, data, options) => {
    // IF two colors, use gradient; else just single color
    if (options.waveColor2) {
      var gradient = context.createLinearGradient(0, 0, options.width, 0);
      gradient.addColorStop(0, options.waveColor);
      gradient.addColorStop(1, options.waveColor2);
      context.fillStyle = gradient;
    } else {
      context.fillStyle = options.waveColor;
    }
    var waveHeight = options.waveBottom - options.waveTop;
    var barX = d3
      .scaleBand()
      .paddingInner(0.1)
      .paddingOuter(0)
      .domain(d3.range(data.length))
      .range([options.waveLeft, options.waveRight]);
    var height = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, waveHeight]);
    var barWidth = barX.bandwidth(),
      brickHeight = 10,
      brickGap = 3,
      maxBricks = Math.max(1, Math.floor(waveHeight / (brickHeight + brickGap)));
    data.forEach(function(val, i) {
      var bricks = Math.max(1, Math.floor(height(val[0]) / (brickHeight + brickGap))),
        x = barX(i);
      d3.range(bricks).forEach(function(b) {
        context.fillRect(x, options.waveTop + brickHeight * (b + 1) + brickGap * b, barWidth, brickHeight);
      });
    });
  };
}

function progressiveBars() {
  return (context, data, options) => {
    // IF two colors, use gradient; else just single color
    if (options.waveColor2) {
      var gradient = context.createLinearGradient(0, 0, options.width, 0);
      gradient.addColorStop(0, options.waveColor);
      gradient.addColorStop(1, options.waveColor2);
      context.fillStyle = gradient;
    } else {
      context.fillStyle = options.waveColor;
    }
    var numBars = 20;
    var waveHeight = options.waveBottom - options.waveTop;
    var baseline = options.waveTop + waveHeight / 2;
    var total = d3.sum(data, function(d) {
      return d[0];
    });
    var fillCapacityRatio = total / data.length;
    var currentScale = 1;
    var decayFactor = 0.7;
    var scaleFactor = [];
    for (var ind = 1; ind <= numBars; ind++) {
      var threshold = 1 - (1 - fillCapacityRatio * 3);
      if (ind + 1 >= threshold * numBars) {
        currentScale *= decayFactor;
      }
      scaleFactor.push(currentScale);
    }
    var interpolation = function(x) {
      // defined on x = 0 to 1
      return 1 - Math.sqrt(Math.pow(x, 3));
    };
    var progressiveBarData = d3.range(numBars).map(function(d, i) {
      return scaleFactor[i] * interpolation(d / numBars);
    });

    var barX = d3
      .scaleBand()
      .paddingInner(0.15)
      .paddingOuter(0.01)
      .domain(d3.range(progressiveBarData.length))
      .range([options.waveLeft, options.waveRight]);
    var height = d3
      .scaleLinear()
      .domain([0, 1])
      .range([0, waveHeight / 2]);
    var barWidth = barX.bandwidth();
    progressiveBarData.forEach(function(val, i) {
      var h = height(val) * 2,
        x = barX(i),
        y = baseline - height(val);
      context.fillRect(x, y, barWidth, h);
    });
  };
}

function ripple() {
  return function(context, data, options) {
    //data
    data = movingAvg(
      data.map(function(d) {
        return Math.sqrt(d[0]);
      }),
      3
    );
    data.splice(0, 3);
    data = data.filter((d, i) => i % 6 === 0);

    //fill
    context.fillStyle = options.waveColor;
    context.strokeStyle = options.waveColor;
    context.lineWidth = 1.5;
    //variables
    var waveHeight = options.waveBottom - options.waveTop;
    waveHeight = waveHeight / 60;
    var allpoints = [
      [
        [0, 0],
        [2, 0],
        [3, 0],
        [4, 0],
        [5, 0],
        [6, 0],
        [7, 0],
        [8, 0],
        [9, 0],
        [10, 0],
        [11, 0],
        [12, 0],
        [13, 0],
        [14, 0],
        [15, 0],
        [16, 0],
      ],
    ];
    var points = [];
    var yp = 2;
    var wavenum = 1;
    const smallPeakRatio = Math.min(1, data[0] * 0.05);
    const largePeakRatio = Math.min(1, data[1] * 0.08);
    const xlargePeakRatio = Math.min(1, data[2] * 0.1);
    const smallPeakSize = waveHeight * smallPeakRatio;
    const largePeakSize = waveHeight * largePeakRatio;
    const xlargePeakSize = waveHeight * xlargePeakRatio;

    //create data for single line
    for (var x = 0; x < 17; x++) {
      var point = [x, 0];
      if (x == yp) {
        yp = yp + 3;
        switch (wavenum) {
          case 1:
            point[1] = smallPeakSize;
            break;
          case 2:
            point[1] = largePeakSize;
            break;
          case 3:
            point[1] = xlargePeakSize;
            break;
          case 4:
            point[1] = largePeakSize;
            break;
          case 5:
            point[1] = smallPeakSize;
            break;
        }
        wavenum++;
      }
      points.push(point);
    }

    // console.log(points);

    allpoints.push(points);
    //create 12 lines data based on line above
    //with space depending on height of wave
    for (var l = 1; l <= 12; l++) {
      var npoints = [];
      var wavenum = 1;
      for (var x = 0; x < 17; x++) {
        var npoint = [points[x][0], points[x][1]];
        if (points[x][1] != 0) {
          switch (wavenum) {
            case 1:
              npoint[1] = npoint[1] + (npoint[1] / 3) * l;
              break;
            case 2:
              npoint[1] = npoint[1] + (npoint[1] / 2) * l;
              break;
            case 3:
              npoint[1] = npoint[1] + npoint[1] * l;
              break;
            case 4:
              npoint[1] = npoint[1] + (npoint[1] / 2) * l;
              break;
            case 5:
              npoint[1] = npoint[1] + (npoint[1] / 3) * l;
              break;
          }
          wavenum++;
        }
        npoints.push(npoint);
      }
      allpoints.push(npoints);
    }
    //scale of based on height and data
    var xScale = d3
      .scaleLinear()
      .domain([0,16])
      .range([options.waveLeft, options.waveRight]);

    //var maxYScale = xlargePeakSize + xlargePeakSize * 7;
    var yScale = d3
      .scaleLinear()
      .domain([0, 4])
      .range([options.waveBottom, options.waveTop]);

    //generate line data
    var generateline = d3
      .line()
      .x(p => xScale(p[0]))
      .y(p => yScale(p[1]))
      .curve(d3.curveBasis)
      .context(context);

    //add lines to context
    for (var l = 0; l < 12; l++) {
      context.beginPath();
      generateline(allpoints[l]);
      context.stroke();
    }
  };
}

export default {
  wave: filledPath(d3.curveCardinal.tension(0.1)),
  pixel: filledPath(d3.curveStep),
  roundBars: bars(true),
  bars: bars(),
  bricks: bricks(),
  equalizer: bricks(true),
  line: strokedPath(),
  curve: strokedPath(d3.curveCardinal.tension(0.1)),
  spacedBars: spacedBars(),
  roundSpacedBars: spacedBars(true),
  downwardBars: downwardBars(),
  progressiveBars: progressiveBars(),
  circlePulse: circlePulse(),
  circleWaves: circleWaves(),
  rectangleBorder: rectangleBorder(),
  seaUrchin: seaUrchin(),
  siri: siri(),
  vinyl: vinyl(),
  blob: blob(),
  ripple: ripple(),
};
