<template>
  <div @click="dismissPopover">
    <loader v-if="isCreating || !user" :progressLabel="progressLabel" :description="progressDescription"
      :title="progressTitle"></loader>
    <div v-else>
      <!-- <section class="section has-small-padding-bottom">
        <div class="container">
          h1 class="is-size-2 has-text-weight-bold">Create</h1>
          <div v-if="true">
            <p v-if="true" class="tip primary">
              <span class="has-text-weight-bold">You have {{ user.timeLeftString }} of video time remaining</span>
              <br />
              <span>
                Your next month begins on {{ user.videoPlan.nextMonthStartDateString }}. Consider
                <router-link class="has-text-weight-bold" :to="{ name: 'pricing' }">upgrading your plan</router-link>
              </span>
            </p>
            <p v-if="true" class="tip danger">
              <span class="has-text-weight-bold">
                You are out of video time this month.
                <router-link class="has-text-weight-bold" :to="{ name: 'plan' }">See usage details.</router-link>
              </span>
              <br />
              <span>
                Your next month begins on {{ user.videoPlan.nextMonthStartDateString }}.
                <router-link class="has-text-weight-bold" :to="{ name: 'pricing' }">Upgrade your plan</router-link>
              </span>
            </p>
          </div>
        </div>
      </section> -->
      <div>
        <section class="section has-padding-top">
          <div class="container">
            <h2 class="pagetitle is-size-2 has-text-weight-bold has-large-margin-top">Create Wavve Video</h2>
          </div>
        </section>
        <div class="container">
          <section class="section has-no-padding-vertical is-clearfix">
            <!-- <span v-touch:swipe="swipeHandlerStep"> -->
            <el-tabs v-model="stepTabs" :tab-position="tabPosition" :stretch="true" :before-leave="handleTabChange">
              <!-- step 1 -->
              <el-tab-pane name="step1" ref="createStep1" v-bind:disabled="stepTabs === 'step3'">
                <span id="createSelectAudioStep" slot="label" v-if="stepTabs === 'step1'"
                  class="has-text-weight-semibold">
                  1. Select Your Audio
                  <span v-if="isStep1Disabled" class="fa fa-check-circle has-small-margin-left">
                  </span>
                </span>
                <span slot="label" v-else>
                  1. Select Your Audio
                  <span v-if="isStep1Disabled" class="fa fa-check-circle has-small-margin-left">

                  </span>
                </span>

                <section class="has-large-margin-top">
                  <div class="is-fullwidth has-small-padding-bottom ">
                    <audio-picker :fromEditor="fromEditor"></audio-picker>
                  </div>
                </section>
              </el-tab-pane>
              <!-- end step 1 -->
              <!-- step 2 -->
              <el-tab-pane name="step2" ref="createStep2" v-bind:disabled="stepTabs === 'step3' || stepTabs === 'step1'">
                <span id="createSelectDesignStep" slot="label" v-if="stepTabs === 'step2'"
                  class="has-text-weight-semibold">
                  2. Select Your Design
                  <span v-if="selectedDesign" class="fa fa-check-circle has-small-margin-left">
                  </span>
                </span>
                <span slot="label" v-else>
                  2. Select Your Design
                  <span v-if="selectedDesign" class="fa fa-check-circle has-small-margin-left">
                  </span>
                </span>

                <section class="section">
                  <div class="is-fullwidth">
                    <design-picker v-bind:selectedDesign.sync="selectedDesign"></design-picker>
                  </div>
                </section>
                <!-- <div class="is-fullwidth">
                      <el-button class="has-margin-right has-small-margin-top" type="primary" v-on:click="navTo('step3')" style="float: right">
                        <b v-if="!selectedDesign && stepTabs === 'step2'">Next Step</b>
                        <b v-if="selectedDesign && stepTabs === 'step2'">Next Step</b>
                        <i class="el-icon-right"
                      /></el-button>
                    </div> -->
              </el-tab-pane>
              <!-- end step 2 -->
              <!--Step 3 -->
              <el-tab-pane label="3. Create Clip" name="step3" ref="createStep3" v-bind:disabled="stepTabs !== 'step3'">
                <span id="createClipStep" slot="label" class="has-text-weight-semibold" v-if="stepTabs === 'step3'">
                  3. Create Clip
                </span>
                <span slot="label" v-else>
                  3. Create Clip
                </span>

                <section class="section" v-if="stepTabs === 'step3'">
                  <div class="column has-large-margin-bottom" v-loading="!audioReady || !waveformReady"
                    :element-loading-text="loadingText">

                    <div v-if="audioReady">
                      <audio-viewer-wavesurfer :key="this.episodeInfo.guid || 'wavesurfer'"></audio-viewer-wavesurfer>
                    </div>
                  </div>

                  <div v-if="audioReady" style=" margin-top:-40px">
                  </div>
                  <div style="text-align:center; width: 100%;vertical-align:top; margin-top:0px">

                    <div style="text-align:left; margin:20px;width:350px;  display:inline-block;vertical-align:top">
                      <small>Video Name</small>
                      <!-- <el-input  v-model="video_title" style="text-align:center"></el-input> -->

                      <el-autocomplete id="videoTittleInput" :popper-append-to-body="false" class="inline-input"
                        v-model="video_title" :fetch-suggestions="querySearch"></el-autocomplete>
                      <!-- placeholder="Please Input" -->
                      <!-- @select="handleSelect" -->
                    </div>


                    <div
                      style="min-width:100%;height:400px;margin:20px;margin-top:40px;display:inline-block;;vertical-align:top;">
                      <design-animated-preview :ref="selectedDesign.id" v-if="selectedDesign"
                        :design="selectedDesign"></design-animated-preview>
                    </div>
                  </div>
                  <div class="container" style="margin-top:-50px;margin-bottom:-50px">
                    <div class="columns is-multiline is-desktop is-full-desktop">
                      <div class="column has-text-centered"></div>
                    </div>
                  </div>
                  <div class="container has-text-centered">
                    <div class="columns is-multiline is-desktop">
                      <div class="column is-one-quarter-fullhd is-full-desktop is-hidden-touch"></div>
                    </div>
                  </div>
                </section>
              </el-tab-pane>
              <!-- End Step 3 -->
            </el-tabs>
            <!-- </span> -->
          </section>
        </div>

        <section class="section" style=margin-top:20px;padding-top:0px>
          <div class="container has-text-centered">
            <el-button id="videoConfirmAudioButton" v-if="stepTabs === 'step1'" :disabled="!isStep1Disabled" round
              class=" has-small-margin-top generate-button" type="primary" @click="goToDesignSelection">
              <b>Confirm audio</b>
              {{ ' ' }}<i class="el-icon-right" /></el-button>

            <el-button id="videoConfirmDesignButton" :disabled="!selectedDesign"
              v-if="stepTabs === 'step2' && isStep1Disabled" round class=" has-small-margin-top generate-button"
              type="primary" @click="navTo('step3')">
              <b>Confirm design</b>
              {{ ' ' }}<i class="el-icon-right" /></el-button>

            <!-- <el-popover trigger="hover">
              <el-button
                slot="reference"
                :disabled="!isStep1Disabled"
                v-if="stepTabs === 'step2' && !isStep1Disabled"
                class=" has-small-margin-top generate-button"
                type="primary"
                @click="navTo('step3')"
              >
                <b>Confirm design</b>
                {{ ' ' }}<i class="el-icon-right"
              /></el-button>
              <p>Hey!sss</p>
            </el-popover> -->

            <el-popover placement="top" trigger="hover"
              content="Please select and confirm your audio in the previous step before proceeding"
              v-if="stepTabs === 'step2' && !isStep1Disabled">
              <el-button slot="reference" v-if="stepTabs === 'step2' && !isStep1Disabled"
                class=" has-small-margin-top generate-button looks-disabled" type="primary">
                <b>Confirm design</b>
                {{ ' ' }}<i class="el-icon-right" /></el-button>
            </el-popover>
            <el-button id="videoGenerateVideoButton" v-if="stepTabs === 'step3'"
              class=" has-small-margin-top generate-button" type="primary" @click="createJob" :disabled="!audioReady">
              <b>Generate Video</b>
              {{ ' ' }}<i class="el-icon-right" /></el-button>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint no-var: off */
import { v4 as uuidv4 } from 'uuid';
import EventBus from '@/helpers/event-bus';
import UserService from '@/api/user.service';
import DesignPicker from '@/components/Create/DesignPicker.vue';
import AudioPicker from '@/components/Create/AudioPicker.vue';
import DesignAnimatedPreview from '@/components/Create/DesignAnimatedPreview.vue';
import Loader from '@/components/Loader.vue';
import {
  SET_EDITING_JOB,
  JOB_CREATE,
  CLEAR_AUDIO_FILE,
  START_AUDIO_PROCESSING,
  STOP_AUDIO_PROCESSING,
  SET_POPOVER_DISMISSED,
  SET_MEDIA,
  SET_SELECTED_THEME
} from '@/store/action-types';
import Vue from 'vue';
import Vue2TouchEvents from 'vue2-touch-events';
import AudioViewerWavesurfer from '@/components/Create/AudioViewerWavesurfer.vue';

var checkInterval = null;
Vue.use(Vue2TouchEvents, {
  disableClick: true,
  touchClass: '',
  tapTolerance: 10,
  touchHoldTolerance: 400,
  swipeTolerance: 30,
  longTapTimeInterval: 400,
  namespace: 'touch',
});

const generateUuid = () => uuidv4();

export default {
  name: 'Create',
  components: {
    DesignPicker,
    AudioPicker,
    Loader,
    DesignAnimatedPreview,
    AudioViewerWavesurfer,
  },
  data() {
    return {
      video_title: "My Video Title",
      selectedDesign: null,
      start: 0,
      end: 0,
      loading: null,
      detailedStatus: null,
      progressLabel: undefined,
      progressDescription: undefined,
      progressTitle: undefined,
      isCreating: false,
      stepTabs: 'step1',
      tabPosition: 'top',
      isStep1Disabled: false,
      isStep2AndStep3Disabled: true,
      episodeInfo: null,
      waveformReady: false,
      loadingText: `Wavve is preparing your audio.`,
      fromEditor: false,
    };
  },
  async beforeRouteLeave(to, _, next) {
    if (to.name !== 'editor') {
      if (!(to.query.changingTeam === 'true') && !this.isCreating) {
        const confData = { confirmButtonText: 'Yes', cancelButtonText: 'No', type: 'info' };

        try {
          await this.$confirm('Are you sure you want to leave the page without saving?', 'Warning', confData);
        } catch {
          return false;
        }
      }

      await this.$store.dispatch(CLEAR_AUDIO_FILE);
      await this.$store.dispatch(SET_MEDIA, null);
    }
    next();
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name === 'editor') {
        vm.stepTabs = 'step2';
        vm.fromEditor = true;
      }
    });
  },
  watch: {
    stepTabs(newValue) {
      EventBus.$emit('onStepTabsChange', newValue);

      // if (oldValue === 'step1') {
      //   if (!this.$store.state.selectedMedia && this.episodeInfo) {
      //     this.startAudioProcessing();
      //   }
      // }
    },
  },

  created() {
    // this.selectedDesign = this.$route.query.theme ? this.$route.query.theme : null
    EventBus.$on('updated:startTime', val => {
      this.start = val;
    });
    EventBus.$on('updated:endTime', val => {
      this.end = val;
    });
    if (this.$route.query.design) {
      this.selectedDesign = this.$store.getters.getDesignById(this.$route.query.design) || null;

      if (this.episodeInfo) {
        this.isStep1Disabled = true;
        this.navTo('step2');
      }
    }
    this.$store.dispatch(SET_EDITING_JOB, {
      job: null,
    });

    this.forceLoadNewState();

    // EventBus.$on('update:selectedDesign', data => { this.selectedDesign=data });
    EventBus.$on('setEnableStep2', this.enableStep2);
    EventBus.$on('SelectedEpisodeChange', ep => this.onSelectedEpisodeChange(ep));
    EventBus.$on('setWaveformReady', val => this.setWaveformReady(val));
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    audioReady() {
      return this.$store.state.audioReady;
    },
    selectedAudio() {
      return this.$store.state.audioDataUrl;
    },

    showStartingHint() {
      return this.user.noJobs;
    },
    showRewardsHint() {
      return this.user.subscribedNoJobs && !this.user.stinger;
    },
    audioDuration() {
      return this.end - this.start;
    },
    decoding() {
      return this.$store.state.audioBufferDecoding;
    },
    audioType() {
      return this.$store.state.audioType;
    },
    selectedTheme() {
      return this.$store.state.selectedTheme;
    },
    stateSelectedEpisode() {
      return this.$store.state.selectedEpisode;
    },
    randomAdjective() {
      return Math.floor(Math.random() * this.adjective.length);
    },
  },
  methods: {
    querySearch(queryString, cb) {
      let sug = [];
      let data = Date.toString()
      var dt = new Date();
      let year = dt.getFullYear();
      let month = (dt.getMonth() + 1).toString().padStart(2, "0");
      let sm = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
      let day = dt.getDate().toString().padStart(2, "0");

      sug.push({ value: `${this.video_title} - ${sm[dt.getMonth()]} ${day} - ${year}` })
      sug.push({ value: `${this.video_title} - ${sm[dt.getMonth()]} ${day}` })
      sug.push({ value: `${this.video_title} - ${month}-${day}-${year}` })
      if (this.episodeInfo.author) sug.push({ value: `${this.video_title} by ${this.episodeInfo.author}` })
      if (this.episodeInfo.episodeNumber) sug.push({ value: `#${this.episodeInfo.episodeNumber} - ${this.video_title}` })
      if (this.episodeInfo.episodeNumber) sug.push({ value: `Episode #${this.episodeInfo.episodeNumber} - ${this.video_title}` })
      if (this.episodeInfo.episodeNumber && this.episodeInfo.seasonNumber) sug.push({ value: `Episode #${this.episodeInfo.episodeNumber} , Season #${this.episodeInfo.seasonNumber} - ${this.video_title}` })
      if (this.episodeInfo.title) sug.push({ value: this.episodeInfo.title })
      if (this.episodeInfo.author) sug.push({ value: this.episodeInfo.author })
      // var results = queryString ? sug.filter(this.createFilter(queryString)) : sug;
      // call callback function to return suggestions
      // console.log(sug)


      cb(sug);
    },
    createFilter(queryString) {
      return (link) => {
        return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    title_suggestions() {

    },
    setWaveformReady(val) {
      this.waveformReady = val;
    },
    onSelectedEpisodeChange(ep) {
      this.isStep1Disabled = true;
      this.episodeInfo = ep;
    },
    dismissPopover() {
      this.$store.dispatch(SET_POPOVER_DISMISSED, true);
    },
    enableStep2() {
      this.isStep2AndStep3Disabled = false;
      this.isStep1Disabled = true;
    },
    navTo(gotToID) {
      this.stepTabs = gotToID;
    },
    handleTabChange(activeName, oldActiveName) {
      if (oldActiveName === 'step2' && activeName === 'step1') {
        this.selectedDesign = null;
        this.$store.dispatch(SET_SELECTED_THEME, null).catch();
      }

      return true;
    },
    swipeHandlerStep(direction) {
      this.dismissPopover();
      if (direction === 'left' && this.stepTabs === 'step2') {
        this.navTo('step3');
      }
      if (direction === 'left' && this.stepTabs === 'step1') {
        if (this.isStep1Disabled) {
          this.navTo('step2');
        }
      }
      if (direction === 'right' && this.stepTabs === 'step2') {
        this.navTo('step1');
      }
      if (direction === 'right' && this.stepTabs === 'step3') {
        this.navTo('step2');
      }
    },

    async forceLoadNewState() {
      await this.$store.dispatch('SYNC_USER_TO_DB');
    },

    scrollToDesignPicker() {
      window.scrollTo({
        top: 100,
        left: 0,
        behavior: 'smooth',
      });
    },

    // async toggleBrandingOn() {
    //   let message = 'Wavve Branding has been enabled.';
    //   try {
    //     await UserService.updateStingerPreference(this.user.id, {
    //       stinger: true,
    //     });
    //     await this.$store.dispatch('SYNC_USER_TO_DB');
    //     this.$message.success({
    //       message,
    //     });
    //   } catch (err) {
    //     message = err;
    //     this.$message.error({
    //       message,
    //     });
    //   }
    // },

    async clearAudio(next) {
      try {
        const options = {
          confirmButtonText: 'Continue',
          cancelButtonText: 'Stay Here',
        };
        await this.$confirm(`You will lose your audio file and will need to re-upload it when you return.`, 'Warning: Unsaved Changes', options);
        await this.$store.dispatch('CLEAR_AUDIO_FILE');
        next();
      } catch (err) {
        console.log('Error:', err);
      }
    },

    async createJob() {
      this.progressTitle = 'Generating your video. Please keep this tab open while the video is generating.';
      this.progressDescription = 'This should take 20-45 seconds depending on your connection.';

      const duration = this.end - this.start;
      // verify that duration isn't zero
      if (duration === 0) {
        this.$notify.error({
          title: 'No Audio Selected',
          message: 'You must choose a duration greater than 3 seconds.',
        });
        return;
      }
      // if not unlimited and customer has exceed their 1 minute overage
      // if (!this.user.planIsUnlimited && (this.user.timeLeft - duration < -1 * 60 || this.user.timeLeft < 0)) {
      //   try {
      //     await this.$confirm(`You have ${this.user.timeLeftString} of video time remaining.`, `You don't have enough time`, {
      //       confirmButtonText: 'Upgrade',
      //       cancelButtonText: 'Cancel',
      //       type: 'info',
      //     });
      //     this.$router.push('/pricing');
      //   } catch (e) {
      //     this.$notify.info({
      //       title: 'Info',
      //       message: 'Please trim your video to a shorter duration.',
      //     });
      //   } finally {
      //     this.isCreating = false;
      //   }
      // } else {
      this.isCreating = true;

      const uuid = generateUuid();

      const job = {
        uuid,
        start: +this.start.toFixed(2),
        end: +this.end.toFixed(2),
        title: this.video_title,
        themeId: this.selectedDesign.id,
        isDemoTheme: this.user.themes.length === 0, // automatically demo theme if user has no themes,
        episodeInfo: this.episodeInfo,
        editCount: 0,
      };
      console.log('job to be created', job);
      await this.$store.dispatch(JOB_CREATE, job);
      this.progressLabel = undefined;
      this.$router.push('/videos');
      // this.detailedStatus = this.$store.getters.
      // }
    },

    async goToDesignSelection() {
      this.navTo('step2');
      this.startAudioProcessing();
    },

    async startAudioProcessing() {
      await this.$store.dispatch(STOP_AUDIO_PROCESSING, {});
      await this.$store.dispatch(SET_MEDIA, null);
      // console.log('start audio processing', this.episodeInfo);

      // console.log('this.episodeInfo.guid', this.episodeInfo.guid);

      const uuid = this.episodeInfo.guid || generateUuid();
      const audioData = {
        ...this.episodeInfo,
        uuid,
        processingStartDate: new Date(),
      };

      // console.log('audioData', audioData);

      await this.$store.dispatch(START_AUDIO_PROCESSING, audioData);
      if (checkInterval && checkInterval != null && checkInterval !== undefined) {
        clearInterval(checkInterval);
      }
      checkInterval = setInterval(() => {
        const threeMinutes = 180000; // in ms
        const currentDate = Date.now();
        const mediaProcessingDate = (this.$store.state.selectedMedia && this.$store.state.selectedMedia.dateAdded)
          ? new Date(this.$store.state.selectedMedia.dateAdded).getTime()
          : currentDate;
        const progressPercentage = (((currentDate - mediaProcessingDate) / threeMinutes) * 100).toFixed(2);

        this.loadingText = `Wavve is preparing your audio. ${progressPercentage}%`;

        if (this.waveformReady) {
          clearInterval(checkInterval);
          this.loadingText = 'Wavve is preparing your audio.';
        }

        if (currentDate - mediaProcessingDate > threeMinutes) {
          clearInterval(checkInterval);
          this.loadingText = `Wavve is preparing your audio. If still loading after 3 minutes, contact support@wavve.co or chat for support.`;
        }
      }, 100);

      this.step2 = 'unlocked';

      // TODO: BELOW IS LEGACY CODE. REMOVE IF VIDEO CREATION IS STABLE.
      // this.isCreating = true;

      // // verify that duration isn't zero
      // if (!this.episodeInfo) {
      //   this.$notify.error({
      //     title: 'No Audio Selected',
      //     message: 'You must choose a duration greater than 3 seconds.',
      //   });
      //   return;
      // }

      // console.log('this.user.timeLeft', this.user.timeLeft);
      // // if not unlimited and customer has exceed their 1 minute overage
      // if (!this.user.planIsUnlimited && this.user.timeLeft < 0) {
      //   try {
      //     await this.$confirm(`You have ${this.user.timeLeftString} of video time remaining.`, `You don't have enough time`, {
      //       confirmButtonText: 'Upgrade',
      //       cancelButtonText: 'Cancel',
      //       type: 'info',
      //     });
      //     this.$router.push('/pricing');
      //   } catch (e) {
      //     this.$notify.info({
      //       title: 'Info',
      //       message: 'Please trim your video to a shorter duration.',
      //     });
      //   } finally {
      //     this.isCreating = false;
      //   }
      // } else {
      //   const uuid = generateUuid();

      //   this.progressTitle = 'Processing your audio. Please keep this tab open while we get your audio ready.';
      //   console.log('this.selectedDesign');
      //   const audioToBeProcessed = {
      //     ...this.episodeInfo,
      //     uuid,
      //   };
      // // console.log('job to be created', job);
      // await this.$store.dispatch(START_AUDIO_PROCESSING, audioToBeProcessed);

      // setTimeout(async () => {
      //   this.progressLabel = undefined;
      //   this.isCreating = false;
      //   this.stepTabs = 'step3';
      //   await this.$store.dispatch(SET_AUDIO_FILE_FROM_REMOTE_URL, audioToBeProcessed);
      // }, 5000);

      // this.$router.push('/videos');
      // this.detailedStatus = this.$store.getters.
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.pageTitle {
  font-size: 24px;
  font-weight: bold;
}

.leftButton {
  margin-left: auto;
  margin-right: auto;
}

.rightButton {
  margin-left: auto;
  margin-right: auto;
}

.buttonCenter {
  margin: auto;

  width: 50%;
}

.generate-button {
  width: 60%;
  padding-top: 1.05em;
  padding-bottom: 1.05em;
  font-size: 1.1em;

  &.looks-disabled {
    opacity: 0.6;
  }
}

.has-text-danger {
  font-weight: bold;
}

.pulse {
  border-radius: 5%;
  animation: pulse-animation 1s infinite;
}

@keyframes pulse-animation {
  0% {
    padding: 4px;
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    padding: 4px;
    box-shadow: 0 0 0 5px rgba(0, 247, 255, 0.219);
    transform: scale(0.25);
  }
}
</style>
<style>
.el-autocomplete-suggestion__list {
  min-width: 500px !important;
  overflow-x: scroll !important;
  ;
}
</style>
