<template>
  <div id="app">
    <link rel="stylesheet" href="https://fonts.googleapis.com/css?family=Lora" />
    <component :is="layout">
      <div v-if="!hideAllNav && routerIsReady">
        <div class="sidebar-container" v-if="!transcriptionNavbar">
          <the-sidebar></the-sidebar>
        </div>
        <div class="main-container">
          <div v-if="!hideMobileNav && !transcriptionNavbar" class="the-navbar">
            <the-navbar></the-navbar>
          </div>
          <div v-if="transcriptionNavbar" class="the-navbar" style="z-index: 30 !important">
            <the-navbar-transcription></the-navbar-transcription>
          </div>
          <div style="width: 100%" v-if="!editorNavBat && !transcriptionNavbar">
            <div style="width: 100%" v-if="this.teamStripePlan?.planName && !isTeamAdminOrOwner">
              <div class="trialWrapper" style="width: 100%" v-if="this.teamStripePlan.trialEndAt && this.teamStripePlan.active">
                <div class="trialCard" style="width: 400px">
                  <el-progress
                    :stroke-width="5"
                    :width="60"
                    type="circle"
                    :percentage="teamTrialEndPercentage"
                    :color="colors"
                    :format="() => teamTrialEndDaysLeft"
                  >
                  </el-progress>
                  <div class="has-text-weight-bold has-margin-left trialText" style="color: white">Days of free trial left on your team plan.</div>
                </div>
              </div>
              <div v-if="!this.teamStripePlan.active" class="noPlanWrapper" style="width: 100%">
                <div class="noPlanCard">
                  <div class="has-text-weight-bold has-margin-left noPlanText" style="color: white">
                    Your team is inactive.<br />To access your content, contact the account owner.
                  </div>
                </div>
              </div>
              <div v-if="this.teamStripePlan.active && this.teamStripePlan.isPausePlan" class="noPlanWrapper" style="width: 100%">
                <div class="noPlanCard" style="width: 550px">
                  <div class="has-text-weight-bold has-margin-left noPlanText" style="color: white">
                    Your team is on a pause plan.<br />To access your content, contact the account owner.
                  </div>
                </div>
              </div>
              <div
                v-if="this.teamStripePlan.active && this.teamStripePlan.isCanceled && !this.teamStripePlan.isPausePlan"
                class="noPlanWrapper"
                style="width: 100%"
              >
                <div class="noPlanCard" style="width: 550px">
                  <el-progress :stroke-width="5" :width="60" type="circle" :percentage="25" :color="colors" :format="() => canceledDaysLeft"> </el-progress>
                  <div class="has-text-weight-bold has-margin-left noPlanText" style="color: white">Days left of Unlimited Plan on your Team.</div>
                </div>
              </div>
            </div>
            <div style="width: 100%" v-else>
              <div
                v-if="this.stripePlan.active && !this.stripePlan.isPausePlan && !this.stripePlan.isCanceled && this.stripePlan.collectionPause"
                class="trialWrapper"
                style="width: 100%"
              >
                <div class="trialCard">
                  <el-progress :stroke-width="5" :width="60" type="circle" :percentage="resumePercentage" :color="colors" :format="() => resumeDaysLeft">
                  </el-progress>
                  <div class="has-text-weight-bold has-margin-left trialText" style="color: white">
                    Days until billing resumes.<br />Resume to continue creating.
                  </div>
                  <el-button round class="trialButton has-margin-right" @click="goToBillingPage()">Resume now</el-button>
                </div>
              </div>
              <div v-if="this.stripePlan.isTrial && this.stripePlan.active" class="trialWrapper" style="width: 100%">
                <div class="trialCard">
                  <el-progress :stroke-width="5" :width="60" type="circle" :percentage="trialEndPercentage" :color="colors" :format="() => trialEndDaysLeft">
                  </el-progress>
                  <div class="has-text-weight-bold has-margin-left trialText" style="color: white">
                    Days of free trial left.
                    <span v-if="stripePlan.status !== 'trialing'">
                      <br />
                      Upgrade to continue creating.
                    </span>
                  </div>
                  <el-button round class="trialButton has-margin-right" v-if="stripePlan.status !== 'trialing'" @click="goToBillingPage()"
                    >Upgrade now</el-button
                  >
                  <el-button round class="trialButton has-margin-right" v-if="stripePlan.status === 'trialing'" @click="goToBillingPage()"
                    >Manage Subscription</el-button
                  >
                </div>
              </div>
              <div v-if="!this.stripePlan.active && !this.stripePlan.isCanceled" class="noPlanWrapper" style="width: 100%">
                <div class="noPlanCard">
                  <div class="has-text-weight-bold has-margin-left noPlanText" style="color: white" v-if="this.stripePlan.status === 'past_due'">
                    Your subscription is past due. Renew or Upgrade to save your work.
                  </div>
                  <div class="has-text-weight-bold has-margin-left noPlanText" style="color: white" v-else-if="stripePlan.status === 'incomplete'">
                    There was an error charging your subscription. <br />
                    Please update your billing information.
                  </div>
                  <div class="has-text-weight-bold has-margin-left noPlanText" style="color: white" v-else>
                    Your account is inactive. <br />To access your content, upgrade now.
                  </div>
                  <el-button v-if="this.stripePlan.status === 'past_due'" round class="noPlanButton" @click="goToBillingPage()">Renew</el-button>
                  <el-button v-else-if="this.stripePlan.status === 'incomplete'" round class="noPlanButton" @click="goToBillingPage()">Update</el-button>
                  <el-button v-else round class="noPlanButton" @click="goToBillingPage()">Upgrade</el-button>
                </div>
              </div>
              <div v-if="this.stripePlan.active && this.stripePlan.isPausePlan && stripePlan.status !== 'trialing'" class="noPlanWrapper" style="width: 100%">
                <div class="noPlanCard">
                  <div class="has-text-weight-bold has-margin-left noPlanText" style="color: white">
                    Your account is currently Paused.<br />To access your content again, click Resume.
                  </div>
                  <el-button round class="noPlanButton" @click="goToBillingPage()">Resume</el-button>
                </div>
              </div>
              <div v-if="this.stripePlan.active && this.stripePlan.isCanceled && !this.stripePlan.isPausePlan" class="noPlanWrapper" style="width: 100%">
                <div class="noPlanCard">
                  <el-progress :stroke-width="5" :width="60" type="circle" :percentage="25" :color="colors" :format="() => canceledDaysLeft"> </el-progress>
                  <div class="has-text-weight-bold has-margin-left noPlanText" style="color: white">
                    Days left of Unlimited Plan until cancellation.<br />Renew or Pause to save your work.
                  </div>
                  <el-button round class="noPlanButton" @click="handlePauseAccountDialog()">Pause</el-button>
                </div>
              </div>
            </div>
          </div>
          <router-view></router-view>
        </div>
      </div>
      <div v-else-if="routerIsReady">
        <router-view></router-view>
      </div>
    </component>
    <div v-if="!this.$route.fullPath.startsWith('/editor') && !hideAllNav">
      <br />
      &nbsp; <br />
      &nbsp; <br />
      &nbsp; <br />
      &nbsp; <br />
      &nbsp;
    </div>
    <el-dialog
      title="Additional Seats"
      :width="isMobile ? '90%' : '600px'"
      center
      class="additionals-seats"
      style="overflow: hidden"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :show-close="false"
      v-if="this.teams && team"
      :visible.sync="additionalSeatsDialog"
    >
      <div class="has-margin-bottom" style="display: flex; flex-direction: column; align-items: center">
        <p style="text-align: justify; font-size: 1em; color: black">
          It seems that your team has <b>{{ team.users.length >= team.seats ? team.users.length - 2 : team.seats - 3 }} additional paid seat(s)</b>. Each
          additional seat has a price of <b>$4.99/month.</b>
        </p>
      </div>
      <template #footer>
        <span class="dialog-footer has-margin-top" v-loading="loadingSeats">
          <el-button round plain icon="el-icon-delete" @click="deleteSeats">Delete Seat(s)</el-button>
          <el-button round type="primary" @click="addSeats"> Keep Seat(s) </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog :visible.sync="pauseAccountDialog" title="Pause Confirmation" :width="isMobile ? '90%' : '40%'" center class="additionals-seats">
      <template #title>
        <h5 class="is-size-5 has-text-weight-bold">Subscription Pause</h5>
      </template>
      <div>
        <h4 class="is-size-4 has-text-weight-bold">Would you rather pause instead?</h4>
        <p class="has-margin-top" style="text-align: justify; font-size: 1em; color: rgba(107, 114, 128) !important">
          Retain your current account as is for up to one year or until you are ready to create content again. Renews annually until canceled or upgraded to a
          Paid account again. Pause for only <b>$23.99/year</b>.
        </p>
        <div class="has-large-margin-top" style="display: flex; flex-direction: row; justify-content: center; align-items: center">
          <ul>
            <li class="has-margin-bottom" style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style="flex-shrink: 0; width: 1.5rem !important; color: #4d47e8; height: 1.5rem !important"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span style="line-height: 1.5rem !important; font-size: 1rem !important; margin-left: 25px"> Keep all videos created </span>
            </li>
            <li class="has-margin-bottom" style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style="flex-shrink: 0; width: 1.5rem !important; color: #4d47e8; height: 1.5rem !important"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span style="line-height: 1.5rem !important; font-size: 1rem !important; margin-left: 25px">Keep all transcriptions</span>
            </li>
            <li class="has-margin-bottom" style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style="flex-shrink: 0; width: 1.5rem !important; color: #4d47e8; height: 1.5rem !important"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span style="line-height: 1.5rem !important; font-size: 1rem !important; margin-left: 25px">Keep all designs</span>
            </li>
            <li class="has-margin-bottom" style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style="flex-shrink: 0; width: 1.5rem !important; color: #4d47e8; height: 1.5rem !important"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span style="line-height: 1.5rem !important; font-size: 1rem !important; margin-left: 25px">Keep all account settings, tags</span>
            </li>
            <li class="has-margin-bottom" style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style="flex-shrink: 0; width: 1.5rem !important; color: #4d47e8; height: 1.5rem !important"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span style="line-height: 1.5rem !important; font-size: 1rem !important; margin-left: 25px">Renews annually until you resume or cancel</span>
            </li>
            <li style="display: flex; flex-direction: row; justify-content: flex-start; align-items: center">
              <svg
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                style="flex-shrink: 0; width: 1.5rem !important; color: #4d47e8; height: 1.5rem !important"
              >
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
              </svg>
              <span style="line-height: 1.5rem !important; font-size: 1rem !important; margin-left: 25px"> Your team members will see account as paused </span>
            </li>
          </ul>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer has-margin-top">
          <div style="display: flex; justify-content: space-between; flex-wrap: nowrap; flex-direction: row; align-items: center">
            <el-button round plain class="has-large-margin-top" @click="pauseAccountDialog = false">Go back</el-button>
            <el-button round type="primary" class="has-large-margin-top" @click="pauseSubscription">Pause Subscription</el-button>
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { CONVERT_KIT_ENABLED, IS_PRODUCTION } from './config';

window.ChatraSetup = {
  name: 'Undefined',
};

if (IS_PRODUCTION) {
  (function (d, w, c) {
    w.ChatraID = 'fDvPuiM2cWnT957YE';
    // w.clientId=details.clientId;
    var s = d.createElement('script');
    w[c] =
      w[c] ||
      function () {
        (w[c].q = w[c].q || []).push(arguments);
      };
    s.async = true;
    s.src = 'https://call.chatra.io/chatra.js';
    if (d.head) d.head.appendChild(s);
  })(document, window, 'Chatra');
}

const cityCountry = {
  Andorra: 'Andorra',
  Dubai: 'United Arab Emirates',
  Kabul: 'Afghanistan',
  Tirane: 'Albania',
  Yerevan: 'Armenia',
  Casey: 'Antarctica',
  Davis: 'Antarctica',
  Mawson: 'Antarctica',
  Palmer: 'Antarctica',
  Rothera: 'Antarctica',
  Troll: 'Antarctica',
  Vostok: 'Antarctica',
  Buenos_Aires: 'Argentina',
  Cordoba: 'Argentina',
  Salta: 'Argentina',
  Jujuy: 'Argentina',
  Tucuman: 'Argentina',
  Catamarca: 'Argentina',
  La_Rioja: 'Argentina',
  San_Juan: 'Argentina',
  Mendoza: 'Argentina',
  San_Luis: 'Argentina',
  Rio_Gallegos: 'Argentina',
  Ushuaia: 'Argentina',
  Pago_Pago: 'Samoa (American)',
  Vienna: 'Austria',
  Lord_Howe: 'Australia',
  Macquarie: 'Australia',
  Hobart: 'Australia',
  Melbourne: 'Australia',
  Sydney: 'Australia',
  Broken_Hill: 'Australia',
  Brisbane: 'Australia',
  Lindeman: 'Australia',
  Adelaide: 'Australia',
  Darwin: 'Australia',
  Perth: 'Australia',
  Eucla: 'Australia',
  Baku: 'Azerbaijan',
  Barbados: 'Barbados',
  Dhaka: 'Bangladesh',
  Brussels: 'Belgium',
  Sofia: 'Bulgaria',
  Bermuda: 'Bermuda',
  Brunei: 'Brunei',
  La_Paz: 'Bolivia',
  Noronha: 'Brazil',
  Belem: 'Brazil',
  Fortaleza: 'Brazil',
  Recife: 'Brazil',
  Araguaina: 'Brazil',
  Maceio: 'Brazil',
  Bahia: 'Brazil',
  Sao_Paulo: 'Brazil',
  Campo_Grande: 'Brazil',
  Cuiaba: 'Brazil',
  Santarem: 'Brazil',
  Porto_Velho: 'Brazil',
  Boa_Vista: 'Brazil',
  Manaus: 'Brazil',
  Eirunepe: 'Brazil',
  Rio_Branco: 'Brazil',
  Thimphu: 'Bhutan',
  Minsk: 'Belarus',
  Belize: 'Belize',
  St_Johns: 'Canada',
  Halifax: 'Canada',
  Glace_Bay: 'Canada',
  Moncton: 'Canada',
  Goose_Bay: 'Canada',
  Toronto: 'Canada',
  Nipigon: 'Canada',
  Thunder_Bay: 'Canada',
  Iqaluit: 'Canada',
  Pangnirtung: 'Canada',
  Winnipeg: 'Canada',
  Rainy_River: 'Canada',
  Resolute: 'Canada',
  Rankin_Inlet: 'Canada',
  Regina: 'Canada',
  Swift_Current: 'Canada',
  Edmonton: 'Canada',
  Cambridge_Bay: 'Canada',
  Yellowknife: 'Canada',
  Inuvik: 'Canada',
  Dawson_Creek: 'Canada',
  Fort_Nelson: 'Canada',
  Whitehorse: 'Canada',
  Dawson: 'Canada',
  Vancouver: 'Canada',
  Cocos: 'Cocos (Keeling) Islands',
  Zurich: 'Switzerland',
  Abidjan: "Côte d'Ivoire",
  Rarotonga: 'Cook Islands',
  Santiago: 'Chile',
  Punta_Arenas: 'Chile',
  Easter: 'Chile',
  Shanghai: 'China',
  Urumqi: 'China',
  Bogota: 'Colombia',
  Costa_Rica: 'Costa Rica',
  Havana: 'Cuba',
  Cape_Verde: 'Cape Verde',
  Christmas: 'Christmas Island',
  Nicosia: 'Cyprus',
  Famagusta: 'Cyprus',
  Prague: 'Czech Republic',
  Berlin: 'Germany',
  Copenhagen: 'Denmark',
  Santo_Domingo: 'Dominican Republic',
  Algiers: 'Algeria',
  Guayaquil: 'Ecuador',
  Galapagos: 'Ecuador',
  Tallinn: 'Estonia',
  Cairo: 'Egypt',
  El_Aaiun: 'Western Sahara',
  Madrid: 'Spain',
  Ceuta: 'Spain',
  Canary: 'Spain',
  Helsinki: 'Finland',
  Fiji: 'Fiji',
  Stanley: 'Falkland Islands',
  Chuuk: 'Micronesia',
  Pohnpei: 'Micronesia',
  Kosrae: 'Micronesia',
  Faroe: 'Faroe Islands',
  Paris: 'France',
  London: 'Britain (UK)',
  Tbilisi: 'Georgia',
  Cayenne: 'French Guiana',
  Gibraltar: 'Gibraltar',
  Nuuk: 'Greenland',
  Danmarkshavn: 'Greenland',
  Scoresbysund: 'Greenland',
  Thule: 'Greenland',
  Athens: 'Greece',
  South_Georgia: 'South Georgia & the South Sandwich Islands',
  Guatemala: 'Guatemala',
  Guam: 'Guam',
  Bissau: 'Guinea-Bissau',
  Guyana: 'Guyana',
  Hong_Kong: 'Hong Kong',
  Tegucigalpa: 'Honduras',
  'Port-au-Prince': 'Haiti',
  Budapest: 'Hungary',
  Jakarta: 'Indonesia',
  Pontianak: 'Indonesia',
  Makassar: 'Indonesia',
  Jayapura: 'Indonesia',
  Dublin: 'Ireland',
  Jerusalem: 'Israel',
  Kolkata: 'India',
  Calcutta: 'India',
  Chagos: 'British Indian Ocean Territory',
  Baghdad: 'Iraq',
  Tehran: 'Iran',
  Reykjavik: 'Iceland',
  Rome: 'Italy',
  Jamaica: 'Jamaica',
  Amman: 'Jordan',
  Tokyo: 'Japan',
  Nairobi: 'Kenya',
  Bishkek: 'Kyrgyzstan',
  Tarawa: 'Kiribati',
  Kanton: 'Kiribati',
  Kiritimati: 'Kiribati',
  Pyongyang: 'Korea (North)',
  Seoul: 'Korea (South)',
  Almaty: 'Kazakhstan',
  Qyzylorda: 'Kazakhstan',
  Qostanay: 'Kazakhstan',
  Aqtobe: 'Kazakhstan',
  Aqtau: 'Kazakhstan',
  Atyrau: 'Kazakhstan',
  Oral: 'Kazakhstan',
  Beirut: 'Lebanon',
  Colombo: 'Sri Lanka',
  Monrovia: 'Liberia',
  Vilnius: 'Lithuania',
  Luxembourg: 'Luxembourg',
  Riga: 'Latvia',
  Tripoli: 'Libya',
  Casablanca: 'Morocco',
  Monaco: 'Monaco',
  Chisinau: 'Moldova',
  Majuro: 'Marshall Islands',
  Kwajalein: 'Marshall Islands',
  Yangon: 'Myanmar (Burma)',
  Ulaanbaatar: 'Mongolia',
  Hovd: 'Mongolia',
  Choibalsan: 'Mongolia',
  Macau: 'Macau',
  Martinique: 'Martinique',
  Malta: 'Malta',
  Mauritius: 'Mauritius',
  Maldives: 'Maldives',
  Mexico_City: 'Mexico',
  Cancun: 'Mexico',
  Merida: 'Mexico',
  Monterrey: 'Mexico',
  Matamoros: 'Mexico',
  Mazatlan: 'Mexico',
  Chihuahua: 'Mexico',
  Ojinaga: 'Mexico',
  Hermosillo: 'Mexico',
  Tijuana: 'Mexico',
  Bahia_Banderas: 'Mexico',
  Kuala_Lumpur: 'Malaysia',
  Kuching: 'Malaysia',
  Maputo: 'Mozambique',
  Windhoek: 'Namibia',
  Noumea: 'New Caledonia',
  Norfolk: 'Norfolk Island',
  Lagos: 'Nigeria',
  Managua: 'Nicaragua',
  Amsterdam: 'Netherlands',
  Oslo: 'Norway',
  Kathmandu: 'Nepal',
  Nauru: 'Nauru',
  Niue: 'Niue',
  Auckland: 'New Zealand',
  Chatham: 'New Zealand',
  Panama: 'Panama',
  Lima: 'Peru',
  Tahiti: 'French Polynesia',
  Marquesas: 'French Polynesia',
  Gambier: 'French Polynesia',
  Port_Moresby: 'Papua New Guinea',
  Bougainville: 'Papua New Guinea',
  Manila: 'Philippines',
  Karachi: 'Pakistan',
  Warsaw: 'Poland',
  Miquelon: 'St Pierre & Miquelon',
  Pitcairn: 'Pitcairn',
  Puerto_Rico: 'Puerto Rico',
  Gaza: 'Palestine',
  Hebron: 'Palestine',
  Lisbon: 'Portugal',
  Madeira: 'Portugal',
  Azores: 'Portugal',
  Palau: 'Palau',
  Asuncion: 'Paraguay',
  Qatar: 'Qatar',
  Reunion: 'Réunion',
  Bucharest: 'Romania',
  Belgrade: 'Serbia',
  Kaliningrad: 'Russia',
  Moscow: 'Russia',
  Simferopol: 'Russia',
  Kirov: 'Russia',
  Volgograd: 'Russia',
  Astrakhan: 'Russia',
  Saratov: 'Russia',
  Ulyanovsk: 'Russia',
  Samara: 'Russia',
  Yekaterinburg: 'Russia',
  Omsk: 'Russia',
  Novosibirsk: 'Russia',
  Barnaul: 'Russia',
  Tomsk: 'Russia',
  Novokuznetsk: 'Russia',
  Krasnoyarsk: 'Russia',
  Irkutsk: 'Russia',
  Chita: 'Russia',
  Yakutsk: 'Russia',
  Khandyga: 'Russia',
  Vladivostok: 'Russia',
  'Ust-Nera': 'Russia',
  Magadan: 'Russia',
  Sakhalin: 'Russia',
  Srednekolymsk: 'Russia',
  Kamchatka: 'Russia',
  Anadyr: 'Russia',
  Riyadh: 'Saudi Arabia',
  Guadalcanal: 'Solomon Islands',
  Mahe: 'Seychelles',
  Khartoum: 'Sudan',
  Stockholm: 'Sweden',
  Singapore: 'Singapore',
  Paramaribo: 'Suriname',
  Juba: 'South Sudan',
  Sao_Tome: 'Sao Tome & Principe',
  El_Salvador: 'El Salvador',
  Damascus: 'Syria',
  Grand_Turk: 'Turks & Caicos Is',
  Ndjamena: 'Chad',
  Kerguelen: 'French Southern & Antarctic Lands',
  Bangkok: 'Thailand',
  Dushanbe: 'Tajikistan',
  Fakaofo: 'Tokelau',
  Dili: 'East Timor',
  Ashgabat: 'Turkmenistan',
  Tunis: 'Tunisia',
  Tongatapu: 'Tonga',
  Istanbul: 'Turkey',
  Funafuti: 'Tuvalu',
  Taipei: 'Taiwan',
  Kiev: 'Ukraine',
  Uzhgorod: 'Ukraine',
  Zaporozhye: 'Ukraine',
  Wake: 'US minor outlying islands',
  New_York: 'United States',
  Detroit: 'United States',
  Louisville: 'United States',
  Monticello: 'United States',
  Indianapolis: 'United States',
  Vincennes: 'United States',
  Winamac: 'United States',
  Marengo: 'United States',
  Petersburg: 'United States',
  Vevay: 'United States',
  Chicago: 'United States',
  Tell_City: 'United States',
  Knox: 'United States',
  Menominee: 'United States',
  Center: 'United States',
  New_Salem: 'United States',
  Beulah: 'United States',
  Denver: 'United States',
  Boise: 'United States',
  Phoenix: 'United States',
  Los_Angeles: 'United States',
  Anchorage: 'United States',
  Juneau: 'United States',
  Sitka: 'United States',
  Metlakatla: 'United States',
  Yakutat: 'United States',
  Nome: 'United States',
  Adak: 'United States',
  Honolulu: 'United States',
  Montevideo: 'Uruguay',
  Samarkand: 'Uzbekistan',
  Tashkent: 'Uzbekistan',
  Caracas: 'Venezuela',
  Ho_Chi_Minh: 'Vietnam',
  Efate: 'Vanuatu',
  Wallis: 'Wallis & Futuna',
  Apia: 'Samoa (western)',
  Johannesburg: 'South Africa',
  Antigua: 'Antigua & Barbuda',
  Anguilla: 'Anguilla',
  Luanda: 'Angola',
  McMurdo: 'Antarctica',
  DumontDUrville: 'Antarctica',
  Syowa: 'Antarctica',
  Aruba: 'Aruba',
  Mariehamn: 'Åland Islands',
  Sarajevo: 'Bosnia & Herzegovina',
  Ouagadougou: 'Burkina Faso',
  Bahrain: 'Bahrain',
  Bujumbura: 'Burundi',
  'Porto-Novo': 'Benin',
  St_Barthelemy: 'St Barthelemy',
  Kralendijk: 'Caribbean NL',
  Nassau: 'Bahamas',
  Gaborone: 'Botswana',
  'Blanc-Sablon': 'Canada',
  Atikokan: 'Canada',
  Creston: 'Canada',
  Kinshasa: 'Congo (Dem. Rep.)',
  Lubumbashi: 'Congo (Dem. Rep.)',
  Bangui: 'Central African Rep.',
  Brazzaville: 'Congo (Rep.)',
  Douala: 'Cameroon',
  Curacao: 'Curaçao',
  Busingen: 'Germany',
  Djibouti: 'Djibouti',
  Dominica: 'Dominica',
  Asmara: 'Eritrea',
  Addis_Ababa: 'Ethiopia',
  Libreville: 'Gabon',
  Grenada: 'Grenada',
  Guernsey: 'Guernsey',
  Accra: 'Ghana',
  Banjul: 'Gambia',
  Conakry: 'Guinea',
  Guadeloupe: 'Guadeloupe',
  Malabo: 'Equatorial Guinea',
  Zagreb: 'Croatia',
  Isle_of_Man: 'Isle of Man',
  Jersey: 'Jersey',
  Phnom_Penh: 'Cambodia',
  Comoro: 'Comoros',
  St_Kitts: 'St Kitts & Nevis',
  Kuwait: 'Kuwait',
  Cayman: 'Cayman Islands',
  Vientiane: 'Laos',
  St_Lucia: 'St Lucia',
  Vaduz: 'Liechtenstein',
  Maseru: 'Lesotho',
  Podgorica: 'Montenegro',
  Marigot: 'St Martin (French)',
  Antananarivo: 'Madagascar',
  Skopje: 'North Macedonia',
  Bamako: 'Mali',
  Saipan: 'Northern Mariana Islands',
  Nouakchott: 'Mauritania',
  Montserrat: 'Montserrat',
  Blantyre: 'Malawi',
  Niamey: 'Niger',
  Muscat: 'Oman',
  Kigali: 'Rwanda',
  St_Helena: 'St Helena',
  Ljubljana: 'Slovenia',
  Longyearbyen: 'Svalbard & Jan Mayen',
  Bratislava: 'Slovakia',
  Freetown: 'Sierra Leone',
  San_Marino: 'San Marino',
  Dakar: 'Senegal',
  Mogadishu: 'Somalia',
  Lower_Princes: 'St Maarten (Dutch)',
  Mbabane: 'Eswatini (Swaziland)',
  Lome: 'Togo',
  Port_of_Spain: 'Trinidad & Tobago',
  Dar_es_Salaam: 'Tanzania',
  Kampala: 'Uganda',
  Midway: 'US minor outlying islands',
  Vatican: 'Vatican City',
  St_Vincent: 'St Vincent',
  Tortola: 'Virgin Islands (UK)',
  St_Thomas: 'Virgin Islands (US)',
  Aden: 'Yemen',
  Mayotte: 'Mayotte',
  Lusaka: 'Zambia',
  Harare: 'Zimbabwe',
};

// import * as Sentry from '@sentry/browser';
import TheSidebar from '@/components/TheSidebar.vue';
import TheNavbar from '@/components/TheNavbar.vue';
import TheNavbarTranscription from '@/components/TheNavbarTranscription.vue';
import EventBus from '@/helpers/event-bus';
import UserService from '@/api/user.service';
import AuthService from '@/api/auth.service';
import ConvertKit from '@/api/convertkit.service';
import NotificationsService from '@/api/notifications.service';
import {
  REFRESH_AUTH_TOKEN,
  SYNC_USER_TO_DB,
  TEAMS_ADD_SEATS,
  TEAMS_DELETE_USER_ACTION,
  TEAMS_DELETE_ADDITIONAL_SEATS_ACTION,
  TEAMS_DELETE_EMPTY_SEATS_ACTION,
  STRIPE_PAUSE_PLAN,
  CAPTIONS_DONE_EVENT,
  JOB_UPDATE,
  LOGOUT,
} from '@/store/action-types';
import store from './store';
import * as getters from './store/getters';
import loadChurnKey from './helpers/loadChurnKey';

const defaultLayout = 'default';

export default {
  name: 'app',
  components: {
    TheSidebar,
    TheNavbar,
    TheNavbarTranscription,
  },
  data() {
    return {
      additionalSeatsDialog: false,
      additionalSeatsDialogLock: false,
      team: undefined,
      pauseAccountDialog: false,
      loadingSeats: false,
      pollingInterval: null,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    layout() {
      // eslint-disable-next-line prefer-template
      return (this.$route.meta.layout || defaultLayout) + '-layout';
    },
    transcriptionNavbar() {
      return ['Transcription Editor'].includes(this.$route.name);
    },
    editorNavBat() {
      return ['editor'].includes(this.$route.name);
    },
    colors() {
      return [
        { color: '#63e418', percentage: 20 },
        { color: '#22b82e', percentage: 40 },
        { color: '#b8b75c', percentage: 60 },
        { color: '#faa019', percentage: 80 },
        { color: '#d36f6f', percentage: 100 },
      ];
    },
    hideAllNav() {
      return [
        'blank',
        'notEnabled',
        'signup',
        'login',
        'setup',
        'logout',
        'reset',
        'resetEmail',
        'confirmEmailChange',
        'confirmEmailReset',
        'confirmReset',
        'confirmEmail',
        'privacyPolicy',
        'gdpr',
        'terms',
        'oauth',
        'oauthConfirm',
      ].includes(this.$route.name);
    },
    hideMobileNav() {
      return ['editor'].includes(this.$route.name);
    },
    routerIsNotReady() {
      return this.$route.fullPath === '/' && this.$route.matched.length === 0 && this.$route.name === null;
    },
    routerIsReady() {
      return !this.routerIsNotReady;
    },
    user() {
      if (!this.pollingInterval && this.$store.state.user && this.$store.state.user.id) {
        this.startNotificationLongPolling();
      }

      return this.$store.state.user;
    },
    stripePlan() {
      return this.user.stripePlan;
    },
    teamStripePlan() {
      return this.user.teamStripePlan;
    },
    isTeamAdminOrOwner() {
      return this.user.id === this.user.teamInfo.ownerId;
    },
    teams() {
      if (this.team || !this.$store.state.teams || !this.$store.state.teams.length || this.additionalSeatsDialogLock) {
        return [this.team];
      }

      const team = this.$store.state.teams.filter(team => team.ownerId === this.user.id)[0];

      if (
        this.stripePlan.active &&
        !this.stripePlan.isCanceled &&
        !this.stripePlan.isPausePlan &&
        !this.stripePlan.isTrial &&
        (team.seats > this.stripePlan.seats || team.users.length >= this.stripePlan.seats)
      ) {
        this.additionalSeatsDialog = true;
        this.additionalSeatsDialogLock = true;
      }

      this.team = team;

      return team;
    },
    planSeats() {
      return this.stripePlan.seats;
    },
    canceledDaysLeft() {
      const now = Date.now();
      const diffTime = Math.abs(new Date(this.stripePlan.currentPeriodEnd).getTime() - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    },
    teamCanceledEndDaysLeft() {
      const now = Date.now();
      const diffTime = Math.abs(new Date(this.teamStripePlan.currentPeriodEnd).getTime() - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    },
    resumeDaysLeft() {
      const now = Date.now();
      const diffTime = Math.abs(new Date(this.stripePlan.collectionResumeAt).getTime() - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    },
    resumePercentage() {
      return 100 - Math.abs(this.resumeDaysLeft > 7 ? this.resumeDaysLeft / 30 : this.resumeDaysLeft / 7) * 100;
    },
    trialEndDaysLeft() {
      const now = Date.now();
      const diffTime = Math.abs(new Date(this.stripePlan.trialEndAt).getTime() - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    },
    trialEndPercentage() {
      const value = (this.trialEndDaysLeft / 7) * 100;

      return value > 100 ? 0 : 100 - value;
    },
    teamTrialEndDaysLeft() {
      const now = Date.now();
      const diffTime = Math.abs(new Date(this.teamStripePlan.trialEndAt).getTime() - now);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return diffDays;
    },
    teamTrialEndPercentage() {
      const value = (this.teamTrialEndDaysLeft / 7) * 100;

      return value > 100 ? 0 : 100 - value;
    },
  },

  async mounted() {
    loadChurnKey();

    var pathArray = window.location.pathname.split('/');

    const vm = this;
    if (pathArray[1] != 'login') {
      AuthService.ping();
    }

    EventBus.$on('destroyIntercom', () => vm.destroyIntercom());
    EventBus.$on('updateIntercom', user => vm.updateIntercom(user));
    EventBus.$on('trackIntercomEvent', (event, metadata) => vm.trackIntercomEvent(event, metadata));
    EventBus.$on('trackTapfiliate', userId => vm.setAffiliateId(userId));
    EventBus.$on('trackReferral', userId => vm.addConversion(userId));
    EventBus.$on('trackGTM', event => vm.trackGTM(event));
    EventBus.$on('trackView', (name, path) => vm.trackPageView(name, path));
    EventBus.$on('loginBoot', event => vm.loginBoot(event));
    // EventBus.$on('chatraBoot', event => vm.chatraBoot(event));

    vm.loginBoot();

    this.startNotificationLongPolling();

    // var dialogs = document.querySelectorAll(".el-dialog");
    //     dialogs.forEach(el => {
    //      try { el.removeAllListeners("wheel") } catch(e) {}
    //     });
  },
  methods: {
    async handleEvents(data) {
      switch (data.type) {
        case 'CAPTIONS_FINISHED': {
          store.dispatch(CAPTIONS_DONE_EVENT, data.payload.guid).catch(() => console.log('Error to process captions finished event'));
        }
        case 'TRANSCRIPTION_FINISHED': {
          store.dispatch(SYNC_USER_TO_DB).catch(() => console.log('Error to process transcription finished event'));
        }
      }
    },
    startNotificationLongPolling() {
      this.pollingInterval = setInterval(async () => {
        try {
          if (!this.$store.state.user || !this.$store.state.user.id) {
            this.stopNotificationLongPolling();

            return;
          }
          const response = await NotificationsService.getNotifications();

          if (response.status === 401) {
            this.stopNotificationLongPolling();

            return;
          }

          if (response && response.data && response.data.length) {
            response.data.forEach(event => this.handleEvents(event));
          }
        } catch {}
      }, 10000);
    },
    stopNotificationLongPolling() {
      if (this.pollingInterval) {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
      }
    },
    pauseSubscription() {
      const stripeId = this.user.teamStripePlan?.stripeId ? this.user.teamStripePlan.stripeId : this.user.stripePlan.stripeId;

      this.$store.dispatch(STRIPE_PAUSE_PLAN, { stripeId }).then(() => console.log('FEEDBACK'));

      this.pauseAccountDialog = false;
    },
    handlePauseAccountDialog() {
      this.pauseAccountDialog = true;
    },
    fillEmptySeats(team) {
      if (!team || team.seats - (team.users.length + 1) <= 0) {
        return [];
      }

      return new Array(team.seats - (team.users.length + 1)).fill({ empty: true });
    },
    refreshAdditionalSeatsVerification() {
      this.additionalSeatsDialogLock = false;
      this.team = undefined;
    },
    removeEmptySeat() {
      this.$store
        .dispatch(TEAMS_DELETE_EMPTY_SEATS_ACTION)
        .then(() => {
          // console.log('FEEDBACK');

          this.refreshAdditionalSeatsVerification();
        })
        .catch(() => console.log('FEEDBACK ERROR'));
    },
    removeMember(member) {
      const id = member._id;

      this.$store
        .dispatch(TEAMS_DELETE_USER_ACTION, { id, teamId: this.team._id })
        .then(() => {
          // console.log('FEEDBACK');

          this.refreshAdditionalSeatsVerification();
        })
        .catch(() => console.log('FEEDBACK ERROR'));
    },
    deleteSeats() {
      this.loadingSeats = true;

      const quantity = this.team.users.length >= this.stripePlan.seats ? this.team.users.length - 2 : this.team.seats - this.stripePlan.seats;

      if (quantity <= 0) {
        this.additionalSeatsDialog = false;
        this.loadingSeats = false;

        return;
      }

      this.$store
        .dispatch(TEAMS_DELETE_ADDITIONAL_SEATS_ACTION)
        .then(() => {
          console.log('FEEDBACK');

          this.refreshAdditionalSeatsVerification();

          this.additionalSeatsDialog = false;
        })
        .catch(() => console.log('FEEDBACK ERROR'))
        .finally(() => {
          this.loadingSeats = false;
        });
    },
    addSeats() {
      this.loadingSeats = true;

      const quantity = this.team.users.length >= this.stripePlan.seats ? this.team.users.length - 2 : this.team.seats - this.stripePlan.seats;

      if (quantity <= 0) {
        this.additionalSeatsDialog = false;
        this.loadingSeats = false;

        return;
      }

      this.$store
        .dispatch(TEAMS_ADD_SEATS, { quantity })
        .then(() => {
          // console.log('FEEDBACK');

          this.additionalSeatsDialog = false;
        })
        .finally(() => {
          this.loadingSeats = false;
        });
    },
    goToBillingPage() {
      this.$router.push({ name: 'billing' });
    },
    stopElementPropagation(e) {
      // e.preventDefault();
      e.stopPropagation();
    },
    stopElementPropagationPrevent(e) {
      e.preventDefault();
      e.stopPropagation();
    },
    destroyIntercom() {
      this.$intercom.shutdown();
    },
    loginBoot() {
      this.$store.dispatch(REFRESH_AUTH_TOKEN);
      if (this.$store.state.user) {
        this.$store.dispatch(SYNC_USER_TO_DB);
        // console.log( this.$store.state.user.email);
      }
      // ConvertKit.subscribeUser({ email: this.$store.state.user.email })
      // this.$intercom.boot({ hide_default_launcher: false });
    },
    // chatraBoot(details) {
    //   if (!IS_PRODUCTION) {
    //     return
    //   }
    //   if (details._id) {
    //     details.clientId = details._id
    //   };

    //   document.chatraDetails = {}
    //   document.chatraDetails.clientId = details._id || undefined
    //   // document.chatraDetails.name='Undefined'
    //   // details.name || details.email || 'Undefined'
    //   document.chatraDetails.email = details.email || undefined
    //   //       window.ChatraSetup = {
    //   // ...chatraDetails
    //   //       };

    //   // console.log("updating chatra", details);
    //   // setTimeout(function ()  {
    //   Chatra('setIntegrationData', details);
    //   Chatra('setIntegrationData', { ...document.chatraDetails, ...fields });
    //   // }, 1000);
    // },

    updateIntercom(user) {
      if (user.id == undefined) return;
      const options = {
        email: user.email,
        industry: user.industry,
        workRole: user.workRole,
        fbclid: user.fbclid,
        gclid: user.gclid,
        promo: user.promo,
        refby: user.refby,
        plan: user.products.video,
        last_seen_at: Math.floor(Date.now() / 1000),
        last_video_at: user.jobLastCreatedAt,
        jobCount: user.jobs ? user.jobs.length : 0,
        subscriber: user.isSubscribed,
        designCount: user.themes ? user.themes.length : 0,
      };

      // console.log('updateintercom', options);

      // Launch with option to hide chat ui
      // const intercomOptions = { ...options };
      // setTimeout(() => {
      //   this.$intercom.update(intercomOptions);
      // }, 500);
      // let country=fetch('https://extreme-ip-lookup.com/json/')
      // .then( res => res.json())
      // .then(response => {
      //     console.log("Country: ", response.country);
      //  })
      //  .catch((data, status) => {
      //     console.log('Request failed');
      //  })

      // cityCountry
      let city = Intl.DateTimeFormat().resolvedOptions().timeZone.split('/').slice(-1);
      let detectedCountry = cityCountry[city];

      let fields = {};
      fields['first_name'] = user.name ? user.name : '';
      fields['app_sign_up_date'] = user.dateAdded ? user.dateAdded : '';
      fields['job_count'] = user.jobs ? user.jobs.length : 0;
      fields['work_role'] = user.workRole;
      fields['country'] = detectedCountry;
      fields['utm_campaign'] = '';
      fields['utm_source'] = '';
      fields['utm_medium'] = '';
      fields['utm_term'] = '';
      fields['fbclid'] = user.fbclid;
      fields['gclid'] = user.promo;
      fields['refby'] = user.refby;
      fields['plan'] = user.products.video;
      fields['last_seen_at'] = Math.floor(Date.now() / 1000);
      fields['design_count'] = user.themes ? user.themes.length : 0;
      fields['industry'] = user.industry;
      fields['trial_end_at'] = user.stripePlan.trialEndAt;

      const convertKitOptions = {
        email_address: user.email,
        created_at: user.dateAdded,
        first_name: user.name ? user.name : '',
        fields,
      };

      if (CONVERT_KIT_ENABLED === 'true') {
        if (this.$store.state.user.convertKitId && (Number(this.$store.state.user.convertKitId > 0) || this.$store.state.user.convertKitId != '')) {
          // ConvertKit.updateUser(this.$store.state.user.convertKitId, { email: this.$store.state.user.email, ...convertKitOptions }).catch();
        } else {
          setTimeout(() => {
            ConvertKit.subscribeUser({ email: this.$store.state.user.email, ...convertKitOptions }).then(data => {
              UserService.updateConvertKitId(user.id, data.subscription.subscriber.id);
              this.$store.state.user.convertKitId = data.subscription.subscriber.id;
            });
          }, 500);
        }
      }
      // if (IS_PRODUCTION) {
      //   Chatra('setIntegrationData', { ...document.chatraDetails, ...fields });
      // }
      // Chatra('setIntegrationData',details);
      // Chatra('setIntegrationData',{...document.chatraDetails,...fields});

      // Sentry.configureScope(scope => scope.setUser({ username: user.email }));
    },
    trackIntercomEvent(event, metadata = null) {
      if (metadata) {
        this.$intercom.trackEvent(event, metadata);
      } else {
        this.$intercom.trackEvent(event);
      }
    },
    trackPageView(name, path) {
      this.$gtm.trackView(name, path);
    },
    trackGTM(event) {
      this.$gtm.trackEvent(event);
    },
    // If affiliate id, store referral to user
    async setAffiliateId(userId) {
      const tapfiliateId = localStorage.getItem('tapfiliateId');
      if (tapfiliateId !== null) {
        try {
          await UserService.addAffiliateId(userId, { affiliateId: tapfiliateId });
        } catch (err) {
          console.log('error', err);
        }
      }
    },
    // Attribute conversion to referring affiliate
    async addConversion(userId) {
      try {
        await UserService.addConversion(userId);
      } catch (err) {
        console.log('error', err);
      }
    },
  },
};

(function (w, d, s) {
  var a = d.getElementsByTagName('head')[0];
  var r = d.createElement('script');
  r.async = 1;
  r.src = s;
  r.setAttribute('async', 'async');
  r.setAttribute('defer', 'defer');
  r.setAttribute('id', 'usetifulScript');
  r.dataset.token = '34a539390da995f3b73cdd5b02054562';
  a.appendChild(r);
})(window, document, 'https://www.usetiful.com/dist/usetiful.js');
</script>

<style lang="scss">
#app {
  font-family: 'Rubik', 'Avenir', 'Helvetica', 'Arial', sans-serif;
}

.el-card {
  border-radius: 12px !important;
}

.el-form-item__error {
  position: relative !important;
}

.el-dialog {
  border-radius: 12px !important;
}

.looks-disabled {
  opacity: 0.6;
}

.el-dialog__body {
  word-break: normal !important;
}

#offlineModal {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.65);
  z-index: 99999999999999;
  justify-content: center;
  align-items: center;
  display: flex;

  .el-loading-mask {
    background: rgba(0, 0, 0, 0.65);
  }

  .offline-message {
    position: relative;
    padding: 20px 40px;
    border-radius: 5px;
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2);
    border: 2px solid black;
    margin-top: -10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #aaa;
    text-align: center;
    background: rgba(0, 0, 0, 0.8);

    strong {
      color: white;
      font-size: 140%;
    }
  }
}

.el-dialog__body {
  overflow-y: scroll !important;
}

.trialWrapper {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.trialCard {
  align-items: center;
  background-color: #4d47e8;
  min-width: 45%;
  max-width: 60%;
  max-height: 80%;
  border-radius: 70px;
  display: flex;
  padding: 10px;
  position: fixed;
  z-index: 29;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
}

.trialText {
  font-size: 1rem;
  align-self: center;
}

.trialButton {
  color: rgb(77, 71, 232) !important;
  background-color: white;
  height: 40px;
  align-self: center;
  margin-left: auto !important;
}

.el-progress__text {
  font-size: 14px !important;
  color: white !important;
}

@media only screen and (max-width: 768px) {
  .trialWrapper {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .trialCard {
    align-items: center;
    margin-top: 20px;
    background-color: #4d47e8;
    min-width: 98%;
    max-width: 100%;
    max-height: 80%;
    border-radius: 70px;
    display: flex;
    padding: 10px;
    -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
  }

  .trialText {
    font-size: 0.75rem !important;
    align-self: center;
  }

  .trialButton.el-button.is-round {
    font-size: 0.75em;
    padding: 10px;
    color: rgb(77, 71, 232) !important;
    background-color: white;
    height: 40px;
    align-self: center;
    margin-left: auto !important;
  }

  .el-progress__text {
    font-size: 14px !important;
    color: white !important;
    color: #63e418;
    color: #22b82e;
    color: #b8b75c;
    color: #faa019;
    color: #d36f6f;
  }
}

.noPlanWrapper {
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  flex-direction: row;
}

.noPlanCard {
  background-color: #4d47e8;
  width: 55%;
  max-height: 80%;
  border-radius: 30px;
  display: flex;
  padding: 10px;
  position: fixed;
  z-index: 29;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
}

.noPlanText {
  font-size: 1rem;
  align-self: center;
}

.noPlanButton {
  color: rgb(77, 71, 232) !important;
  background-color: white;
  height: 40px;
  align-self: center;
  margin-left: auto !important;
}

@media only screen and (max-width: 768px) {
  .noPlanWrapper {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    flex-direction: row;
  }

  .noPlanCard {
    align-items: center;
    margin-top: 20px;
    background-color: #4d47e8;
    width: 98%;
    max-height: 80%;
    border-radius: 100px;
    display: flex;
    padding: 10px;
    position: fixed;
    z-index: 29;
    -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
    -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27);
  }

  .noPlanText {
    font-size: 0.75rem !important;
    align-self: center;
  }

  .noPlanButton.el-button.is-round {
    font-size: 0.75em;
    padding: 10px;
    color: rgb(77, 71, 232) !important;
    background-color: white;
    height: 40px;
    align-self: center;
    margin-left: auto !important;
  }
}

.additionals-seats > .el-dialog {
  overflow: auto !important;
  padding-bottom: 0px;
}

.additionals-seats > .el-dialog > .el-dialog__body {
  overflow: hidden !important;
  padding-bottom: 0px;
}
</style>
