export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const SET_USER = 'SET_USER';
export const SET_AUTH_SUCCESS = 'SET_AUTH_SUCCESS';
export const SET_AUTH_ERROR = 'SET_AUTH_ERROR';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SAVE_CANVAS = 'SAVE_CANVAS';
export const SAVE_HISTORY = 'SAVE_HISTORY';
export const CLEAR_CANVAS = 'CLEAR_HISTORY';
export const SET_AUDIO_TYPE = 'SET_AUDIO_TYPE';
export const SET_AUDIO_START = 'SET_AUDIO_START';
export const SET_AUDIO_END = 'SET_AUDIO_END';
export const SET_EDITING_JOB = 'SET_EDITING_JOB';
export const SET_AUDIO_BUFFER = 'SET_AUDIO_BUFFER';
export const SET_AUDIO_BUFFER_DECODED = 'SET_AUDIO_BUFFER_DECODED';
export const SET_AUDIO_BUFFER_DECODING = 'SET_AUDIO_BUFFER_DECODING';
export const SET_REMOTE_AUDIO_URL = 'SET_REMOTE_AUDIO_URL';
export const SET_EPISODE_INFO = 'SET_EPISODE_INFO';
export const SET_REMOTE_AUDIO_DOWNLOADING = 'SET_REMOTE_AUDIO_DOWNLOADING';
export const SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS = 'SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS';
export const SET_AUDIO_DATA_URL = 'SET_AUDIO_DATA_URL';
export const SET_AUDIO_UPLOAD_DATA_URL = 'SET_AUDIO_UPLOAD_DATA_URL';
export const SET_UPLOAD_AUDIO_PROCESSING = 'SET_UPLOAD_AUDIO_PROCESSING';
export const SET_DOWNLOAD_REQUEST = 'SET_DOWNLOAD_REQUEST';
export const CLEAR_AUDIO_DATA = 'CLEAR_AUDIO_DATA';
export const SET_AUDIO_READY = 'SET_AUDIO_READY';
export const SET_POPOVER_DISMISSED = 'SET_POPOVER_DISMISSED';
export const SET_SELECTED_EPISODE = 'SET_SELECTED_EPISODE';
export const SET_JOB_DATA = 'SET_JOB_DATA';
export const SET_USER_DATA = 'SET_USER_DATA';
export const SET_LINK_DATA = 'SET_LINK_DATA';
export const ADD_LINK_DATA = 'ADD_LINK_DATA';
export const SET_CANCELING_LINK_ID = 'SET_CANCELING_LINK_ID';
export const SET_PODCAST_RSS = 'SET_PODCAST_RSS';
export const SET_DEFLECTOR_RESULTS = 'SET_DEFLECTOR_RESULTS';
export const SET_DEFLECTOR_SURVEY = 'SET_DEFLECTOR_SURVEY';
export const SET_DEFLECTOR_STATUS = 'SET_DEFLECTOR_STATUS';
export const OAUTH_STATE = 'OAUTH_STATE';
export const SET_AUDIO_BUFFER_DECODE_ESTIMATE_DATE = 'SET_AUDIO_BUFFER_DECODE_ESTIMATE_DATE';
export const SET_AUDIO_READING = 'SET_AUDIO_READING';
export const SET_SELECTED_THEME = 'SET_SELECTED_THEME';
export const SET_MEDIA = 'SET_MEDIA';
export const SET_AUDIO_REGION = 'SET_AUDIO_REGION';
export const UPDATE_TRANSCRIPTIONS = 'UPDATE_TRANSCRIPTIONS';
export const UPDATE_PENDING_TRANSCRIPTIONS = 'UPDATE_PENDING_TRANSCRIPTIONS';
export const DELETE_TRANSCRIPTION = 'DELETE_TRANSCRIPTION';
export const UPDATE_TRANSCRIPTION = 'UPDATE_TRANSCRIPTION';
export const SAVE_TRANSCRIPTION_TITLE = 'SAVE_TRANSCRIPTION_TITLE';
// export const UPDATE_TRANSCRIPTION_USAGE_STATUS = 'UPDATE_TRANSCRIPTION_USAGE_STATUS';
export const SET_TEAMS = 'SET_TEAMS';
export const SET_TEAMS_INVITES = 'SET_TEAMS_INVITES';
export const SET_USER_TEAM = 'SET_USER_TEAM';
export const SET_IS_CHANGING_TEAM = 'SET_USER_TEAM';
