import Axios from 'axios';

const RESOURCE = '/api/media';

export default {
  /**
   * Find job by guid
   * @param {string} job.id - Job Identifier
   */
  get(id) {
    // ts is required to axios to prevent caching the request.
    return Axios.get(`${RESOURCE}/${id}?ts=${new Date().getTime()}`);
  },

  /**
   * Get Job creation status
   * @param {string} job.id - Job Identifier (not used, but in place for more RESTful endpoint)
   * @param {object} data - { uuid, duration }
   */
  checkStatus(id) {
    return Axios({ method: 'get', url: `${RESOURCE}/${id}/status` });
  },

  /**
   * Get Job creation status
   * @param {string} job.id - Job Identifier (not used, but in place for more RESTful endpoint)
   * @param {object} data - { uuid, duration }
   */
  createMedia(id, data) {
    return Axios.post(`${RESOURCE}/${id}`, data);
  },

  deleteMedia(guid) {
    return Axios.delete(`${RESOURCE}/${guid}`);
  },
  changeMedia(guid, data) {
    return Axios.put(`${RESOURCE}/${guid}`, data);
  },
};
