export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const STAGE = process.env.STAGE || '';
export const WAVVE_API_BASE_URL = process.env.VUE_APP_WAVVE_API_BASE_URL;
export const SENTRY_DSN = process.env.VUE_APP_SENTRY_DSN;
export const SENTRY_RELEASE = process.env.VUE_APP_SENTRY_RELEASE;
export const SENTRY_ENVIRONMENT = process.env.VUE_APP_SENTRY_ENVIRONMENT;
export const INTERCOM_APP_ID = process.env.VUE_APP_INTERCOM_APP_ID;
export const GTM_ID = process.env.VUE_APP_GTM_ID;
export const STRIPE_KEY = process.env.VUE_APP_STRIPE_KEY;
export const RETENTION_COUPON_A = process.env.VUE_APP_STRIPE_PROMO_A;
export const RETENTION_COUPON_B = process.env.VUE_APP_STRIPE_PROMO_B;
export const SRC_BUCKET = process.env.VUE_APP_SRC_BUCKET;
export const DEST_BUCKET = process.env.VUE_APP_DEST_BUCKET;
export const CONVERTKIT_KEY = process.env.VUE_APP_CONVERTKIT_KEY;
export const CONVERTKIT_API_URL = process.env.VUE_APP_CONVERTKIT_API_URL;
export const STRIPE_PRICING_TABLE_PUBLISHABLE_KEY = process.env.VUE_APP_STRIPE_PRICING_TABLE_PUBLISHABLE_KEY;
export const STRIPE_PRICING_TABLE_ID = process.env.VUE_APP_STRIPE_PRICING_TABLE_ID;
export const HOTJAR_ENABLED = process.env.VUE_APP_HOTJAR_ENABLED;
export const CONVERT_KIT_ENABLED = process.env.VUE_APP_CONVERT_KIT_ENABLED;
export const OPEN_REPLAY_ENABLED = process.env.VUE_APP_OPEN_REPLAY_ENABLED;
export const WAVVE_V2_API_BASE_URL = process.env.VUE_APP_WAVVE_V2_API_BASE_URL;
