import { min, sum, timeFormat } from 'd3';
import moment from 'moment';
import { getThisMonthStart } from './date-utils';

const d3 = { min, sum, timeFormat };

export const subscribedNoJobs = ({ planStartDate, jobs }) => {
  return jobs.some(job => job.dateAdded > planStartDate);
};

export const getRewardSeconds = ({ allowance, jobs, hasVideoSubscription }) => {
  // reward video subscribed users 1 minute per video that has Wavve branding visible
  let rewardSeconds = 0;
  if (hasVideoSubscription) {
    // max possible rewards calculated as a percentage of monthly minute allowance
    const maxMonthlyRewardSeconds = allowance * 0.5;
    rewardSeconds = d3.sum(jobs, job => (job.stingerVisible ? 60 : 0));
    rewardSeconds = Math.min(maxMonthlyRewardSeconds, rewardSeconds);
  }
  return rewardSeconds;
};

export const getTimeLeft = ({ currentPeriodStart, allowance, rollover, jobs, hasVideoSubscription }) => {
  const currentPeriodJobs = jobs.filter(job => {
    const jobDateExists = !!job.dateAdded;
    const withinRange = job.dateAdded > currentPeriodStart;
    const isComplete = job.status === 'complete';
    return jobDateExists && withinRange && isComplete;
  });
  // calculate how many seconds the user has used

  const secondsUsed = d3.sum(currentPeriodJobs, job => job.duration + job.extraDurationCharged);
  const rewardSeconds = getRewardSeconds({ allowance, jobs: currentPeriodJobs, hasVideoSubscription });
  return allowance + rollover + rewardSeconds - secondsUsed;
};

export const setBillingDetails = user => {
  user.timeLeft = null;
  user.timeLeftString = 'Unlimited plan';
};

export const getNoStripeTimeLeft = ({ userDateAdded, jobs, allowance, rollover }) => {
  const currentPeriodStart = getThisMonthStart(new Date(userDateAdded));
  return getTimeLeft({
    currentPeriodStart,
    jobs,
    allowance,
    rollover,
    hasVideoSubscription: false,
  });
};

export const getAnnualTierTimeLeft = ({ annualPeriodStart, jobs, allowance }) => {
  const currentPeriodStart = getThisMonthStart(new Date(annualPeriodStart));
  return getTimeLeft({
    currentPeriodStart,
    jobs,
    allowance,
    hasVideoSubscription: true,
  });
};

export const getVideosBetween = ({ jobs, startDate, endDate }) =>
  jobs.filter(job => {
    const jobDateExists = !!job.dateAdded;
    const withinRange = job.dateAdded > startDate && (endDate ? job.dateAdded < endDate : true);
    const isComplete = job.status === 'complete';
    return jobDateExists && withinRange && isComplete;
  });

export const getRolloverTime = ({ planStartDate, allowance, rolloverAllowance, jobs }) => {
  const now = new Date();
  try {
    let rollover = 0;
    let monthsSincePlanStart = 0;
    let renewalDate = moment(planStartDate);
    let nextRenewalDate = moment(planStartDate).add(1, 'month');
    // console.log(`=> Their plan was renewed on ${day(renewalDate)}`)
    while (nextRenewalDate < now) {
      // eslint-disable-next-line
      const videosBetweenDates = (jobs || []).filter(job => {
        const completed = job.status === 'complete';
        const { duration, dateAdded } = job;
        if (dateAdded && completed && duration) {
          return dateAdded > renewalDate && dateAdded < nextRenewalDate;
        }
        return false;
      });

      const videoTime = d3.sum(videosBetweenDates, d => +d.duration + +d.extraDurationCharged);
      const rewardSeconds = getRewardSeconds({ allowance, jobs: videosBetweenDates, hasVideoSubscription: true });
      const minRollover = Math.max(0, allowance + rewardSeconds + rollover - videoTime);
      rollover = Math.min(minRollover, rolloverAllowance);
      monthsSincePlanStart += 1;
      renewalDate = nextRenewalDate;
      nextRenewalDate = moment(planStartDate).add(monthsSincePlanStart + 1, 'month');
    }
    return rollover;
  } catch (err) {
    console.log('error in calculating rollover time:', err);
    return 0;
  }
};

export const formatTimeLeft = timeLeft => {
  const minutesLeft = Math.max(0, Math.floor(timeLeft / 60));
  // eslint-disable-next-line
  const minutesText = minutesLeft > 0 ? minutesLeft + (minutesLeft === 1 ? ' minute and ' : ' minutes and ') : '';
  const secondsLeft = Math.max(0, Math.floor(timeLeft - minutesLeft * 60));
  const secondsText = secondsLeft + (secondsLeft === 1 ? ' second left' : ' seconds left');
  return `${minutesText}${secondsText}`;
};

export default {
  getRolloverTime,
  subscribedNoJobs,
  getNoStripeTimeLeft,
  getAnnualTierTimeLeft,
  getVideosBetween,
  getTimeLeft,
  formatTimeLeft,
};
