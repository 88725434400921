<template>
  <div style=position:relative;text-align:center;>
    <div class="has-text-centered" v-if="rendered != 'no'">

      <p></p>
    </div>
    <div onmouseover="this.style.opacity=1" onmouseout="this.style.opacity=0.5"
      class="has-text-centered preview-container" ref="preview-container"
      style="opacity:0.5; transition: opacity 0.35s ease-in-out;display:block; width: 100%; height:100%; display : flex;align-items : center; text-align:center; background:#eee;">
      <el-tooltip class="item tooltipcontainer" v-if="rendered == 'no'" effect="dark"
        content="You have a draft for this video." placement="top-start">
        <div class="preview-canvas-d" style="max-height:350px">
          <canvas :key="design.id + design.previewImage" ref="animatedPreviewCanvas" :width="canvasWidth"
            :height="canvasHeight" class="preview-canvas"></canvas>
        </div>
      </el-tooltip>
    </div>

    <div v-if="rendered != 'no'" class="has-text-centered" ref="preview-container"
      style="max-height:350px;display : flex;align-items : center">
      <div class="preview-canvas-d">
        <canvas v-if="canvasHeight > 0" :key="design.id + design.previewImage" ref="animatedPreviewCanvas"
          :width="canvasWidth" :height="canvasHeight" class="preview-canvas"></canvas>
      </div>
    </div>
    <div v-loading="true" v-if="rendered == 'no' && pending"
      style="position: absolute;top:48%; left:47%;width:0px; height:0px"></div>
    <!--      <div class="has-text-centered not-redered" v-if="rendered=='no' && !pending">
      Last changes have not been applied yet.
    </div>
     <div class="has-text-centered rendering" >
      Your video is being prepared...
    </div> -->
    <div style="text-align:center; width: 100%">

      <div :style="`text-align:center;width:${canvasWidth}px;margin:auto; font-size:90%`">{{ design.name }}<br>
        <small>{{ design.width }}x{{ design.height }}{{ designIsNotNull }}</small>
      </div>
    </div>
  </div>
</template>

<script>
import progressBars from '@/helpers/design/progress-bars';
import animations from '@/helpers/design/animations';
// import sampleWave from '@/helpers/design/sample-wave'
import sampleWaves from '@/helpers/design/sample-waves';
import { fabric } from 'fabric';
export default {
  name: 'DesignAnimatedPreview',
  props: {
    pending: Boolean,
    rendered: String,
    doanimate: String,
    design: Object,
  },
  data() {
    return {
      mounted: false,
      mctx: {},
      mctx2: {},
      canvasWidth: 640,
      canvasHeight: 640 * (this.design.height / this.design.width),
      backgroundImageFile: null,
      animationFrame: 0,
      totalFrames: sampleWaves.length - 1,
      animationTimeout: null,
      refreshTimeout: 1000 / 24,
    };

  },
  watch: {
    design: {
      handler(val) {
        // console.log('d', d)
      },
      deep: true,
    },
  },

  mounted() {
    // this.doMounted();
    this.mounted = true;
    // console.log('this.design',this.$refs["this.design"])
    // console.log('this.canvasHeight',this.canvasHeight)
  },
  computed: {
    designIsNotNull() {
      if (this.mounted == true && this.design != undefined && this.$refs['preview-container']) this.doMounted();
      return this.design != undefined && this.$refs['preview-container'] ? '' : ' '
    }
  },
  methods: {
    doMounted() {

      this.mounted = 'done'


      const container = this.$refs['preview-container'];
      const maxWidth = container.clientWidth;
      const maxHeight = container.clientHeight;
      const maincanvas = new fabric.StaticCanvas(this.$refs['animatedPreviewCanvas']);
      // const  maincanvas = new  fabric.StaticCanvas("preview-canvas");
      const maincanvas2 = new fabric.StaticCanvas(null);

      // maincanvas.setHeight(this.design.height)
      // maincanvas.setWidth(this.design.width)
      // maincanvas.setZoom(0.5);
      // maincanvas.setHeight(maxHeight)
      // maincanvas.setWidth(maxWidth)
      const ratio = this.design.width / this.design.height
      const widthFactor = maxWidth / this.design.width; // max 640 pixels wide
      const heightFactor = maxHeight / this.design.height; // or 360 pixels tall
      const factor = Math.min(widthFactor, heightFactor);
      maincanvas.setHeight(maxHeight)
      maincanvas.setWidth(maxHeight * ratio)
      maincanvas2.setHeight(this.design.height)
      maincanvas2.setWidth(this.design.width)
      // maincanvas2.getContext('2d').setTransform(factor, 0, 0, factor, 0, 0);
      maincanvas.setZoom(1)
      maincanvas2.setZoom(1)
      maincanvas2.getContext('2d').scale(0.5, 0.5)
      // maincanvas2.setZoom(0.5);
      maincanvas.getContext('2d').patternQuality = 'best'
      // maincanvas.loadFromJSON({objects: this.design.variables ? this.design.variables : [] })
      // maincanvas.setZoom(0.5);
      // this.mctx=fabric.util.getNodeCanvas(maincanvas.lowerCanvasEl)
      // let vm = this
      fabric.util.enlivenObjects(this.design.variables ? this.design.variables : [], function (objects) {
        var origRenderOnAddRemove = maincanvas2.renderOnAddRemove;
        maincanvas2.renderOnAddRemove = false;

        objects.forEach(function (o) {
          // console.log('adding',o)
          maincanvas2.add(o);
        });

        maincanvas2.renderOnAddRemove = origRenderOnAddRemove;
        maincanvas2.renderAll();
      });
      // let cctx=maincanvas.getContext('2d')
      // cctx.clearRect(0, 0, maincanvas.width, maincanvas.height);
      this.mctx = maincanvas
      this.mctx2 = maincanvas2
      // cctx.getImageData(0, 0, this.design.width, this.design.height);
      // var mctx=fabric.util.getNodeCanvas(maincanvas.lowerCanvasEl)
      // this.$refs.preview.lowerCanvasEl./setZoom(1);
      // this.ctx = this.$refs.preview.getContext('2d');
      this.ctx = maincanvas.getContext('2d');
      const vvm = this

      // this.ctx.scale(2, 2)
      this.ctx.patternQuality = 'best';

      this.resize();
      // console.log('this.canvasHeight',this.canvasHeight)
      let vm = this
      if (this.design.backgroundImage) {
        this.backgroundImageFile = new Image();
        this.backgroundImageFile.src = this.design.backgroundImage;
        this.backgroundImageFile.onload = () => {

          this.animate();
          // this.drawFrame({ waveform: sampleWave })
        };
      }
      // console.log('canvas',this.$refs["animatedPreviewCanvas"])
      // console.log('preview-container',this.$refs["preview-container"])
      // console.log('this.design.height',this.$refs["this.design.height"])
      // console.log('this.canvasHeight',this.canvasHeight)
    },
    resize() {
      const container = this.$refs['preview-container'];
      if (container) {
        const maxWidth = container.clientWidth;
        const maxHeight = container.clientHeight;
        const widthFactor = maxWidth / this.design.width; // max 640 pixels wide
        const heightFactor = maxHeight / this.design.height; // or 360 pixels tall
        const factor = Math.min(widthFactor, heightFactor);
        this.canvasHeight = factor * this.design.height;
        this.canvasWidth = factor * this.design.width;
        // this.mctx.setHeight(this.canvasHeight)
        // this.mctx.setWidth(this.canvasWidth)
        // this.mctx.getContext('2d').setTransform(factor/2, 0, 0, factor/2, 0, 0);
        this.ctx.setTransform(factor, 0, 0, factor, 0, 0);
        this.ctx.scale(1, 1)
      }
    },
    animate() {
      this.animationFrame += 1;
      if (this.animationFrame >= this.totalFrames) {
        this.animationFrame = 0;
      }
      this.drawFrame({
        time: this.animationFrame,
        totalTime: this.totalFrames,
        waveform: sampleWaves[this.animationFrame],
      });
      if (this.doanimate != "no") {
        this.animationTimeout = setTimeout(() => {
          this.animate();
        }, this.refreshTimeout);
      }
    },
    drawFrame({ time = 68, totalTime = 100, waveform }) {
      this.resize();
      const factor = this.canvasWidth / this.design.width;
      this.ctx.setTransform(factor, 0, 0, factor, 0, 0);
      this.ctx.clearRect(0, 0, this.design.width, this.design.height);
      this.ctx.fillStyle = this.design.backgroundColor;
      this.ctx.fillRect(0, 0, this.design.width, this.design.height);

      // this.ctx.drawImage((this.mctx, 0, 0);
      if (this.backgroundImageFile) {
        this.ctx.drawImage(this.backgroundImageFile, 0, 0, this.design.width, this.design.height);
      }
      this.ctx.drawImage(this.mctx2.lowerCanvasEl, 0, 0);

      if (this.design.progressBar) {
        progressBars[this.design.progressBarType]({
          ctx: this.ctx,
          theme: this.design,
          time,
          totalTime,
        });
      }
      if (this.design.waveform) {
        animations[this.design.pattern || 'wave'](this.ctx, waveform, this.design);
      }

      // console.log( this.mctx);

    },
  },
};
</script>

<style lang="scss" scoped>
canvas {


  display: inline-block;
  box-shadow: 0 4px 8px 0 rgba(100, 100, 100, 0.1), 0 6px 20px 0 rgba(100, 100, 100, 0.09);
}

.not-redered {
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  padding: 3px;
  font-size: 88%;
  background: lightyellow;
  color: darkorange;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  border: 0.5px dashed white;
  border-radius: 3px;
}

.rendering {
  position: absolute;
  left: -5px;
  right: -5px;
  top: -5px;
  padding: 3px;
  font-size: 88%;
  background: #fefefa;
  color: navy;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  border: 0.5px dashed white;
  border-radius: 3px;
}

.animatedPreviewInside {
  vertical-align: middle;
  min-width: 280px;
  min-height: 280px;
}

.preview-canvas-d {
  display: inline-block;
  position: relative;
  margin: auto;
  max-heigt: 100%;
}

.pppreview-canvas-d:before {
  content: '';

  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 40px 40px 0;
  border-color: transparent #FFEA00 transparent transparent;
  right: -3px;
  top: -3px;
  position: absolute;
}

.pppreview-canvas-d:after {
  content: 'draft';
  font-size: 9px;
  font-weight: 500;
  color: #555;
  text-align: right;
  right: 2px;
  top: 3px;
  position: absolute;
  transform: rotate(45deg);
}
</style>
