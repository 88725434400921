import Vue from 'vue';
import Component from 'vue-class-component';
import Router from 'vue-router';
import EventBus from '@/helpers/event-bus';
import Create from '@/views/Create.vue';
import Designs from '@/views/Designs.vue';
// eslint-disable-next-line import/no-cycle
import store from '@/store';
import AuthService from '@/api/auth.service';
import { IS_PRODUCTION } from './config.js';
// Helper function to add an exception handler for NavigationDuplicated errors
const routerPush = Router.prototype.push;

Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate', 'beforeRouteLeave']);

function pushWithoutDuplication(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return routerPush.call(this, location, onResolve, onReject);
  }

  console.log('routing', location, onResolve, onReject);
  return routerPush.call(this, location).catch(error => {
    if (error.name === 'NavigationDuplicated') {
      console.log('routing NavigationDuplicated', error);
      return;
    }
    console.log('routing error', error);
  });
  // console.log('routing',location, onResolve, onReject);
  return;
}

Router.prototype.push = pushWithoutDuplication;

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/create',
      name: 'create',
      component: Create,
      meta: { requiresAuth: true },
    },
    {
      path: '/designs',
      name: 'designs',
      component: Designs,
      meta: { requiresAuth: true },
    },
    {
      path: '/transcriptions/:guid?',
      name: 'transcriptions',
      component: () => import('./views/Transcriptions.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/transcriptionEditor/:_id?',
      name: 'Transcription Editor',
      component: () => import('./views/ContentEditor.vue'),
      meta: { requiresAuth: true },
    },
    // {
    //   path: '/feeds/:_id?',
    //   name: 'My Feeds',
    //   component: () => import('./views/Feeds.vue'),
    //   meta: { requiresAuth: true },
    // },
    {
      path: '/teams',
      name: 'teams',
      component: () => import('./views/Teams.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/billing',
      name: 'billing',
      component: () => import('./views/Billing.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/videos/:guid?',
      name: 'videos',
      component: () => import('./views/Videos.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/media/:guid?',
      name: 'media',
      component: () => import('./views/Media.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/transcribe/:guid?',
      name: 'transcribe',
      component: () => import('./views/Transcribe.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/edit/:guid?',
      name: 'edit',
      component: () => import('./views/Edit.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/editor/:id?',
      name: 'editor',
      component: () => import('./views/Editor.vue'),
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: '/link',
      name: 'link',
      component: () => import('./views/Links.vue'),
      meta: { requiresAuth: true },
      props: true,
    },
    {
      path: '/settings',
      name: 'settings',
      component: () => import('./views/Settings.vue'),
      meta: { requiresAuth: true },
    },
    // {
    //   path: '/pricing/:tab?',
    //   name: 'pricing',
    //   component: () => import('./views/Pricing.vue'),
    //   meta: { requiresAuth: true },
    // },
    // {
    //   path: '/plan',
    //   name: 'plan',
    //   component: () => import('./views/Plan.vue'),
    //   meta: { requiresAuth: true },
    // },
    // {
    //   path: '/plan/change/:productSlug(video|both)/:nameSlug(creator|influencer|agency|network)/:duration(monthly|annual)',
    //   name: 'ChangePlan(video|both)',
    //   component: () => import('./views/ChangePlan.vue'),
    // },
    // {
    //   path: '/plan/change/:productSlug(link)/:nameSlug(premium|addon)/:duration(monthly|annual)',
    //   name: 'ChangePlan(link)',
    //   component: () => import('./views/ChangePlan.vue'),
    // },
    // {
    //   path: '/plan/change/:productSlug(link)/:nameSlug(premium)/:duration(monthly|annual)',
    //   name: 'ChangePlan(link)',
    //   component: () => import('./views/ChangePlan.vue'),
    // },
    // {
    //   path: '/plan/cancel/:productSlug(video|link)',
    //   name: 'CancelLanding',
    //   component: () => import('./views/cancel/Landing.vue'),
    //   meta: { layout: 'offboarding', requiresAuth: true },
    // },
    // {
    //   path: '/plan/cancel/:productSlug(video|link|link_addon)',
    //   name: 'CancelLanding',
    //   component: () => import('./views/cancel/Landing.vue'),
    //   meta: { layout: 'offboarding', requiresAuth: true },
    // },
    // {
    //   path: '/plan/cancel/:productSlug(video|link|link_addon)/survey',
    //   name: 'CancelSurvey',
    //   component: () => import('./views/cancel/Survey.vue'),
    //   meta: { layout: 'offboarding', requiresAuth: true },
    // },
    // {
    //   path: '/plan/cancel/:productSlug(video|link|link_addon)/confirm',
    //   name: 'CancelConfirm',
    //   component: () => import('./views/cancel/Confirm.vue'),
    //   meta: { layout: 'offboarding', requiresAuth: true },
    // },
    // {
    //   path: '/setup',
    //   name: 'signup',
    //   component: () => import('./views/SignUp.vue'),
    //   meta: { requiresAuth: false },
    // },
    // {
    //   path: '/signup',
    //   name: 'setup',
    //   component: () => import('./views/Setup.vue'),
    //   meta: { requiresAuth: false, redirectIfAuthenticated: '/' },
    // },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      meta: { requiresAuth: false, redirectIfAuthenticated: '/' },
    },
    {
      path: '/blank',
      name: 'blank',
      component: () => import('./views/Blank.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/logout',
      name: 'logout',
      component: () => import('./views/Logout.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/oauth/authorize',
      name: 'oauth',
      component: () => import('./views/oauth/Login.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/oauth/confirm',
      name: 'oauthConfirm',
      component: () => import('./views/oauth/Confirm.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/reset',
      name: 'reset',
      component: () => import('./views/Reset.vue'),
      meta: { requiresAuth: false, redirectIfAuthenticated: '/' },
    },
    {
      path: '/reset-email',
      name: 'resetEmail',
      component: () => import('./views/ResetEmail.vue'),
      meta: { requiresAuth: false, redirectIfAuthenticated: '/' },
    },
    {
      path: '/reset-email/:token',
      name: 'confirmEmailReset',
      component: () => import('./views/ConfirmEmailReset.vue'),
      meta: { requiresAuth: false, redirectIfAuthenticated: '/' },
    },
    {
      path: '/reset/:token',
      name: 'confirmReset',
      component: () => import('./views/ConfirmReset.vue'),
      meta: { requiresAuth: false, redirectIfAuthenticated: '/' },
    },
    {
      path: '/confirm/:token',
      name: 'confirmEmail',
      component: () => import('./views/ConfirmEmail.vue'),
      meta: { requiresAuth: true },
    },
    {
      path: '/change-email',
      name: 'confirmEmailChange',
      component: () => import('./views/ConfirmEmailChange.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/privacy-policy',
      name: 'privacyPolicy',
      component: () => import('./views/legal/PrivacyPolicy.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/eu-data-protection',
      name: 'gdpr',
      component: () => import('./views/legal/GDPR.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/terms',
      name: 'terms',
      component: () => import('./views/legal/TermsOfUse.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '*',
      redirect: '/videos',
      meta: { requiresAuth: true },
    },
    {
      path: '/integrations',
      name: 'externalIntegrations',
      component: () => import('./views/externalIntegrations.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/notEnabled',
      name: 'notEnabled',
      component: () => import('./views/NotEnabled.vue'),
      meta: { requiresAuth: false },
    },
    {
      path: '/enableDevInstance',
      name: 'enableDevInstance',
      component: () => {
        return;
      },
      meta: { requiresAuth: false },
    },
  ],
});

// Ensure that the Vuex store has been restored properly before routing
const waitForStorageToBeReady = async (to, from, next) => {
  // if(to.name === from.name || '/'+to.name === from.name) {
  //   console.log('routing mesma rota !!!!')
  //   return
  // }

  console.log('routing', from.name, to.name);
  if (to.name === 'login' || to.name === 'signup' || to.name === 'logout' || to.name === 'blank') {
    next();
  } else {
    await store.restored;
    next();
  }
};
// router.beforeEach(waitForStorageToBeReady);

// Handle routing security and user sync
router.beforeEach(async (to, from, next) => {
  //     console.log('routing', from.name, to.name)
  //    // if (!(to.name === 'login' || to.name === 'signup' || to.name === 'logout' || to.name === 'blank')) {
  //      // await store.restored;
  // if ( to.name !== 'logout') {
  await store.restored;

  // }
  // localStorage.setItem('tokenx','aaaaa')

  const ignoreDevEnabled = true; // after usage change to !localStorage.getItem('dev_enabled')

  if (
    !IS_PRODUCTION &&
    ignoreDevEnabled &&
    (location.host.startsWith('localhost') ||
      String(localStorage.getItem('myPublicIp')) == '44.206.65.172' ||
      String(localStorage.getItem('myICEcandidates')).includes('10.8.0'))
  ) {
    // await localStorage.setItem('dev_enabled', 1);
  }

  if (!IS_PRODUCTION && !ignoreDevEnabled && to.path == '/enableDevInstance' && to.path != '/notEnabled') {
    // localStorage.setItem('dev_enabled', 1);
    next('/login');
  }

  if (!IS_PRODUCTION && !ignoreDevEnabled && to.path != '/notEnabled') {
    next('/notEnabled');
  }
  // Update route for Intercom
  EventBus.$emit('trackIntercomEvent', to.name);
  // Router path tracking
  EventBus.$emit('trackView', to.name, to.fullPath);

  // Tapfiliate: check for referral attribution id
  // if (to.name === 'login' || to.name === 'signup') {
  //   window.tap('create', '5975-99f9c9');
  //   window.tap('getTrackingId', null, tapfiliateId => {
  //     if (tapfiliateId !== null) {
  //       localStorage.setItem('tapfiliateId', tapfiliateId);
  //     }
  //   });
  // }

  // check for coupon code and store value
  if (to.query && to.query.coupon) {
    localStorage.setItem('coupon', to.query.coupon);
  }
  // if (store.getters.isLoggedIn) {
  //   AuthService.ping();
  // }
  // perform standard auth check

  if (to.meta.requiresAuth === true) {
    // console.log('Autenticando')
    if (store.getters.isLoggedIn) {
      // console.log('Autenticado')
      // AuthService.ping();
      if (store.state && store.state.user === null) {
        try {
          store.dispatch('SYNC_USER_TO_DB');
        } catch (err) {
          console.log('ERROR!', err);
        }
      }

      if (to.query.teamId && to.name !== 'teams') {
        next({ path: 'teams', query: to.query });

        return;
      }

      next();
      return;
    }

    next({ path: 'login', query: { to: to.name } });
  } else if (store.getters.isLoggedIn && to.matched.some(record => record.meta.redirectIfAuthenticated)) {
    if (to.query.teamId) {
      next({ path: 'teams', query: to.query });

      return;
    }

    next({ path: to.meta.redirectIfAuthenticated, query: to.query });
  } else {
    //     const fromCancelFlow = from.path.includes('cancel');
    // const toCancelFlow = to.path.includes('cancel');
    // // if starting cancel flow
    // if (!fromCancelFlow && toCancelFlow) {
    //   store.dispatch('START_DEFLECTOR_RECORDING');
    // }

    next();
  }
});

// router.beforeEach((to, from, next) => {

//   if(to.path != '/login' && to.path != '/logout'  && to.path != '/logoff' && to.path != '/signup'  ) {

// }

//   next();
// });

router.afterEach(async (to, from) => {
  if (window.Chatra) window.Chatra('show');
  const fromCancelFlow = from.path.includes('cancel');
  const toCancelFlow = to.path.includes('cancel');

  // if leaving cancel flow
  if (fromCancelFlow && !toCancelFlow) {
    // stop recording
    store.dispatch('STOP_DEFLECTOR_RECORDING');
  }

  // safety check to ensure session doesn't run when it shouldn't
  if (!toCancelFlow && store.getters.isDeflectorRecording) {
    console.log('store.getters.isDeflectorRecording', store.getters.isDeflectorRecording);
    // stop recording
    store.dispatch('STOP_DEFLECTOR_RECORDING');
  }

  window.scrollTo(0, 0);
});

window.onbeforeunload = event => {
  var routeLocation = event.currentTarget.location.pathname.split('/');
  if (routeLocation[1] === 'editor' || routeLocation[1] === 'create') {
    event.preventDefault();
    return true;
  } else {
    return;
  }
};

export default router;
