class Theme {
  constructor(theme) {
    // eslint-disable-next-line
    this.id = theme._id;
    this.name = theme.name;
    this.deleted = theme.deleted;
    this.dateAdded = new Date(theme.dateAdded);
    this.backgroundImageKey = theme.backgroundImage;
    this.backgroundImage = `https://s3-us-west-2.amazonaws.com/wavvegram/background/${theme.backgroundImage}`;
    this.previewImage = theme.previewImage != "" &&  theme.previewImage != undefined  && theme.previewImage != null
    ? `https://s3-us-west-2.amazonaws.com/wavvegram/background/${theme.previewImage}` : null;
    this.cardType = theme.cardType;
    this.height = theme.height;
    this.width = theme.width;
    this.waveColor = theme.waveColor;
    this.objects = theme.objects;
    this.isLegacy = theme.objects === undefined;
    this.ownerName = theme.owner_name;
    this.name = theme.name;
    this.objects = theme.objects;
    this.pattern = theme.pattern;
    this.waveform = theme.waveform;
    this.backgroundColor = theme.backgroundColor || '#000000';
    this.waveColor = theme.waveColor || theme.foregroundColor || '#000000';
    this.waveTop = theme.waveTop !== undefined ? +theme.waveTop : 0;
    this.waveLeft = theme.waveLeft !== undefined ? +theme.waveLeft : 0;
    this.waveBottom = theme.waveBottom !== undefined ? +theme.waveBottom : this.height;
    this.waveRight = theme.waveRight !== undefined ? +theme.waveRight : this.width;
    this.progressBar = theme.progressBar; // boolean if there is a progress bar
    this.progressBarColor = theme.progressBarColor;
    this.progressBarSecondaryColor = theme.progressBarSecondaryColor;
    this.progressBarType = theme.progressBarType;
    this.progressBarTop = +theme.progressBarTop;
    this.progressBarBottom = +theme.progressBarBottom;
    this.progressBarLeft = +theme.progressBarLeft;
    this.progressBarRight = +theme.progressBarRight;
    this.variables = theme.variables ? theme.variables : [] ;

    // flip if necessary so that the coordinates (0,0) start from the top left
    if (this.waveTop > this.waveBottom) {
      const oldTop = this.waveTop;
      this.waveTop = this.waveBottom;
      this.waveBottom = oldTop;
    }
    if (this.progressBarTop > this.progressBarBottom) {
      const oldTop = this.progressBarTop;
      this.progressBarTop = this.progressBarBottom;
      this.progressBarBottom = oldTop;
    }
  }
}

export default Theme;
