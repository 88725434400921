import Axios from 'axios';

const RESOURCE = '/api/v2/transcribe';

export default {
  /**
   * Find transcription by guid
   * @param {string} transcription.id - transcription Identifier
   */
  get(id) {
    return Axios.get(`${RESOURCE}/transcribed/${id}`);
  },

  clone(id) {
    return Axios.get(`${RESOURCE}/clone/${id}`);
  },
  summary(id) {
    return Axios.get(`${RESOURCE}/summary/${id}`);
  },
  /**
   * Find all transcriptions by logged user
   */
  getAll() {
    return Axios.get(`${RESOURCE}/all`);
  },

  /**
   * Find pending transcriptions by logged user
   */
  getPending(lastupdate) {
    return Axios.get(`${RESOURCE}/all?onlyPending=${lastupdate}`);
  },
  /**
   * Find transcription by guid
   * @param {string} transcription.guid - S3 transcription Identifier
   */
  findByGuid(guid) {
    return Axios.get(`${RESOURCE}/${guid}/guid`);
  },

  /**
   * Not used. See this instead: /api/users/:id/transcription
   */
  create(data) {
    return Axios.post(RESOURCE, data);
  },

  /**
   * Edit a single transcription
   * @param {string} transcription.id - transcription Identifier
   * @param {object} data - New transcription properties.
   */
  update(id, data) {
    return Axios.put(`${RESOURCE}/transcribed/${id}`, data);
  },

  /**
   * Delete a single transcription
   * @param {string} transcription.id - transcription Identifier
   */
  delete(id) {
    return Axios.get(`${RESOURCE}/delete?_id=${id}`);
  },

  /**
   * Create a single transcription
   * @param {videourl}  - video url
   */
  transcribe(data) {
    return Axios.get(`${RESOURCE}/transcribe?title=${data.title}&guid=${data.guid}&url=${data.url}`);
  },

  transcribe_and_summary(data) {
    return Axios.get(`${RESOURCE}/transcribe_and_summary?title=${data.title}&guid=${data.guid}&url=${data.url}`);
  },


 usage_status() {
    return Axios.get(`${RESOURCE}/usage_status`);
  },

  /**
   * Retry transcription - Admins Only
   * @param {string} transcription.id - transcription Identifier
   * @param {object} data - transcription theme object
   */
  retry(id, data) {
    return Axios.post(`${RESOURCE}/${id}/retry`, data);
  },
  /**
   * Get transcription creation status
   * @param {string} transcription.id - transcription Identifier (not used, but in place for more RESTful endpoint)
   * @param {object} data - { uuid, duration }
   */
  checkStatus(id, data) {
    return Axios.post(`${RESOURCE}/status`, data);
  },
};
