import Axios from 'axios';

const RESOURCE = '/api/teams';

export default {
  getUserTeams() {
    return Axios.get(`${RESOURCE}`);
  },
  getUserInvites() {
    return Axios.get(`${RESOURCE}/invites`);
  },
  addUser(userInfo) {
    return Axios.post(`${RESOURCE}`, userInfo);
  },
  acceptInvite(data) {
    return Axios.post(`${RESOURCE}/invites`, data);
  },
  resendInvite(data) {
    return Axios.put(`${RESOURCE}/invites`, data);
  },
  declineInvite(teamId) {
    return Axios.delete(`${RESOURCE}/${teamId}/invites`,);
  },
  deleteUser({ id, teamId }) {
    return Axios.delete(`${RESOURCE}/${teamId}/user/${id}`);
  },
  deleteAdditionalSeats() {
    return Axios.delete(`${RESOURCE}`);
  },
  deleteEmpySeat() {
    return Axios.delete(`${RESOURCE}/empty`);
  },
  removeSeat(teamOwnerId) {
    return Axios.delete(`${RESOURCE}/${teamOwnerId}/seats`);
  },
  changeTeam(teamId) {
    return Axios.post(`${RESOURCE}/change`, { teamId });
  },
  leaveTeam(teamId) {
    return Axios.post(`${RESOURCE}/leave`, { teamId });
  },
  changeTeamName(data) {
    return Axios.put(`${RESOURCE}/name`, data);
  },
  changeTeamColor(data) {
    return Axios.put(`${RESOURCE}/color`, data);
  },
  changeRole(data) {
    return Axios.put(`${RESOURCE}/role`, data);
  },
  addSeats(data) {
    return Axios.post(`${RESOURCE}/seats`, data);
  }
};
