<template>
  <div>
    <div>
      <wavesurfer></wavesurfer>
    </div>
    <div>
      <div class="controls">
        <div class="columns">
          <div class="column is-one-third-desktop is-full-mobile">
            <el-button id="videoPlayAudioButton" type="primary" v-if="!playing" @click="play()"
              class="is-pulled-left circle-button large">
              <span class="fas fa-play"></span>
            </el-button>
            <el-button type="primary" v-else @click="pause()" class="is-pulled-left circle-button large">
              <span class="fas fa-pause"></span>
            </el-button>
            <el-tooltip content="Go to the start of the clip" placement="bottom" effect="light"
              popper-class="hint-tooltip">
              <el-button type="primary" class="is-pulled-left circle-button" @click="restart()">
                <span class="fas fa-step-backward"></span>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Listen to the last 3 seconds" placement="bottom" effect="light"
              popper-class="hint-tooltip">
              <el-button type="primary" class="is-pulled-left circle-button" @click="playEnding()">
                <span class="icon-last-3"></span>
              </el-button>
            </el-tooltip>
            <el-tooltip content="Clear selection" placement="bottom" effect="light" popper-class="hint-tooltip">
              <el-button type="primary" class="is-pulled-left circle-button" @click="clearSelection()">
                <span class="icon-clear-selection"></span>
              </el-button>
            </el-tooltip>
          </div>
          <div class="column is-one-third-desktop is-hidden-touch">
            <div class="columns has-small-padding-top">
              <div class="column is-half has-text-centered">
                <p class="is-size-6-7">Start Time</p>
                <masked-input v-model="startTimeString" type="text" name="start"
                  class=" column el-input__inner masked-input" :mask="getTimeMask(startTimeString)" :guide="true"
                  @change="updateTimeInput" @blur="updateTimeInput" :keepCharPositions="true"></masked-input>
              </div>
              <div class="column is-half has-text-centered">
                <p class="is-size-6-7">End Time</p>
                <masked-input v-model="endTimeString" type="text" name="end" class=" column el-input__inner masked-input"
                  :mask="getTimeMask(endTimeString)" :guide="true" @change="updateTimeInput" @blur="updateTimeInput"
                  :keepCharPositions="true"></masked-input>
              </div>
            </div>
            <el-tooltip :content="this.selectedDurationString" placement="bottom" popper-class="hint-tooltip">
              <p class="has-text-centered">{{ this.selectedDurationHumanReadableString }}</p>
            </el-tooltip>
          </div>

          <div
            class="is-hidden-desktop is-clearfix has-small-padding-top is-hidden-desktop has-margin-top has-margin-right">
            <div class="has-large-margin-top is-flex has-margin-left">
              <el-button type="primary" class=" small-circle-button" :disabled="zoomLevel <= 20" @click="zoomOut()">
                <span class="fas fa-search-minus zoom-icon"></span>
              </el-button>
              <el-slider class=" slider" v-model="zoomLevel" :min="minPxPerSec" :max="100" :step="10"
                @input="val => setZoom(val)" :show-tooltip="false"></el-slider>
              <el-button type="primary" class=" small-circle-button" :disabled="zoomLevel >= 100" @click="zoomIn()">
                <span class="fas fa-search-plus zoom-icon"></span>
              </el-button>
            </div>
          </div>
          <div class="column is-one-third-desktop is-hidden-touch ">
            <div class="zoom-container">
              <el-button type="primary" class="is-pulled-right small-circle-button" :disabled="zoomLevel <= 1"
                @click="zoomOut()">
                <span class="fas fa-search-minus zoom-icon"></span>
              </el-button>
              <el-slider class="is-pulled-right slider" :min="minPxPerSec" :max="100" :step="10" v-model="zoomLevel"
                @input="val => setZoom(val)" :show-tooltip="false"></el-slider>

              <el-button type="primary" class="is-pulled-right small-circle-button" :disabled="zoomLevel >= 100"
                @click="zoomIn()">
                <span class="fas fa-search-plus zoom-icon"></span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="is-clearfix has-small-padding-top is-hidden-desktop">
          <div>
            <p class="is-size-6-7">Start Time</p>
            <masked-input v-model="startTimeString" type="text" name="start" class=" column el-input__inner masked-input"
              :mask="getTimeMask(startTimeString)" :guide="true" @change="updateTimeInput" @blur="updateTimeInput"
              :keepCharPositions="true"></masked-input>
          </div>
          <div class="has-small-margin-top ">
            <p class="is-size-6-7">End Time</p>
            <masked-input v-model="endTimeString" type="text" name="end" class=" column el-input__inner masked-input"
              :mask="getTimeMask(endTimeString)" :guide="true" @change="updateTimeInput" @blur="updateTimeInput"
              :keepCharPositions="true"></masked-input>
          </div>
          <div class="has-small-margin-top">
            <p class="is-size-6-7">{{ this.selectedDurationHumanReadableString }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MaskedInput from 'vue-text-mask';
import EventBus from '@/helpers/event-bus';

import secondsToTimeString from '@/util/secondsToTimeString';
import Wavesurfer from './Wavesurfer.vue';

function getSeconds(string) {
  if (string.split(':').length > 2) {
    const [hours, minutes, secondsCs] = string.split(':');
    const [seconds, centiSeconds] = secondsCs.split('.');
    return parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10) + parseInt(centiSeconds, 10) / 100;
  }
  const [minutes, secondsCs] = string.split(':');
  const [seconds, centiSeconds] = secondsCs.split('.');
  return parseInt(minutes, 10) * 60 + parseInt(seconds, 10) + parseInt(centiSeconds, 10) / 100;
}

export default {
  name: 'AudioViewerWavesurfer',
  components: {
    MaskedInput,
    Wavesurfer,
  },
  data() {
    return {
      selectedStart: 0,
      selectedEnd: 0,
      startTimeString: '',
      endTimeString: '',
      duration: 100,
      // timeMask: [/[0-9]/, /[0-9]/, ':', /[0-6]/, /[0-9]/, '.', /[0-9]/, /[0-9]/],
      // timeRegex: /[0-9]:[0-9][0-9]:[0-6][0-9]\.[0-9][0-9]/,
      audio: null,
      time: 0,
      minPxPerSec: 20,
      waveformReady: false,
      zoomLevel: this.minPxPerSec,
      playing: false,
    };
  },

  computed: {
    selectedDuration() {
      return this.selectedEnd - this.selectedStart;
    },
    selectedDurationHumanReadableString() {
      const duration = this.selectedDuration;
      const h = Math.floor(duration / 3600);
      const m = Math.floor((duration % 3600) / 60);
      const s = Math.floor((duration % 3600) % 60);
      const selectedText = ' selected';
      const hDisplay = h > 0 ? h + (h === 1 ? ' hour ' : ' hours ') : '';
      const mDisplay = m > 0 ? m + (m === 1 ? ' minute ' : ' minutes ') : '';
      const sDisplay = s > 0 ? s + (s === 1 ? ' second' : ' seconds') : '';
      return hDisplay + mDisplay + sDisplay + selectedText;
    },
    selectedDurationString() {
      let num = this.selectedDuration.toFixed(2);
      let suffix = 'seconds';
      if (+this.selectedDuration.toFixed(0) === +num) {
        num = this.selectedDuration.toFixed(0);
        if (num === '1') {
          suffix = 'second';
        }
      }
      return `${num} ${suffix}`;
    },
    durationIsLessThanOneHour() {
      return this.duration < 3600;
    },
  },
  mounted() {
    EventBus.$on('updated:startTime', val => this.updateStartTime(val));
    EventBus.$on('updated:endTime', val => this.updateEndTime(val));
    EventBus.$on('setPlaying', playing => this.setPlaying(playing));
    EventBus.$on('setDuration', duration => this.setDuration(duration));
    EventBus.$on('setMinPxPerSec', val => this.setMinPxPerSec(val));
    EventBus.$on('setWaveformReady', val => this.setWaveformReady(val));
  },
  methods: {
    getTimeRegex(timeString) {
      if (timeString.split(':').length > 2) {
        return /[0-9]:[0-9][0-9]:[0-6][0-9]\.[0-9][0-9]/;
      }
      return /[0-9][0-9]:[0-6][0-9]\.[0-9][0-9]/;
    },
    setWaveformReady(val) {
      this.waveformReady = val;
    },
    setMinPxPerSec(val) {
      this.minPxPerSec = val;
    },
    updateTimeInput() {
      if (!this.getTimeRegex(this.startTimeString).test(this.startTimeString) || !this.getTimeRegex(this.endTimeString).test(this.endTimeString)) {
        return;
      }

      const start = getSeconds(this.startTimeString);
      const end = getSeconds(this.endTimeString);
      if (start > end) {
        return;
      }
      if (start < 0) {
        return;
      }
      if (end > this.duration * 1.01) {
        return;
      }

      EventBus.$emit('addRegion', { selectedStart: Math.max(0, start), selectedEnd: Math.min(this.duration, end) });
    },
    updateStartTime(val) {
      this.selectedStart = val;
      this.startTimeString = secondsToTimeString(this.selectedStart);
    },
    updateEndTime(val) {
      this.selectedEnd = val;
      this.endTimeString = secondsToTimeString(this.selectedEnd);
    },
    getTimeMask(timeString) {
      // if (timeString.length > 8) return [/[0-9]/, /[0-9]/, /[0-9]/, ':', /[0-6]/, /[0-9]/, '.', /[0-9]/, /[0-9]/];
      if (timeString.split(':').length > 2) {
        return [/[0-9]/, ':', /[0-9]/, /[0-9]/, ':', /[0-6]/, /[0-9]/, '.', /[0-9]/, /[0-9]/];
      }
      return [/[0-9]/, /[0-9]/, ':', /[0-6]/, /[0-9]/, '.', /[0-9]/, /[0-9]/];
    },
    async updateAudioPosition(time) {
      this.isSliding = true;
      this.audio.currentTime = Math.floor(time * 100) / 100;
      this.isSliding = false;
    },
    setDuration(duration) {
      this.duration = duration;
    },
    setPlaying(playing) {
      this.playing = playing;
    },
    play() {
      EventBus.$emit('play');
    },
    playEnding() {
      EventBus.$emit('playEnding');
    },
    clearSelection() {
      EventBus.$emit('clearSelection');
    },
    restart() {
      EventBus.$emit('restart');
    },
    pause() {
      this.setPlaying(false);

      EventBus.$emit('pause');
    },
    zoomIn() {
      this.zoomLevel = this.zoomLevel + 10 > 100 ? 100 : this.zoomLevel + 10;
      EventBus.$emit('setZoomLevel', this.zoomLevel);
    },
    setZoom(val) {
      this.zoomLevel = val;
      EventBus.$emit('setZoomLevel', this.zoomLevel);
    },
    zoomOut() {
      this.zoomLevel = this.zoomLevel - 10 < 20 ? 20 : this.zoomLevel - 10;
      EventBus.$emit('zoomOut', this.zoomLevel);
    },
  },
};
</script>

<style lang="scss">
.hint-tooltip {
  border: none !important;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);

  .popper__arrow {
    border: none !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
  }
}

.controls {
  // el-button circle doesn't work with fa or custom icons
  margin-top: 20px;

  .circle-button {
    border: none;
    width: 45px;
    height: 45px;
    padding: 8px 2px;
    border-radius: 22.5px;
    text-align: center;
    font-size: 18px;
    line-height: 1.40857;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

    &.large {
      width: 60px;
      height: 60px;
      padding: 6px 2px 9px 6px;
      border-radius: 30px;
      font-size: 24px;
      line-height: 1.33;
      margin-top: -6px;
    }
  }

  .small-circle-button {
    border: none;
    width: 30px;
    height: 30px;
    padding: 2px 2px;
    border-radius: 22.5px;
    text-align: center;
    font-size: 12px;
    justify-content: center;
    line-height: 1.40857;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;

    &.mobile {
      padding: 6px 2px 9px 6px;
    }
  }

  .column .masked-input {
    width: 90%;
    margin: 0 auto;
    font-size: 0.875em;
  }
}

.zoom-icon {
  width: 10px;
  height: 10px;
}

.zoom-container {
  display: flex;
  align-items: center;
  justify-content: right;
}

.slider {
  width: 100px;
  margin-right: 10px;
  margin-left: 10px;
  padding: 0 5px;
  text-align: center;
}

// custom icons
.icon-last-3 {
  background: url('https://s3-us-west-2.amazonaws.com/wavve-assets/last-3s-white.svg') no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  width: 36px;
  margin-top: -5px;
}

.icon-clear-selection {
  background: url('https://wavve-web-assets.s3.amazonaws.com/clear-selection-white.svg') no-repeat top left;
  background-size: contain;
  cursor: pointer;
  display: inline-block;
  height: 36px;
  width: 36px;
  margin-top: -3px;
}

.el-dialog {
  overflow: scroll;
  border-radius: 5px;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 992px) {
  handle.wavesurfer-handle {
    width: 4px !important;
  }
}
</style>
