<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'default',

  mounted() {},

  data() {
    return {};
  },

  methods: {},
};
</script>
