import Axios from 'axios';

const RESOURCE = '/api/jobs';

export default {
  /**
   * Find job by guid
   * @param {string} job.id - Job Identifier
   */
  get(id) {
    return Axios.get(`${RESOURCE}/${id}`);
  },

  /**
   * Find job by guid
   * @param {string} job.guid - S3 Job Identifier
   */
  findByGuid(guid) {
    return Axios.get(`${RESOURCE}/${guid}/guid`);
  },

   clone(id) {
    return Axios.put(`${RESOURCE}/${id}/clone`);
  },

  /**
   * Get job design by guid
   * @param {string} job.guid - S3 Job Identifier
   */
  findDesignByGuid(guid) {
    return Axios.get(`${RESOURCE}/${guid}/guid/design`);
  },

  /**
   * Not used. See this instead: /api/users/:id/job
   */
  create(data) {
    return Axios.post(RESOURCE, data);
  },

  /**
   * Edit a single job
   * @param {string} job.id - Job Identifier
   * @param {object} data - New job properties.
   */
  update(id, data) {
    return Axios.put(`${RESOURCE}/${id}`, data);
  },



  /**
   * Delete a single job
   * @param {string} job.id - Job Identifier
   */
  delete(id) {
    return Axios.delete(`${RESOURCE}/${id}`);
  },

  /**
   * Retry Job - Admins Only
   * @param {string} job.id - Job Identifier
   * @param {object} data - Job theme object
   */
  retry(id, data) {
    return Axios.post(`${RESOURCE}/${id}/retry`, data);
  },
  /**
   * Get Job creation status
   * @param {string} job.id - Job Identifier (not used, but in place for more RESTful endpoint)
   * @param {object} data - { uuid, duration }
   */
  checkStatus(id, data) {
    return Axios.post(`${RESOURCE}/status`, data);
  },
};
