<template>
  <div v-loading="!ready">
    <div id="waveform"></div>
  </div>
</template>

<script>
import Axios from 'axios';
import WaveSurfer from 'wavesurfer.js';
import Cursor from 'wavesurfer.js/dist/plugin/wavesurfer.cursor';
import Regions from 'wavesurfer.js/dist/plugin/wavesurfer.regions';
import EventBus from '@/helpers/event-bus';
import { SET_AUDIO_REGION } from '@/store/action-types';
import secondsToTimeString from '@/util/secondsToTimeString';

export default {
  data() {
    return {
      waveSurfer: {},
      selectedStart: 0,
      selectedEnd: 0,
      waveformReady: false,
      regionColor: 'rgba(113, 235, 234, 0.5)',
      ready: false,
    };
  },
  computed: {
    selectedMedia() {
      return this.$store.state.selectedMedia;
    },
    audioRegion() {
      return this.$store.state.audioRegion;
    },
  },

  async mounted() {
    this.$nextTick(async () => {
      const options = {
        waveColor: '#7fa7ff',
        progressColor: '#4d47e8',
        cursorColor: '#ffa500',
        cursorWidth: 2,
        partialRender: true,
        pixelRatio: 1,
      };
      const wsOptions = Object.assign(
        {
          container: document.querySelector('#waveform'),
          backend: 'MediaElement',
          plugins: [
            Cursor.create({
              showTime: true,
              opacity: 1,
              formatTimeCallback: secondsToTimeString,
              customShowTimeStyle: {
                'background-color': '#000',
                color: '#fff',
                padding: '2px',
                'font-size': '10px',
              },
            }),
            Regions.create({
              minLength: 3,
              dragSelection: {
                slop: 5,
              },
            }),
          ],
        },
        options
      );

      console.log('starting wavesurfer with options', wsOptions);

      try {
        // eslint-disable-next-line new-cap
        this.waveSurfer = new WaveSurfer.create(wsOptions); // create wavesurfer object
        // this.waveSurfer.load(this.selectedMedia.s3AudioUrl); // load data

        const instance = Axios.create();
        instance.defaults.headers.common = {};
        instance.defaults.headers.common.accept = 'application/json';
        console.log('loading peaks...');
        const peaks = await instance.get(this.selectedMedia.peaksUrl, { withCredentials: false });
        console.log('loaded peaks. sample rate:', peaks.data.sample_rate);
        console.log('loaded peaks. data:', peaks.data.data);
        this.waveSurfer.load(this.selectedMedia.s3AudioUrl, peaks.data.data); // load data
      } catch (error) {
        console.log(error);
        this.waveSurfer.load(this.selectedMedia.s3AudioUrl); // load data
      }

      try {
        this.waveSurfer.on('region-created', () => {
          // add region creation listener
          this.waveSurfer.regions.clear();
        });
        this.waveSurfer.on('ready', () => {
          this.ready = true;

          this.waveSurfer.on('pause', () => {
            EventBus.$emit('setPlaying', false);
          });
          this.waveSurfer.on('play', () => {
            EventBus.$emit('setPlaying', true);
          });

          this.waveSurfer.enableDragSelection({
            drag: true,
            slop: 1,
          });
          // update region time
          EventBus.$emit('updated:startTime', 0);
          EventBus.$emit('updated:endTime', this.waveSurfer ? this.waveSurfer.getDuration() : 0);
          EventBus.$emit('setDuration', this.waveSurfer ? this.waveSurfer.getDuration() : 0);
          this.selectedStart = 0;
          this.selectedEnd = this.waveSurfer ? this.waveSurfer.getDuration() : 0;
          this.waveSurfer.on('region-updated', region => {
            if (region.end - region.start < 3 && this.waveSurfer) {
              region.end = region.start + 3 < this.waveSurfer.getDuration() ? region.start + 3 : this.waveSurfer.getDuration();
            }
            region.color = this.regionColor;
            this.selectedStart = region.start;
            this.selectedEnd = region.end;

            this.$store.dispatch(SET_AUDIO_REGION, { selectedStart: this.selectedStart, selectedEnd: this.selectedEnd, zoomLevel: this.zoomLevel });
            EventBus.$emit('updated:startTime', region.start);
            EventBus.$emit('updated:endTime', region.end);

            if (this.waveSurfer.isPlaying()) {
              this.waveSurfer.pause();
            }
          });

          if (this.audioRegion) {
            this.addRegion({ selectedStart: this.audioRegion.selectedStart, selectedEnd: this.audioRegion.selectedEnd });

            if (this.ready) this.setZoomLevel(this.audioRegion.zoomLevel);
          }

          EventBus.$emit('setMinPxPerSec', this.waveSurfer.params.minPxPerSec);
          EventBus.$emit('setWaveformReady', true);
        });

        EventBus.$on('play', () => this.play());
        EventBus.$on('pause', () => this.pause());
        EventBus.$on('restart', () => this.restart());
        EventBus.$on('playEnding', () => this.playEnding());
        EventBus.$on('setZoomLevel', val => this.setZoomLevel(val));
        EventBus.$on('clearSelection', () => this.clearSelection());
        EventBus.$on('addRegion', borders => this.addRegion(borders));
      } catch (error) {
        console.log('error in setting up wavesurfer events', error);
      }
    });
  },

  methods: {
    addRegion({ selectedStart, selectedEnd }) {
      this.waveSurfer.addRegion({
        start: selectedStart,
        end: selectedEnd,
        color: this.regionColor,
      });

      this.selectedStart = selectedStart;
      this.selectedEnd = selectedEnd;

      EventBus.$emit('updated:startTime', selectedStart);
      EventBus.$emit('updated:endTime', selectedEnd);
    },
    setZoomLevel(zoomLevel) {
      try {
        if (this.ready) {
          if (zoomLevel <= 20) {
            this.waveSurfer.zoom(false);
          } else {
            this.waveSurfer.zoom(zoomLevel);
          }
        }
      } catch (error) {
        console.log(error);
      }
    },

    play() {
      try {
        this.waveSurfer.play();
      } catch (error) {
        console.log('error in play', error);
      }
    },
    pause() {
      try {
        this.waveSurfer.pause();
      } catch (error) {
        console.log('error in pause', error);
      }
    },
    restart() {
      try {
        this.waveSurfer.play(this.selectedStart, this.selectedEnd);
      } catch (error) {
        console.log('error in restart', error);
      }
    },
    playEnding() {
      try {
        this.waveSurfer.play(this.selectedEnd - 3, this.selectedEnd);
        EventBus.$emit('setPlaying', true);
      } catch (error) {
        console.log('error in playEnding', error);
      }
    },
    clearSelection() {
      try {
        this.waveSurfer.regions.clear();
        this.selectedStart = 0;
        this.selectedEnd = this.waveSurfer ? this.waveSurfer.getDuration() : 0;
      } catch (error) {
        console.log('error in clearSelection', error);
      }
    },
  },
  beforeDestroy() {
    try {
      this.waveSurfer.backend.ac.close();
      } catch (error) {
      // console.log(error);
    }
   try {
      if (this.waveSurfer.backend.source) delete this.waveSurfer.backend.source.buffer;
      // Clearing cache manually prevents memory leak
      
    } catch (error) {
      // console.log(error);
    }
    if (this.waveSurfer) {
        this.waveSurfer.unAll();
        this.waveSurfer.empty();
        this.waveSurfer.destroy();
      }
  },
};
</script>

<style lang="scss" scoped>
div {
  position: relative;
}
</style>
