import UserService from '@/api/user.service';
import { IS_PRODUCTION } from '@/config';
import store from '@/store';
import * as actions from '../store/action-types';

export async function launchCancelFlow(user) {
  const customerId = user?.stripe.id;

  if (!customerId) {
    return;
  }

  try {
    const { authHash, customerAttributes } = await UserService.getChurnKeyHash(customerId);

    window.churnkey.init('show', {
      appId: 'ikmp8qyfz',
      provider: 'stripe',
      mode: IS_PRODUCTION ? 'live' : 'test',
      customerId,
      authHash,
      onClose: async result => {
        store.dispatch(actions.SYNC_USER_TO_DB).catch();
      },
      customerAttributes,
    });
  } catch (error) {
    console.log('Error', error);
  }
}

export async function pauseWallCheck(user, logoutHandler) {
  const customerId = user?.stripe.id;

  if (!customerId) {
    return;
  }

  try {
    const { authHash } = await UserService.getChurnKeyHash(customerId);

    window.churnkey.check('pause', {
      customerId,
      authHash,
      appId: 'ikmp8qyfz',
      mode: IS_PRODUCTION ? 'live' : 'test',
      provider: 'stripe',
      softWall: true,
      forceCheck: true,
      handleLogout: logoutHandler,
      onCancel: () => {
        store.dispatch(actions.SYNC_USER_TO_DB).catch();
      },
      onResume: () => {
        store.dispatch(actions.SYNC_USER_TO_DB).catch();
      },
    });
  } catch (error) {
    console.log('Error', error);
  }
}

export async function failedPaymentCheck(user, logoutHandler) {
  const customerId = user?.stripe.id;

  if (!customerId) {
    return;
  }

  try {
    const { authHash } = await UserService.getChurnKeyHash(customerId);

    window.churnkey.check('failed-payment', {
      customerId,
      authHash,
      appId: 'ikmp8qyfz',
      mode: IS_PRODUCTION ? 'live' : 'test',
      provider: 'stripe',
      softWall: false,
      forceCheck: true,
      gracePeriodDays: 0,
      ignoreInvoicesWithoutAttempt: true,
      onUpdatePaymentInformation() {
        store.dispatch(actions.SYNC_USER_TO_DB).catch();
      },
      handleLogout: logoutHandler,
      onCancel: () => {
        store.dispatch(actions.SYNC_USER_TO_DB).catch();
      },
      onResume: () => {
        store.dispatch(actions.SYNC_USER_TO_DB).catch();
      },
    });
  } catch (error) {
    console.log('Error', error);
  }
}
