/* eslint-disable class-methods-use-this */
import Axios from 'axios';
import moment from 'moment';
import pako from 'pako';
import { v4 as uuidv4 } from 'uuid';

import { WAVVE_API_BASE_URL } from '../config';

const rrweb = require('rrweb');

const URL = `${WAVVE_API_BASE_URL}/deflector`;

export default class SessionRecorder {
  constructor(wavveId = '', url = URL) {
    this.wavveId = wavveId;
    this.url = url;
    this.events = [];
    this.fileName = '';

    // Timer
    this.startTime = 0;
    this.endTime = 0;
    this.session = '';
    this.stopFn = {};

    // Initialize Recorder
    this.init = this.init.bind(this);
    this.setSession = this.setSession.bind(this);

    // Actions
    this.record = this.record.bind(this);
    this.stop = this.stop.bind(this);
    this.upload = this.upload.bind(this);

    // Compression
    this.minimize = this.minimize.bind(this);
    this.restore = this.restore.bind(this);

    this.init();
  }

  init() {
    this.setSession();
  }

  setSession() {
    this.session = uuidv4();
    this.startTime = moment().unix();
    this.events = [];
  }

  record() {
    const self = this;
    this.stopFn = rrweb.record({
      emit(event, checkout) {
        if (checkout) {
          self.upload(self.url);
        }
        // Add latest event
        self.events.push(event);
      },
      // max record chunk of 3 minutes
      checkoutEveryNms: 1000 * 60 * 3,
    });
  }

  stop() {
    // upload event data on recording stop
    this.upload(this.url);
    this.stopFn();
  }

  minimize(source) {
    return pako.deflate(JSON.stringify(source), { to: 'string' });
  }

  restore(binaryString) {
    return JSON.parse(pako.inflate(binaryString, { to: 'string' }));
  }

  async upload(url) {
    if (this.startTime) {
      this.endTime = moment().unix();
    }

    const squashedData = this.minimize(this.events);

    // Request body
    const params = {
      data: squashedData,
      session: this.session,
      startTime: this.startTime,
      endTime: this.endTime,
      fileName: `${JSON.stringify(this.startTime)}.json`,
      eventGroup: this.wavveId,
    };

    // update session on upload
    this.setSession();

    try {
      const response = await Axios.post(url, params);
      console.log('success response', response);
    } catch (err) {
      console.error(err);
    }
  }
}
