/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
/* eslint no-var: off */
import axios from 'axios';
import Parser from 'rss-parser';
import router from '@/router';
import EventBus from '@/helpers/event-bus';
import * as mutations from '@/store/mutation-types';
import * as actions from '@/store/action-types';
import * as events from '@/config/gtm-events';
import AuthService from '@/api/auth.service';
import UserService from '@/api/user.service';
import TeamsService from '@/api/teams.service';
import TranscriptionService from '@/api/transcription.service';
import JobService from '@/api/job.service';
import ZubtitleService from '@/api/zubtitle.service';
import ThemeService from '@/api/theme.service';
import AwsService from '@/api/aws.service';
import MediaService from '@/api/media.service';
import LinkService from '@/api/link.service';
import MailingListService from '@/api/mailing-list.service';
import { demoThemes } from '@/config/default-themes';
import SessionRecorder from '@/helpers/eventRecorder';
import User from './models/User';
import Link from './models/Link';
import { WAVVE_API_BASE_URL, DEST_BUCKET, OPEN_REPLAY_ENABLED } from '../config';
import Media from './models/Media';

const PROXY_BASE_URL = 'https://redirect.wavve.co'; // Runs on a t2.nano instance on EC2
import { LOAD_PENDING_TRANSCRIPTIONS } from '@/store/action-types';
import { OpenReplayTracker } from '../main';
import { failedPaymentCheck, pauseWallCheck } from '../helpers/launchChurnKey';

var PollingTimeout = null;

const setup = async ({ commit }, payload) => {
  try {
    await UserService.startUserSetup(payload);
  } catch (e) {
    throw e;
  }
};

const signup = async ({ commit, dispatch }, payload) => {
  try {
    const response = await UserService.createUser(payload);
    const { token } = response;
    localStorage.setItem('token', `Bearer ${token}`);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    commit(mutations.SET_AUTH_SUCCESS, token);
    const me = await UserService.getMe();
    const user = new User(me);
    commit(mutations.SET_USER, user);
    // await UserService.createEmailVerificationRequest({ email: user.email });
    dispatch(actions.FETCH_PODCAST_RSS);
    await UserService.createEmailVerificationRequest({ email: user.email });

    EventBus.$emit('updateIntercom', user);
    EventBus.$emit('trackTapfiliate', user.id);

    const eventID = events.generateEventId();

    EventBus.$emit('trackGTM', { ...events.newUser, user: user.id, 'x-fb-event_id': eventID });
    EventBus.$emit('trackGTM', { event: 'start_pw', pw_user_email: user.email });
    if (window.fbq) window.fbq('track', 'Lead', { user_data: { email_address: user.email } }, { eventID });
  } catch (e) {
    commit(mutations.SET_AUTH_ERROR);
    localStorage.removeItem('token');
    throw e;
  }
};

const syncUserToDb = async ({ commit, dispatch }) => {
  try {
    let user;
    const me = await UserService.getMe();
    // check if stripe record exists
    if (me.stripe && me.stripe.id) {
      const sub = await UserService.getBillingStatus(me._id);
      user = new User(me, sub);
    } else {
      user = new User(me);
    }

    commit(mutations.SET_USER, user);
    dispatch(actions.TEAMS_GET_INVITES_ACTION);
    dispatch(actions.TEAMS_GET_TEAMS_ACTION);

    const now = Date.now();
    const diffTimeTrial = Math.abs(new Date(user.stripePlan.trialEndAt).getTime() - now);
    const diffDaysTrial = Math.ceil(diffTimeTrial / (1000 * 60 * 60 * 24));

    // Usetiful Configuration
    window.usetifulTags = {
      userId: user.id,
      jobsCount: user.jobs?.length || 0,
      mediaCount: user.media?.length || 0,
      cardsCount: user.cards?.length || 0,
      linksCount: user.links?.length || 0,
      isPlanActive: user.stripePlan?.active ? 'true' : 'false',
      isTrial: user.stripePlan?.isTrial ? 'true' : 'false',
      isPause: user.stripePlan?.isPause ? 'true' : 'false',
      isCanceled: user.stripePlan?.isCanceled ? 'true' : 'false',
      daysUntilTrialEnd: user.stripePlan?.isTrial ? diffDaysTrial : 0,
      trialRedeem: user.trialRedeem ? 'true' : 'false',
      emailVerified: user.emailVerified ? 'true' : 'false',
      workRole: user.workRole,
    };

    // OpenReplay Configuration
    if (OpenReplayTracker && OPEN_REPLAY_ENABLED === 'true') {
      OpenReplayTracker.setUserID(user.email);
      OpenReplayTracker.setMetadata('isTrial', user.stripePlan?.isTrial ? 'true' : 'false');
      OpenReplayTracker.setMetadata('isPause', user.stripePlan?.isPause ? 'true' : 'false');
      OpenReplayTracker.setMetadata('isPlanActive', user.stripePlan?.active ? 'true' : 'false');
      OpenReplayTracker.setMetadata('jobsCount', user.jobs?.length || '0');
      OpenReplayTracker.setMetadata('workRole', user.workRole);

      if (!OpenReplayTracker.isActive()) {
        OpenReplayTracker.start().catch(error => console.log(error));
      }
    }

    // Chatra Configuration
    window.ChatraIntegration = {
      dateAdded: user.dateAdded ? user.dateAdded.toISOString() : '',
      name: user.name ? user.name + user.lastName : undefined,
      email: user.email,
      phone: user.phone,
      clientId: user.id + user.dateAdded.getTime().toString(),
      jobsCount: user.jobs?.length || 0,
      mediaCount: user.media?.length || 0,
      cardsCount: user.cards?.length || 0,
      linksCount: user.links?.length || 0,
      isPlanActive: user.stripePlan?.active,
      isTrial: user.stripePlan?.isTrial,
      isPause: user.stripePlan?.isPause,
      isCanceled: user.stripePlan?.isCanceled,
      daysUntilTrialEnd: user.stripePlan?.isTrial ? diffDaysTrial : 0,
      trialRedeem: user.trialRedeem,
      emailVerified: user.emailVerified,
      workRole: user.workRole,
    };

    window.churnkey.clearState();

    pauseWallCheck(user, async () => {
      window.churnkey.clearState();
      await router.replace('/logout');
      dispatch(actions.LOGOUT).catch();
    });

    failedPaymentCheck(user, async () => {
      window.churnkey.clearState();
      await router.replace('/logout');
      dispatch(actions.LOGOUT).catch();
    });

    dispatch(actions.FETCH_PODCAST_RSS);
  } catch (err) {
    console.log('Error syncing user', err);
  }
};

const login = async ({ commit, dispatch }, payload) => {
  commit(mutations.SET_AUTH_LOADING);

  try {
    const response = await AuthService.login(payload);
    const { token } = response;
    localStorage.setItem('token', `Bearer ${token}`);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    commit(mutations.SET_AUTH_SUCCESS, token);
    const me = await UserService.getMe();
    let user;
    // check if stripe record exists
    if (me.stripe && me.stripe.id) {
      const sub = await UserService.getBillingStatus(me._id);
      user = new User(me, sub);
    } else {
      user = new User(me);
    }
    commit(mutations.SET_USER, user);

    dispatch(actions.FETCH_PODCAST_RSS);
    EventBus.$emit('loginBoot', me);
    EventBus.$emit('updateIntercom', me);
    // EventBus.$emit('chatraBoot', me);
    EventBus.$emit('trackGTM', { event: 'start_pw', pw_user_email: me.email });
  } catch (e) {
    commit(mutations.SET_AUTH_ERROR);
    localStorage.removeItem('token');
  }
};

const logout = async ({ commit }) => {
  window.churnkey.clearState();

  try {
    await AuthService.logout();
  } catch (err) {
    console.log('Error logging out', err);
    commit(mutations.SET_LOGOUT);
    EventBus.$emit('destroyIntercom');
    localStorage.removeItem('token');
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
    commit(mutations.SET_USER, null);
    // await router.push('/blank').catch();
  } finally {
    commit(mutations.SET_LOGOUT);
    EventBus.$emit('destroyIntercom');
    localStorage.removeItem('token');
    localStorage.removeItem('token');
    delete axios.defaults.headers.common.Authorization;
    commit(mutations.SET_USER, null);
    localStorage.removeItem('token');
    // await router.push('/blank').catch();
  }
};

const clearToken = async ({ commit }) => {
  commit(mutations.SET_LOGOUT);
  EventBus.$emit('destroyIntercom');
  localStorage.removeItem('token');
  delete axios.defaults.headers.common.Authorization;
  commit(mutations.SET_USER, null);
  // await router.push('/blank').catch();
};

const refreshAuthToken = async ({ commit, dispatch }) => {
  try {
    if (axios.defaults.headers.common.Authorization == undefined) {
      return;
    }
    const { token } = await AuthService.refresh();

    if (token != undefined) {
      // localStorage.removeItem('token');
      localStorage.setItem('sessiontoken', `Bearer ${token}`);
      // localStorage.setItem('token', `Bearer ${token}`);
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      // commit(mutations.SET_AUTH_SUCCESS, token);
      await dispatch(actions.SYNC_USER_TO_DB);
      // syncUserToDb
    } else {
      console.log('Failed');
    }
  } catch (_) {
    /* Do nothing on error */
  }
};

// Save canvas for history
const saveCanvas = async ({ commit }, canvas) => {
  commit(mutations.SAVE_CANVAS, canvas);
};

const saveHistory = async ({ commit }, history) => {
  commit(mutations.SAVE_HISTORY, history);
};

const clearCanvas = async ({ commit }) => {
  commit(mutations.CLEAR_CANVAS);
};

const setAudioUploadFile = ({ commit }, { file, type }) => {
  if (file) {
    commit(mutations.SET_UPLOAD_AUDIO_PROCESSING, true);

    const urlReader = new FileReader();
    urlReader.addEventListener('load', () => {
      commit(mutations.SET_AUDIO_UPLOAD_DATA_URL, urlReader.result);
      commit(mutations.SET_AUDIO_TYPE, type);
      commit(mutations.SET_UPLOAD_AUDIO_PROCESSING, false);
    });
    return urlReader.readAsDataURL(file);
  }

  return 'nofile';
};

// AUDIO ACTIONS
const setAudioFile = ({ dispatch, commit }, { file, type }) => {
  if (file) {
    const start = +new Date();
    commit(mutations.SET_AUDIO_READY, false);
    commit(mutations.SET_AUDIO_READING, true);

    const urlReader = new FileReader();
    urlReader.addEventListener(
      'load',
      () => {
        window.audioResult = urlReader.result;
        commit(mutations.SET_AUDIO_DATA_URL, urlReader.result);
        commit(mutations.SET_AUDIO_TYPE, type);
      },
      { once: false }
    );
    urlReader.readAsDataURL(file);

    const arrayBufferReader = new FileReader();
    arrayBufferReader.addEventListener(
      'load',
      () => {
        const end = +new Date();
        const time = end - start;
        const timeEstimate = time * 3.5; // decoding generally takes 2.5 times more than readAsArrayBuffer call
        commit(mutations.SET_AUDIO_BUFFER, arrayBufferReader.result);
        dispatch(actions.DECODE_AUDIO_BUFFER, timeEstimate);
        commit(mutations.SET_AUDIO_READING, false);
      },
      { once: false }
    );
    arrayBufferReader.readAsArrayBuffer(file);
  }
};

const setEditingJob = ({ state, dispatch, commit }, { job }) => {
  if (job && !state.remoteAudioUrl) {
    commit(mutations.SET_EDITING_JOB, job);

    dispatch(actions.SET_AUDIO_FILE_FROM_REMOTE_URL, { url: job.inputUrl, episodeInfo: job.episodeInfo });

    commit(mutations.SET_AUDIO_START, job.editedStart ? job.editedStart : job.start);
    commit(mutations.SET_AUDIO_END, job.editedEnd ? job.editedEnd : job.end);
  } else if (!job) {
    commit(mutations.SET_EDITING_JOB, null);
  }
};

const setEpisodeInfo = ({ commit }, episodeInfo) => {
  commit(mutations.SET_EPISODE_INFO, episodeInfo);
};

const setAudioFileFromRemoteUrl = ({ dispatch, commit }, episodeInfo) => {
  const { audioUrl, url } = episodeInfo;
  commit(mutations.SET_REMOTE_AUDIO_DOWNLOADING, true);
  // commit(mutations.SET_AUDIO_READY, true);
  // commit(mutations.SET_EPISODE_INFO, episodeInfo);

  const downloadUrl = audioUrl || url;

  let largeFileWarning = false;
  const maxFileSize = 75; // MB
  let downloadProgress = 0;
  if (downloadUrl) {
    const request = new XMLHttpRequest();
    commit(mutations.SET_DOWNLOAD_REQUEST, request);

    request.open('GET', downloadUrl, true);
    request.setRequestHeader('Content-Type', 'audio/mpeg');

    request.responseType = 'arraybuffer';

    request.onprogress = ev => {
      const { loaded, total } = ev;
      const size = parseInt(total) / 1024 / 1024;
      if (!largeFileWarning && size > maxFileSize) {
        // largeFileWarning = true;
        EventBus.$emit('AudioFileExceedsMaxSize');
      }
      if (loaded && total) {
        if (downloadProgress + 0.01 < loaded / total) {
          downloadProgress = loaded / total;
          commit(mutations.SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS, downloadProgress);
        }
      }
    };
    request.onload = () => {
      const responseBlob = new Blob([request.response], { type: 'text/plain' });
      dispatch(actions.SET_AUDIO_FILE, { file: responseBlob, type: 'remote' });
      commit(mutations.SET_REMOTE_AUDIO_URL, downloadUrl);
      commit(mutations.SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS, 0);
      commit(mutations.SET_REMOTE_AUDIO_DOWNLOADING, false);
      commit(mutations.SET_DOWNLOAD_REQUEST, null);
    };
    request.onabort = () => {
      commit(mutations.SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS, 0);
      commit(mutations.SET_REMOTE_AUDIO_DOWNLOADING, false);
      commit(mutations.SET_DOWNLOAD_REQUEST, null);
    };
    request.onerror = () => {
      // when the first request to the URL is failed, try to retieve the URL from the reverse proxy.
      const proxyRequest = new XMLHttpRequest();

      commit(mutations.SET_DOWNLOAD_REQUEST, proxyRequest);
      proxyRequest.open('GET', `${PROXY_BASE_URL}/${downloadUrl}`, true);
      proxyRequest.setRequestHeader('Content-Type', 'audio/mpeg');

      proxyRequest.responseType = 'arraybuffer';
      proxyRequest.onprogress = ev => {
        const { loaded, total } = ev;
        const size = parseInt(total) / 1024 / 1024;
        if (!largeFileWarning && size > maxFileSize) {
          largeFileWarning = true;
          EventBus.$emit('AudioFileExceedsMaxSize');
        }
        if (loaded && total) {
          commit(mutations.SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS, loaded / total);
        }
      };

      proxyRequest.onload = () => {
        const responseBlob = new Blob([request.response], { type: 'text/plain' });
        dispatch(actions.SET_AUDIO_FILE, { file: responseBlob, type: 'remote' });
        commit(mutations.SET_REMOTE_AUDIO_URL, downloadUrl);
        commit(mutations.SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS, 0);
        commit(mutations.SET_REMOTE_AUDIO_DOWNLOADING, false);
        commit(mutations.SET_DOWNLOAD_REQUEST, null);
      };
      proxyRequest.onabort = () => {
        commit(mutations.SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS, 0);
        commit(mutations.SET_REMOTE_AUDIO_DOWNLOADING, false);
        commit(mutations.SET_DOWNLOAD_REQUEST, null);
      };
      proxyRequest.onerror = () => {
        // if the request still fails with reverse proxy, show an error message
        EventBus.$emit('remoteAudioDownloadFail');
      };
      proxyRequest.send();
    };
    request.send();
  }
};

const decodeAudioBuffer = async ({ state, commit }, timeEstimate) => {
  commit(mutations.SET_AUDIO_BUFFER_DECODING, true);
  commit(mutations.SET_AUDIO_BUFFER_DECODE_ESTIMATE_DATE, { startDate: +new Date(), endDate: +new Date() + timeEstimate });

  if (window.audioBuffer) {
    const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
    window.audioCtx = audioCtx;
    audioCtx.decodeAudioData(
      window.audioBuffer,
      audioBuffer => {
        commit(mutations.SET_AUDIO_BUFFER_DECODED, audioBuffer);
        commit(mutations.SET_AUDIO_BUFFER_DECODING, false);
        commit(mutations.SET_AUDIO_READY, true);
        commit(mutations.SET_AUDIO_BUFFER_DECODE_ESTIMATE_DATE, null);
        // window.audioBuffer=undefined
      },
      err => {
        console.log('Error decoding audio:', err);
      }
    );
  }
};
const clearAudioFile = ({ state, commit }) => {
  // console.log('download request', state.downloadRequest);
  if (state.downloadRequest) {
    state.downloadRequest.abort();
  }
  commit(mutations.CLEAR_AUDIO_DATA);
};

const jobUpdate = async ({ commit, dispatch }, { id, data }) => {
  try {
    await JobService.update(id, data);
    commit(mutations.SET_JOB_DATA, {
      id,
      data,
    });
  } catch (err) {
    console.log('Error updating job', err);
  }
  dispatch(actions.SYNC_USER_TO_DB);
};

const stopAudioProcessing = async ({ dispatch, commit }) => {
  commit(mutations.SET_AUDIO_READY, false);
  commit(mutations.SET_AUDIO_REGION, null);
  dispatch(actions.STOP_POLL_MEDIA_PROCESSING, null);
  dispatch(actions.SET_MEDIA, null);
};

const startAudioProcessing = async ({ state, dispatch, commit }, audioData) => {
  // console.log(state);
  try {
    const { uuid } = audioData;
    // console.log('Start audio processing', audioData);

    let savedMediaObj;
    if (audioData.type === 'library') {
      savedMediaObj = new Media(await MediaService.get(uuid));
      // console.log('savedMediaObj from Library', savedMediaObj);
    } else {
      const savedMedia = await MediaService.createMedia(uuid, audioData);
      savedMediaObj = new Media(savedMedia);
    }
    commit(mutations.SET_AUDIO_READY, false);
    commit(mutations.SET_AUDIO_REGION, null);

    dispatch(actions.POLL_MEDIA_PROCESSING, uuid);
    dispatch(actions.SET_MEDIA, savedMediaObj);
  } catch (err) {
    // console.log('Error creating job', err);
  }
};
const stopPollMediaProcessing = async () => {
  if (PollingTimeout && PollingTimeout != null && PollingTimeout !== undefined) {
    clearTimeout(PollingTimeout);
  }
};
const pollMediaProcessing = async ({ dispatch, commit }, guid) => {
  try {
    // const maxProcessingTime = 1 * 10 * 60 * 1000; // 10 mins (10m/h * 60s/m * 1000ms/s)
    const media = await MediaService.get(guid);
    // console.log(media);

    if (media.status && media.status === 'complete' && router.currentRoute.name === 'create') {
      // const mediaObj = new Media(media);
      console.log('status changed to complete....');
      commit(mutations.SET_AUDIO_READY, true);
      commit(mutations.SET_AUDIO_TYPE, 'remote');

      // dispatch(actions.SET_AUDIO_FILE_FROM_REMOTE_URL, { audioUrl: mediaObj.s3AudioUrl });
      return { status: 'complete' };
    }

    if (!media.status) {
      // console.error('Error polling media', media);
    }
  } catch (e) {
    console.error('Error polling lambda video', e);
  }

  PollingTimeout = setTimeout(() => {
    console.log('Polling media again');
    dispatch(actions.POLL_MEDIA_PROCESSING, guid);
  }, 5000);

  return false;
};

const deleteMedia = async ({ dispatch }, guid) => {
  await MediaService.deleteMedia(guid);

  await dispatch(actions.SYNC_USER_TO_DB);
};
const changeMedia = async ({ dispatch }, { guid, data }) => {
  await MediaService.changeMedia(guid, data);

  await dispatch(actions.SYNC_USER_TO_DB);
};

const jobCreate = async ({ state, commit, dispatch }, { uuid, start, end, title = 'My Video', themeId, isDemoTheme, episodeInfo }) => {
  EventBus.$emit('trackGTM', events.createVideo);
  try {
    // add { status: pending, specs, showWatermark }
    // create the job object in the DB
    // fetch the theme specs from the state
    // trigger the lambda job runner
    // trigger status polling
    let theme;
    let objectsUrl;
    if (isDemoTheme) {
      theme = demoThemes.find(t => t._id === themeId);
    } else {
      theme = await ThemeService.get(themeId, true);
      ({ objectsUrl } = theme);
      delete theme.objectsUrl;
    }

    const stingerVisible = false;

    const duration = (end - start).toFixed(2);

    const specs = {
      ...theme,
      start,
      end,
      duration,
      objects: objectsUrl, // replace objects with url containing objects
    };

    const newJob = await UserService.createJob(state.user.id, {
      guid: uuid,
      title,
      status: 'pending',
      specs,
      stingerVisible,
      episodeInfo,
    });
    const themeData = {
      uuid,
      duration,
      stingerVisible,
      width: theme.width,
      height: theme.height,
      waveform: theme.waveform,
      waveColor: theme.waveColor,
      waveTop: theme.waveTop,
      waveBottom: theme.waveBottom,
      waveLeft: theme.waveLeft,
      waveRight: theme.waveRight,
      progressBar: theme.progressBar,
      progressBarType: theme.progressBarType,
      progressBarColor: theme.progressBarColor,
      progressBarSecondaryColor: theme.progressBarSecondaryColor,
      progressBarTop: theme.progressBarTop,
      progressBarBottom: theme.progressBarBottom,
      progressBarLeft: theme.progressBarLeft,
      progressBarRight: theme.progressBarRight,
      backgroundImage: theme.backgroundImage, // looks in s3:wavvegram/background/{backgroundImage}
      pattern: theme.pattern,
      backgroundColor: theme.backgroundColor || 'white',
      samplesPerFrame: 128,
    };
    const lambdaJob = {
      uuid,
      start,
      end,
      theme: themeData,
      externalAudio: state.selectedMedia.s3AudioUrl,
    };

    await AwsService.startLambda(lambdaJob);
    commit(mutations.CLEAR_AUDIO_DATA);
    dispatch(actions.POLL_VIDEO_CREATION, newJob._id);
    dispatch(actions.SYNC_USER_TO_DB);
  } catch (err) {
    console.log('Error creating job', err);
  }
};
const jobRecreate = async (
  { state, commit, dispatch },
  { uuid, start, end, title = 'My Video', theme, themeId, srcFeed, id, editCount, extraDurationCharged }
) => {
  EventBus.$emit('trackGTM', events.createVideo);
  // TODO: add edit video event
  try {
    // add { status: pending, specs, showWatermark }
    // create the job object in the DB
    // fetch the theme specs from the state
    // trigger the lambda job runner
    // trigger status polling

    const demoThemeObject = demoThemes.find(t => t._id === themeId);
    let themeObject;

    if (demoThemeObject) {
      themeObject = demoThemeObject;
    } else if (!theme) {
      themeObject = await ThemeService.get(themeId);
    } else {
      console.log('using theme object');
      const job = await JobService.get(id);
      themeObject = job.specs;
    }

    const stingerVisible = false;

    const duration = (end - start).toFixed(2);

    // console.log('creating job with duration', duration);

    const themeData = {
      uuid,
      duration,
      stingerVisible,
      width: themeObject.width,
      height: themeObject.height,
      waveform: themeObject.waveform,
      waveColor: themeObject.waveColor,
      waveTop: themeObject.waveTop,
      waveBottom: themeObject.waveBottom,
      waveLeft: themeObject.waveLeft,
      waveRight: themeObject.waveRight,
      progressBar: themeObject.progressBar,
      progressBarType: themeObject.progressBarType,
      progressBarColor: themeObject.progressBarColor,
      progressBarSecondaryColor: themeObject.progressBarSecondaryColor,
      progressBarTop: themeObject.progressBarTop,
      progressBarBottom: themeObject.progressBarBottom,
      progressBarLeft: themeObject.progressBarLeft,
      progressBarRight: themeObject.progressBarRight,
      backgroundImage: themeObject.backgroundImage, // looks in s3:wavvegram/background/{backgroundImage}
      pattern: themeObject.pattern,
      backgroundColor: themeObject.backgroundColor || 'white',
      samplesPerFrame: 128,
    };

    const specs = {
      ...themeObject,
      start,
      end,
      duration,
    };
    // if (objectsUrl) {
    //   specs.objects = objectsUrl;
    // }

    const jobData = {
      guid: uuid,
      title,
      status: 'pending',
      specs,
      stingerVisible,
      srcFeed,
      date_edited: new Date(),
      editedSpecs: {},
      editCount,
      extraDurationCharged,
      subtitles: null,
      transcript: null,
    };

    const updatedJob = await JobService.update(id, jobData);

    const lambdaJob = {
      uuid,
      start,
      end,
      theme: themeData,
      editing: true,
    };
    if (state.audioType === 'remote') {
      lambdaJob.externalAudio = state.remoteAudioUrl;
    }

    AwsService.startLambda(lambdaJob);
    await dispatch(actions.SYNC_USER_TO_DB);

    commit(mutations.CLEAR_AUDIO_DATA);
    dispatch(actions.POLL_VIDEO_CREATION, updatedJob._id);
  } catch (err) {
    console.log('Error creating job', err);
  }
};

const pollVideoCreation = async ({ dispatch, commit }, id) => {
  try {
    const maxProcessingTime = 1 * 60 * 60 * 1000; // one hour (60m/h * 60s/m * 1000ms/s)
    // const status = AwsService.getVideoRenderingStatus({ uuid, duration })
    const job = await JobService.get(id);
    if (job.status === 'complete') {
      // TODO - Distinguish between job.status checks and status.stage checks
      // eslint-disable-next-line
      commit(mutations.SET_JOB_DATA, {
        id,
        data: {
          status: 'complete',
          url: `https://s3.amazonaws.com/${DEST_BUCKET}/${job.guid}.mp4?t=${new Date().getTime()}`,
          stingerUrl: `https://s3.amazonaws.com/${DEST_BUCKET}/${job.guid}-stinger.mp4?t=${new Date().getTime()}`,
        },
      });
      await dispatch(actions.JOB_UPDATE, {
        id,
        data: {
          status: 'complete',
        },
      });
      dispatch(actions.SYNC_USER_TO_DB);
      return;
    }
    const status = await JobService.checkStatus(id, {
      duration: +job.specs.duration,
      uuid: job.guid,
    });
    if (!status || status.error !== undefined) {
      console.log('Error polling lambda video', status.error);
      return;
    }
    if (status.stage === 'complete') {
      // console.log('status.state === complete', status.stage);
      // lambda will update the job.status to 'complete'
      // sync with the database to update the time remaining, etc.
      // eslint-disable-next-line
      commit(mutations.SET_JOB_DATA, {
        id,
        data: {
          status: 'complete',
          url: `https://s3.amazonaws.com/${DEST_BUCKET}/${job.guid}.mp4`,
          stingerUrl: `https://s3.amazonaws.com/${DEST_BUCKET}/${job.guid}-stinger.mp4`,
        },
      });
      await dispatch(actions.JOB_UPDATE, {
        id,
        data: {
          status: 'complete',
        },
      });
      dispatch(actions.SYNC_USER_TO_DB);
      // return { status: 'complete' }
    } else {
      if (job.date_edited && new Date(job.date_edited) < Date.now() - maxProcessingTime) {
        // console.log('date edited took so long', job.date_edited);
        // job took too long, mark as failed (used for not polling on videos page)
        dispatch(actions.JOB_UPDATE, {
          id: job._id,
          data: {
            status: 'failed',
          },
        });
      } else if (!job.date_edited && new Date(job.date_added) < Date.now() - maxProcessingTime) {
        // console.log('date added took so long', job.date_added);
        // job took too long, mark as failed (used for not polling on videos page)
        dispatch(actions.JOB_UPDATE, {
          id: job._id,
          data: {
            status: 'failed',
          },
        });
      }
      // this data is set locally, but we don't need to update DB since status is already 'pending'
      commit(mutations.SET_JOB_DATA, {
        id,
        data: {
          detailedStatus: status,
        },
      });
      // not available yet, poll again in 5 seconds
      setTimeout(() => {
        dispatch(actions.POLL_VIDEO_CREATION, id);
      }, 5000);
    }
  } catch (e) {
    console.log('Error polling lambda video', e);
  }
};

const pollZubtitleTranscription = async ({ dispatch }, guid) => {
  try {
    // console.log('guid', guid);

    const job = await JobService.findByGuid(guid);
    // console.log('job', job);
    if (!job) {
      return null;
    }
    if (job.transcript && job.transcript.finished) {
      return {
        status: 'complete',
      };
    }
    const doc = await ZubtitleService.get(guid);
    if (doc && doc.exists) {
      const transcriptionData = doc.data;
      if (transcriptionData.captions) {
        // we have a transcript!
        await dispatch(actions.JOB_UPDATE, {
          id: job._id,
          data: {
            transcript: {
              started: true,
              finished: true,
            },
          },
        });
        return {
          status: 'complete',
        };
      }
      // not available yet, poll again in 5 seconds
      setTimeout(() => {
        dispatch(actions.POLL_ZUBTITLE_TRANSCRIPTION, guid);
      }, 5000);
      return {
        status: 'pending',
      };
    }
    // no doc found
    return null;
  } catch (e) {
    console.log('Error polling transcription', e);
    return null;
  }
};

const finishCaptionRender = async ({ commit, dispatch }, guid) => {
  try {
    // const job = await JobService.findByGuid(guid);

    // if (!job) {
    //   return null;
    // }

    // await commit(mutations.SET_JOB_DATA, {
    //   id: job._id,
    //   captionUrl: `https://s3.amazonaws.com/${DEST_BUCKET}/${guid}_captions.mp4`,

    //   data: {
    //     subtitles: {
    //       started: true,
    //       finished: true,
    //       captionUrl: `https://s3.amazonaws.com/${DEST_BUCKET}/${guid}_captions.mp4`,
    //     },
    //   },
    // });

    // await JobService.update(job._id, {
    //   subtitles: {
    //     started: true,
    //     finished: true,
    //   },
    // });

    dispatch(actions.SYNC_USER_TO_DB);
  } catch (err) {
    console.log('Error saving video captions', err);
  }
};

const pollZubtitleFinalization = async ({ commit, dispatch }, guid) => {
  try {
    const job = await JobService.findByGuid(guid);
    if (!job) {
      return null;
    }
    if (job.subtitles && job.subtitles.ref) {
      // finished and uploaded to wavve
      // make sure that vuex data matches
      const { ref, stingerRef } = job.subtitles;
      // eslint-disable-next-line
      await commit(mutations.SET_JOB_DATA, {
        id: job._id,
        captionUrl: `https://s3.amazonaws.com/subtitles-out/${ref}`,
        captionStingerUrl: `https://s3.amazonaws.com/subtitles-out/${stingerRef}`,

        data: {
          subtitles: {
            started: true,
            finished: true,
            ref,
            captionUrl: `https://s3.amazonaws.com/subtitles-out/${ref}`,
            captionStingerUrl: `https://s3.amazonaws.com/subtitles-out/${stingerRef}`,
          },
        },
      });
      return {
        status: 'complete',
      };
    }
    const progressResponse = await ZubtitleService.getProgress(guid);
    const { status, progress } = progressResponse;
    if (status === 'complete') {
      // upload to S3 from zubtitle, this will also write to mongo db
      await ZubtitleService.upload(guid);
      // write to vuex state, manually set captionUrl
      const ref = `${job.guid}.mp4`;
      const stingerRef = `${job.guid}-stinger.mp4`;

      // eslint-disable-next-line
      await commit(mutations.SET_JOB_DATA, {
        id: job._id,
        data: {
          captionUrl: `https://s3.amazonaws.com/subtitles-out/${ref}`,
          captionStingerUrl: `https://s3.amazonaws.com/subtitles-out/${stingerRef}`,

          subtitles: {
            started: true,
            finished: true,
            ref,
            stingerRef,
            captionUrl: `https://s3.amazonaws.com/subtitles-out/${ref}`,
            captionStingerUrl: `https://s3.amazonaws.com/subtitles-out/${stingerRef}`,
          },
        },
      });
      JobService.update(job._id, {
        subtitles: {
          started: true,
          finished: true,
          ref,
        },
      });
      return {
        status: 'complete',
      };
    }
    commit(mutations.SET_JOB_DATA, {
      id: job._id,
      data: {
        subtitles: {
          started: true,
          finished: false,
          progress,
        },
      },
    });

    setTimeout(() => {
      dispatch(actions.POLL_ZUBTITLE_FINALIZATION, guid);
    }, 5000);
    return {
      status: 'pending',
      progress,
    };
  } catch (err) {
    console.log('Error polling zubtitle finalization', err);
    return null;
  }
};

const createWavveLink = async ({ commit, dispatch }) => {
  try {
    const newLink = await LinkService.create();
    const link = new Link(newLink);
    commit(mutations.ADD_LINK_DATA, link);
    await dispatch(actions.SYNC_USER_TO_DB);

    EventBus.$emit('trackIntercomEvent', 'wavvelink_created');
    EventBus.$emit('trackGTM', events.createWavveLink);
    await dispatch(actions.FETCH_PODCAST_RSS);
    return link;
  } catch (e) {
    console.log('Error creating wavve link', e);
    return null;
  }
};

const getUserTeamsAction = async ({ commit, dispatch, state }) => {
  const teams = await TeamsService.getUserTeams();

  commit(mutations.SET_TEAMS, teams);
};

const getUserInvitesAction = async ({ commit, dispatch }) => {
  const invites = await TeamsService.getUserInvites();

  commit(mutations.SET_TEAMS_INVITES, invites);
};

const addUserAction = async ({ commit, dispatch }, userInfo) => {
  await TeamsService.addUser(userInfo);

  await dispatch(actions.TEAMS_GET_TEAMS_ACTION);
};

const leaveUserAction = async ({ dispatch }, { teamId }) => {
  await TeamsService.leaveTeam(teamId);

  await dispatch(actions.SYNC_USER_TO_DB);
};

const changeTeamUser = async ({ commit, dispatch }, { teamId }) => {
  commit(mutations.SET_IS_CHANGING_TEAM, true);

  try {
    await TeamsService.changeTeam(teamId);

    await commit(mutations.SET_USER_TEAM, teamId);

    await dispatch(actions.SYNC_USER_TO_DB);
  } catch (error) {
    console.error(error);
  } finally {
    commit(mutations.SET_IS_CHANGING_TEAM, false);
  }
};

const changeRole = async ({ dispatch }, data) => {
  await TeamsService.changeRole(data);

  await dispatch(actions.TEAMS_GET_TEAMS_ACTION);
};

const changeTeamName = async ({ dispatch }, data) => {
  await TeamsService.changeTeamName(data);

  await dispatch(actions.TEAMS_GET_TEAMS_ACTION);
};

const changeTeamColor = async ({ dispatch }, data) => {
  await TeamsService.changeTeamColor(data);

  await dispatch(actions.TEAMS_GET_TEAMS_ACTION);
};

const removeSeat = async ({ dispatch }, teamOwnerId) => {
  await TeamsService.removeSeat(teamOwnerId);

  await dispatch(actions.SYNC_USER_TO_DB);
};

const addSeats = async ({ dispatch }, data) => {
  await TeamsService.addSeats(data);

  await dispatch(actions.SYNC_USER_TO_DB);
};

const acceptInviteAction = async ({ dispatch }, data) => {
  await TeamsService.acceptInvite(data);

  await dispatch(actions.TEAMS_GET_INVITES_ACTION);
  await dispatch(actions.TEAMS_GET_TEAMS_ACTION);
};

const resendInvite = async ({ dispatch }, data) => {
  await TeamsService.resendInvite(data);
};

const declineInvite = async ({ dispatch }, { teamId }) => {
  await TeamsService.declineInvite(teamId);

  await dispatch(actions.TEAMS_GET_INVITES_ACTION);
};

const deleteUserAction = async ({ dispatch }, { id, teamId }) => {
  await TeamsService.deleteUser({ id, teamId });

  await dispatch(actions.TEAMS_GET_TEAMS_ACTION);
};

const deleteAdditionalSeatsAction = async ({ dispatch }) => {
  await TeamsService.deleteAdditionalSeats();

  await dispatch(actions.TEAMS_GET_TEAMS_ACTION);
};

const deleteEmptySeatsAction = async ({ dispatch }) => {
  await TeamsService.deleteEmpySeat();

  await dispatch(actions.TEAMS_GET_TEAMS_ACTION);
};

const pauseStripePlan = async ({ dispatch }, data) => {
  await UserService.pausePlan(data);

  await dispatch(actions.SYNC_USER_TO_DB);
};
const checkoutPausePlan = async () => {
  return await UserService.checkoutPausePlan();
};

const deleteUserAccount = async ({ dispatch }) => {
  await UserService.deleteAccount();
};

const scheduleDeleteUserAccount = async ({ dispatch }) => {
  await UserService.scheduleDeleteUserAccount();

  await dispatch(actions.SYNC_USER_TO_DB);
};

const cancelStripePlan = async ({ dispatch }, data) => {
  await UserService.cancelPlan(data);

  await dispatch(actions.SYNC_USER_TO_DB);
};

const updateFeeds = async ({ commit }, update) => {
  const feedUrls = update.feeds.map(feed => feed.feedUrl);
  await LinkService.updateFeeds(feedUrls);

  commit(mutations.SET_PODCAST_RSS, update);
  commit(mutations.SET_USER_DATA, {
    rssFeeds: feedUrls,
  });
};

const updateWavveLink = async ({ commit, dispatch }, { _id, update }) => {
  // console.log('updateWavveLink commit', update);

  if (update.feed) {
    try {
      let feed = update.feed.trim();
      const parser = new Parser();
      feed = await parser.parseURL(`${PROXY_BASE_URL}/${update.feed}`);
      // console.log('feed', feed);
    } catch (err) {
      EventBus.$emit('addRssLinkFail', _id);
    }
  }
  await LinkService.update({ _id, ...update });
  commit(mutations.SET_LINK_DATA, {
    _id,
    data: update,
  });
  await dispatch(actions.FETCH_PODCAST_RSS);
};

const deleteWavveLink = async ({ commit, dispatch }, { id }) => {
  try {
    await LinkService.delete(id);
    await dispatch(actions.SYNC_USER_TO_DB);

    EventBus.$emit('trackIntercomEvent', 'wavvelink_created');
    EventBus.$emit('trackGTM', events.deleteWavveLink);
    await dispatch(actions.FETCH_PODCAST_RSS);
  } catch (e) {
    console.log('Error deleting wavve link', e);
  }
};

const setCancelingLinkId = ({ commit }, { id }) => {
  console.log(`Action: ${id}`);
  commit(mutations.SET_CANCELING_LINK_ID, id);
};

const deleteTheme = async ({ state, commit }, id) => {
  try {
    // send HTTP request
    await ThemeService.delete(id, {
      deleted: true,
    });
    // set vuex state
    const filteredThemes = state.user.themes.filter(theme => theme.id !== id);
    commit(mutations.SET_USER_DATA, {
      themes: filteredThemes,
      canCreateNewDesign: true,
    });
  } catch (err) {
    console.log('Error deleting theme', err);
  }
};

const setSelectedTheme = async ({ commit }, theme) => {
  commit(mutations.SET_SELECTED_THEME, theme);
};
const fetchMailingListSubscribers = async (_, listOwnerId) => MailingListService.listSubscribers(listOwnerId);

const subscribeToMailingList = async (_, { listOwnerId, email }) =>
  MailingListService.subscribe(listOwnerId, {
    email,
  });

const setCustomDomain = async ({ state, commit }, { customDomain }) => {
  const link = {
    ...state.user.link,
    customDomain,
  };
  await LinkService.update(link);
  commit(mutations.SET_USER_DATA, {
    link,
  });
};

const removeCustomDomain = async ({ state, commit }) => {
  const link = {
    ...state.user.link,
    customDomain: null,
  };
  await LinkService.update(link);
  commit(mutations.SET_USER_DATA, {
    link,
  });
};

const fetchPodcastRss = async ({ state, commit }) => {
  try {
    const { links, feeds } = await LinkService.getRssFeed();

    const feedItems = state.user.jobs.map(job => (job.episodeInfo ? job.episodeInfo.srcFeed : job.srcFeed));
    const filteredFeedItems = feedItems.filter(item => item !== undefined);

    const feedItemCounts = filteredFeedItems.reduce((obj, val) => {
      obj[val] = (obj[val] || 0) + 1;
      return obj;
    }, {});

    feeds.sort((a, b) => {
      return feedItemCounts[b.feedUrl] - feedItemCounts[a.feedUrl];
    });

    // console.log({ links, feeds });
    await commit(mutations.SET_PODCAST_RSS, { links, feeds });
    return { links, feeds };
  } catch (e) {
    console.log(e);
    return null;
  }
};

const setAudioRegion = async ({ commit }, { selectedStart, selectedEnd }) => {
  commit(mutations.SET_AUDIO_REGION, { selectedStart, selectedEnd });
};

const setPopoverDismissed = async ({ commit }, popoverDismissed) => {
  commit(mutations.SET_POPOVER_DISMISSED, popoverDismissed);
};
const setMedia = async ({ commit }, media) => {
  commit(mutations.SET_MEDIA, media);
};
const setSelectedEpisode = async ({ commit }, selectedEpisode) => {
  commit(mutations.SET_SELECTED_EPISODE, selectedEpisode);
};

const setDeflectorSurvey = async ({ commit }, surveyData) => {
  // ex/ {feedback: '', cancelReason: ''}
  commit(mutations.SET_DEFLECTOR_SURVEY, surveyData);
  // console.log(surveyData);
};

let sessionCapture;

const startDeflectorRecording = async ({ state, commit }) => {
  // init session recorder
  const url = `${WAVVE_API_BASE_URL}/deflector`;
  sessionCapture = new SessionRecorder(state.user.id, url);
  // console.log(sessionCapture);

  // these methods
  sessionCapture.record();
  commit(mutations.SET_DEFLECTOR_STATUS, true);
};

const stopDeflectorRecording = async ({ commit }) => {
  // init session recorder
  if (sessionCapture) {
    sessionCapture.stop();
    commit(mutations.SET_DEFLECTOR_STATUS, false);
  }
};

const sendDeflectorResults = async ({ state, commit }, eventData) => {
  // ex/ {success: true, acceptedOffer: true, offerDetails: '50% off coupon'}
  commit(mutations.SET_DEFLECTOR_RESULTS, eventData);

  // prepare payload
  const payload = {
    wavveId: state.user.id,
    email: state.user.email,
    type: 'survey',
    ...eventData,
    ...state.deflectorSurvey,
  };
  try {
    await AwsService.sendDeflectorSurvey(payload);
  } catch (err) {
    console.log(err);
  }
};

const oauthAuthorize = async ({ commit }, payload) => {
  try {
    const response = await AuthService.oauthAuthorize(payload);
    commit(mutations.OAUTH_STATE, response);
  } catch (e) {
    console.log(e);
  }
};

const oauthConfirm = async ({ commit }, payload) => {
  try {
    const response = await AuthService.oauthConfirm(payload);
    commit(mutations.OAUTH_STATE, response);
  } catch (e) {
    console.log(e);
  }
};

const loadTranscriptions = async ({ dispatch, commit }) => {
  const data = await TranscriptionService.getAll();
  try {
    commit(mutations.UPDATE_TRANSCRIPTIONS, data);
    dispatch(LOAD_PENDING_TRANSCRIPTIONS);
  } catch (e) {
    console.log('ERR', e);
  }
};
const loadPendingTranscriptions = async context => {
  if (router.currentRoute.name !== 'transcriptions') {
    return;
  }
  const data = await TranscriptionService.getPending(context.state.transcriptions_meta.lastupdate);
  // var self = this;
  // self.dispatch = dispatch();/
  try {
    // frequency = context.store.state.transcriptions
    // console.log(context.state.transcriptions);
    let pendingList = context.state.transcriptions.filter(function (obj) {
      return obj.statusText === 'Pending';
    });
    // console.log( pendingList.length);
    var waitTime = 6000;
    if (pendingList.length > 0) {
      waitTime = 2000;
    }
    context.commit(mutations.UPDATE_PENDING_TRANSCRIPTIONS, data);
    clearTimeout(document.transcriptionTimeout);
    document.transcriptionTimeout = setTimeout(function () {
      clearInterval(document.transcriptionInterval);
      document.transcriptionInterval = setInterval(function () {
        context.dispatch(LOAD_PENDING_TRANSCRIPTIONS);
      }, 30000);
      context.dispatch(LOAD_PENDING_TRANSCRIPTIONS);
    }, waitTime);
  } catch (e) {
    console.log('ERR', e);
  }
};
const loadOneTranscription = async ({ commit, state }, _id) => {
  const data = await TranscriptionService.get(_id);
  try {
    commit(mutations.UPDATE_TRANSCRIPTION, data);
  } catch (e) {
    console.log('ERR', e);
  }
};
const deleteTranscription = async ({ commit }, _id) => {
  try {
    commit(mutations.DELETE_TRANSCRIPTION, _id);
    await TranscriptionService.delete(_id);
  } catch (e) {
    console.log('ERR', e);
  }
};

const cloneVideo = async ({ state, dispatch }, data) => {
  try {
    console.log('cloning', data);
    await JobService.clone(data);
    return await dispatch(actions.SYNC_USER_TO_DB);
  } catch (e) {
    console.log('ERR', e);
    return await dispatch(actions.SYNC_USER_TO_DB);
  }
};

// const summaryTranscribeVideo = async ({ state }, data) => {
//   try {
//     await TranscriptionService.transcribe_and_summary(data);
//   } catch (e) {
//     console.log('ERR', e);
//   }
//       dispatch(LOAD_PENDING_TRANSCRIPTIONS);
// };

const transcribeVideo = async ({ state }, data) => {
  try {
    await TranscriptionService.transcribe(data);
  } catch (e) {
    console.log('ERR', e);
  }
  dispatch(LOAD_PENDING_TRANSCRIPTIONS);
};
const summaryTranscribeVideo = async ({ state }, data) => {
  try {
    await TranscriptionService.transcribe_and_summary(data);
  } catch (e) {
    console.log('ERR', e);
  }
  dispatch(LOAD_PENDING_TRANSCRIPTIONS);
};

const saveTranscriptionTitle = async ({ commit, state }, data) => {
  try {
    commit(mutations.UPDATE_TRANSCRIPTION, data);
    await TranscriptionService.update(data._id, { title: data.title });
  } catch (e) {
    console.log('ERR', e);
  }
};
const saveTranscriptionContent = async ({ commit, state }, data) => {
  try {
    commit(mutations.UPDATE_TRANSCRIPTION, data);
    await TranscriptionService.update(data._id, { contentWithoutTitle: data.contentWithoutTitle });
  } catch (e) {
    console.log('ERR', e);
  }
};

const createTranscriptionLoader = async ({ dispatch, commit, state }, data) => {
  try {
    // console.log('dataqveio',data);
    commit(mutations.UPDATE_PENDING_TRANSCRIPTIONS, {
      docs: [
        {
          _id: data._id,
          title: data.title + ` (Creating New Transcription...)`,
          lastStatusUpdate: 0,
        },
      ],
    });
    dispatch(LOAD_PENDING_TRANSCRIPTIONS);
  } catch (e) {
    console.log('ERR', e);
  }
};

const createTranscriptionClone = async ({ dispatch, commit, state }, data) => {
  try {
    // console.log("Creating a Clone", data._id)
    // commit(mutations.UPDATE_TRANSCRIPTION, data);
    if (data.kind == 'clone') {
      var returnData = await TranscriptionService.clone(data._id);
    } else {
      var returnData = await TranscriptionService.summary(data._id);
    }
    // console.log('returnData',returnData);
    commit(mutations.UPDATE_PENDING_TRANSCRIPTIONS, {
      docs: [
        {
          _id: returnData._id,
          title: data.title + ` (Creating New ${data.kind[0].toUpperCase() + data.kind.slice(1)}...)`,
          lastStatusUpdate: 0,
        },
      ],
    });
    dispatch(LOAD_PENDING_TRANSCRIPTIONS);
  } catch (e) {
    console.log('ERR', e);
  }
};

// const syncTranscriptionUsageStatus = async ({ commit, state }, data) => {
//   try {

//     let usage_status = await TranscriptionService.usage_status();
//     if (usage_status.month.length > 0) {
//       commit(mutations.UPDATE_TRANSCRIPTION_USAGE_STATUS, usage_status.month[0].Total);
//     } else {
//       commit(mutations.UPDATE_TRANSCRIPTION_USAGE_STATUS, 0);
//     }
//   } catch (e) {
//     console.log('ERR', e);
//   }
// };

export default {
  [actions.SETUP]: setup,
  [actions.SIGNUP]: signup,
  [actions.LOGIN]: login,
  [actions.LOGOUT]: logout,
  [actions.CLEAR_TOKEN]: clearToken,
  [actions.REFRESH_AUTH_TOKEN]: refreshAuthToken,
  [actions.SAVE_CANVAS]: saveCanvas,
  [actions.SAVE_HISTORY]: saveHistory,
  [actions.CLEAR_CANVAS]: clearCanvas,
  [actions.SET_AUDIO_FILE]: setAudioFile,
  [actions.SET_AUDIO_UPLOAD_FILE]: setAudioUploadFile,
  [actions.SET_EPISODE_INFO]: setEpisodeInfo,
  [actions.SET_AUDIO_FILE_FROM_REMOTE_URL]: setAudioFileFromRemoteUrl,
  [actions.SET_SELECTED_EPISODE]: setSelectedEpisode,
  [actions.SET_EDITING_JOB]: setEditingJob,
  [actions.DECODE_AUDIO_BUFFER]: decodeAudioBuffer,
  [actions.CLEAR_AUDIO_FILE]: clearAudioFile,
  [actions.START_AUDIO_PROCESSING]: startAudioProcessing,
  [actions.STOP_AUDIO_PROCESSING]: stopAudioProcessing,
  [actions.SET_MEDIA]: setMedia,
  [actions.JOB_UPDATE]: jobUpdate,
  [actions.JOB_CREATE]: jobCreate,
  [actions.SET_POPOVER_DISMISSED]: setPopoverDismissed,
  [actions.JOB_RECREATE]: jobRecreate,
  [actions.POLL_VIDEO_CREATION]: pollVideoCreation,
  [actions.POLL_MEDIA_PROCESSING]: pollMediaProcessing,
  [actions.STOP_POLL_MEDIA_PROCESSING]: stopPollMediaProcessing,
  [actions.SYNC_USER_TO_DB]: syncUserToDb,
  [actions.POLL_ZUBTITLE_FINALIZATION]: pollZubtitleFinalization,
  [actions.POLL_ZUBTITLE_TRANSCRIPTION]: pollZubtitleTranscription,
  [actions.CREATE_WAVVE_LINK]: createWavveLink,
  [actions.UPDATE_WAVVE_LINK]: updateWavveLink,
  [actions.DELETE_WAVVE_LINK]: deleteWavveLink,
  [actions.SET_CANCELING_LINK_ID]: setCancelingLinkId,
  [actions.UPDATE_FEEDS]: updateFeeds,
  [actions.SET_SELECTED_THEME]: setSelectedTheme,
  [actions.DELETE_THEME]: deleteTheme,
  [actions.FETCH_MAILING_LIST_SUBSCRIBERS]: fetchMailingListSubscribers,
  [actions.SUBSCRIBE_TO_MAILING_LIST]: subscribeToMailingList,
  [actions.SET_CUSTOM_DOMAIN]: setCustomDomain,
  [actions.REMOVE_CUSTOM_DOMAIN]: removeCustomDomain,
  [actions.FETCH_PODCAST_RSS]: fetchPodcastRss,
  [actions.SET_DEFLECTOR_SURVEY]: setDeflectorSurvey,
  [actions.SET_DEFLECTOR_RESULTS]: sendDeflectorResults,
  [actions.SET_AUDIO_REGION]: setAudioRegion,
  [actions.START_DEFLECTOR_RECORDING]: startDeflectorRecording,
  [actions.STOP_DEFLECTOR_RECORDING]: stopDeflectorRecording,
  [actions.OAUTH_AUTHORIZE]: oauthAuthorize,
  [actions.OAUTH_CONFIRM]: oauthConfirm,
  [actions.LOAD_TRANSCRIPTIONS]: loadTranscriptions,
  [actions.LOAD_PENDING_TRANSCRIPTIONS]: loadPendingTranscriptions,
  [actions.REMOVE_TRANSCRIPTION]: deleteTranscription,
  [actions.TRANSCRIBE_VIDEO]: transcribeVideo,
  [actions.SUMMARY_TRANSCRIBE_VIDEO]: summaryTranscribeVideo,
  [actions.LOAD_ONE_TRANSCRIPTION]: loadOneTranscription,
  [actions.SAVE_TRANSCRIPTION_TITLE]: saveTranscriptionTitle,
  [actions.SAVE_TRANSCRIPTION_CONTENT]: saveTranscriptionContent,
  [actions.CREATE_TRANSCRIPTION_CLONE]: createTranscriptionClone,
  [actions.CREATE_TRANSCRIPTION_LOADER]: createTranscriptionLoader,
  // [actions.SYNC_TRANSCRIPTION_USAGE_STATUS]: syncTranscriptionUsageStatus,
  [actions.CLONE_VIDEO]: cloneVideo,
  [actions.TEAMS_GET_TEAMS_ACTION]: getUserTeamsAction,
  [actions.TEAMS_GET_INVITES_ACTION]: getUserInvitesAction,
  [actions.TEAMS_ACCEPT_INVITE_ACTION]: acceptInviteAction,
  [actions.TEAMS_ADD_USER_ACTION]: addUserAction,
  [actions.TEAMS_LEAVE_USER_ACTION]: leaveUserAction,
  [actions.TEAMS_DELETE_USER_ACTION]: deleteUserAction,
  [actions.TEAMS_DELETE_ADDITIONAL_SEATS_ACTION]: deleteAdditionalSeatsAction,
  [actions.TEAMS_DELETE_EMPTY_SEATS_ACTION]: deleteEmptySeatsAction,
  [actions.TEAMS_CHANGE_TEAM]: changeTeamUser,
  [actions.TEAMS_CHANGE_NAME]: changeTeamName,
  [actions.TEAMS_CHANGE_COLOR]: changeTeamColor,
  [actions.TEAMS_CHANGE_ROLE]: changeRole,
  [actions.TEAMS_REMOVE_SEAT]: removeSeat,
  [actions.TEAMS_ADD_SEATS]: addSeats,
  [actions.TEAMS_RESEND_INVITE]: resendInvite,
  [actions.TEAMS_DECLINE_INVITE]: declineInvite,
  [actions.STRIPE_CANCEL_PLAN]: cancelStripePlan,
  [actions.STRIPE_PAUSE_PLAN]: pauseStripePlan,
  [actions.STRIPE_CHECKOUT_PAUSE_PLAN]: checkoutPausePlan,
  [actions.USER_DELETE_ACCOUNT]: deleteUserAccount,
  [actions.USER_SCHEDULE_DELETE_ACCOUNT]: scheduleDeleteUserAccount,
  [actions.MEDIA_DELETE]: deleteMedia,
  [actions.MEDIA_CHANGE]: changeMedia,
  [actions.CAPTIONS_DONE_EVENT]: finishCaptionRender,
};
