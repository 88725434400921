import Axios from 'axios';
import { CONVERTKIT_KEY, CONVERTKIT_API_URL } from '../config';

export default {
  subscribeUser(content) {
    return Axios.post(`${CONVERTKIT_API_URL}/forms/3618266/subscribe`,
      { "api_key": CONVERTKIT_KEY, ...content },
      {
        headers: { 'Content-Type': "application/json; charset=utf-8" },
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.common
            return JSON.stringify(data);
          },
        ],
      });
  },
  updateUser(subscriberId, content) {
    return Axios.put(`${CONVERTKIT_API_URL}/subscribers/${subscriberId}`,
      { "api_key": CONVERTKIT_KEY, ...content },
      {
        headers: { 'Content-Type': "application/json; charset=utf-8" },
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.common
            return JSON.stringify(data);
          },
        ],
      });
  },
};
