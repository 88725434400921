const FREE = 'free';
const ALPHA = 'alpha';
const ALPHA10 = 'alpha10';
const ALPHA365 = 'alpha365';
const BETA = 'beta';
const BETA18 = 'beta18';
const BETA365 = 'beta365';
const GAMMA = 'gamma';
const GAMMA30 = 'gamma30';
const GAMMA365 = 'gamma365';
const GAMMA32 = 'gamma32';
const GAMMALIMITED365 = 'gammalimited365';
const PRO60 = 'pro60';
const PRO495 = 'pro495';
const PRO600 = 'pro600';
const LINK_FREE = 'link_free';
const LINK9 = 'link_9';
const LINK90 = 'link_90';
const CREATOR_MONTHLY = 'creator_monthly';
const CREATOR_ANNUAL = 'creator_annual';
const INFLUENCER_MONTHLY = 'influencer_monthly';
const INFLUENCER_ANNUAL = 'influencer_annual';
const AGENCY_MONTHLY = 'agency_monthly';
const AGENCY_ANNUAL = 'agency_annual';
const NETWORK_MONTHLY = 'network_monthly';
const NETWORK_ANNUAL = 'network_annual';
const LINK_INCLUDED = 'link_included';
const LINK_ADDON = 'link_addon';
const LINK_MONTHLY = 'link_premium_monthly';
const LINK_ANNUAL = 'link_premium_annual';

export const PLANS = {
  FREE,
  ALPHA,
  ALPHA10,
  ALPHA365,
  BETA,
  BETA18,
  BETA365,
  GAMMA,
  GAMMA30,
  GAMMA32,
  GAMMA365,
  GAMMALIMITED365,
  PRO60,
  PRO495,
  PRO600,
  LINK_FREE,
  LINK9,
  LINK90,
  CREATOR_MONTHLY,
  CREATOR_ANNUAL,
  INFLUENCER_MONTHLY,
  INFLUENCER_ANNUAL,
  AGENCY_MONTHLY,
  AGENCY_ANNUAL,
  NETWORK_MONTHLY,
  NETWORK_ANNUAL,
  LINK_INCLUDED,
  LINK_ADDON,
  LINK_MONTHLY,
  LINK_ANNUAL,
};

export const CONSUMER_NAME = {
  [FREE]: 'Free',
  [ALPHA]: 'Alpha',
  [BETA]: 'Beta',
  [GAMMA]: 'Gamma',
  [ALPHA10]: 'Alpha',
  [BETA18]: 'Beta',
  [GAMMA30]: 'Gamma',
  [GAMMA32]: 'Gamma',
  [ALPHA365]: 'Alpha Annual',
  [BETA365]: 'Beta Annual',
  [GAMMA365]: 'Gamma Annual',
  [GAMMALIMITED365]: 'Gamma Annual',
  [PRO60]: 'Pro',
  [PRO495]: 'Pro Annual',
  [PRO600]: 'Pro Annual',
  [LINK_FREE]: 'Wavve Link Free',
  [LINK9]: 'Wavve Link',
  [LINK90]: 'Wavve Link Annual',
  [CREATOR_MONTHLY]: 'Creator',
  [CREATOR_ANNUAL]: 'Creator Annual',
  [INFLUENCER_MONTHLY]: 'Influencer',
  [INFLUENCER_ANNUAL]: 'Influencer Annual',
  [AGENCY_MONTHLY]: 'Agency',
  [AGENCY_ANNUAL]: 'Agency Annual',
  [NETWORK_MONTHLY]: 'Network',
  [NETWORK_ANNUAL]: 'Network Annual',
  [LINK_ADDON]: 'Wavve Link Premium Add-On',
  [LINK_INCLUDED]: 'Wavve Link Premium',
  [LINK_MONTHLY]: 'Wavve Link Premium',
  [LINK_ANNUAL]: 'Wavve Link Premium Annual',
};

export const CONSUMER_SHORT_NAME = {
  [FREE]: 'Free',
  [ALPHA]: 'Alpha',
  [BETA]: 'Beta',
  [GAMMA]: 'Gamma',
  [ALPHA10]: 'Alpha',
  [BETA18]: 'Beta',
  [GAMMA30]: 'Gamma',
  [GAMMA32]: 'Gamma',
  [ALPHA365]: 'Alpha',
  [BETA365]: 'Beta',
  [GAMMA365]: 'Gamma',
  [GAMMALIMITED365]: 'Gamma',
  [PRO60]: 'Pro',
  [PRO495]: 'Pro',
  [PRO600]: 'Pro',
  [LINK_FREE]: 'Wavve Link Free',
  [LINK9]: 'Wavve Link',
  [LINK90]: 'Wavve Link',
  [CREATOR_MONTHLY]: 'Creator',
  [CREATOR_ANNUAL]: 'Creator',
  [INFLUENCER_MONTHLY]: 'Influencer',
  [INFLUENCER_ANNUAL]: 'Influencer',
  [AGENCY_MONTHLY]: 'Agency',
  [AGENCY_ANNUAL]: 'Agency',
  [NETWORK_MONTHLY]: 'Network',
  [NETWORK_ANNUAL]: 'Network',
  [LINK_ADDON]: 'Wavve Link',
  [LINK_INCLUDED]: 'Wavve Link',
  [LINK_MONTHLY]: 'Wavve Link',
  [LINK_ANNUAL]: 'Wavve Link',
};

export const CONSUMER_FULL_NAME = {
  [FREE]: 'Wavve Video Free',
  [ALPHA]: 'Wavve Video Alpha',
  [BETA]: 'Wavve Video Beta',
  [GAMMA]: 'Wavve Video Gamma',
  [ALPHA10]: 'Wavve Video Alpha',
  [BETA18]: 'Wavve Video Beta',
  [GAMMA30]: 'Wavve Video Gamma',
  [GAMMA32]: 'Wavve Video Gamma',
  [ALPHA365]: 'Wavve Video Alpha Annual',
  [BETA365]: 'Wavve Video Beta Annual',
  [GAMMA365]: 'Wavve Video Gamma Annual',
  [GAMMALIMITED365]: 'Wavve Video Gamma Annual',
  [PRO60]: 'Wavve Video Pro',
  [PRO495]: 'Wavve Video Pro Annual',
  [PRO600]: 'Wavve Video Pro Annual',
  [LINK_FREE]: 'Wavve Link Free',
  [LINK9]: 'Wavve Link Premium',
  [LINK90]: 'Wavve Link Premium Annual',
  [CREATOR_MONTHLY]: 'Wavve Video Creator',
  [CREATOR_ANNUAL]: 'Wavve Video Creator Annual',
  [INFLUENCER_MONTHLY]: 'Wavve Video Influencer',
  [INFLUENCER_ANNUAL]: 'Wavve Video Influencer Annual',
  [AGENCY_MONTHLY]: 'Wavve Video Agency',
  [AGENCY_ANNUAL]: 'Wavve Video Agency Annual',
  [NETWORK_MONTHLY]: 'Wavve Video Network',
  [NETWORK_ANNUAL]: 'Wavve Video Network Annual',
  [LINK_ADDON]: 'Wavve Link Premium Add-On',
  [LINK_INCLUDED]: 'Wavve Link Premium Included',
  [LINK_MONTHLY]: 'Wavve Link Premium',
  [LINK_ANNUAL]: 'Wavve Link Premium Annual',
};

export const PLAN_MONTHLY_COST = {
  [FREE]: 0,
  [ALPHA]: 7,
  [BETA]: 12,
  [GAMMA]: 18,
  [ALPHA10]: 10,
  [BETA18]: 18,
  [GAMMA30]: 30,
  [GAMMA32]: 32,
  [ALPHA365]: 8,
  [BETA365]: 13,
  [GAMMA365]: 21,
  [GAMMALIMITED365]: 22,
  [PRO60]: 60,
  [PRO495]: 41.25,
  [PRO600]: 50,
  [LINK_FREE]: 0,
  [LINK9]: 9,
  [LINK90]: 7.5,
  [CREATOR_MONTHLY]: 12.99,
  [CREATOR_ANNUAL]: 10.99,
  [INFLUENCER_MONTHLY]: 19.99,
  [INFLUENCER_ANNUAL]: 16.99,
  [AGENCY_MONTHLY]: 32.99,
  [AGENCY_ANNUAL]: 27.99,
  [NETWORK_MONTHLY]: 59.99,
  [NETWORK_ANNUAL]: 49.99,
  [LINK_ADDON]: 2.99,
  [LINK_INCLUDED]: 0,
  [LINK_MONTHLY]: 9.99,
  [LINK_ANNUAL]: 8.49,
};

export const PLAN_STORAGE_DAY_LIMIT = {
  [FREE]: 7,
  [ALPHA]: 93,
  [BETA]: 279,
  [GAMMA]: 365,
  [ALPHA10]: 93,
  [BETA18]: 279,
  [GAMMA30]: 365,
  [GAMMA32]: 365,
  [ALPHA365]: 93,
  [BETA365]: 279,
  [GAMMA365]: 365,
  [GAMMALIMITED365]: 365,
  [PRO60]: Infinity,
  [PRO495]: Infinity,
  [PRO600]: Infinity,
  [CREATOR_MONTHLY]: 93,
  [CREATOR_ANNUAL]: 93,
  [INFLUENCER_MONTHLY]: 279,
  [INFLUENCER_ANNUAL]: 279,
  [AGENCY_MONTHLY]: 365,
  [AGENCY_ANNUAL]: 365,
  [NETWORK_MONTHLY]: Infinity,
  [NETWORK_ANNUAL]: Infinity,
};

export const PLAN_ANNUAL_COST = Object.keys(PLANS).reduce((costs, PLAN) => {
  const plan = PLANS[PLAN];
  costs[plan] = PLAN_MONTHLY_COST[plan] * 12;
  return costs;
}, {});

export const PAID_PLANS = [
  ALPHA,
  ALPHA10,
  ALPHA365,
  BETA,
  BETA18,
  BETA365,
  GAMMA,
  GAMMA30,
  GAMMA365,
  GAMMA32,
  GAMMALIMITED365,
  PRO60,
  PRO495,
  PRO600,
  LINK9,
  LINK90,
  CREATOR_MONTHLY,
  CREATOR_ANNUAL,
  INFLUENCER_MONTHLY,
  INFLUENCER_ANNUAL,
  AGENCY_MONTHLY,
  AGENCY_ANNUAL,
  NETWORK_MONTHLY,
  NETWORK_ANNUAL,
  LINK_INCLUDED,
  LINK_ADDON,
  LINK_MONTHLY,
  LINK_ANNUAL,
];
export const UNLIMITED_PLANS = [GAMMA, GAMMA30, GAMMA365, PRO60, PRO495, NETWORK_MONTHLY, NETWORK_ANNUAL];
export const ANNUAL_PLANS = [
  ALPHA365,
  BETA365,
  GAMMA365,
  GAMMALIMITED365,
  PRO495,
  PRO600,
  LINK90,
  CREATOR_ANNUAL,
  INFLUENCER_ANNUAL,
  AGENCY_ANNUAL,
  NETWORK_ANNUAL,
  LINK_ANNUAL,
];
export const MONTHLY_PLANS = [
  ALPHA,
  ALPHA10,
  BETA,
  BETA18,
  GAMMA,
  GAMMA30,
  GAMMA32,
  PRO60,
  LINK9,
  CREATOR_MONTHLY,
  INFLUENCER_MONTHLY,
  AGENCY_MONTHLY,
  NETWORK_MONTHLY,
  LINK_MONTHLY,
  LINK_ADDON,
];
export const UNLIMITED_DESIGNS_PLANS = [PRO60, PRO495, PRO600];
export const WAVVE_LINK_PLANS = [LINK_FREE, LINK9, LINK90, LINK_ADDON, LINK_INCLUDED, LINK_MONTHLY, LINK_ANNUAL];
export const DEACTIVATABLE_LINK_PLANS = [LINK9, LINK90, LINK_ADDON, LINK_MONTHLY, LINK_ANNUAL];
// needed for legacy support?
// export const BUYABLE_PLANS = [ALPHA10, ALPHA365, BETA18, BETA365, GAMMA32, GAMMALIMITED365, LINK9, LINK90, PRO60, PRO600];
export const BUYABLE_PLANS = [
  CREATOR_MONTHLY,
  CREATOR_ANNUAL,
  INFLUENCER_MONTHLY,
  INFLUENCER_ANNUAL,
  AGENCY_MONTHLY,
  AGENCY_ANNUAL,
  NETWORK_MONTHLY,
  NETWORK_ANNUAL,
  LINK_INCLUDED,
  LINK_ADDON,
  LINK_MONTHLY,
  LINK_ANNUAL,
];
export const INCLUDED_WITH_VIDEO_PLAN = [LINK_INCLUDED];

// minutes
export const PLAN_MONTHLY_ALLOWANCE = {
  [FREE]: 2 * 60,
  [ALPHA]: 10 * 60,
  [BETA]: 30 * 60,
  [GAMMA]: Infinity,
  [ALPHA10]: 10 * 60,
  [BETA18]: 30 * 60,
  [GAMMA30]: Infinity,
  [GAMMA32]: 5 * 60 * 60,
  [ALPHA365]: 10 * 60,
  [BETA365]: 30 * 60,
  [GAMMA365]: Infinity,
  [GAMMALIMITED365]: 5 * 60 * 60,
  [PRO60]: Infinity,
  [PRO495]: Infinity,
  [PRO600]: Infinity,
  [CREATOR_MONTHLY]: 30 * 60,
  [CREATOR_ANNUAL]: 30 * 60,
  [INFLUENCER_MONTHLY]: 60 * 60,
  [INFLUENCER_ANNUAL]: 60 * 60,
  [AGENCY_MONTHLY]: 300 * 60,
  [AGENCY_ANNUAL]: 300 * 60,
  [NETWORK_MONTHLY]: Infinity,
  [NETWORK_ANNUAL]: Infinity,
  default: null,
};

// weeks
export const PLAN_STORAGE_TIME_ALLOWANCE = {
  [FREE]: 2,
  [CREATOR_MONTHLY]: 3 * 4,
  [CREATOR_ANNUAL]: 3 * 4,
  [INFLUENCER_MONTHLY]: 9 * 4,
  [INFLUENCER_ANNUAL]: 9 * 4,
  [AGENCY_MONTHLY]: 12 * 4,
  [AGENCY_ANNUAL]: 12 * 4,
  [NETWORK_MONTHLY]: Infinity,
  [NETWORK_ANNUAL]: Infinity,
  default: null,
};

export const PLAN_ROLLOVER = Object.keys(PLANS).reduce((rollover, PLAN) => {
  const plan = PLANS[PLAN];
  if (plan === PLANS.FREE) {
    // no rollover on free
    rollover[plan] = 0;
  } else if (PLAN_MONTHLY_ALLOWANCE[plan]) {
    // 2x on paid plans
    rollover[plan] = PLAN_MONTHLY_ALLOWANCE[plan] * 2;
  } else {
    // unlimited
    rollover[plan] = PLAN_MONTHLY_ALLOWANCE[plan];
  }
  return rollover;
}, {});

export const PLAN_STORAGE_TIME = Object.keys(PLANS).reduce((storageTime, PLAN) => {
  const plan = PLANS[PLAN];
  storageTime[plan] = PLAN_STORAGE_TIME_ALLOWANCE[plan];
  return storageTime;
}, {});

export const PLAN_INTERVAL = {
  [FREE]: 'month',
  [ALPHA]: 'month',
  [BETA]: 'month',
  [GAMMA]: 'month',
  [ALPHA10]: 'month',
  [BETA18]: 'month',
  [GAMMA30]: 'month',
  [GAMMA32]: 'month',
  [ALPHA365]: 'year',
  [BETA365]: 'year',
  [GAMMA365]: 'year',
  [GAMMALIMITED365]: 'year',
  [PRO60]: 'month',
  [PRO495]: 'year',
  [PRO600]: 'year',
  [LINK_FREE]: 'month',
  [LINK9]: 'month',
  [LINK90]: 'year',
  [CREATOR_MONTHLY]: 'month',
  [CREATOR_ANNUAL]: 'year',
  [INFLUENCER_MONTHLY]: 'month',
  [INFLUENCER_ANNUAL]: 'year',
  [AGENCY_MONTHLY]: 'month',
  [AGENCY_ANNUAL]: 'year',
  [NETWORK_MONTHLY]: 'month',
  [NETWORK_ANNUAL]: 'year',
  [LINK_ADDON]: 'month',
  [LINK_INCLUDED]: 'month',
  [LINK_MONTHLY]: 'month',
  [LINK_ANNUAL]: 'year',
  default: 'month',
};

export const PLAN_MAX_LENGTH = {
  [FREE]: 60,
  [ALPHA]: 10 * 60,
  [BETA]: 30 * 60,
  [GAMMA]: 60 * 60,
  [ALPHA10]: 10 * 60,
  [ALPHA365]: 10 * 60,
  [BETA18]: 30 * 60,
  [BETA365]: 30 * 60,
  [GAMMA30]: 48 * 60,
  [GAMMA32]: 48 * 60,
  [GAMMA365]: 48 * 60,
  [GAMMALIMITED365]: 48 * 60,
  [PRO60]: 60 * 60,
  [PRO495]: 60 * 60,
  [PRO600]: 60 * 60,
  default: 48 * 60,
};

export const PLAN_SAVINGS = {
  [PLANS.ALPHA10]: '20%',
  [PLANS.ALPHA365]: '20%',
  [PLANS.BETA18]: '25%',
  [PLANS.BETA365]: '25%',
  [PLANS.GAMMA32]: '30%',
  [PLANS.GAMMALIMITED365]: '30%',
  [PLANS.LINK90]: '20%',
  [CREATOR_ANNUAL]: '15%',
  [INFLUENCER_ANNUAL]: '15%',
  [AGENCY_ANNUAL]: '15%',
  [NETWORK_ANNUAL]: '17%',
  [LINK_ANNUAL]: '15%',
};

export const PLAN_DOLLAR_SAVINGS = {
  [PLANS.ALPHA365]: 36,
  [PLANS.BETA365]: 60,
  [PLANS.GAMMALIMITED365]: 120,
  [PLANS.LINK90]: 18,
  [PLANS.PRO600]: 120,
  [CREATOR_ANNUAL]: 24,
  [INFLUENCER_ANNUAL]: 36,
  [AGENCY_ANNUAL]: 60,
  [NETWORK_ANNUAL]: 120,
  [LINK_ANNUAL]: 20,
};

export default {
  PLANS,
  CONSUMER_NAME,
  PAID_PLANS,
  UNLIMITED_PLANS,
  WAVVE_LINK_PLANS,
  ANNUAL_PLANS,
  MONTHLY_PLANS,
  PLAN_MONTHLY_ALLOWANCE,
  PLAN_INTERVAL,
  PLAN_MAX_LENGTH,
  PLAN_DOLLAR_SAVINGS,
  PLAN_SAVINGS,
};
