<template>
  <div class="columns is-multiline is-desktop">

    <!-- <audio preload="none" class="rssInlineAudioPlayer" :id="'player'+ep.guid"  :src="ep.enclosure.url"></audio>  -->
    <div class="column is-full-desktop">
      <div v-if="mode === 'edit'">
        <div>
          <p v-if="editingJob && editingJob.episodeInfo">
            Audio source: <span class="has-text-weight-semibold">{{ editingJob.episodeInfo.title }}</span> from
            <span class="has-text-weight-semibold">{{ editingJob.episodeInfo.feedTitle }}</span>
          </p>
        </div>
      </div>
      <div v-else>
        <!-- When no audio -->
        <div v-show="mode !== 'edit'" class="has-margin-bottom">
          <!-- Wavve Link RSS -->
          <p style="width: 70%">
            <b>Choose from </b>your Wavve Link podcast episodes, a podcast episode with an RSS feed, a file on your
            smartphone or computer, a recording you can
            make in Wavve, or choose from a previously added audio file in My Library for faster processing times.
          </p>
        </div>
      </div>
      <div class="columns is-multiline">
        <div v-loading="loading" v-if="mode !== 'edit'" class="column is-fullhd">
          <el-tabs v-model="audioTypeTab" style="min-height: 200px" class="has-margin-top">
            <el-tab-pane label="Wavve Link" name="wavve-link" id="test">
              <div v-if="links">
                <div v-for="(link, linkIndex) in links" v-bind:key="links.indexOf(link)">
                  <div v-if="link && link.items && link.items.length" class="linkselect has-small-margin-top">
                    <p class="has-text-grey">From {{ link.title }}</p>
                    <div class="has-small-margin-top">
                      <div class="is-pulled-left">
                        <img :src="(link.image && link.image.url) || (link.itunes && link.itunes.image)"
                          :style="{ maxHeight: '36px', width: 'auto' }" alt="Feature Image" />
                      </div>
                      <el-select class="has-margin-left select-active" v-model="selectedEpisode.links[linkIndex]"
                        placeholder="Select Episode" filterable
                        @change="e => onEpisodeSelectChange({ guid: e, rss: link, type: 'link', feedIndex: linkIndex })"
                        ref="episodeSelectFeeds" :style="{ maxWidth: isMobile ? '170px' : '190px' }" auto-complete="off">
                        <el-option v-for="ep in link.items" :key="ep.guid" :label="ep.title" :value="ep.guid">
                          <span class="is-pulled-left"
                            style="max-width: 200px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{
                              ep.title
                            }}</span>
                          <span class="is-pulled-right has-text-grey-light has-margin-left is-size-6-7">{{ formatDate(new
                            Date(ep.isoDate)) }}</span>
                        </el-option>
                      </el-select>
                      <router-link to="/link">
                        <el-tooltip content="Delete your main RSS feed from the Wavve.Link page in the menu"
                          placement="top" popper-class="hint-tooltip">
                          <el-button class="has-margin-left" type="text"><i class="el-icon-delete"></i></el-button>
                        </el-tooltip>
                      </router-link>

                      <!-- Hardcoded additional feed limit until the pricing model is implemented -->
                      <router-link to="/link">
                        <el-tooltip content="Edit your main RSS feed from the Wavve.Link page in the menu" placement="top"
                          popper-class="hint-tooltip">
                          <el-button class="has-margin-left" type="text"><i class="el-icon-edit"></i></el-button>
                        </el-tooltip>
                      </router-link>

                      <el-button v-show="isPopoverDismissed && selectedEpisode.links[linkIndex]" class="has-margin-left"
                        type="text"><span class="fa fa-check-circle"></span></el-button>

                      <el-popover :placement="windowWidth > 768 ? `right` : `left`" title="Great!" width="270"
                        trigger="manual" :value="isPopoverVisible({ type: 'link', index: linkIndex })"
                        class="has-word-break-normal">
                        <el-button slot="reference" v-show="isPopoverVisible({ type: 'link', index: linkIndex })"
                          class="has-margin-left" type="text"><span class="fa fa-check-circle"></span></el-button>
                        <p class="has-word-break-normal">Please click Confirm button below to start processing your audio
                          file and move to Step 2.</p>
                      </el-popover>
                    </div>

                    <div class="is-clearfix"></div>
                  </div>

                  <div class="is-clearfix"></div>
                </div>
              </div>
              <div
                v-show="!addFirstWavveDialogVisible && !showAddNewFeedForm && editingRssIndex === undefined && links && links.length !== 0">
                <div class="is-pulled-left button-container">
                  <router-link to="/link">
                    <el-button type="text" @click="toggleAddNewFeedForm" v-if="!remoteAudioDownloading"><i
                        class="el-icon-plus"></i></el-button>
                  </router-link>
                </div>
              </div>
              <div v-if="links && links.length === 0">
                <div class="is-pulled-left button-container">
                  <router-link to="/link">
                    <el-button type="text" @click="toggleAddNewFeedForm" v-if="!remoteAudioDownloading"><i
                        class="el-icon-plus"></i> New Wavve Link</el-button>
                  </router-link>
                </div>
              </div>
            </el-tab-pane>

            <el-tab-pane label="RSS" name="rss" :id="'rsstab' + feeds.length">
              <div v-if="feeds.length === 0">
                <p class="has-margin-right has-small-margin-top has-text-grey">Podcast RSS Feed URL</p>
                <div class="is-flex">

                  <!--  
                  <el-input
                    v-model="addNewFeedForm.feed"
                    class="is-fullwidth-full-hd is-full-desktop"
                    :disabled="loadingRss"
                    @change="addFirstWavveDialogVisible = false"
                  ></el-input>
 -->
                  <el-autocomplete :popper-append-to-body="true" id="podcastSelect" v-model="addNewFeedFormSearch"
                    :clearable="true" popper-class="podcast-search"
                    placeholder="Type your podcast name or paste the RSS feed URL" @select="handleSelect"
                    :trigger-on-focus="false" :fetch-suggestions="querySearch" :debounce="400"
                    @change="addFirstWavveDialogVisible = false">
                    <template slot-scope="{ item }" class="podcast-item" v-if="showSuggestions">
                      <div class="media-left">
                        <figure class="image">
                          <img :src="item.thumbnail" />
                        </figure>
                      </div>
                      <p class="value">{{ item.value }}</p>
                      <p class="description">{{ item.description }}</p>
                    </template>
                  </el-autocomplete>

                  <el-button slot="reference" class="has-margin-left" type="primary" round @click="saveFeed"
                    v-if="!loadingRss">
                    <i class="el-icon-check"></i>
                  </el-button>
                  <el-button slot="reference" class="has-margin-left" style="border: 0px;" v-if="loadingRss"
                    v-loading="loadingRss">
                    <i class="el-icon-check"></i>
                  </el-button>

                </div>
                <div>
                  <p style=cursor:pointer;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;
                    class="ml-5 gray has-small-margin-top" @click="copy(rssUrl)"
                    :style="{ maxWidth: isMobile ? (windowWidth - 208) + 'px' : '500px' }" v-if="rssUrl"> Feed URL:<b>
                      {{ rssUrl }}</b></p>
                </div>

              </div>


              <div v-if="feeds && !loading">

                <div v-for="(feed, feedIndex) in feeds.slice(paginationStartIndex, paginationEndIndex)"
                  v-bind:key="feeds.indexOf(feed)" class="has-small-margin-top">

                  <div v-if="editingRssURL === feed.feedUrl"
                    style="margin-left:5px;;background:#fafafa; border: 1px solid #ffffff; box-shadow: 1px 1px 10px rgba(0,0,0,0.2); border-radius:5px; height:94px; margin-bottom:5px; margin-top:-5px; maxWidth: 720px; padding: 5px"
                    class="has-small-margin-top">
                    <div class="is-pulled-left has-small-margin-rigth" style=width:90px;height:95px>
                      <img cache :src="(feed.image && feed.image.url) || (feed.itunes && feed.itunes.image)"
                        :style="{ border: ' 1px dashed  #666666, 2px solid white ', opacity: '1', 'border-radius': '4px', maxHeight: '70px', maxWidth: '70px', 'margin-right': '20px', 'margin-left': '5px', 'margin-top': '5px' }"
                        alt="Feature Image" />
                    </div>

                    <p class="has-small-margin-top has-margin-right has-text-darkgrey">Updating <span
                        style=font-weight:500>{{ feed.title }}</span> Feed URL</p>

                    <div class="is-flex">
                      <el-input
                        :style="{ maxWidth: isMobile ? (windowWidth - 208) + 'px' : '500px', 'margin-top': '8px' }"
                        v-model="editFeedForm.feed" class="is-fullwidth-full-hd is-full-desktop"
                        :disabled="loadingRss"></el-input>
                      <el-button class="has-margin-left mini" size="mini" :disabled="loadingRss"
                        @click="deleteFeed(feed, feedIndex, feed.feedUrl)"><i class="el-icon-delete"></i></el-button>
                      <el-button class="has-margin-left mini" type="secondary" size="mini" v-loading="loadingRss"
                        @click="saveEditFeed"><i class="el-icon-check"></i></el-button>
                    </div>
                  </div>
                  <div>

                    <KeepAlive>
                      <div style="position:relative" :key="'link' + feed.feedUrl" class="has-small-margin-top"
                        v-if="editingRssURL !== feed.feedUrl">
                        <div class="is-pulled-left has-small-margin-rigth" style=width:90px;height:95px>
                          <img cache :src="(feed.image && feed.image.url) || (feed.itunes && feed.itunes.image)"
                            :style="{ 'border-radius': '4px', maxHeight: '70px', maxWidth: '70px', 'margin-right': '20px', 'margin-left': '5px', 'margin-top': '5px', 'box-shadow': '0px 0px 10px rgba(0,0,0,0.1)' }"
                            alt="Feature Image" />
                        </div>

                        <div>
                          <p @click="copy(feed.feedUrl)" style=cursor:pointer class=" has-text-darkgray  has-margin-left">
                            From {{ feed.title }}</p>
                          <div @click="copy(feed.feedUrl)"
                            :style="{ maxWidth: isMobile ? (windowWidth - 208) + 'px' : '500px' }"
                            style=cursor:pointer;font-size:70%;color:gray;margin-left:20px;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;>
                            {{ feed.feedUrl }}</div>

                        </div>
                        <el-select class="" v-model="selectedEpisode.feeds[currentPage][feedIndex]"
                          placeholder="Select Episode" filterable :key="selectedEpisode.feeds[currentPage][feedIndex]"
                          @change="e => onEpisodeSelectChange({ guid: e, rss: feed, type: 'feed', feedIndex })"
                          :ref="`episodeSelectRss-${feedIndex}`"
                          :style="{ maxWidth: isMobile ? (windowWidth - 208) + 'px' : '500px' }" auto-complete="off"
                          @visible-change="handlePauseAll" @blur="handlePauseAll">
                          <el-option v-for="ep in feed.items" :key="ep.guid" :label="ep.title" :value="ep.guid"
                            style=minHeight:50px;>
                            <div style=height:5px v-if="isPlaying != 2"></div>
                            <div :style="{ maxWidth: isMobile ? (windowWidth - 208) + 'px' : '500px' }">
                              <span style=font-size:25px;margin-left:-5px;margin-right:9px class=is-pulled-left>
                                <a class="el-icon-video-play handlePlay playbutton" v-if="isPlaying != ep.guid" href=#
                                  :name="ep.guid" @click="handlePlay($event, ep)"></a>
                                <a class="el-icon-video-pause pausebutton" v-if="isPlaying == ep.guid" href=#
                                  :name="ep.guid" @click="handleStop($event, ep)"></a>
                              </span>
                              <span class="is-pulled-left" style="width:50%">

                                {{
                                  ep.title }}</span>
                              <span style="width:15%; text-align: right"
                                class="is-pulled-right has-text-grey-light has-margin-left is-size-6-7">{{ formatDate(new
                                  Date(ep.isoDate)) }}</span>


                              <div style=width:100% v-if="ep.enclosure && ep.enclosure.url">
                                <br>


                                <div style=margin-top:-8px v-if="isPlaying == ep.guid"
                                  @click="handleProgressbarClick(ep.guid)" class="hp_slide">
                                  <div class="hp_slideInnet">
                                    <div class="hp_range"></div>
                                  </div>
                                </div>
                              </div>



                            </div>

                          </el-option>
                        </el-select>


                        <el-button class="has-margin-left has-small-margin-right" type="text"
                          @click="deleteFeed(feed, feedIndex, feed.feedUrl)"><i class="el-icon-delete"></i></el-button>
                        <el-button class="has-margin-left" type="text" @click="editFeed(feedIndex, feed.feedUrl)"
                          :disabled="remoteAudioDownloading"><i class="el-icon-edit"></i></el-button>

                        <el-popover :placement="windowWidth > 768 ? `right` : `left`" title="Great!" width="270"
                          trigger="manual"
                          :value="isPopoverVisible({ type: 'feeds', index: feedIndex, page: currentPage })"
                          class="has-word-break-normal">
                          <el-button slot="reference"
                            :style="{ opacity: selectedEpisode.feeds[currentPage][feedIndex] ? 1 : 0 }"
                            class="has-margin-left" type="text"><span class="fa fa-check-circle"></span></el-button>
                          <p class="has-word-break-normal">Please click Confirm button below to start processing your
                            audio file and move to Step 2.</p>
                        </el-popover>
                      </div>
                    </KeepAlive>

                  </div>

                  <div
                    v-if="remoteAudioDownloading && remoteAudioDownloadProgress && selectedEpisode.feeds[currentPage][feedIndex]"
                    class="has-margin-top">
                    <p class="has-text-grey">Downloading audio from RSS...</p>
                    <el-link>
                      <p class="has-text-primary" @click="clearAudioFile">Cancel</p>
                    </el-link>
                  </div>
                  <div class="is-clearfix"></div>
                </div>

                <!-- only show add rss feed button if there is no current action -->
                <div
                  v-show="!addFirstWavveDialogVisible && !showAddNewFeedForm && editingRssIndex === undefined && feeds.length !== 0">
                  <div class="is-pulled-left button-container">
                    <el-button type="text" @click="toggleAddNewFeedForm" v-if="!remoteAudioDownloading"><i
                        class="el-icon-plus"></i> Add new RSS feed</el-button>
                  </div>

                </div>

              </div>

              <div class="is-clearfix"></div>

              <div v-if="showAddNewFeedForm" class="has-margin-top">
                <p class="has-margin-right has-text-grey">Podcast RSS Feed URL</p>

                <div class="is-flex">
                  <!-- <el-form-item label="Podcast" prop="podcast"> -->
                  <el-autocomplete :popper-append-to-body="true" id="podcastSelect" v-model="addNewFeedFormSearch"
                    :clearable="true" popper-class="podcast-search"
                    placeholder="Type your podcast name or paste the RSS feed URL" @select="handleSelect"
                    :trigger-on-focus="false" :fetch-suggestions="querySearch" :debounce="400">
                    <template slot-scope="{ item }" class="podcast-item" v-if="showSuggestions">
                      <div class="media-left">
                        <figure class="image">
                          <img :src="item.thumbnail" />
                        </figure>
                      </div>
                      <p class="value">{{ item.value }}</p>
                      <p class="description">{{ item.description }}</p>
                    </template>
                  </el-autocomplete>
                  <el-button slot="reference" class="has-margin-left" plain round @click="clearAddFeedForm"
                    v-if="!loadingRss">
                    <i class="el-icon-delete"></i>
                  </el-button>
                  <el-button slot="reference" class="has-margin-left" type="primary" round @click="saveFeed"
                    v-if="!loadingRss">
                    <i class="el-icon-check"></i>
                  </el-button>
                  <el-button slot="reference" class="has-margin-left" style="border: 0px;" v-if="loadingRss"
                    v-loading="loadingRss">
                    <i class="el-icon-check"></i>
                  </el-button>
                  <!-- <el-button class="has-margin-left mini" type="text" :disabled="loadingRss" @click="clearAddFeedForm">
                    <i class="el-icon-delete"></i>
                  </el-button>
                  <el-button slot="reference" class="has-margin-left" type="text" @click="saveFeed">
                    <i class="el-icon-check"></i>
                  </el-button> -->
                </div>
                <p style=cursor:pointer;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;
                  class="ml-5 gray has-small-margin-top" @click="copy(rssUrl)"
                  :style="{ maxWidth: isMobile ? (windowWidth - 208) + 'px' : '500px' }" v-if="rssUrl">Feed URL:<b> {{
                    rssUrl }}-</b></p>
              </div>

              <div class="is-clearfix"></div>
              <el-pagination background small layout="prev, pager, next" :total="feeds.length" :page-size="pageSize"
                :current-page.sync="currentPage" class="has-margin-top" :hide-on-single-page="true">
              </el-pagination>

            </el-tab-pane>

            <el-tab-pane label="Upload" name="upload" class="has-small-margin-top">
              <div class="upload-container">
                <el-upload :on-change="handleUploadSelect"
                  accept="audio/wav, audio/mpeg, audio/mp3, audio/x-m4a, audio/flac, audio/ogg, video/mp4" action
                  :auto-upload="false" :multiple="false" :file-list="fileList">
                  <el-button slot="trigger" type="primary" round>{{ audio ? 'Upload New File' : 'Upload File'
                  }}</el-button>
                </el-upload>

                <el-tooltip class="item has-small-margin-left" effect="dark"
                  content="Supported file formats: .mp3, .mp4, .m4a, .flac, .wav" placement="right" v-show="!audio">
                  <i class="el-icon-info"></i>
                </el-tooltip>

                <el-button v-show="uploadingAudio" class="has-margin-left" type="text"><span
                    class="fa fa-check-circle"></span></el-button>

                <el-popover :placement="windowWidth > 768 ? `right` : `left`" title="Great!" width="270" trigger="manual"
                  :value="isPopoverVisible({ type: 'audio' })" class="has-word-break-normal">
                  <el-button slot="reference" v-show="isPopoverVisible({ type: 'audio' })" class="has-margin-left"
                    type="text"><span class="fa fa-check-circle"></span></el-button>
                  <p class="has-word-break-normal">Please click Confirm button below to start processing your audio file
                    and move to Step 2.</p>
                </el-popover>
              </div>

              <div v-if="uploadingAudio" class="has-margin-top" :style="{ width: '400px' }">
                <p>Uploading...</p>
                <el-progress :percentage="audioUploadPercentCompleted"></el-progress>
              </div>
            </el-tab-pane>

            <el-tab-pane label="Record" name="record" class="has-small-margin-top">
              <el-button type="primary" round v-if="!showRecorder" @click="showRecorder = true">Record Now</el-button>
              <el-button type="text" v-if="showRecorder" @click="showRecorder = false">Hide Recorder</el-button>
              <div class="column is-two-thirds-fullhd is-full-desktop">
                <div v-show="showRecorder">
                  <audio-recorder v-if="$store.state.browser.name === 'chrome'" :style="{ margin: '0 auto' }"
                    upload-url="#" format="wav" :attempts="100" :time="5" :show-upload-button="false"
                    :after-recording="afterRecord" :before-recording="emptyCallback" :pause-recording="emptyCallback"
                    :select-record="emptyCallback" :before-upload="emptyCallback" :bit-rate="192"
                    :sample-rate="48000"></audio-recorder>
                  <p class="tip primary" v-else>
                    If you would like to record audio through Wavve, please use Google Chrome on your laptop or desktop.
                    They support the latest audio features
                    that we need.
                  </p>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="My Library" name="mylibrary" class="has-small-margin-top">
              <el-collapse v-model="activeLibraryItem" accordion>
                <el-collapse-item v-for="(mediaItem, mediaItemIndex) in mediaLibrary" :key="mediaItem._id"
                  :name="mediaItem.guid" :class="{ 'library-border': mediaItemIndex !== mediaLibrary.length - 1 }">
                  <template slot="title">
                    <!-- <el-button class="fas fa-trash-alt has-margin-right" round size="mini" plain
                      @click.stop="deleteMedia(mediaItem.guid)"></el-button> -->
                    <div style="text-align:center;" class="media-title has-cursor-pointer"
                      v-show="!titleInputVisible[mediaItem.guid]" @click.stop="showTitleInput(mediaItem)">
                      <el-tooltip style=margin-top:1px class="item" effect="dark" :content="mediaItem.title || 'Untitled'"
                        placement="top-start" offset=4>
                        <p class="media-title">
                          <span>{{ mediaItem.title }}</span>
                          <el-button style='padding:0px; font-size:110%' type="text" size="normal"
                            @click.stop="showTitleInput(mediaItem)">
                            <i style=" margin-left: 10px" class="el-icon-edit"></i>
                          </el-button>
                          <el-button class="has-margin-left" style='padding:0px; font-size:110%' type="text" size="normal"
                            @click.stop="handleDeleteMedia(mediaItem.guid)"><i class="el-icon-delete"></i></el-button>
                        </p>
                      </el-tooltip>
                    </div>
                    <div style="display: flex; flex-direction: row; justify-content: flex-start;">
                      <el-input autofocus v-model="titleInputValue[mediaItem.guid]"
                        v-show="titleInputVisible[mediaItem.guid]"
                        @keyup.enter.native="handleTitleInputConfirm(mediaItem)" :ref="`title-input-${mediaItem.guid}`"
                        @blur="
                          hideblur(mediaItem)
                          " :clearable="true"></el-input>
                      <el-button class="has-margin-left" type="text" v-show="titleInputVisible[mediaItem.guid]"
                        @click.stop="
                          $forceUpdate();
                        titleInputValue[mediaItem.guid] = mediaItem.title;
                        titleInputVisible[mediaItem.guid] = false;
                        $forceUpdate();">
                        <i class="el-icon-close"></i>
                      </el-button>

                      <el-button type="text" v-show="titleInputVisible[mediaItem.guid]"
                        @click.stop="handleTitleInputConfirm(mediaItem)"><i class="el-icon-check"></i></el-button>
                    </div>
                    <!-- {{ mediaItem.title }} -->
                    <el-button v-if="selectedLibraryItem === mediaItem.guid" class="has-margin-left" type="text"><span
                        class="fa fa-check-circle"></span></el-button>
                    <el-button type="primary" slot="reference" round class="has-margin-left"
                      @click="() => onEpisodeSelectChange({ guid: mediaItem.guid, type: 'library' })" size="small"
                      :disabled="selectedLibraryItem === mediaItem.guid" v-else>
                      {{ selectedLibraryItem === mediaItem.guid ? `Selected` : `Select` }}
                    </el-button>
                  </template>
                  <audio preload="none" controls class="is-fullwidth" :ref="`selectedAudio-${mediaItem.guid}`">
                    <source :src="mediaItem.s3AudioUrl" type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  <div class="media-box">
                    <!-- <img :src="mediaItem.imageSrc || `./img/image_placeholder.png`"
                      :style="{ maxHeight: '36px', width: 'auto' }" alt="Feature Image" /> -->
                    <p class="has-small-margin-left">
                      {{ mediaItem.feedTitle }}
                    </p>
                    <p class="has-small-margin-left">
                      {{ getFormattedDate(mediaItem) }}
                    </p>
                  </div>

                  <!-- <el-popover :placement="windowWidth > 768 ? `right` : `left`" title="Great!" width="270"
                    trigger="manual" :value="isPopoverVisible({ type: 'library', guid: mediaItem.guid })"
                    class="has-word-break-normal">
                    <el-button type="primary" slot="reference" round
                      @click="() => onEpisodeSelectChange({ guid: mediaItem.guid, type: 'library' })" size="small"
                      class="has-margin-top is-fullwidth" :disabled="selectedLibraryItem === mediaItem.guid">
                      {{ selectedLibraryItem === mediaItem.guid ? `Selected` : `Select` }}
                    </el-button>

                    <p class="has-word-break-normal">Please click Confirm button below to start processing your audio file
                      and move to Step 2.</p>
                  </el-popover> -->
                </el-collapse-item>
              </el-collapse>
              <!-- <el-select
                class="select-active"
                v-model="selectedLibraryItem"
                placeholder="Select Audio File"
                filterable
                @change="e => onEpisodeSelectChange({ guid: selectedLibraryItem, type: 'library' })"
                ref="episodeSelectFeeds"
                :style="{ maxWidth: isMobile() ? '170px' : '190px' }"
                auto-complete="off"
              >
                <el-option v-for="mediaItem in mediaLibrary" :key="mediaItem.guid" :label="mediaItem.title" :value="mediaItem.guid">
                  <span class="is-pulled-left" style="max-width: 200px; text-overflow: ellipsis; overflow: hidden; white-space: nowrap">{{
                    mediaItem.title
                  }}</span>
                  <span class="is-pulled-right has-text-grey-light has-margin-left is-size-6-7">{{ formatDate(new Date(mediaItem.dateAdded)) }}</span>
                </el-option>
              </el-select> -->
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
    <br />
    <el-dialog width="400px" :visible.sync="warnFileSize" center key="warn-file-size">
      <span slot="title" class="has-text-centered is-size-3">
        <p class="is-size-4">Hey, while that's loading...</p>
      </span>

      <div v-if="!this.decodeAudioFile">
        <p class="is-size-6 has-word-break-normal">
          That file is over 150MB which could crash the browser if we attempt to load it. Try trimming your audio to under
          75MB before using it on Wavve.
          <br />
          <br />
          <a href="https://www.audacityteam.org/download/" target="_blank" rel="noopener noreferrer">Audacity</a> is a
          great (and free) program that you can use
          to do this.
        </p>
      </div>
      <div v-else>
        <p class="is-size-6 has-word-break-normal">
          We noticed this file is over 75MB, which means on a slower connection or browser, you may wait 3-5 minutes
          before the audio waveform loads. If it
          hangs up and fails to load in that time frame, you may want to try editing it down in size or length.
          <br />
          <a href="https://www.audacityteam.org/download/" target="_blank" rel="noopener noreferrer">Audacity</a> is a
          free tool if you don't have one.
        </p>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" round @click="warnFileSize = false">OK, thanks!</el-button>
      </span>
    </el-dialog>
    <audio inline preload="none" class="rssInlineAudioPlayer" id='player' ref="player"></audio>
    <el-dialog class="delete-dialog" title="Delete confirmation" center :width="isMobile ? '90%' : '40%'"
      :visible.sync="deleteMediaDialog">
      <span>
        Are you sure you want to delete?
      </span>
      <template #footer>
        <span class="dialog-footer has-margin-top">
          <el-button round @click="deleteMediaDialog = false">No</el-button>
          <el-button round type="primary" @click="deleteMedia">
            Yes
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import { timeFormat } from 'd3';
import Parser from 'rss-parser';
import {
  CREATE_WAVVE_LINK,
  UPDATE_WAVVE_LINK,
  FETCH_PODCAST_RSS,
  CLEAR_AUDIO_FILE,
  UPDATE_FEEDS,
  SET_SELECTED_EPISODE,
  SET_EPISODE_INFO,
  SET_POPOVER_DISMISSED,
  SET_AUDIO_UPLOAD_FILE,
  MEDIA_DELETE,
  MEDIA_CHANGE,
} from '@/store/action-types';
import EventBus from '@/helpers/event-bus';
import AwsService from '@/api/aws.service';
import { SRC_BUCKET } from '@/config';
import axios from 'axios';

// import LinkService from '@/api/link.service';
const itunesUrl = 'https://itunes.apple.com/search';
const dateTimeFormat = timeFormat('%_d %b %Y');
const PROXY_BASE_URL = 'https://redirect.wavve.co'; // Runs on a t2.nano instance on EC2

export default {
  name: 'AudioPicker',
  props: ['mode', 'selectedAudioFile', 'fromEditor'],

  data() {
    return {
      isPlaying: null,
      showSuggestions: false,
      rssUrl: '',
      fileList: [],
      recordedFilename: null,
      showRecorder: false,
      warnFileSize: false,
      decodeAudioFile: true,
      selectedLibraryItem: null,
      addFirstWavveDialogVisible: false,
      isAudioSelected: false,
      selectedEpisode: {
        links: [],
        feeds: [],
      },
      showAddNewFeedForm: false,
      addNewFeedFormSearch: '',
      loading: false,
      loadingRss: false,
      editingRssIndex: undefined,
      editingRssURL: undefined,
      uploadingAudio: false,
      popoverVisible: false,
      linkPopoverVisible: false,
      currentPage: 1,
      actionPage: null,
      pageSize: window.innerWidth <= 760 ? 3 : 3,
      paginationStartIndex: 0,
      paginationEndIndex: 3,
      decodingProgress: null, // estimated progress based on audio url creation time
      visible: true,
      hidden: false,
      progressLabel: undefined,
      windowWidth: 1280,
      popoverVisibleOnTab: null,
      currentTab: null,
      activeLibraryItem: null,
      audioUploadPercentCompleted: 0,
      stepTabs: 'step1',
      addNewFeedForm: {
        feed: '',
      },
      editFeedForm: {
        feed: '',
      },
      audioElement: null,
      titleInputVisible: {},
      titleInputValue: {},
      mediaGuidToDelete: undefined,
      deleteMediaDialog: false,
    };
  },
  async created() {
    this.paginationStartIndex = 0
    this.paginationEndIndex = 1 * this.pageSize
    this.loading = true;

    if (!this.$store.state.podcastRss) await this.$store.dispatch(FETCH_PODCAST_RSS);

    setTimeout(async () => {
      await this.setSelectedEpisodeFromState();
      this.loading = false;
    }, 10);

    EventBus.$on('remoteAudioDownloadFail', this.handleRemoteAudioDownloadFail);
    // EventBus.$on('AudioFileExceedsMaxSize', this.handleAudioFileExceedsMaxSize);

    this.currentTab = 'upload';
    if (this.$route.query['show-rss'] === 'true') this.currentTab = 'rss';
    if (this.feeds.length > 0) this.currentTab = 'rss';

    this.titleInputVisible = this.mediaLibrary.reduce((titleInputVisible, media) => {
      titleInputVisible[media.guid] = false;
      return titleInputVisible;
    }, {});
    this.titleInputValue = this.mediaLibrary.reduce((titleInputValue, media) => {
      titleInputValue[media.guid] = media.title || '';
      return titleInputValue;
    }, {});
  },
  mounted() {
    this.windowWidth = parseInt(window.innerWidth);
    window.addEventListener('resize', () => {
      this.windowWidth = parseInt(window.innerWidth);
    });
    this.audioElement = document.getElementById('selectedAudio');
    EventBus.$on('onStepTabsChange', stepTabs => this.onStepTabsChange(stepTabs));
  },
  watch: {
    decoding() {
      if (this.decoding) {
        // start estimated progress
        this.startDecodingProgress();
      }
    },
    audioTypeTab() {
      this.showAddNewFeedForm = false;
    },
    feeds() {
      this.clearFeeds();
    },
    currentPage(val) {
      if (val) {
        this.paginationStartIndex = this.currentPage * this.pageSize - this.pageSize;
        this.paginationEndIndex = this.currentPage * this.pageSize;
      }
    },
    audio(val) {
      if (val) {
        this.showRecorder = false;
      }
    },

    async uploadAudioProcessing(isProcessing) {
      if (!isProcessing) {
        await this.uploadAudio();
      }
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth <= 768;
    },
    user() {
      return this.$store.state.user;
    },
    audioTypeTab: {
      get() {
        return this.currentTab;
      },
      set(newAudioTypeTab) {
        this.currentTab = newAudioTypeTab;
      },
    },
    storeFeeds() {
      return this.$store.state.user.rssFeeds;
    },

    activeLinks() {
      return this.$store.state.user.links.filter(link => link.status === 'FREE' || link.status === 'LIVE');
    },
    mediaLibrary() {
      return (this.$store.state.user && this.$store.state.user.media) || [];
    },
    audio() {
      return this.$store.state.audioDataUrl;
    },
    decoding() {
      return this.$store.state.audioBufferDecoding;
    },
    selectedAudio() {
      return this.$store.state.audioUploadDataUrl;
    },
    audioReady() {
      return this.$store.state.audioReady;
    },
    uploadAudioProcessing() {
      return this.$store.state.uploadAudioProcessing;
    },
    audioReading() {
      return this.$store.state.audioReading;
    },
    remoteAudioDownloading() {
      return this.$store.state.remoteAudioDownloading;
    },
    remoteAudioDownloadProgress() {
      return this.$store.state.remoteAudioDownloadProgress;
    },
    links() {
      return this.$store.state.podcastRss ? this.$store.state.podcastRss.links : null;
    },
    isPopoverDismissed() {
      return this.$store.state.popoverDismissed;
    },
    stateSelectedEpisode() {
      return this.$store.state.selectedEpisode;
    },
    feeds() {
      return this.$store.state.podcastRss ? this.$store.state.podcastRss.feeds : [];
    },
    shownFeeds() {
      return this.$store.state.podcastRss ? this.$store.state.podcastRss.feeds.slice(this.paginationStartIndex, this.paginationEndIndex) : [];
    },
    editingJob() {
      return this.$store.state.editingJob || null;
    },
    loadingText() {
      if (this.remoteAudioDownloading) return `Downloading audio ${(this.remoteAudioDownloadProgress * 100).toFixed(2)}%`;
      if (this.decoding) return `Decoding audio for trimming... ${this.decodingProgress ? `${(this.decodingProgress * 100).toFixed(2)}%` : ''}`;
      if (!this.audioReady) return `Getting your audio ready...`;
      return 'This moment of peace is brought to you by your browser, internet speed, file size, and some cute code koalas. Please stay on this page until your audio is perfectly painted on the screen.';
    },
    episodeInfo() {
      return this.$store.state.episodeInfo;
    },
  },

  methods: {
    showTitleInput(media) {
      this.$forceUpdate();
      this.titleInputVisible[media.guid] = true;
      this.$refs[`title-input-${media.guid}`][0].$refs.input.focus();
      this.$refs[`title-input-${media.guid}`][0].$refs.input.select();
      this.$forceUpdate();
      this.$nextTick(() => {
        this.$refs[`title-input-${media.guid}`][0].$refs.input.focus();
        this.$forceUpdate();
      });
    },
    hideblur(media) {
      setTimeout(() => {
        this.titleInputValue[media.guid] = media.title;
        this.titleInputVisible[media.guid] = false;
      }, 200)
    },
    async handleTitleInputConfirm(media) {
      const inputValue = this.titleInputValue[media.guid];

      if (inputValue) {
        this.$store.dispatch(MEDIA_CHANGE, { guid: media.guid, data: { title: inputValue } })
          .then(() => console.log('FEEDBACK'))
          .catch(() => console.error('ERROR FEEDBACK'));
      }

      this.titleInputVisible[media.guid] = false;
    },
    handleDeleteMedia(guid) {
      this.mediaGuidToDelete = guid;
      this.deleteMediaDialog = true;
    },
    async deleteMedia() {
      if (this.selectedLibraryItem === this.mediaGuidToDelete) {
        await this.$store.dispatch(CLEAR_AUDIO_FILE);

        this.selectedLibraryItem = null;
        this.isAudioSelected = false;
      }

      this.$store.dispatch(MEDIA_DELETE, this.mediaGuidToDelete).then(() => console.log('FEEDBACK')).catch(() => console.error('ERROR FEEDBACK'));
      this.deleteMediaDialog = false;
    },
    handlePauseAll() {
      let els = document.getElementsByClassName('playbutton')
      Array.prototype.forEach.call(els, function (el) {
        el.classList.add('el-icon-video-play')
        el.classList.remove('el-icon-loading');
      })
      this.$refs['player'].pause()


    },
    copy(val) {
      navigator.clipboard.writeText(val)
      Message.success({ message: "RSS URL copied to clipboard", duration: 5000 });
    }
    ,
    handleProgressbarClick(elid) {
      event.preventDefault();

      // var player = document.getElementById('player'+elid)
      var player = document.getElementById('player')
      // console.log(event.target,event.target.getAttribbute("name"),player)

      let els = document.getElementsByClassName('hp_range')
      Array.prototype.forEach.call(els, function (el) {
        // el/.style.width((currentTime +.25)/duration*100+'%')
        el.style.width = (event.offsetX / event.target.clientWidth) * 100 + '%'
        // console.log( el.style.width)
        // el.animate().cancel().animate({'width':(currentTime +.25)/duration*100+'%'},250,'linear');
      })

      let position = (event.offsetX / event.target.clientWidth) * player.duration
      player.currentTime = position
      // duration
      event.stopPropagation();
    },
    handlePlay(event, ep) {
      this.isPlaying = null
      let els = document.getElementsByClassName('playbutton')
      Array.prototype.forEach.call(els, function (el) {
        el.classList.add('el-icon-video-play')
        el.classList.remove('el-icon-loading');
      })
      event.target.classList.remove('el-icon-video-play');
      event.target.classList.add('el-icon-loading');

      event.preventDefault();
      var player = this.$refs['player']
      //       let els = document.getElementsByClassName('rssInlineAudioPlayer')
      // Array.prototype.forEach.call(els, function(el) {
      //   el.pause();
      player.pause();
      player.src = null
      player.load()
      // console.log(el);
      // document.getElementById( el).stop()
      // });
      // var player = document.getElementById('player'+event.target.name)

      // document.getElementById('player')
      player.src = ep.enclosure.url
      // console.log(player,ep)
      self = this
      player.addEventListener("timeupdate", function () {
        if (player.duration > 0 && !player.paused) {
          self.isPlaying = event.target.name
          event.target.classList.remove('el-icon-loading');
          event.target.classList.add('el-icon-video-play');
        }

        var currentTime = player.currentTime;
        var duration = player.duration;
        // console.log('play',(currentTime +.25)/duration*100+'%');
        let els = document.getElementsByClassName('hp_range')
        Array.prototype.forEach.call(els, function (el) {
          // el/.style.width((currentTime +.25)/duration*100+'%')
          el.style.width = (currentTime + .25) / duration * 100 + '%'
          // el.animate().cancel().animate({'width':(currentTime +.25)/duration*100+'%'},250,'linear');
        })
      });
      // player.currentTime=0
      player.play()

      event.stopPropagation();
    },
    handleStop(event, ep) {
      this.isPlaying = null
      event.preventDefault();
      let els = document.getElementsByClassName('rssInlineAudioPlayer')
      Array.prototype.forEach.call(els, function (el) {
        el.pause();
        // console.log(el);
        // document.getElementById( el).stop()
      });
      event.stopPropagation();
    },

    async querySearch(queryString, cb) {
      this.showSuggestions = true;
      if (queryString.startsWith('http')) {
        this.rssUrl = queryString;
        this.addNewFeedForm.feed = queryString;
        this.showSuggestions = false;
        cb([]);
        // return;
      }
      // const { results } = await axios.post(`${PROXY_BASE_URL}/${itunesUrl}?limit=6&media=podcast&term=${queryString}`);

      const { results } = await axios.get(`${PROXY_BASE_URL}/${itunesUrl}?limit=6&media=podcast&term=${queryString}`,
        {
          headers: { 'Content-Type': "application/json; charset=utf-8" },
          withCredentials: false,
          transformRequest: [
            (data, headers) => {
              delete headers.common//['Authorization'];
              return JSON.stringify(data);
            },
          ],
        });

      const podcastList = results.map(({ trackName, artworkUrl100, feedUrl, artistName }) => ({
        value: trackName,
        thumbnail: artworkUrl100,
        description: artistName,
        feedUrl,
      }));
      cb(podcastList);
    },
    handleSelect(item) {
      this.rssUrl = item.feedUrl;
      this.addNewFeedForm.feed = item.feedUrl;
      // this.signupForm.podcastName = item.value;
      // this.signupForm.podcastRss = item.feedUrl;
    },
    onStepTabsChange(stepTabs) {
      this.stepTabs = stepTabs;
      this.mediaLibrary.forEach(item => {
        const audioElement = this.$refs[`selectedAudio-${item.guid}`][0];
        if (audioElement) {
          audioElement.pause();
        }
      });
    },
    isPopoverVisible({ type, index, page, guid }) {
      if (this.isPopoverDismissed) return false;
      if (this.fromEditor) return false;

      if (type === 'link' && this.popoverVisibleOnTab === 'link') return !!this.selectedEpisode.links[index];
      if (type === 'library' && this.popoverVisibleOnTab === 'library') return this.selectedLibraryItem === guid;
      if (type === 'audio' && this.popoverVisibleOnTab === 'audio') return this.isAudioSelected;
      if (type === 'feeds' && this.popoverVisibleOnTab === 'feeds') return !!this.selectedEpisode.feeds[page][index];

      return false;
    },
    getFormattedDate(media) {
      if (media.dateAdded) {
        return timeFormat('%B %d, %Y')(new Date(media.dateAdded));
      }
      return 'Audio date not available';
    },
    async uploadAudio() {
      this.isAudioSelected = false;
      setTimeout(() => {
        this.$store.dispatch(SET_POPOVER_DISMISSED, false);
        this.popoverVisibleOnTab = 'audio';
      }, 100);
      this.uploadingAudio = true;
      const uploadResponse = await AwsService.s3Signing(
        {
          type: 'audio',
          data: this.selectedAudio,
        },
        {
          onUploadProgress: progressEvent => {
            const percentCompleted = (Math.round((progressEvent.loaded * 1000) / progressEvent.total) / 10).toFixed(1);
            this.audioUploadPercentCompleted = parseInt(percentCompleted);
            this.progressLabel = `${percentCompleted}% Uploaded`;
          },
        }
      );
      this.progressLabel = undefined;
      // eslint-disable-next-line prefer-destructuring
      const uuid = uploadResponse.uuid;

      this.setUploadAudio(uuid);
    },
    setUploadAudio(uuid) {
      this.isAudioSelected = true;

      const audioUrl = `https://${SRC_BUCKET}.s3.amazonaws.com/tmp/${uuid}/audiofile`;
      const episodeInfo = {
        type: 'upload',
        title: 'My Audio File',
        audioUrl,
      };

      this.$store.dispatch(SET_SELECTED_EPISODE, { type: 'upload', guid: uuid });

      this.uploadingAudio = false;
      this.selectAudioUrl({ url: audioUrl, episodeInfo });
      this.$emit('setEnableStep2');
    },
    startDecodingProgress() {
      // estimated decorder progress bar
      const estimateDate = this.$store.state.audioBufferDecodeEstimateDate;
      if (estimateDate) {
        const startEndTimeDifference = estimateDate.endDate - estimateDate.startDate;

        const vm = this;
        const interval = setInterval(() => {
          const now = +new Date();
          const estimatedRemainingTime = estimateDate.endDate - now;

          const progress = (startEndTimeDifference - estimatedRemainingTime) / startEndTimeDifference;
          vm.decodingProgress = progress;

          if (progress > 1) {
            clearInterval(interval);
            vm.decodingProgress = null;
          }
        }, 100);
      }
    },
    async setSelectedEpisodeFromState() {
      this.clearFeeds();
      if (this.stateSelectedEpisode) {
        const { type, guid, feedIndex, page, file } = this.stateSelectedEpisode;

        if (type === 'recording') {
          this.afterRecord(file);
          return;
        }
        if (type === 'upload') {
          this.currentTab = 'upload';
          this.setUploadAudio(guid);
          return;
        }
        if (type === 'link') {
          this.selectedEpisode.links[feedIndex] = guid;
          this.currentTab = 'wavve-link';
        } else if (type === 'feed') {
          // this.clearFeeds();
          this.currentTab = 'rss';
          this.selectedEpisode.feeds[page][feedIndex] = guid;
        }

        await this.onEpisodeSelectChange(this.stateSelectedEpisode);
      }
    },
    clearFeeds() {
      const pageCount = Math.ceil(this.feeds.length / this.pageSize);
      for (let i = 1; i <= pageCount; i += 1) {
        this.selectedEpisode.feeds[i] = [];
      }
    },
    clearWavveLinks() {
      for (let i = 0; i <= this.activeLinks.length; i += 1) {
        this.selectedEpisode.links[i] = null;
      }
    },
    async clearAudioFile() {
      if (this.feeds) {
        this.clearFeeds();
      }
      if (this.links) {
        this.clearWavveLinks();
      }
      await this.$store.dispatch(CLEAR_AUDIO_FILE);
    },
    clearAddFeedForm() {
      this.editFeedForm.feed = ''
      this.rssUrl = ''
      this.addNewFeedFormSearch = ''
      this.addNewFeedForm.feed = '';
      this.showAddNewFeedForm = false;
      this.rssUrl = '';
      // this.addNewFeedForm.feedname = '';
    },
    async createWavveLink() {
      this.loadingRss = true;
      this.popoverVisible = false;

      try {
        const link = await this.$store.dispatch(CREATE_WAVVE_LINK);
        const parser = new Parser();
        const rss = await parser.parseURL(`${PROXY_BASE_URL}/${this.addNewFeedForm.feed.trim()}`);
        const update = {
          feed: this.addNewFeedForm.feed.trim(),
          description: rss.description,
          title: rss.title,
        };
        await this.$store.dispatch(UPDATE_WAVVE_LINK, { _id: link._id, update });
        this.$router.push('/link');

        // TODO: remove old functionality
        // const linkData = await LinkService.getUserLink();
        // const newLink = linkData.find(linkObject => linkObject._id === link._id);
        // console.log('newLink', newLink);
        // const parser = new Parser();
        // const rss = await parser.parseURL(`${PROXY_BASE_URL}/${newLink.feed}`);
        // console.log('newLink rss', rss);
        // await this.$store.dispatch(UPDATE_FEEDS, { links: [rss], feeds: this.feeds });
        // this.$notify.success({ title: 'Success', message: 'Saved Wavve Link feed' });
        // this.addNewFeedForm.feed = '';
        // this.audioTypeTab = 'wavve-link';
      } catch (error) {
        this.$notify.error({ title: 'Error', message: 'No RSS Feed found at given URL' });
      }
      this.loadingRss = false;
    },
    async toggleAddNewFeedForm() {
      this.editFeedForm.feed = ''
      this.rssUrl = ''
      this.addNewFeedFormSearch = ''
      this.editingRssIndex = undefined;
      this.actionPage = null;
      this.showAddNewFeedForm = true;
    },
    async saveEditFeed() {
      this.loadingRss = true;
      try {
        const parser = new Parser();
        const rss = await parser.parseURL(`${PROXY_BASE_URL}/${this.editFeedForm.feed}`);
        this.feeds[this.editingRssIndex] = rss;
        this.editingRssURL = undefined;
        await this.$store.dispatch(UPDATE_FEEDS, { links: this.links, feeds: this.feeds });

        this.editingRssIndex = undefined;
        this.editingRssURL = undefined;
        this.actionPage = null;
        this.$notify.success({ title: 'Success', message: 'Edited RSS Feed' });
        this.clearAudioFile();
      } catch (err) {
        console.log(err);
        this.editingRssIndex = undefined;
        this.editingRssURL = undefined;

        this.$notify.error({ title: 'Error', message: 'No RSS Feed found at given URL' });
      }
      this.loadingRss = false;
    },
    editFeed(feedIndex, feedURL) {
      // this.editFeedForm.feed = this.$store.state.user.rssFeeds[3 * (this.currentPage - 1) + feedIndex];

      this.editFeedForm.feed = this.$store.state.user.rssFeeds.find(element => element == feedURL)
      this.editingRssURL = feedURL
      this.editingRssIndex = this.$store.state.user.rssFeeds.findIndex(element => element == feedURL);
      this.actionPage = this.currentPage;
      // console.log(this.editFeedForm,  this.editingRssIndex, feedURL);
      this.showAddNewFeedForm = false;
    },
    async deleteFeed(feed, feedIndex, feedURL) {
      try {
        const msgOptions = { confirmButtonText: 'Confirm', cancelButtonText: 'Cancel', type: 'warning' };
        await this.$confirm(`Are you sure you want to delete this feed?`, msgOptions);

        // using splice since we only want to delete one item
        // filter might delete multiple items if there are duplicates

        if (this.selectedEpisode.feeds[this.currentPage][feedIndex]) {
          this.$store.dispatch(CLEAR_AUDIO_FILE);
          if (this.feeds) {
            this.clearFeeds();
          }
        }

        const feeds = [...this.feeds];
        feeds.splice(this.pageSize * (this.currentPage - 1) + feedIndex, 1);

        await this.$store.dispatch(UPDATE_FEEDS, { links: this.links, feeds });

        this.$notify.success({ title: 'Success', message: 'Deleted RSS Feed' });
      } catch (err) {
        console.log('err', err);
        if (err !== 'cancel') {
          this.$notify.error({ title: 'Error', message: 'Error deleting RSS Feed' });
        }
      }
      this.editingRssIndex = undefined;
    },
    checkRssExists() {
      const rssIndex = this.user.rssFeeds.findIndex(feed => this.addNewFeedForm.feed === feed);
      if (rssIndex !== -1) {
        this.$notify.error({
          title: `Error`,
          message: 'Looks like this RSS Feed is already in your list here.',
        });

        const audioPickerIndex = this.feeds.map(feed => feed.feedUrl).indexOf(this.addNewFeedForm.feed);
        this.showAddNewFeedForm = false;
        this.currentPage = Math.floor(audioPickerIndex / this.pageSize) + 1;
        const highlightedFeedIndex = audioPickerIndex % this.pageSize;

        // wait for pagination to render
        setTimeout(() => {
          this.$refs[`episodeSelectRss-${highlightedFeedIndex}`][0].focus();
        }, 100);
        return true;
      }
      return false;
    },
    async saveFeed() {
      if (this.addNewFeedForm.feed) {
        const rssExists = this.checkRssExists();
        if (rssExists) {
          return;
        }
        this.loadingRss = true;
        try {
          const parser = new Parser();
          const rss = await parser.parseURL(`${PROXY_BASE_URL}/${this.addNewFeedForm.feed}`);
          rss.feedUrl = rss.feedUrl || this.addNewFeedForm.feed
          if (this.feeds) {
            await this.$store.dispatch(UPDATE_FEEDS, { links: this.links, feeds: [...this.feeds, rss] });
          } else {
            await this.$store.dispatch(UPDATE_FEEDS, { links: this.links, feeds: [rss] });
          }

          this.showAddNewFeedForm = false;
          this.addNewFeedForm.feed = '';
          this.editingRssIndex = undefined;
          this.clearAddFeedForm();
          this.currentPage = Math.ceil(this.feeds.length / this.pageSize);
          this.$notify.success({ title: 'Success', message: 'Added RSS Feed' });
          this.clearAudioFile();
        } catch (err) {
          console.log(err);
          this.$notify.error({ title: 'Error', message: 'No RSS Feed found at given URL' });
        }
        this.addFirstWavveDialogVisible = false;
        this.loadingRss = false;
      }
    },
    blobToBase64(blob) {
      return new Promise(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.readAsDataURL(blob);
      });
    },
    async afterRecord(data) {
      const base64Data = await this.blobToBase64(data.blob);

      this.isAudioSelected = false;

      this.uploadingAudio = true;
      const uploadResponse = await AwsService.s3Signing(
        {
          type: 'audio',
          data: base64Data,
        },
        {
          onUploadProgress: progressEvent => {
            const percentCompleted = (Math.round((progressEvent.loaded * 1000) / progressEvent.total) / 10).toFixed(1);
            this.audioUploadPercentCompleted = parseInt(percentCompleted);
            this.progressLabel = `${percentCompleted}% Uploaded`;
          },
        }
      );
      this.progressLabel = undefined;
      // eslint-disable-next-line prefer-destructuring
      const uuid = uploadResponse.uuid;

      this.setUploadAudio(uuid);
    },

    emptyCallback() { },
    select(audio) {
      this.$emit('update:audio', audio);
    },
    async onEpisodeSelectChange(value) {
      await this.$store.dispatch(CLEAR_AUDIO_FILE);

      if (value) {
        this.$emit('setEnableStep2');
        if (value.type === 'library') {
          this.selectFromLibrary(value);
        } else {
          this.selectPodcastEpisode(value);
        }
      }

      this.$store.dispatch(SET_POPOVER_DISMISSED, false);
    },


    async selectFromLibrary({ guid, type }, epInfo = undefined) {
      // this.audioTypeTab = 'mylibrary';
      this.selectedLibraryItem = guid;
      this.isAudioSelected = true;
      // this.popoverVisibleOnTab = 'library';
      // clean all other fields
      for (let i = 0; i <= this.activeLinks.length; i += 1) {
        this.selectedEpisode.links[i] = null;
      }
      const pageCount = Math.ceil(this.feeds.length / this.pageSize);
      for (let i = 1; i <= pageCount; i += 1) {

        for (let i = 0; i <= this.activeLinks.length; i += 1) {
          this.selectedEpisode.links[i] = null;
        }

        this.selectedEpisode.feeds[i].forEach((element, index) => {
          if (i !== this.currentPage) {
            this.selectedEpisode.feeds[i][index] = null;
          } else {
            this.selectedEpisode.feeds[i][index] = null;
          }
        });
      }

      const selectedMediaItem = this.mediaLibrary.find(mediaItem => mediaItem.guid === guid);

      // select library item
      var episodeInfo = {
        type: 'library',
        title: selectedMediaItem.title,
        audioUrl: selectedMediaItem.s3AudioUrl,
        feedUrl: selectedMediaItem.feedUrl,
        guid,
      };

      if (epInfo) {
        episodeInfo = { ...epInfo, ...episodeInfo };
      }

      this.$store.dispatch(SET_EPISODE_INFO, { ...episodeInfo });
      this.$store.dispatch(SET_SELECTED_EPISODE, { type: 'library', guid });

      EventBus.$emit('SelectedEpisodeChange', episodeInfo);

      // wait for render
      setTimeout(() => {
        this.$store.dispatch(SET_POPOVER_DISMISSED, false);
      }, 100);

      // this.$store.dispatch(SET_AUDIO_FILE_FROM_REMOTE_URL, { audioUrl: selectedMediaItem.s3AudioUrl });
    },

    async selectPodcastEpisode({ guid, rss, type, feedIndex }) {
      let els = document.getElementsByClassName('rssInlineAudioPlayer')
      Array.prototype.forEach.call(els, function (el) {
        el.pause();
        // console.log(el);
        // document.getElementById( el).stop()
      });
      this.isPlaying = null
      // this.selectedLibraryItem = guid;
      this.isAudioSelected = true;
      // deselect the items on other selection inputs

      if (type === 'link') {
        this.popoverVisibleOnTab = 'link';
        this.selectedEpisode.feeds = [];
        if (this.feeds) {
          this.clearFeeds();
        }

        for (let i = 0; i <= this.activeLinks.length; i += 1) {
          if (i !== feedIndex) this.selectedEpisode.links[i] = null;
        }

        this.$store.dispatch(SET_SELECTED_EPISODE, { type: 'link', guid, feedIndex, rss });
      } else if (type === 'feed') {
        this.popoverVisibleOnTab = 'feeds';
        this.$store.dispatch(SET_SELECTED_EPISODE, { type: 'feed', guid, feedIndex, page: this.currentPage, rss });

        this.actionPage = this.currentPage;
        this.clearWavveLinks();

        const pageCount = Math.ceil(this.feeds.length / this.pageSize);
        for (let i = 1; i <= pageCount; i += 1) {
          this.selectedEpisode.feeds[i].forEach((element, index) => {
            if (i !== this.currentPage) {
              this.selectedEpisode.feeds[i][index] = null;
            } else if (index !== feedIndex) {
              this.selectedEpisode.feeds[i][index] = null;
            }
          });
        }
      }
      // force Vue to rerender select box. Known bug in Element UI
      this.$forceUpdate();

      const ep = rss.items.find(item => item.guid === guid);

      const episodeInfo = {
        type,
        title: ep.title,
        audioUrl: ep.enclosure ? ep.enclosure.url : null,
        feedTitle: rss.title,
        feedUrl: rss.feedUrl,
        feedGuid: guid,
        imageSrc: (rss.image && rss.image.url) || (rss.itunes && rss.itunes.image) || undefined,
        author: ep.author || ep.creator || '',
        link: ep.link ? ep.link : "",
        episodeNumber: ep.itunes && ep.itunes.episode ? ep.itunes.episode : "",
        seasonNumber: ep.itunes && ep.itunes.season ? ep.itunes.season : "",

      };

      const libraryItem = this.mediaLibrary.find(item => item.feedGuid === guid);

      if (libraryItem) {
        // this.audioTypeTab = 'mylibrary';
        this.activeLibraryItem = libraryItem.guid;
        if (!this.fromEditor)
          this.$notify.success({ title: 'Hey!', message: 'We found this episode in your library. You can create Wavves faster using My Library tab.' });
        if (type === 'link') {
          await this.selectFromLibrary(libraryItem, ep ? episodeInfo : undefined);
          this.popoverVisibleOnTab = 'link'
          this.selectedEpisode.links[feedIndex] = guid
        }

        if (type === 'feed') {
          await this.selectFromLibrary(libraryItem, ep ? episodeInfo : undefined);
          this.popoverVisibleOnTab = 'feeds'
          this.selectedEpisode.feeds[this.currentPage][feedIndex] = guid
        }
        this.$forceUpdate();
        // this.selectedEpisode.feeds[page][index] ////////////////////////+++++++++++++++++++++
      } else {

        if (!episodeInfo.audioUrl) {
          this.$notify.error({
            title: `Audio file not found.`,
            message: 'Please make sure RSS feed contains audio files.',
          });
          if (this.feeds) {
            this.clearFeeds();
          }
          if (this.links) {
            this.clearWavveLinks();
          }
        }

        if (episodeInfo.audioUrl) {
          // check for audio size

          if (episodeInfo.audioUrl.includes('podtrac.com')) {
            const redirectUrl = episodeInfo.audioUrl.replace(/.+redirect\.mp3\/(.*)/, 'https://$1');
            this.selectAudioUrl({ url: redirectUrl, episodeInfo });
          } else if (episodeInfo.audioUrl.includes('anchor.fm')) {
            const redirectUrl = decodeURIComponent(episodeInfo.audioUrl.replace(/.+podcast\/play\/.+\/https(.*)/, 'https$1'));
            this.selectAudioUrl({ url: redirectUrl, episodeInfo });
          } else {
            this.selectAudioUrl({ url: ep.enclosure.url, episodeInfo });
          }
        } else {
          this.$notify.error({ title: 'Error', message: 'No audio files found. Please try another selection or RSS feed link.' });
        }
      }
    },
    selectAudioUrl({ url, episodeInfo }) {
      this.$store.dispatch(SET_EPISODE_INFO, { ...episodeInfo, url });
      EventBus.$emit('SelectedEpisodeChange', this.episodeInfo);
    },

    handleRemoteAudioDownloadFail() {
      this.$store.dispatch(CLEAR_AUDIO_FILE);
      this.$message({
        showClose: true,
        message:
          "Sorry! Something went wrong trying to download the audio file. This is a new beta feature and we're continuing to improve it. Please upload a file directly.",
        type: 'info',
        duration: 15000,
      });
    },
    handleAudioFileExceedsMaxSize() {
      this.warnFileSize = true;
    },

    async handleUploadSelect(file, fileList) {
      this.$store.dispatch(SET_POPOVER_DISMISSED, false);
      this.popoverVisibleOnTab = 'audio';
      await this.$store.dispatch(CLEAR_AUDIO_FILE);
      this.progressLabel = ``;
      const fileSizeWarning = 75; // MB
      const fileSizeMax = 150; // MB
      this.fileList = fileList.slice(-1);
      const { raw, size } = file;
      if (size / 1024 / 1024 > fileSizeWarning) {
        this.warnFileSize = true;
        EventBus.$emit('trackIntercomEvent', 'File Over 75MB');
      }
      // don't bother decoding files over 100MB (prevent browser crash)
      if (size / 1024 / 1024 > fileSizeMax) {
        this.decodeAudioFile = false;
        EventBus.$emit('trackIntercomEvent', 'File Over 150MB');
      }

      if (raw && this.decodeAudioFile) {
        await this.$store.dispatch(SET_AUDIO_UPLOAD_FILE, { file: raw, type: 'file' });
      }
    },
    formatDate(date) {
      return dateTimeFormat(date);
    },
  },
};
</script>

<style lang="scss">
.delete-dialog>.el-dialog {
  height: unset !important;
  overflow: hidden !important;
  border-radius: 5px;
}

.delete-dialog>.el-dialog>.el-dialog__body {
  height: unset !important;
  overflow-y: hidden !important;
}

p {
  font-size: 0.875em;
}

.media-title {
  p {
    text-align: left;
    width: 100%;
    padding: 10px 0px;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 400;
  }
}

.stepTitle {
  padding-bottom: 3px;
  font-size: 20px;
  font-weight: bold;
}

.button-container {
  margin-left: 10px;
}

.popover-action {
  padding-top: 10px;
  margin: 0;
}

.looks-disabled {
  opacity: 0.5;
}

.el-dialog {
  overflow: scroll;
  border-radius: 5px;
}

.el-select .el-input__inner .select-active {
  border-color: #4d47e8;
}

.upload-container {
  display: flex;
  align-items: center;
}

.media-box {
  display: flex;
  align-items: center;
}

.media-box p:last-child {
  margin-left: auto;
}

.el-scrollbar__bar {
  opacity: 1 !important;
}

$brand-green: #71ebea;
$brand-blue: #31d2e4;

.branding-half {
  background-color: $brand-green;
  color: white;
  height: 102vh;
  background-image: linear-gradient(to bottom right, $brand-green, $brand-blue);
}

.logo-left {
  max-width: 40%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.el-select {
  width: 100%;
}

.el-select-dropdown__item {
  padding: 0 20px;
}

.el-autocomplete {
  width: 100%;
}

.podcast-search {
  .media-left {
    float: left;
  }

  li {
    line-height: normal;

    img {
      width: 80px;
      height: 80px;
    }

    .value {
      padding-left: 5px;
      line-height: 15px;
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: bold;
      font-size: 1em;
    }

    .description {
      padding-left: 5px;
      font-size: 14px;
      color: #b4b4b4;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.hp_slide {
  maxWidth: 100%;
  background: transparent;
  height: 20px;
  padding-top: 10px;
  cursor: pointer
}

.hp_slideInnet {
  width: 100%;
  background: #bbbbbb;

  height: 1px;
}

.hp_range {
  width: 0;
  border-radius: 2px;
  background: #4D47E8;

  height: 100%;
  margin-top: -1px;
  -moz-transition: width 0.2s linear;
  -webkit-transition: width -0.2s linear;
  transition: width 0.2s linear;
}

hp_slide:hover .hp_slideInnet .hp_range {
  margin-top: -5px;
  height: 6px;
}

.hp_slide:hover .hp_slideInnet {
  height: 3px;
  margin-top: -2px;
}

.handlePlay {
  color: gray;
}

.handlePlay:hover {
  color: #4D47E8;
}

.library-border {
  border-bottom: 1px solid #DCDFE6;
}
</style>
