import Axios from 'axios';
import { WAVVE_V2_API_BASE_URL } from '../config';
import { formatResponse } from '../main';

const BASE_PATH = 'notifications';

const axiosInstance = Axios.create({
  baseURL: WAVVE_V2_API_BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `${localStorage.getItem('token')}`,
  },
});

axiosInstance.interceptors.response.use(formatResponse);

export default {
  async getNotifications() {
    return await axiosInstance.get(`${BASE_PATH}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('token')}`,
      },
    });
  },
};
