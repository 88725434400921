<template>
  <aside v-if="!isOnTranscript" class="sidebar rr-block has-background-grey-darker has-shadow has-text-white">
    <p class="menu-label">
      <el-image class="has-margin-top" :src="require('@/assets/brand/logo-light-text.png')" alt="Wavve Logo" fit="contain"
        :style="{ height: '60px' }"></el-image>
    </p>
    <ul class="menu-list">
      <!--<li>
          <router-link :to="{ name: 'create' }" class="navbar-item">
            <span class="icon">
              <i class="fas fa-plus-circle has-small-margin-right"></i>
            </span>
            Create
          </router-link>
        </li>-->
      <li id="sidebarTranscriptionsItem">
        <router-link :to="{ name: 'transcriptions' }" class="navbar-item">
          <span class="icon">
            <i class="fas fa-copy has-small-margin-right" style=margin-left:-4px;margin-right:6px;></i>
          </span>
          Transcriptions<div
            style="margin-left: 0px; margin-top: -8px;  margin-bottom: -10px; float: right;margin-right:19px"><el-tag
              size="mini"
              style="padding:3px; border:none;padding-top:0px;line-height:12px;height:11px;font-size:40%;background-color:#24E0DE;color:#4d47e8;font-weight:900">BETA</el-tag>
          </div>
        </router-link>
      </li>
      <li id="sidebarVideosItem">
        <router-link :to="{ name: 'videos' }" class="navbar-item" disabled>
          <span class="icon">
            <i class="fas fa-play-circle has-small-margin-right"></i>
          </span>
          Videos
        </router-link>
      </li>
      <li v-if="STAGE == 'local'">
        <router-link :to="{ name: 'designs' }" class="navbar-item">
          <span class="icon">
            <i class="fas fa-object-group has-small-margin-right"></i>
          </span>
          Designs
        </router-link>
      </li>
      <!-- <li>
          <router-link :to="{ name: 'media' }" class="navbar-item">
            <span class="icon">
              <i class="fas fa-headphones has-small-margin-right"></i>
            </span>
            My Library
          </router-link>
        </li> -->
      <li id="sidebarLinkItem">
        <router-link :to="{ name: 'link' }" class="navbar-item">
          <span class="icon">
            <i class="fas fa-link has-small-margin-right"></i>
          </span>
          Wavve Link
        </router-link>
      </li>
      <li class="has-padding-top">
        <span class="is-uppercase has-small-margin-left">Support</span>
        <ul>
          <li id="sidebarFaqsItem">
            <a href="https://wavve.co/faqs" analytics-on="click" analytics-event="Navigate" analytics-category="New Page"
              analytics-label="/support" target="_blank">FAQs</a>
          </li>
          <!-- <li>
              <a
                href="https://www.youtube.com/playlist?list=PLSOcvEjrm8_e9627RGae2UBNvvQd-pHO6"
                analytics-on="click"
                analytics-event="Navigate"
                analytics-category="New Page"
                analytics-label="/tutorials"
                target="_blank"
                >Video Tutorials</a
              >
            </li> -->
          <!--  <li>
              <a
                href="http://wavve.co/affiliate-program"
                analytics-on="click"
                analytics-event="Navigate"
                analytics-category="New Page"
                analytics-label="/affiliate"
                target="_blank"
                >Affiliate Program</a
              >
            </li> -->
          <li id="sidebarIntegrationsItem">
            <router-link :to="{ name: 'externalIntegrations' }" class="navbar-item">
              Integrations
            </router-link>
          </li>
        </ul>
      </li>
      <li></li>
      <li class="has-padding-top">
        <span class="is-uppercase has-small-margin-left">Account</span>
        <ul>
          <li id="sidebarTeamsItem">
            <div class="team-nav-item" style="display: flex; flex-direction: row; align-items: center;">
              <el-popover ref="invitePopover" placement="right" title="New invite" class="team-nav-item"
                popper-class="invite-popover" :width="270" v-if="invites">
                <template #reference>
                  <router-link class="navbar-item team-nav-item" :to="{ name: 'teams' }"
                    :class="{ 'team-select-visible': !isOnTeamPage && showTeamSelect }">
                    <div>Teams</div>
                    <div class="sidebar-team-image"
                      :style="{ 'border-color': getTeamColor(currentUserTeamId), 'background-color': getTeamColor(currentUserTeamId) }">
                    </div>
                  </router-link>
                </template>
                <div>
                  You received a new team invitation.
                </div>
                <div class="has-small-margin-top">
                  Click here to accept it.
                </div>
              </el-popover>
              <span v-else>
                <router-link class="navbar-item team-nav-item" :to="{ name: 'teams' }"
                  :class="{ 'team-select-visible': showTeamSelect }">
                  <div>Teams</div>
                  <div class="sidebar-team-image"
                    :style="{ 'border-color': getTeamColor(currentUserTeamId), 'background-color': getTeamColor(currentUserTeamId) }">
                  </div>
                </router-link>
              </span>
              <el-dropdown ref="teamsDropdown" placement="right" trigger="click" @visible-change="changeTeamDropDown">
                <el-button class="team-drop-button" type="text" :class="{ 'team-drop-button-active': showTeamSelect }"
                  @click="showTeamSelect = !showTeamSelect">
                  <i :class="{ 'el-icon-arrow-right': !showTeamSelect, 'el-icon-arrow-left': showTeamSelect }"></i>
                </el-button>
                <template #dropdown>
                  <el-dropdown-menu class="team-select">
                    <el-dropdown-item v-for="(teamInfo) in teams" :key="`team_${teamInfo._id}`"
                      :class="{ 'sidebar-team-selected': currentUserTeamId === teamInfo.ownerId || (currentUserTeamId === undefined && teamInfo.ownerId === userId) }"
                      :disabled="currentUserTeamId === teamInfo.ownerId || (currentUserTeamId === undefined && teamInfo.ownerId === userId)">
                      <a style="display: flex; flex-direction: row; align-items: center !important;"
                        @click="changeTeam(teamInfo)" :style="{ 'border-color': teamInfo.color }">
                        <div class="sidebar-team-image" :style="{ 'background-color': teamInfo.color }">
                        </div>
                        <div class="team-select-container">
                          {{ userId === teamInfo.ownerId ? 'My Team' : teamInfo.name }}
                        </div>
                        <div
                          style="margin-left: 5px; margin-top: -30px;  margin-bottom: -10px; float: right;margin-right:5px"
                          v-if="currentUserTeamId === teamInfo.ownerId || (currentUserTeamId === undefined && teamInfo.ownerId === userId)">
                          <el-tag size="mini"
                            style="padding:3px; border:none;padding-top:0px;line-height:12px;height:11px;font-size:40%;font-weight:900"
                            :style="{ 'background-color': teamInfo.color, color: teamInfo.textColor }">ACTIVE</el-tag>
                        </div>
                      </a>
                    </el-dropdown-item>

                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </div>
          </li>
          <li>
          </li>
          <li id="sidebarSettingsItem">
            <router-link :to="{ name: 'settings' }" class="navbar-item">Settings</router-link>
          </li>
          <li id="sidebarBillingItem" v-if="canAccessTeamBilling">
            <router-link :to="{ name: 'billing' }" class="navbar-item">Billing</router-link>
          </li>
          <li>
            <a @click="logout" class="navbar-item">Logout</a>
          </li>
        </ul>
      </li>
      <li style="margin-top: 20px; background-color: whitesmoke;">
        <a style="color: #4d47e8" :href="this.IS_PRODUCTION ? 'https://beta.wavve.co' : 'https://dev-beta.wavve.co'">
          Go to new experience
        </a>
      </li>
    </ul>
  </aside>
</template>

<script>
import { IS_PRODUCTION, STAGE } from '../config.js'
import { TEAMS_CHANGE_TEAM } from '../store/action-types';

export default {
  name: 'Sidebar',
  data() {
    return {
      isOnTranscript: false,
      showTeamSelect: false,
    };
  },
  methods: {
    changeTeamDropDown(visible) {
      this.showTeamSelect = visible;

      if (!this.showTeamSelect) {
        this.$refs.teamsDropdown.hide();
      }
    },
    getTeamColor(teamId) {
      const currentTeam = this.$store.state.teams?.filter(team => team.ownerId === (teamId || this.userId))[0];

      return currentTeam?.color;
    },
    async changeTeam(team) {
      if (this.cantChangeTeam) {
        try {
          const result = await this.$confirm(
            'By switching Teams now, you will lose this video/design.',
            'Switching Teams',
            {
              confirmButtonText: 'Switch',
              cancelButtonText: 'Stay',
              type: 'info',
            }
          );

          if (result !== 'confirm') {
            return;
          }

          await this.$router.push('/videos?changingTeam=true');
        } catch (error) {
          return;
        }
      }

      this.$store.dispatch(TEAMS_CHANGE_TEAM, { teamId: team.owner._id }).then(async () => {
        console.log('FEEDBACK');

        if (this.isBillingPage && team.owner._id !== this.userId) {
          await this.$router.push('/videos');
        }
      });
    },
    async logout() {
      await this.$router.push('/logout');
    },
  },
  computed: {
    IS_PRODUCTION() {
      return IS_PRODUCTION;
    },
    STAGE() {
      return STAGE;
    },
    isBillingPage() {
      return ['billing'].includes(this.$route.name);
    },
    isOnTeamPage() {
      return ['teams'].includes(this.$route.name);
    },
    cantChangeTeam() {
      return ['create', 'editor'].includes(this.$route.name);
    },
    currentUserTeamId() {
      return this.$store.state.user?.team;
    },
    invites() {
      return this.$store.state.invites;
    },
    teams() {
      return this.$store.state.teams;
    },
    userId() {
      return this.$store.state.user.id;
    },
    currentTeamInfo() {
      return this.$store.state.user.teamInfo;
    },
    canAccessTeamBilling() {
      return this.userId === this.currentTeamInfo?.ownerId;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  created() {
    if (window.location.href.indexOf('transcriptionEditor') > -1) {
      this.isOnTranscript = true;
    }
  },
  watch: {
    invites: {
      deep: true,
      handler() {
        if (!this.isMobile && !this.isOnTeamPage && this.$store.state.invites && this.$store.state.invites?.length && this.$refs.invitePopover) {
          this.$refs.invitePopover.doShow();
        }
      }
    }
  }
};
</script>

<style lang="scss">
.invite-popover {
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27) !important;
  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27) !important;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27) !important;
}

.team-dialog>.el-dialog {
  height: unset !important;
  overflow: hidden !important;
  border-radius: 5px;
}

.team-dialog>.el-dialog>.el-dialog__body {
  height: unset !important;
  overflow-y: hidden !important;
}

.team-nav-item>span {
  width: 100%;
}

.team-nav-item a {
  flex: 1;
  display: flex !important;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.team-nav-item .team-nav-item {}

.team-nav-item .team-nav-item .sidebar-team-image {
  margin-right: 0px;
}

.team-drop-button.el-button--text {
  border-radius: 0px 2px 2px 0px;
  color: whitesmoke;
  background-color: #363636;
}

.team-drop-button.team-drop-button-active.el-button--text {
  background-color: whitesmoke;
  color: #363636;
}

.team-drop-button:hover {
  background-color: whitesmoke !important;
  border-radius: 0px 2px 2px 0px;
  color: #363636 !important;
}

.team-drop-button:focus {
  background-color: whitesmoke !important;
  border-radius: 0px 2px 2px 0px;
  color: #363636 !important;
}

.el-popper.team-select>.popper__arrow {
  border-right-color: #363636 !important;
}

.el-popper.team-select>.popper__arrow::after {
  border-right-color: #363636 !important;
}

.team-select.el-dropdown-menu {
  left: 216px !important;
  margin-left: 0px;
  margin-top: 0px;
  margin-right: 0px;
  border-left: 0px;
  padding: 10px;
  background-color: #363636;
  border: 0px;
  border-radius: 10px !important;
  -webkit-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27) !important;
  -moz-box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27) !important;
  box-shadow: 4px 4px 5px 0px rgba(0, 0, 0, 0.27) !important;
}

.team-select .el-popper {
  background-color: #363636;
}

.team-select li {
  line-height: 1.25;
  padding: 0px;
  margin-left: 0px;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: #363636;
}

.team-select li:hover {
  border-radius: 10px;
}

.team-select li a {
  align-items: center;
  color: whitesmoke;
  padding: 0.5em 0.75em
}

.team-select li a:hover {
  align-items: center;
  border-radius: 10px;
  background-color: whitesmoke;
  color: #363636;
  padding: 0.5em 0.75em
}

.team-select-container {
  min-width: 100px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sidebar-team-selected a {
  align-items: flex-start !important;
  border: 2px solid transparent;
  border-radius: 10px !important;

}

.sidebar-team-selected a:hover {
  border-color: #363636;
}

.team-select-visible {
  background-color: #363636 !important;
  color: whitesmoke !important;
}

.team-select-visible:hover {
  background-color: whitesmoke !important;
  color: #363636 !important;
}

.sidebar-team-image {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0px 10px 0px 0px;
  background-color: transparent;
  padding: 1px;
  background-clip: content-box;
  border-radius: 20px;
}

.router-link-active .team-nav-item .sidebar-team-image {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0px 10px 0px 5px;
  background-color: red;
  border: 1px solid #363636;
  padding: 1px;
  background-clip: content-box;
  border-radius: 20px;
}

a:hover .team-nav-item .sidebar-team-image {
  border-color: #363636;
}
</style>

<style lang="scss" scoped>
.menu-list li {
  margin-top: 1px;
}

.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  overflow-x: hidden;
  z-index: 1;
  height: 100vh;
  width: var(--sidebar-width);
  overflow-y: hidden;

  .menu-label {
    text-align: center;

    img {
      width: 80%;
    }
  }

  a {
    color: white;
    transition: all 0.15s;
    border-radius: 0;

    &:hover {
      background-color: whitesmoke;
      color: #363636;
    }
  }

  .has-padding-top {
    a {
      border-radius: 2px;
    }
  }

  .router-link-active {
    background-color: whitesmoke;
    color: #363636;
  }

  .social-links {
    text-align: center;
    position: absolute;
    bottom: 1em;
    left: 0;
    right: 0;

    a {
      font-size: 1.25em;
      border-radius: 3px;
      padding: 0 0.5em;
    }
  }
}

i::active {
  color: 555;
}

i {
  color: 555;
}
</style>
