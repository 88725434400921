import { v4 as uuidv4 } from 'uuid';

export const generateEventId = () => uuidv4();

export const newCustomer = {
  event: 'trackEvent',
  action: 'newCustomer',
  category: 'Revenue',
  label: 'New Customer',
  nonInteraction: true,
};

export const newPaidCustomer = {
  event: 'trackEvent',
  action: 'paidConversion',
  category: 'Revenue',
  label: 'New Paid Customer',
  nonInteraction: true,
};

export const newCard = {
  event: 'trackEvent',
  action: 'newCard',
  category: 'Revenue',
  label: 'Add Credit Card',
  nonInteraction: true,
};

export const changePlan = {
  event: 'trackEvent',
  action: 'changePlan',
  category: 'Revenue',
  label: 'Change Plan',
  nonInteraction: true,
};

export const cancelPlan = {
  event: 'trackEvent',
  action: 'cancelPlan',
  category: 'Revenue',
  label: 'Cancel Subscription',
  nonInteraction: true,
};

export const pausePlan = {
  event: 'trackEvent',
  action: 'pausePlan',
  category: 'Revenue',
  label: 'Pause Subscription',
  nonInteraction: true,
};

export const newUser = {
  event: 'trackEvent',
  action: 'newUser',
  category: 'Revenue',
  label: 'New Customer',
  nonInteraction: true,
};

export const editDesign = {
  event: 'trackEvent',
  action: 'editDesign',
  category: 'Engagement',
  label: 'Edit Design',
  nonInteraction: true,
};

export const saveDesign = {
  event: 'trackEvent',
  action: 'saveDesign',
  category: 'Engagement',
  label: 'Save Design',
  nonInteraction: true,
};

export const createVideo = {
  event: 'trackEvent',
  action: 'createVideo',
  category: 'Engagement',
  label: 'Generate Video',
  nonInteraction: true,
};

export const addSubtitles = {
  event: 'trackEvent',
  action: 'addSubtitles',
  category: 'Engagement',
  label: 'Create Subtitles',
  nonInteraction: true,
};

export const createCaptionedVideo = {
  event: 'trackEvent',
  action: 'createCaptionedVideo',
  category: 'Engagement',
  label: 'Create Subtitled Video',
  nonInteraction: true,
};

export const createWavveLink = {
  event: 'trackEvent',
  action: 'createWavveLink',
  category: 'Engagement',
  label: 'Create Wavve.link',
  nonInteraction: true,
};

export const deleteWavveLink = {
  event: 'trackEvent',
  action: 'deleteWavveLink',
  category: 'Engagement',
  label: 'Delete Wavve.link',
  nonInteraction: true,
};
