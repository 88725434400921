<template>
  <div>
    <slot />
  </div>
</template>

<script>
export default {
  name: 'offboard',

  data() {
    return {};
  },

  methods: {},
};
</script>
