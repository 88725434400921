<template>
  <div class="columns is-multiline is-desktop">
    <div class="column is-one-quarter-fullhd is-full-desktop">
      <!-- For users who haven't created a design yet -->
      <h3 class="is-size-4 has-text-weight-bold has-small-margin-bottom" v-if="showStepHeader">
        1. Your Design
        <span v-show="selectedDesign" class="fa fa-check-circle has-text-primary"></span>
      </h3>
      <div v-if="noUserThemes">
        <p class="has-margin-bottom">
          We went ahead and made a design for you to use, but making your own is more fun.
        </p>

        <router-link :to="{ path: editingJobId ? `/editor?job=${editingJobId}` : '/editor' }">
          <el-button id="videoCreateOwnDesign" round type="primary"
            class="has-small-margin-bottom has-display-block">Create Your Own</el-button>
        </router-link>
        <!-- <a v-else>
          <el-button round type="primary" class="has-small-margin-bottom has-display-block"
            @click="throwOutOfTimeError">Create Your Own</el-button>
        </a> -->
        <div v-show="selectedDesign">
          <el-button class="has-small-margin-bottom has-display-block" type="text" @click="select(null)">Select a
            different design</el-button>
        </div>
      </div>

      <!-- For users with design(s) -->
      <div v-if="!noUserThemes">
        <!-- Description -->
        <div class="has-margin-bottom">
          <p v-if="!selectedDesign">
            Select a recent design to use or edit, or start from scratch with a new design.
          </p>
          <p v-if="!!selectedDesign">
            Here's a preview of your design. The exact animation will change depending on your audio.
          </p>
        </div>

        <!-- Actions Without Selected Design -->
        <div v-if="!selectedDesign">
          <router-link :to="{ path: editingJobId ? `/editor?job=${editingJobId}` : '/editor' }">
            <el-button round type="primary" class="has-small-margin-bottom has-display-block">New Design</el-button>
          </router-link>
          <!-- <a v-else>
            <el-button round type="primary" class="has-small-margin-bottom has-display-block"
              @click="throwOutOfTimeError">New Design</el-button>
          </a> -->
        </div>

        <!-- Actions With Selected Design -->
        <div v-if="selectedDesign">
          <router-link
            :to="{ path: editingJobId ? `/editor/${selectedDesign.id}?job=${editingJobId}` : `/editor/${selectedDesign.id}` }">
            <el-button round type="primary" class="has-small-margin-bottom has-display-block">Edit This Design</el-button>
          </router-link>

          <el-button class="has-small-margin-bottom has-display-block" type="text" @click="select(null)">Choose a
            different design</el-button>
        </div>
      </div>
    </div>
    <div class="column is-three-quarters-fullhd is-full-desktop">
      <!-- Show Designs To Select -->

      <div v-show="!selectedDesign" class="columns is-multiline" v-loading="loading">
        <div class="column is-one-third-desktop is-half-tablet"
          v-for="design in designs.slice((currentPage - 1) * pageSize, currentPage * pageSize)" :key="design.id">
          <el-card shadow="hover" :body-style="{ padding: '20px 0px' }">
            <div class="design-container" @click="select(design)">
              <div class="has-text-centered">
                <el-tooltip style=margin-top:1px class="item" effect="dark" :content="design.name" placement="top-start"
                  offset=4>
                  <p style="white-space:nowrap; width:90%;white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;">{{ design.name }}</p>
                </el-tooltip>
              </div>
              <img :src="design.previewImage || design.backgroundImage" :alt="design.name" />
            </div>
            <div class="has-text-centered has-margin-top">
              <div>
                <el-button round class="has-margin-top" @click="select(design)" type="primary" size="small">Select
                  Design</el-button>
                <router-link
                  :to="{ path: editingJobId ? `/editor/${design.id}?job=${editingJobId}` : `/editor/${design.id}` }">
                </router-link>
                <el-dropdown v-if="!noUserThemes" class="has-margin-left">
                  <span class="has-cursor-pointer">
                    More
                    <i class="el-icon-arrow-down el-icon--right has-text-primary"></i>
                  </span>
                  <el-dropdown-menu class="desing-picker-dropdown" slot="dropdown">
                    <router-link v-if="!design.isLegacy"
                      :to="{ path: editingJobId ? `/editor/${design.id}?job=${editingJobId}` : `/editor/${design.id}` }">
                      <el-dropdown-item icon="el-icon-edit">Edit Design</el-dropdown-item>
                    </router-link>
                    <!-- <a v-if="!design.isLegacy && user.timeLeft < 0" @click="throwOutOfTimeError">
                      <el-dropdown-item icon="el-icon-edit">Edit Design</el-dropdown-item>
                    </a> -->
                    <a v-if="!design.isLegacy" @click="clone(design.id)">
                      <el-dropdown-item icon="fas fa-copy">Clone Design</el-dropdown-item>
                    </a>
                    <el-popover v-if="design.isLegacy" placement="top-start" title="Just a heads up" width="400"
                      trigger="hover">
                      <p>
                        You created this design a while ago. Since then, we've upgraded our design schema. You can still
                        create videos with this design, but you
                        won't be able to edit it.
                      </p>
                      <el-dropdown-item slot="reference" icon="el-icon-edit"
                        class="has-text-grey has-cursor-not-allowed">Edit Design</el-dropdown-item>
                    </el-popover>
                    <a @click="softDelete(design.id)">
                      <el-dropdown-item icon="el-icon-delete">Delete Design</el-dropdown-item>
                    </a>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </el-card>
        </div>
      </div>
      <div class="column has-text-centered" v-if="!selectedDesign">
        <el-pagination layout="prev, pager, next" :current-page.sync="currentPage" :total="designs.length"
          :hide-on-single-page="true" :page-size="pageSize">
        </el-pagination>
      </div>

      <!-- Show Selected Design -->
      <design-animated-preview :key="selectedDesign.id + selectedDesign.previewImage" v-if="selectedDesign"
        :design="selectedDesign"></design-animated-preview>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import DesignAnimatedPreview from '@/components/Create/DesignAnimatedPreview.vue';
import Theme from '@/store/models/Theme';
import ThemeService from '@/api/theme.service';
import { demoThemes } from '@/config/default-themes';
import { DELETE_THEME, SET_SELECTED_THEME, SYNC_USER_TO_DB } from '@/store/action-types';
import downloadUtil from '@/util/download';

export default {
  name: 'DesignPicker',
  components: {
    DesignAnimatedPreview,
  },
  props: {
    selectedDesign: Object,
    editingJobId: String,
  },

  data() {
    return {
      user: this.$store.state.user,
      lookingForReplacement: false,
      noUserThemes: this.$store.state.user.themes.length === 0,
      pageSize: 6,
      currentPage: 1,
      loading: false,
    };
  },
  watch: {
    selectedDesign(v) {
      // console.log('v', v)
    }
  },
  computed: {
    designs() {
      if (this.noUserThemes) {
        return demoThemes.map(theme => new Theme(theme));
      }
      return this.$store.state.user.themes;
    },
    showStepHeader() {
      return this.$router.currentRoute.name === 'edit';
    },
  },
  methods: {
    throwOutOfTimeError() {
      this.$notify.error({
        title: `You don't have enough time.`,
        message: 'Please upgrade to any of our paid plans to continue making videos.',
      });
    },
    async select(design) {
      this.loading = true;
      // if (this.user.timeLeft <= 0 && !this.user.planIsUnlimited) {
      //   this.throwOutOfTimeError();
      // } else {

      // check background existsu
      if (design) {
        let saveImage = false;
        try {
          await downloadUtil.downloadBackground(design.backgroundImage)
        } catch (error) {
          saveImage = true;
        }

        if (saveImage) {
          try {
            const themeObjects = await downloadUtil.downloadThemeJson(design.objects);

            const image = themeObjects.data.filter((themeObject) => themeObject.type === 'image');

            if (!image || image.length === 0 || image.length > 1) {
              this.$notify.error({
                title: `This design seems to have a problem.`,
                message: 'Please edit, re-save, and try again.',
              });

              this.loading = false;

              return;
            }

            await axios.post('/s3signing', { file: design.backgroundImageKey, data: image[0].src, type: 'image' });
          } catch (error) {
            console.error(error);
            this.$notify.error({
              title: `This design seems to have a problem.`,
              message: 'Please edit, re-save, and try again.',
            });

            this.loading = false;

            return;
          }
        }
      }

      this.$emit('update:selectedDesign', design);
      await this.$store.dispatch(SET_SELECTED_THEME, design);

      this.loading = false;
    },
    async softDelete(id) {
      try {
        await this.$confirm(`Are you want to delete this design?`);
        await this.$store.dispatch(DELETE_THEME, id);
        this.$notify.success({ title: 'Success', message: 'Design Removed' });
      } catch (err) {
        if (err !== 'cancel') {
          this.$notify.error({ title: 'Error', message: 'Design Delete Failed' });
        }
      }
    },
    async clone(id) {
      try {
        await ThemeService.clone({ id });
        await this.$store.dispatch(SYNC_USER_TO_DB);

        this.$notify.success({ title: 'Success', message: 'Design Cloned' });
      } catch (err) {
        console.log('err', err);
        this.$notify.error({ title: 'Error', message: 'Clone Design Failed' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.design-container {
  height: 150px;

  img {
    object-fit: scale-down;
    height: 100%;
    width: 100%;
    object-position: center;
  }
}

p {
  font-size: 0.875em;
}
</style>

<style>
.desing-picker-dropdown.el-dropdown-menu {
  padding: 10px;
}
</style>
