import Axios from 'axios';

const RESOURCE = '/api/users';

export default {
  /**
   * Create a new user
   * @param {object} data - User properties
   */
  async createUser(data) {
    const retValue = await Axios.post(RESOURCE, data);
    return retValue;
  },

  async startUserSetup(data) {
    const retValue = await Axios.post(`${RESOURCE}/setup`, data);
    return retValue;
  },
  /**
   * Get Current Logged In User
   * API middleware extracts user from auth header
   */
  async getMe() {
    const retValue = await Axios.get(`${RESOURCE}/me`);
    return retValue;
  },

  /**
   * Get User By Id
   * Admin only
   */
  async getUser(id) {
    const retValue = await Axios.get(`${RESOURCE}/${id}`);
    return retValue;
  },

  /**
   * Change Stinger Preference
   * @param {string} user.id
   * @param {object} preferences - e.g. { stinger: true }
   */
  async updateStingerPreference(id, preferences) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/stinger`, preferences);
    return retValue;
  },

  /**
   * Get Minute Details for Current Logged In User
   * API middleware extracts user from auth header
   */
  async getPlanUsageData(id) {
    const retValue = await Axios.get(`${RESOURCE}/${id}/planUsage`);
    return retValue;
  },

  /**
   * Change Email Preferences
   * @param {string} user.id
   * @param {object} preferences - e.g. { emailOptOut: true }
   */
  async updateUserPreferences(id, preferences) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/info`, preferences);
    return retValue;
  },


  async updateConvertKitId(id, cv_id) {
    const retValue = await Axios.post(`${RESOURCE}/${id}/convertKitId`, { convertKitId: cv_id });
    return retValue;
  },




  /**
   * Change Role
   * @param {string} user.id
   * @param {object} workRole - e.g. { workRole: author }
   */
  async updateWorkRole(id, workRole) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/workRole`, workRole);
    return retValue;
  },

  /**
   * Change Email Address
   * Same as email prefs - need to consolidate
   * @param {string} user.id
   * @param {object} email - e.g. { email: 'string' }
   */
  async updateUserInfo(id, data) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/info`, data);
    return retValue;
  },

  /**
   * Request Email verification
   * @param {object} - { email: 'string' }
   */
  async createEmailVerificationRequest(data) {
    const retValue = await Axios.post(`${RESOURCE}/verifyEmail`, data);
    return retValue;
  },

  /**
   * Confirm Email Verification
   * @param {object} - { verificationToken: jwt }
   */
  async confirmEmailVerification(verificationToken) {
    const retValue = await Axios.post(`${RESOURCE}/confirmVerifyEmail`, { verificationToken });
    return retValue;
  },

  /**
   * Change Password
   * @param {string} user.id
   */
  async updateUserPassword(id, { oldPassword, newPassword }) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/password`, { oldPassword, newPassword });
    return retValue;
  },

  /**
   * Create new job
   * @param {string} user.id - User Identifier
   */
  async createJob(id, data) {
    const retValue = await Axios.post(`${RESOURCE}/${id}/jobs`, data);
    return retValue;
  },

  /**
   * Get user jobs
   * @param {string} user.id
   */
  async getJobs(id) {
    const retValue = await Axios.get(`${RESOURCE}/${id}/jobs`);
    return retValue;
  },

  /**
   * Get user themes
   * @param {string} user.id
   */
  async getThemes(id) {
    const retValue = await Axios.get(`${RESOURCE}/${id}/themes`);
    return retValue;
  },

  /**
   * Get user billing status
   * @param {string} user.id
   */
  async getBillingStatus(id) {
    const retValue = await Axios.get(`${RESOURCE}/${id}/billing`);
    return retValue;
  },

  /**
   * Get user invoices
   * @param {string} user.id
   */
  async getInvoices(id) {
    const retValue = await Axios.get(`${RESOURCE}/${id}/billing/invoices`);
    return retValue;
  },

  /**
   * Create new Stripe customer
   * @param {object} req.body.token
   */
  async createStripeCustomer(id, data) {
    const retValue = await Axios.post(`${RESOURCE}/${id}/billing`, data);
    return retValue;
  },

  /**
   * Add subscription for Stripe customer
   * @param {object} req.body - plan
   */
  async createSubscription(id, data) {
    const retValue = await Axios.post(`${RESOURCE}/${id}/billing/subscribe`, data);
    return retValue;
  },

  /**
   * Edit billing/ subscription for Stripe customer
   * Specify event of 'changePlan' or 'updatePayment'
   * @param {object} req.body - event, plan, stripe token
   */
  async updateBilling(id, data) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/billing`, data);
    return retValue;
  },

  /**
   * Revert cancellation or pause
   * Specify event of 'changePlan' or 'updatePayment'
   * @param {object} req.body - event, plan, stripe token
   */
  async revertPlanChange(id, txId) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/billing/revert`, { txId });
    return retValue;
  },

  /**
   * Edit billing information for Stripe customer
   * this will show up on invoices
   * @param {object} req.body - name, address
   */
  async updateCustomer(id, data) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/billing/customer`, data);
    return retValue;
  },

  /**
   * get billing information for Stripe customer
   * @param {string} user.id
   */
  async getCustomer(id) {
    const retValue = await Axios.get(`${RESOURCE}/${id}/billing/customer`);
    return retValue;
  },

  /**
   * Add coupon to Stripe customer's account
   * @param {object} req.body - coupon
   */
  async applyDiscount(id, data) {
    const retValue = await Axios.post(`${RESOURCE}/${id}/billing/discount`, data);
    return retValue;
  },

  /**
   * Add coupon to Stripe customer's account
   * @param {object} req.body - coupon
   */
  async verifyCoupon(productId, promotionCode) {
    const retValue = await Axios.get(`/api/users/billing/discount/${productId}/${promotionCode}`);
    return retValue;
  },

  /**
   * Add affiliate code for referral tracking
   * @param {object} req.body - affiliateId
   */
  async addAffiliateId(id, data) {
    const retValue = await Axios.put(`${RESOURCE}/${id}/affiliate/referral`, data);
    return retValue;
  },

  /**
   * Add affiliate code for referral tracking
   * @param {object} req.body - coupon
   */
  async addConversion(id) {
    const retValue = await Axios.get(`${RESOURCE}/${id}/affiliate/conversion`);
    return retValue;
  },

  /**
   * Get auth hash for identity verification
   */
  async getChurnKeyHash(stripeId) {
    const retValue = await Axios.get(`${RESOURCE}/churnkey/${stripeId}`);
    return retValue;
  },

  /**
   * Request Reset
   * @param {object} - { email: 'string', newPassword: 'string }
   */
  async createResetRequest(data) {
    const retValue = await Axios.post('/auth/reset', data);
    return retValue;
  },

  /**
   * Confirm New Password
   * @param {object} - { resetToken: jwt }
   */
  async confirmPasswordReset(data) {
    const retValue = await Axios.post('/auth/confirm', data);
    return retValue;
  },

  async getStripePortal(stripeId, returnUrl) {
    const retValue = await Axios.get(`${RESOURCE}/stripe/portal?returnUrl=${returnUrl}&stripeId=${stripeId}`);
    return retValue;
  },
  async getStripeSession(stripeId, returnUrl) {
    const retValue = await Axios.get(`${RESOURCE}/stripe/session?returnUrl=${returnUrl}&stripeId=${stripeId}`);
    return retValue;
  },
  async cancelPlan(data) {
    const retValue = await Axios.post(`${RESOURCE}/stripe/cancel`, data);
    return retValue;
  },

  async pausePlan(data) {
    const retValue = await Axios.post(`${RESOURCE}/stripe/pause`, data);
    return retValue;
  },

  async checkoutPausePlan(returnUrl) {
    const retValue = await Axios.get(`${RESOURCE}/stripe/pause?returnUrl=${returnUrl}`);
    return retValue;
  },

  async deleteAccount() {
    const retValue = await Axios.delete(`${RESOURCE}`);
    return retValue;
  },

  async scheduleDeleteUserAccount(data) {
    const retValue = await Axios.post(`${RESOURCE}/delete`, data);
    return retValue;
  },

  async updateUserName({ name, lastName }) {
    const retValue = await Axios.put(`${RESOURCE}/name`, { name, lastName });
    return retValue;
  },

  async createEmailResetRequest(data) {
    return await Axios.post('/auth/reset-email', data);
  },

  async confirmEmailChangeRequest(token) {
    const retValue = await Axios.post(`${RESOURCE}/email/confirm`, { token });
    return retValue;
  },

  async changeEmail(token) {
    const retValue = await Axios.post(`${RESOURCE}/email/change`, { token });
    return retValue;
  },
};
