/* eslint-disable func-names */
import EventBus from '@/helpers/event-bus';
import Vue from 'vue';
import Tracker from '@openreplay/tracker';
import VueI18n from 'vue-i18n'
import Axios from 'axios';
import AudioRecorder from 'vue-audio-recorder';
import { Message } from 'element-ui';
import { sync } from 'vuex-router-sync';
import VueGtm from 'vue-gtm';
import VueIntercom from 'vue-intercom';
// import * as Sentry from '@sentry/browser';
// import * as Integrations from '@sentry/integrations';
import { ElementTiptapPlugin } from 'element-tiptap';
import VueClipboard from 'vue-clipboard2';
import LocalForage from 'localforage';
import AuthService from '@/api/auth.service';
// import FontPicker from 'font-picker-vue';

// Init OpenReplay tracker
// import OpenReplay from '@openreplay/tracker';
// import trackerAssist from '@openreplay/tracker-assist'; - temp removed, leave entry for now
// import trackerAxios from '@openreplay/tracker-axios'; - temp removed, leave entry for now

import App from './App.vue';
import router from './router';
Vue.LocalForage = LocalForage;
import store from './store';

// load plugins and styles
import './plugins/element';
import './plugins/icons';
import './style/main.scss';
import {
  GTM_ID,
  // HOTJAR_ENABLED,
  INTERCOM_APP_ID,
  IS_PRODUCTION,
  OPEN_REPLAY_ENABLED,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_RELEASE,
  WAVVE_API_BASE_URL
} from './config';

// LOAD LAYOUTS
import Default from './layouts/default.vue';
import Offboard from './layouts/offboard.vue';

// Locales
import en from './locales/en.json';
import ptBR from './locales/pt-BR.json';
import { REFRESH_AUTH_TOKEN, SYNC_USER_TO_DB } from './store/action-types';
// import Hotjar from 'vue-hotjar';
// import { error } from 'logrocket';

// const tracker = new OpenReplay({
//   projectKey: OPEN_REPLAY_PROJECT_KEY,
//   ingestPoint: 'https://openreplay.wavve.co/ingest',
//   __DISABLE_SECURE_MODE: true,
//   onStart: ({ sessionID }) => {
//     console.log('OpenReplay tracker started with session: ', sessionID);
//   },
// });

// tracker.start();

// pass OpenReplay tracker globally
// Vue.prototype.$openReplayTracker = tracker;
// this.$openReplayTracker.start();
// this.$openReplayTracker.use(trackerAxios()); //Enable Axios error tracking, - this is temp removed
// this.$openReplayTrack.use(trackerAssist()); // Enable live assist - this is temp removed
Vue.pendingAxios = [];
// Configure Layouts
Vue.component('default-layout', Default);
Vue.component('offboarding-layout', Offboard);

// if(IS_PRODUCTION) {
// var console = {};
// console.log = function(){};
// console.info = function(){};
// console.warn = function(){};
// window.console = console;
// }
// if (IS_PRODUCTION && SENTRY_DSN && SENTRY_RELEASE && SENTRY_ENVIRONMENT) {
//   Sentry.init({
//     dsn: SENTRY_DSN,
//     integrations: [new Integrations.Vue({ Vue, attachProps: true })],
//     environment: SENTRY_ENVIRONMENT,
//     release: SENTRY_RELEASE,
//   });
// }

// Vue.use(FontPicker);

Vue.use(VueIntercom, { appId: INTERCOM_APP_ID });

Vue.use(VueGtm, { id: GTM_ID, debug: !IS_PRODUCTION });

Vue.use(AudioRecorder);

Vue.use(ElementTiptapPlugin, {
  lang: 'en',
  spellcheck: true,
});
Vue.use(VueClipboard);
Vue.config.productionTip = false;

// return data directly
export const formatResponse = res => {
  // console.log('res',res);
  if (res.config && res.config.cancelToken) {
    const targetElementIndex = Vue.pendingAxios.indexOf(res.config);
    Vue.pendingAxios.splice(targetElementIndex, 1);
  }
  return res.data
};

// intercept errors and display error states
export const intercept = function (err) {
  const { response } = err;
  if (response && response.config && response.config.baseURL != WAVVE_API_BASE_URL) {
    return
  }

  return new Promise(() => {

    if (response && response.status === 422) {
      // handle expired JWT
      // return Message.error({ message: response.data.message, duration: 6000 });
      store.dispatch('REFRESH_AUTH_TOKEN');
      return
    }
    if (response && response.status === 401) {

      Vue.pendingAxios.forEach(async (cancelItem) => {
        cancelItem.cancelSource.cancel('Stopping pending requests due to 401');
      })

      // console.log("token fail", response.data);
      localStorage.setItem('token', '');
      // If jwt is bad, cache route to return after re-authenticating
      var pathArray = window.location.pathname.split('/');
      if (
        pathArray[1] != "login" &&
        pathArray[1] != "logout" &&
        pathArray[1] != "blank" &&
        pathArray[1] != 'signup' &&
        pathArray[1] != 'reset-email' &&
        pathArray[1] != 'reset' &&
        pathArray[1] != 'setup' &&
        pathArray[1] != 'change-email'
      ) {
        if (response.data === 'jwt expired' || response.data === 'invalid token' || response.data === 'Unauthorized') {
          Message.error({ message: 'Session Expired. Please log in again', duration: 5000 });
        } else {
          // Capture and display other auth errors
          if (response.data.message) {
            Message.error({ message: response.data.message, duration: 5000 });
          }
        }
        router.push('/blank').catch();
      }

      store.dispatch('CLEAR_TOKEN');

      if (pathArray[1] == "login") {
        if (response && response.status === 401 && response.data === 'authentication failed') {
          Message.error({ message: "Authentication failed, please try again.", duration: 5000 });
        }
      }

    }
    if (response && response.status === 403) {
      Message.error({ message: response.data.message, duration: 5000 });
    }
    throw err;
  });
};

// Axios configuration
const token = localStorage.getItem('token');
if (token && token != "") {
  Axios.defaults.headers.common.Authorization = token;
}
Axios.interceptors.response.use(formatResponse, intercept);
Axios.defaults.baseURL = WAVVE_API_BASE_URL;
Axios.defaults.withCredentials = true;
AuthService.ping();





Axios.interceptors.request.use(function (config) {
  // Axios.CancelToken
  // const cancelToken = new Axios.CancelToken((excutor) => {
  //   return excutor
  // })

  const cancelToken = Axios.CancelToken.source()

  config.cancelToken = cancelToken.token;
  config.cancelSource = cancelToken;
  Vue.pendingAxios.push(config);
  // console.log('config',config);
  return config;
}, function (error) {
  return Promise.reject(error);
});


var focusfunction = async function () {
  const vm = vueInstance;
  var pathArray = window.location.pathname.split('/');

  const currentVersion = Number(localStorage.getItem('app_store_version'));
  const pingResponse = await AuthService.ping();

  if (pingResponse && currentVersion !== pingResponse.version) {
    localStorage.setItem('app_store_version', pingResponse.version);

    vm.$store.dispatch(REFRESH_AUTH_TOKEN);
    vm.$store.dispatch(SYNC_USER_TO_DB);
    vm.$forceUpdate();

    window.location.replace(window.location.pathname + window.location.search + window.location.hash + '?upb=' + Math.random());

    return;
  }

  // LocalForage.getItem('app_store_version', function (err, value) {
  //   localVersion = value;

  //   AuthService?.ping()?.then(response => {
  //     const apiVersion = response.version;
  //     if (localVersion !== apiVersion) {
  //       LocalForage.setItem('app_store_version', apiVersion, function () {
  //         vm.$store.dispatch(REFRESH_AUTH_TOKEN);
  //         vm.$store.dispatch(SYNC_USER_TO_DB);
  //         vm.$forceUpdate();
  //         window.location.replace(window.location.pathname + window.location.search + window.location.hash + '?upb=' + Math.random());
  //       })
  //     }
  //   });

  //   if (value != null && value < appStoreVersion) {
  //     console.log("refreshing store", appStoreVersion)
  //     // LocalForage.setItem('app_store_version', appStoreVersion, function () {
  //     //   vm.$store.dispatch(REFRESH_AUTH_TOKEN);
  //     //   vm.$store.dispatch(SYNC_USER_TO_DB);
  //     //   vm.$forceUpdate();
  //     //   window.location.replace(window.location.pathname + window.location.search + window.location.hash + '?upb=' + Math.random());
  //     // })


  //   } else if (value == null) {
  //     // LocalForage.setItem('app_store_version', appStoreVersion, function () {
  //     //   console.log("set version done")
  //     // })
  //   }
  //   // LocalForage.setItem('app_store_version', appStoreVersion, function () { })
  // });

  // LocalForage.setItem('app_store_version', appStoreVersion)
  // console.log('gotFocusBack',store.getters.isLoggedIn, localStorage.getItem('token'));
  // console.log('focou')
  // console.log(localStorage.getItem('token')!=null, localStorage.getItem('token')!='', store.getters.isLoggedIn )
  if (localStorage.getItem('token') != null && localStorage.getItem('token') != '' && !store.getters.isLoggedIn) {
    if (pathArray[1] == 'login') {
      // router.push('/blank')
      AuthService?.ping();
      // window.location.refresh()
      // router.push('/blank').catch()
      // window.location.href="/blank"
      window.location.href = "/login"
    } else {
      // router.push('/logout').catch()
      AuthService?.ping();
      // window.location.href="/login"
    }
  }
  // console.log(localStorage.getItem('token')!=null, localStorage.getItem('token')!='', store.getters.isLoggedIn )
  if (localStorage.getItem('token') != null && localStorage.getItem('token') != '' && store.getters.isLoggedIn) {

    // if(pathArray[1]!='login') {
    // }
    if (
      pathArray[1] != 'login' &&
      pathArray[1] != 'reset-email' &&
      pathArray[1] != 'reset' &&
      pathArray[1] != 'signup' &&
      pathArray[1] != 'setup' &&
      pathArray[1] != 'change-email'
    ) {
      AuthService?.ping();
    } else {
      router.push('/videos').catch()
    }
  }
  // } else {
  //  if(pathArray[1]!='login') {
  //    router.push('/logout').catch()
  //  }
  // }

  if (localStorage.getItem('token') == null || localStorage.getItem('token') == '') {
    if (
      pathArray[1] != 'login' &&
      pathArray[1] != 'signup' &&
      pathArray[1] != 'reset-email' &&
      pathArray[1] != 'reset' &&
      pathArray[1] != 'setup' &&
      pathArray[1] != 'change-email'
    ) {
      router.push('/logout').catch();
    }
  }
};

// window.removeEventListener("focus", focusfunction);

// Axios.interceptors.request.use(function (config) {
//   // const token = localStorage.getItem('token');
//   // console.log("TOKEN CORRENTE",token);
//   // if (token) {
//   //   Axios.defaults.headers.common.Authorization = token;
//   // }
//   return config
// });

// sync store & router
sync(store, router);

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    "pt-BR": ptBR
  }
})

let tracker;

if (OPEN_REPLAY_ENABLED === 'true') {
  tracker = new Tracker({
    projectKey: "hRjI53ljVa0pLAkgWwDK",
  });
}

// Vue.use(Hotjar, {
//   id: '3668779',
//   isProduction: HOTJAR_ENABLED === 'true'
// })

var vueInstance = new Vue({
  i18n,
  router,
  store,
  render: h => h(App),
}).$mount('#app');

// export const appStoreVersion = 118;

window.addEventListener("focus", focusfunction);

export const OpenReplayTracker = tracker;
