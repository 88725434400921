import { SRC_BUCKET } from '../../config';

class Media {
  constructor(media) {
    // eslint-disable-next-line
    this.id = media._id;
    this.dateAdded = media.date_added;
    this.guid = media.guid;
    this.ownerId = media.owner;
    this.title = media.title;
    this.feedTitle = media.feed_title;
    this.owner_name = media.owner_name;
    this.feedGuid = media.feed_guid;
    this.status = media.status;
    this.imageSrc = media.image_src;
    this.s3AudioUrl = `https://${SRC_BUCKET}.s3.amazonaws.com/tmp/${media.guid}/processed-audio.mp3`;
    this.peaksUrl = `https://${SRC_BUCKET}.s3.amazonaws.com/tmp/${media.guid}/waveform.json`;
  }
}

export default Media;
