<template>
  <nav class="navbar is-spaced rr-block" role="navigation" aria-label="main navigation">
    <div class="container"
      style="background:white;padding-bottom: 0px;position:fixed;top:0px;width:100%;box-shadow:0px 0px 4px gray">
      <div class="navbar-brand">
        <span class="navbar-item" :to="{ name: 'videos' }">
          <img style="cursor:pointer; scale: 1.3; margin-left:15px; margin-top: 10px;" src="@/assets/brand/logo-text.png"
            alt="Wavve Logo" @click="$router.push('/videos')" />
        </span>
        <a id="teamMenuBurger" role="button" class="team-burger" data-target="teamNavbarMenu" @click="openTeamMenu">
          <div class="navbar-team-image" :style="{ 'background-color': currentTeamInfo?.color }">
          </div>
          <div class="navbar-team-select-container">{{ currentTeamInfo?.name }}</div>
        </a>
        <a id="navbarMenuBurger" role="button" class="wavve-burger navbar-burger burger" aria-label="menu"
          aria-expanded="false" data-target="navbarMenu" @click="openMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div class="navbar-menu" id="teamNavbarMenu" @click="hideTeamMenu">
        <div class="navbar-start has-small-margin-top">
          <div class="team-navbar-tittle is-uppercase">
            Teams
          </div>
          <a class="team-nav-item big" v-for="(teamInfo) in teams" :key="`team_${teamInfo._id}`"
            :class="{ 'team-nav-item-selected': currentUserTeamId === teamInfo.ownerId || (currentUserTeamId === undefined && teamInfo.ownerId === userId) }"
            :disabled="currentUserTeamId === teamInfo.ownerId || (currentUserTeamId === undefined && teamInfo.ownerId === userId)"
            :style="{ 'border-color': currentUserTeamId === teamInfo.ownerId || (currentUserTeamId === undefined && teamInfo.ownerId === userId) ? teamInfo.color : 'transparent' }"
            @click="changeTeam(teamInfo)">
            <div class="navbar-team-image" :style="{ 'background-color': teamInfo.color }"
              v-if="currentUserTeamId === teamInfo.ownerId">
            </div>
            <div class="navbar-team-image" :style="{ 'border-color': teamInfo.color, 'background-color': teamInfo.color }"
              v-else>
            </div>
            <div class="navbar-team-select-container">{{ teamInfo.name }}</div>
            <div class="team-active-tag-container"
              v-if="currentUserTeamId === teamInfo.ownerId || (currentUserTeamId === undefined && teamInfo.ownerId === userId)">
              <el-tag size="mini" class="team-active-tag"
                :style="{ 'background-color': teamInfo.color, color: teamInfo.textColor }">ACTIVE</el-tag>
            </div>
          </a>
        </div>
      </div>
      <div class="navbar-menu" id="navbarMenu" @click="hidemenu">
        <div class="navbar-start has-small-margin-top">
          <router-link :to="{ name: 'transcriptions' }" class="navbar-item big">
            <span class="icon">
              <i class="fad fa-copy has-small-margin-right"></i>
            </span>
            <div style="display:inline-block;margin-left:-2px;">Transcriptions</div>
            <div style="margin-left: 0px; margin-top: -8px;  margin-top: -30px; position: absolute;margin-left:155px">
              <el-tag size="mini"
                style="padding:3px; border:none;padding-top:0px;line-height:12px;height:11px;font-size:35%;background-color:#24E0DE;color:black">BETA</el-tag>
            </div>
          </router-link>
          <router-link :to="{ name: 'videos' }" class="navbar-item big">
            <span class="icon">
              <i class="fad fa-play-circle has-small-margin-right"></i>
            </span>
            Videos
          </router-link>
          <router-link :to="{ name: 'link' }" class="navbar-item big">
            <span class="icon">
              <i class="fad fa-link has-small-margin-right"></i>
            </span>
            Wavve Link
          </router-link>

          <small>
            <span class="navbar-item has-margin-top is-uppercase ">Support</span>
          </small>
          <ul>
            <li>
              <a href="https://wavve.co/faqs" analytics-on="click" analytics-event="Navigate"
                analytics-category="New Page" analytics-label="/support" target="_blank" class="navbar-item">FAQs</a>
            </li>
          </ul>
          <small>
            <span class="navbar-item has-margin-top is-uppercase">Account</span>
          </small>
          <ul>
            <li>
              <router-link :to="{ name: 'settings' }" class="navbar-item">Settings</router-link>
            </li>
            <li v-if="canAccessTeamBilling">
              <router-link :to="{ name: 'billing' }" class="navbar-item">Billing</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'teams' }" class="navbar-item">Teams</router-link>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'plan' }" class="navbar-item">Your Plan</router-link>
            </li> -->
            <!-- <li>
              <router-link :to="{ name: 'pricing' }" class="navbar-item">Pricing</router-link>
            </li> -->
            <!-- <a @click="logout" class="navbar-item">Logout</a> -->
            <li>
              <!-- <router-link :to="{ name: 'logout' }" class="navbar-item">Logout</router-link> -->

            <li>
              <router-link :to="{ name: 'logout' }" class="navbar-item">Logout</router-link>
            </li>
            <!-- <a href="/logout" onclick="window.location.href='/logout'" class="navbar-item">Logout</a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { TEAMS_CHANGE_TEAM } from '../store/action-types';

export default {
  name: 'TheNavbar',
  computed: {
    user() {
      return this.$store.state.user;
    },
    currentUserTeamId() {
      return this.$store.state.user?.team;
    },
    teams() {
      return this.$store.state.teams;
    },
    userId() {
      return this.$store.state.user.id;
    },
    currentTeamInfo() {
      return this.$store.state.user.teamInfo;
    },
    canAccessTeamBilling() {
      return this.userId === this.currentTeamInfo?.ownerId;
    },
    cantChangeTeam() {
      return ['create', 'editor'].includes(this.$route.name);
    },
  },
  mounted() {
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          const { target } = el.dataset;
          const $target = document.getElementById(target);
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }

    const $teamNavbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.team-burger'), 0);

    if ($teamNavbarBurgers.length > 0) {
      $teamNavbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          const { target } = el.dataset;

          const $target = document.getElementById(target);

          el.classList.toggle('is-active');

          $target.classList.toggle('is-active');
        });
      });
    }
  },
  methods: {
    getTeamName(teamId) {
      const currentTeam = this.$store.state.teams?.filter(team => (team.ownerId === teamId) || this.userId)[0];

      return currentTeam?.name;
    },
    getTeamColor(teamId) {
      const currentTeam = this.$store.state.teams?.filter(team => (team.ownerId === teamId) || this.userId)[0];

      return currentTeam?.color;
    },
    async changeTeam(team) {
      if (team.owner._id !== this.currentUserTeamId) {
        if (this.cantChangeTeam) {
          try {
            const result = await this.$confirm(
              'By switching Teams now, you will lose this video/design.',
              'Switching Teams',
              {
                confirmButtonText: 'Switch',
                cancelButtonText: 'Stay',
                type: 'info',
              }
            );

            if (result !== 'confirm') {
              return;
            }

            await this.$router.push('/videos?changingTeam=true');
          } catch (error) {
            return;
          }
        }

        this.$store.dispatch(TEAMS_CHANGE_TEAM, { teamId: team.owner._id }).then(() => {
          console.log('FEEDBACK');
        });
      }

      const elements = [
        document.getElementById('teamNavbarMenu'),
        document.getElementById('teamMenuBurger')
      ];

      elements.forEach(el => {
        if (el) {
          el.classList.remove('is-active');
        }
      });
    },
    openTeamMenu() {
      const elements = [
        document.getElementById('navbarMenu'),
        document.getElementById('navbarMenuBurger'),
      ];

      elements.forEach(el => {
        if (el) {
          el.classList.remove('is-active');
        }
      });
    },
    openMenu() {
      const elements = [
        document.getElementById('teamNavbarMenu'),
        document.getElementById('teamMenuBurger')
      ];

      elements.forEach(el => {
        if (el) {
          el.classList.remove('is-active');
        }
      });
    },
    hidemenu(event) {
      event.target.classList.toggle('is-active');

      document.querySelectorAll('.navbar-burger').forEach(el => {
        el.classList.toggle('is-active');
      })
    },
    hideTeamMenu(event) {
      event.target.classList.toggle('is-active');

      document.querySelectorAll('.team-burger').forEach(el => {
        el.classList.toggle('is-active');
      })
    },
    async logout() {
      // localStorage.removeItem('token');
      // await this.$store.dispatch(LOGOUT);
      await this.$router.push('/logout');
    },
  },
  watch: {
    // eslint-disable-next-line
    $route() {
      const elements = [
        document.getElementById('navbarMenu'),
        document.getElementById('navbarMenuBurger'),
        document.getElementById('teamNavbarMenu'),
        document.getElementById('teamMenuBurger')
      ];

      elements.forEach(el => {
        if (el) {
          el.classList.remove('is-active');
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.team-active-tag-container {
  margin-left: auto;
  margin-top: -25px;
  position: relative;
}

.team-navbar-tittle {
  font-weight: bold;
}

.team-nav-item {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center !important;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid transparent;
}

.team-nav-item:focus-visible {
  border-radius: 10px;
}

.team-nav-item-selected {
  align-items: flex-start !important;
  border: 2px solid #24E0DE;
}

.team-nav-item-selected:hover {
  border-color: #24E0DE;
}

.team-nav-item .team-active-tag-container {
  margin-top: -15px;
}

.team-active-tag {
  padding: 3px;
  border: none;
  padding-top: 0px;
  line-height: 12px;
  height: 11px;
  font-size: 35%;
  background-color: #24E0DE;
  color: black
}

.navbar-team-select-container {
  min-width: 50px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-top: 3px;
  ;
}

.navbar-team-image {
  display: block;
  width: 25px;
  height: 25px;
  margin: 0px 10px 0px 0px;
  background-color: transparent;
  padding: 1px;
  background-clip: content-box;
  border-radius: 20px;
}

.team-burger {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #333;
  font-weight: bold;
}

.wavve-burger {
  margin-left: 10px;
}

.navbar-menu.is-active {
  left: 0px;
  background: rgba(0, 0, 0, 0.2);
  transition: left 0.22s ease-in, background .2s ease-out 0.21s;
}

.navbar-menu {
  background: transparent;
  transition: left 0.15s ease-in, background 0s ease-out;
  box-shadow: none !important;
  padding-top: 0px;
  top: 52px;
  height: 900px;
  left: -500px;
  display: block;
  position: absolute;
  width: 100%;
  // height: 600px;
  padding-bottom: 30px;
}

.navbar-item {
  height: 40px;
}

.navbar-item.big {
  height: 55px;
  font-size: 105% !important;

}

i {
  color: #555;
  scale: 1.4;
  margin-right: 15px;
  margin-left: 6px;
}

.navbar-start {
  box-shadow: 8px 0px 7px -5px rgba(0, 0, 0, 0.2), 0px 5px 3px -4px inset rgba(0, 0, 0, 0.25);
  background: #fefefe;
  margin-top: 23px;
  width: 60%;
  height: 900px;

  padding: 10px;
  padding-left: 15px;
  padding-top: 20px;
  scale: 1.05;

  a {
    font-weight: 200;
    color: black !important;
  }
}

ul {
  margin-top: -10px;
  padding-left: 18px;
}
</style>