import Axios from 'axios';
import { WAVVE_API_BASE_URL } from '../config';

const RESOURCE = '';

export default {
  /**
   * S3 Secure Signing Route
   * @param {object} s3Upload - { type, file, data }
   * @param {object} config - axios config
   */
  s3Signing(s3Upload, config = undefined) {
    return Axios.post(`${RESOURCE}/s3Signing`, s3Upload, config);
  },

  /**
   * Upload audio to S3
   * @param {object} audio - { key: 'audio.mp3', file }
   */
  uploadAudio(audio) {
    return Axios.post(`${RESOURCE}/submit`, audio);
  },

  /**
   * Start lambda-wavve function to render video
   * @param {object} jobSettings - https://github.com/wavve/lambda-wavve
   */
  startLambda(jobSettings) {
    return Axios.post(`${RESOURCE}/start`, jobSettings);
  },

  /**
   * Sync event data with deflector
   * @param {object} eventData
   */
  syncWithDeflector(eventData) {
    return Axios.post(`${WAVVE_API_BASE_URL}/deflector`, eventData);
  },

  /**
   * Sync survey data with deflector
   * @param {object} surveyData
   */
  sendDeflectorSurvey(surveyData) {
    return Axios.post(`${WAVVE_API_BASE_URL}/deflector`, surveyData);
  },
};
