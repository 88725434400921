import Axios from 'axios';
import store from '../store/index.js'
import router from '../router';
const RESOURCE = '';

export default {
  /**
   * Log in with email and password
   * @param {object} payload - { email, password }
   * @param {object} config - axios config
   */
  login(payload, config = undefined) {
    return Axios.post(`${RESOURCE}/auth`, payload, config);
  },

  ping() {
    if (window.pingInterval) clearInterval(window.pingInterval);
    window.pingInterval = setInterval(() => {
      if (localStorage.getItem('token') != null && localStorage.getItem('token') != '' && store.getters.isLoggedIn) {
        Axios.post(`${RESOURCE}/auth/ping`)
      }
      // } else {
      // clearInterval(window.pingInterval);
      // router.push('/login');
      // }
    }, 20000);
    if (localStorage.getItem('token') != '' && localStorage.getItem('token') != '' && store.getters.isLoggedIn) {
      return Axios.post(`${RESOURCE}/auth/ping`);
    }
  },

  /**
   * Log out
   * @param {object} config - axios config
   */
  logout(config = undefined) {
    localStorage.removeItem('token');
    return Axios.delete(`${RESOURCE}/auth`, {}, config);
  },

  /**
   * Refresh the auth token
   * @param {object} config - axios config
   */
  refresh(config = undefined) {
    // localStorage.removeItem('token');
    return Axios.put(`${RESOURCE}/auth`, {}, config);
  },

  /**
   * Authorize a user to OAuth Service
   * @param {object} payload - { clientId, redirectUri }s
   * @param {object} config - axios config
   */
  oauthAuthorize(payload, config = undefined) {
    return Axios.get(
      `${RESOURCE}/auth/oauth/authorize?client_id=${payload.clientId}&redirect_uri=${payload.redirectUri}&response_type=${payload.responseType}&state=${payload.state}&response_mode=api`,
      config
    );
  },

  oauthConfirm(payload, config = undefined) {
    return Axios.post(`${RESOURCE}/auth/oauth/authorize/decision`, payload, config);
  },

  async confirmEmailResetVerification(verificationToken) {
    const retValue = await Axios.post(`${RESOURCE}/confirmVerifyEmail`, { verificationToken });
    return retValue;
  },
};
