import Axios from 'axios';

const RESOURCE = '/api/themes';

export default {
  /**
   * Find theme by id
   * TODO - should this require auth header?
   * @param {string} theme.id - Theme Identifier
   */
  get(id, ignoreObjects) {
    return Axios.get(`${RESOURCE}/${id}?${ignoreObjects ? `ignoreObjects=true` : ''}`);
  },

  /**
   * Create new design
   * @param {object} data - Theme Properties
   */
  create(data) {
    return Axios.post(RESOURCE, data);
  },

  /**
   * Create new design template
   * @param {object} data - Theme Properties
   */
  createTemplateImage(template = 'coverImage', input) {
    return Axios.post(`${RESOURCE}/template`, { template, input });
  },

  /**
   * Edit a single job
   * @param {string} theme.id - Theme Identifier
   * @param {object} data - Theme properties
   */
  update(id, data) {
    return Axios.put(`${RESOURCE}/${id}`, data);
  },

  /**
   * Clone a theme
   * @param {object} data - Theme id
   */
  clone(data) {
    return Axios.post(`${RESOURCE}/clone`, data);
  },

  /**
   * Soft delete a single theme
   * @param {string} theme.id - Theme Identifier
   */
  delete(id, data) {
    return Axios.put(`${RESOURCE}/${id}`, data);
  },
};
