/* eslint-disable */
import {
  timeDay,
  ascending
} from 'd3'

export function addMonths(date, count) {
  if (date && count) {
    var m, d = (date = new Date(+date)).getDate()
    date.setMonth(date.getMonth() + count, 1)
    m = date.getMonth()
    date.setDate(d)
    if (date.getMonth() !== m) date.setDate(0)
  }
  return date
}

export function getThisMonthStart(dateAdded) {
  var monthsSinceStart = 0;
  var now = new Date();
  while (addMonths(dateAdded, monthsSinceStart) < now) {
    monthsSinceStart++;
  }
  return addMonths(dateAdded, monthsSinceStart - 1);
}

export function getThisMonthEnd(dateAdded) {
  var monthsSinceStart = 0;
  var now = new Date();
  while (addMonths(dateAdded, monthsSinceStart) < now) {
    monthsSinceStart++;
  }
  return addMonths(dateAdded, monthsSinceStart);
}

export function gaDateToDate(gaDate) {
  const year = gaDate.substr(0, 4)
  const month = gaDate.substr(4, 2) - 1
  const date = gaDate.substr(6, 2)
  return new Date(year, month, date)
}
export function dateToGaDate(d) {
  const year = d.getFullYear()
  const month = ('0' + (d.getMonth() + 1)).slice(-2)
  const date = ('0' + d.getDate()).slice(-2)
  return `${year}${month}${date}`
}
export function formatDateString(d) {
  const year = d.getFullYear()
  const month = ('0' + (d.getMonth() + 1)).slice(-2)
  const date = ('0' + d.getDate()).slice(-2)
  return `${year}/${month}/${date}`
}

export function getGaDateRange(gaDate, offset) {
  const date = gaDateToDate(gaDate)
  const offsetDate = timeDay.offset(date, offset)
  const dates = [date, offsetDate].sort((a, b) => ascending(+a, +b))
  const days = timeDay.range(dates[0], dates[1], 1)
  return days.map(dateToGaDate)
}

export default {
  addMonths,
  getThisMonthStart,
  getThisMonthEnd,
  gaDateToDate,
  dateToGaDate,
  getGaDateRange,
}
