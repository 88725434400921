import Axios from 'axios';
import fileToDataUri from '@/util/fileToDataUri';

const RESOURCE = '/api/links';

export default {
  /**
   * Fetch user link from JWT
   */
  // TODO fix for multi-link
  getUserLink() {
    return Axios.get(`${RESOURCE}`);
  },

  /**
   * Fetch Parsed RSS Feed
   */
  // TODO fix for multi-link
  getRssFeed() {
    let ret = Axios.get(`${RESOURCE}/parse`);
    // console.log(ret);
    return ret
  },

  /**
   * Update RSS Feeds
   */
  // TODO fix for multi-link
  updateFeeds(feeds) {
    return Axios.post(`${RESOURCE}/update-feeds`, { feeds });
  },
  /**
   * Find Link by shortId
   * @param {string} link.shortId - Link Identifier
   */
  get(id) {
    return Axios.get(`${RESOURCE}/fetch/${id}`);
  },

  /**
   * Create job by guid
   * @param {string} job.guid - S3 Job Identifier
   */
  create() {
    return Axios.get(`${RESOURCE}/create`);
  },

  /**
 * Deleted job by id
 * @param {string} job.guid - S3 Job Identifier
 */
  delete(id) {
    return Axios.delete(`${RESOURCE}/${id}`);
  },

  /**
   * Update wavve.link profile
   * TODO - should use PUT and :id
   * @param {object} data - wavve.link properties
   */
  update(data) {
    return Axios.post(`${RESOURCE}/update`, data);
  },

  /**
   * Set Shortlink
   * TODO - should use PUT and :id
   * @param {string} slug - wavve.link slug
   */
  setShortlink(_id, slug) {
    return Axios.post(`${RESOURCE}/slug`, { _id, slug });
  },

  /**
   * Set custom favicon
   * @param {string} favicon favicon file object
   */
  async setCustomFavicon(_id, favicon) {
    const faviconDataUri = await fileToDataUri(favicon);
    return Axios.put(`${RESOURCE}/favicon`, { _id, favicon: faviconDataUri });
  },

  /**
   * Remove custom favicon
   * @param {string} favicon favicon file object
   */
  removeCustomFavicon(_id) {
    return Axios.delete(`${RESOURCE}/favicon`, { data: { _id } });
  },
};
