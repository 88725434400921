import Axios from 'axios';

const RESOURCE = '/api/zubtitle';

export default {
  // get document
  get(guid) {
    return Axios.get(`${RESOURCE}/${guid}`);
  },

  // saves a new doc to firebase which triggers transcription
  saveDocument(guid, data) {
    return Axios.post(`${RESOURCE}/${guid}/save`, data);
  },

  // check progress
  getProgress(guid) {
    return Axios.get(`${RESOURCE}/${guid}/progress`);
  },

  updateCaptions(guid, data) {
    return Axios.put(`${RESOURCE}/${guid}/captions`, data);
  },

  updateCaptionStyle(guid, data) {
    return Axios.put(`${RESOURCE}/${guid}/captions/style`, data);
  },

  updateCaptionStyles(data) {
    return Axios.put(`${RESOURCE}/styles`, data);
  },

  finalize(id) {
    return Axios.put(`${RESOURCE}/${id}/finalize`);
  },

  upload(guid) {
    return Axios.get(`${RESOURCE}/${guid}/upload`);
  },

  download(guid) {
    return Axios.get(`${RESOURCE}/${guid}/download`);
  },
};
