import Axios from 'axios';

const instance = Axios.create();

instance.interceptors.request.handlers = [];

export default {
  downloadBackground(url) {
    return instance.get(url,
      {
        headers: { 'Content-Type': "application/json; charset=utf-8" },
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.common
            return JSON.stringify(data);
          },
        ],
        responseType: 'blob'
      });
  },

  downloadThemeJson(url) {
    return instance.get(url,
      {
        headers: { 'Content-Type': "application/json; charset=utf-8" },
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.common
            return JSON.stringify(data);
          },
        ],
      });
  },
};
