import Vue from 'vue';
import Vuex from 'vuex';
import { detect } from 'detect-browser';
import VuexPersist from 'vuex-persist';
import LocalForage from 'localforage';
import mutations from '@/store/mutations';
// eslint-disable-next-line import/no-cycle
import actions from '@/store/actions';
import * as getters from '@/store/getters';

Vue.use(Vuex);

const vuexDB = new VuexPersist({
  key: 'vuex',
  storage: LocalForage, // localForage will fall back to localstorage for old browsers
  asyncStorage: true,
  // Function that passes the state and returns the state with only the objects you want to store.
  reducer: state => ({
    transcriptionUsageStatus: state.transcriptionUsageStatus,
    user: state.user,
    token: state.token,
    history: state.history,
    route: state.route,
    podcastRss: state.podcastRss,
    canvasData: state.canvasData,
    route: state.route, // defined by 'vuex-router-sync' in main.js
    transcriptions: state.transcriptions,
    transcriptions_meta: state.transcriptions_meta,
    audioBuffer: undefined,
    audioBufferDecoded: undefined,
    // podcastRss: undefined,
    // audioDataUrl: undefined,
    // audioType: undefined,
  }),
  // Function that passes a mutation and lets you decide if it should update the state in localStorage.
  // filter: mutation => (true)
});

export default new Vuex.Store({
  plugins: [vuexDB.plugin],
  state: {
    transcriptionUsageStatus: 0,
    transcriptions_meta: { docs: [], lastupdate: 0 },
    transcriptions: [],
    user: null,
    browser: detect(),
    token: localStorage.getItem('token') || '',
    authStatus: null,
    canvasData: null,
    audioBuffer: null,
    selectedTheme: null,
    audioBufferDecoded: null,
    audioBufferDecoding: false,
    audioBufferDecodeEstimateDate: null,
    remoteAudioUrl: null,
    remoteAudioDownloading: false,
    linkSelectedEpisode: null,
    remoteAudioDownloadProgress: null,
    audioDataUrl: null,
    audioRegion: null,
    popoverDismissed: false,
    selectedMedia: null,
    audioUploadDataUrl: null,
    audioReady: false,
    uploadAudioProcessing: false,
    audioReading: false,
    audioType: null, // 'remote' | 'file' | 'recording'
    podcastRss: null,
    deflector: {
      isRecording: false,
      success: false,
      acceptedOffer: false,
      offerDetails: '',
    },
    deflectorSurvey: {
      feedback: '',
      cancelReason: '',
    },
    subscriptionData: {
      cancelingLinkId: null,
    },
    history: {
      active: false,
      index: 0,
      log: [],
    },
  },
  actions,
  getters,
  mutations,
  team: null,
  teams: null,
  invites: null,
  isChangingTeam: false,
});
