/* eslint-disable */
import { scaleLinear } from 'd3'

function square(x) {
  return x * x;
}

function radial() {
  var linear = scaleLinear();

  function scale(x) {
    return Math.sqrt(linear(x));
  }

  scale.domain = function(_) {
    return arguments.length ? (linear.domain(_), scale) : linear.domain();
  };

  scale.nice = function(count) {
    return (linear.nice(count), scale);
  };

  scale.range = function(_) {
    return arguments.length ? (linear.range(_.map(square)), scale) : linear.range().map(Math.sqrt);
  };

  scale.ticks = linear.ticks;
  scale.tickFormat = linear.tickFormat;

  return scale;
}

export default {
  scaleRadial: radial
}
