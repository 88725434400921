function pad(num, size) {
  let s = `${num}`;
  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
}

function secondsToTimeString(seconds) {
  const centiseconds = Math.round(seconds * 100).toString();
  const padded = pad(centiseconds, 8);
  const arr = padded.split('');
  const cs = arr.splice(-2).join('');
  const totalSeconds = +arr.join('');
  let s = totalSeconds % 60;
  let m = Math.floor(totalSeconds / 60);
  const h = Math.floor(m / 60);
  m %= 60;
  let strFormat = 'MM:SS.CS';

  if (h > 0) {
    strFormat = 'HH:MM:SS.CS';
    strFormat = strFormat.replace(/HH/, h);
  }

  if (s < 10) {
    s = `0${s}`;
  }
  if (m < 10) {
    m = `0${m}`;
  }
  // if (duration > 60 * 100 && m < 100) m = '0' + m

  strFormat = strFormat.replace(/MM/, m);
  strFormat = strFormat.replace(/SS/, s);
  strFormat = strFormat.replace(/CS/, cs);

  return strFormat;
}

export default secondsToTimeString;
