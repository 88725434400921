class Link {
  constructor(link) {
    Object.assign(
      this,
      {
        shortId: null,
        customDomain: null,
        slug: null,
        formEnabled: false,
        analyticsEnabled: false,
        favicon: null,
      },
      link
    );
  }
}

export default Link;
