import Axios from 'axios';

const RESOURCE = 'https://pf5ig16whe.execute-api.us-east-1.amazonaws.com/prod/mailing-lists';

export default {
  /**
   * Subscribe user to mailing list```
   * @param {string} listOwnerId - Mailing list Identifier```
   * @param {object} subscriber - Subscriber object
   */
  subscribe(listOwnerId, subscriber) {
    return Axios.post(`${RESOURCE}/${listOwnerId}`, subscriber, { withCredentials: false });
  },

  /**
   * Lists all subscribers of a mailing list
   * @param {string} listOwnerId - Mailing list Identifier
   */
  listSubscribers(listOwnerId) {
    return Axios.get(`${RESOURCE}/${listOwnerId}`, { withCredentials: false });
  },
};
