// 16:1 width:height ratio is standard
function youtube() {
  return ({ ctx, theme, time, totalTime }) => {
    const fillRatio = time / totalTime;
    const color = theme.progressBarColor;
    const secondaryColor = theme.progressBarSecondaryColor;
    const [top, right, bottom, left] = ['Top', 'Right', 'Bottom', 'Left'].map(d => theme[`progressBar${d}`]);
    const height = Math.abs(bottom - top);
    const width = Math.abs(right - left);

    // two separate calculations, one for moving the rectangle fill and
    // one for moving the circle (since the start and end points are different)
    const circleRadius = height / 2;
    const rectHeight = height / 5;
    const innerWidth = width - circleRadius * 2;
    const innerPadding = circleRadius;
    const rectTop = top + height / 2 - rectHeight / 2;
    const cx = left + innerPadding + fillRatio * innerWidth;
    const cy = top + height / 2;
    const padding = -circleRadius; // circleRadius / 8

    ctx.fillStyle = secondaryColor;
    ctx.fillRect(
      left + innerPadding + innerWidth * fillRatio + (padding + circleRadius),
      rectTop,
      innerPadding + innerWidth * (1 - fillRatio) - (padding + circleRadius),
      rectHeight
    );
    ctx.fillStyle = color;
    ctx.fillRect(left, rectTop, innerPadding + innerWidth * fillRatio - (padding + circleRadius), rectHeight);
    // circle at current mark
    ctx.beginPath();
    ctx.arc(cx, cy, circleRadius, 0, 2 * Math.PI);
    ctx.fill();
    ctx.lineWidth = circleRadius / 4;
  };
}

function border() {
  return ({ ctx, theme, time, totalTime }) => {
    const color = theme.progressBarColor;
    const secondaryColor = theme.progressBarSecondaryColor;
    const [top, right, bottom, left] = ['Top', 'Right', 'Bottom', 'Left'].map(d => theme[`progressBar${d}`]);
    const height = Math.abs(bottom - top);
    const width = Math.abs(right - left);
    const borderWidth = (height * width) / 45000;
    const pixelsPerSecond = (width + height - 2 * borderWidth) / totalTime;
    const distanceTraveled = time * pixelsPerSecond;

    const secondLegDistance = distanceTraveled > width ? distanceTraveled - width + borderWidth : 0;
    drawRight(secondLegDistance);
    drawLeft(secondLegDistance);
    drawTop(Math.min(distanceTraveled, width));
    drawBottom(Math.min(distanceTraveled, width));

    function drawTop(distance) {
      const ratio = distance / width;
      ctx.fillStyle = secondaryColor;
      ctx.fillRect(left + width * ratio, top, width - width * ratio, borderWidth);
      ctx.fillStyle = color;
      ctx.fillRect(left, top, width * ratio, borderWidth);
    }
    function drawRight(distance) {
      const ratio = distance / height;
      ctx.fillStyle = secondaryColor;
      ctx.fillRect(right - borderWidth, top + height * ratio, borderWidth, height - ratio * height - borderWidth);
      ctx.fillStyle = color;
      ctx.fillRect(right - borderWidth, top, borderWidth, ratio * height);
    }
    function drawBottom(distance) {
      const ratio = distance / width;
      ctx.fillStyle = secondaryColor;
      ctx.fillRect(left, bottom - borderWidth, width - width * ratio, borderWidth);
      ctx.fillStyle = color;
      ctx.fillRect(right - width * ratio, bottom - borderWidth, width * ratio, borderWidth);
    }
    function drawLeft(distance) {
      const ratio = distance / height;
      ctx.fillStyle = secondaryColor;
      ctx.fillRect(left, top + borderWidth, borderWidth, height - ratio * height - borderWidth);
      ctx.fillStyle = color;
      ctx.fillRect(left, bottom - ratio * height, borderWidth, ratio * height);
    }
  };
}

function basicFill() {
  return ({ ctx, theme, time, totalTime }) => {
    const fillRatio = time / totalTime;
    const color = theme.progressBarColor;
    const secondaryColor = theme.progressBarSecondaryColor;
    const [top, right, bottom, left] = ['Top', 'Right', 'Bottom', 'Left'].map(d => theme[`progressBar${d}`]);
    const height = Math.abs(bottom - top);
    const width = Math.abs(right - left);
    ctx.fillStyle = secondaryColor;
    ctx.fillRect(left + width * fillRatio, top, width * (1 - fillRatio), height);
    ctx.fillStyle = color;
    ctx.fillRect(left, top, width * fillRatio, height);
  };
}

function circleFill({ isPie }) {
  return ({ ctx, theme, time, totalTime }) => {
    const fillRatio = time / totalTime;
    const color = theme.progressBarColor;
    const secondaryColor = theme.progressBarSecondaryColor;
    const [top, right, bottom, left] = ['Top', 'Right', 'Bottom', 'Left'].map(d => theme[`progressBar${d}`]);
    const height = Math.abs(bottom - top);
    const width = Math.abs(right - left);
    const cx = left + width / 2;
    const cy = top + height / 2;
    const arcOffset = -Math.PI / 2;
    const radius = Math.min(height, width) / 2;
    const lineWidth = radius / 8;
    ctx.lineWidth = lineWidth;
    let arcRadius = radius - lineWidth / 2;
    if (isPie) {
      ctx.lineWidth = radius;
      arcRadius = radius / 2;
    }
    ctx.strokeStyle = secondaryColor;
    ctx.beginPath();
    ctx.arc(cx, cy, arcRadius, arcOffset + 2 * Math.PI * fillRatio, arcOffset + 2 * Math.PI);
    ctx.stroke();
    ctx.strokeStyle = color;
    ctx.beginPath();
    ctx.arc(cx, cy, arcRadius, arcOffset, arcOffset + 2 * Math.PI * fillRatio);
    ctx.stroke();
  };
}

export default {
  basicFill: basicFill(),
  circleFill: circleFill({}),
  pieFill: circleFill({ isPie: true }),
  youtube: youtube(),
  border: border(),
};
