<template>
  <div>
    <section class="section">
      <div class="container has-padding-top">
        <div class="has-text-centered has-padding-top" v-if="title">
          <p class="is-size-4 has-text-weight-bold title">{{ title }}</p>
        </div>
        <div class="has-text-centered" v-if="description">
          <p class="is-size-6-7 description">{{ description }}</p>
        </div>
      </div>
    </section>
    <div class="wrap-container">
      <div class="wrap">
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
        <div class="lines"></div>
      </div>
    </div>
    <div class="has-text-centered has-margin-top has-padding-top" v-if="shouldDisplayProgressLabel">
      <p class="is-size-5">{{ progressLabel }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    title: String,
    description: String,
    progressLabel: {
      type: String,
      required: false,
    },
  },
  computed: {
    shouldDisplayProgressLabel() {
      return typeof this.progressLabel === 'string';
    },
  },
};
</script>

<style lang="scss" scoped>
@mixin object($width, $height, $bg) {
  width: $width;
  height: $height;
  background: $bg;
}

$gradient: linear-gradient(to top, #4d47e8 0%, #4d47e8 82%, #4d47e8 100%);

.title,
.description {
  background-image: $gradient;
  color: transparent;
  background-clip: text;
}

.wrap-container {
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrap {
  @include object(200px, 35px, null);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-column-gap: 2px;
  transform: rotateY(180deg) rotateZ(180deg);
  .lines {
    background: $gradient;
    border-radius: 3px;
    @for $i from 1 through 12 {
      &:nth-child(#{$i}) {
        animation: lines 1s 0.1s * ($i - 24) ease-in-out infinite alternate;
      }
      @keyframes lines {
        0% {
          height: 0;
          width: 8px;
          margin-top: -25px;
        }
        100% {
          height: 40px;
          width: 8px;
          margin-top: 0px;
        }
      }
    }
  }
}
</style>
