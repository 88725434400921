class ZubCaptionStyle {
  constructor(style) {
    // eslint-disable-next-line
    this.id = style._id;
    this.deleted = style.deleted;
    this.color = style.color;
    this.backgroundColor = style.backgroundColor;
    this.shadowWidth = style.shadowWidth;
    this.backgroundPadding = style.backgroundPadding;
    this.bold = style.bold;
    this.name = style.name;
    this.fontFamily = style.fontFamily;
    this.italic = style.italic;
    this.size = style.size;
    this.shadowColor = style.shadowColor;
    this.outlineColor = style.outlineColor;
    this.fullWidth = style.fullWidth;
    this.outlineWidth = style.outlineWidth;
    this.position = style.position;
    this.alignment = style.alignment;
  }
}

export default ZubCaptionStyle;
