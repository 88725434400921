import moment from 'moment';
import { descending, timeFormat, sum } from 'd3';
import Job from '@/store/models/Job';
import Link from '@/store/models/Link';
import Theme from '@/store/models/Theme';
import ZubCaptionStyle from '@/store/models/ZubCaptionStyle';
import { PLAN_MONTHLY_ALLOWANCE, ANNUAL_PLANS, UNLIMITED_PLANS, PLAN_STORAGE_DAY_LIMIT } from '@/config/account-config';
import { getRolloverTime, subscribedNoJobs, setBillingDetails, getVideosBetween, getNoStripeTimeLeft, formatTimeLeft } from '@/helpers/account-utils';
import { getThisMonthStart, addMonths } from '@/helpers/date-utils';
import Media from './Media';

class User {
  constructor(user, subscription = []) {
    // eslint-disable-next-line no-underscore-dangle
    this.id = user._id;
    this.products = user.products;
    this.email = user.email;
    this.name = user.name;
    this.lastName = user.lastName;
    this.industry = user.industry;
    this.workRole = user.workRole;
    this.fbclid = user.fbclid;
    this.gclid = user.gclid;
    this.ref = user.ref;
    this.role = user.role;
    this.rssFeeds = user.rssFeeds;
    this.refby = user.refby;
    this.promo = user.promo;
    this.stripe = user.stripe || {};
    this.emailOptOut = user.emailOptOut;
    this.emailNewsletter = user.emailNewsletter;
    this.emailBilling = user.emailBilling;
    this.emailVerified = user.emailVerified;
    this.emailAppUpdates = user.emailAppUpdates;
    this.dateAdded = new Date(user.dateAdded);
    this.isSubscribed = subscription.length > 0;
    this.monthlyAllowance = PLAN_MONTHLY_ALLOWANCE[user.products.video]; // in minutes
    this.planIsUnlimited = true;
    this.stinger = false;
    this.hasPremiumLink = true;
    this.videoPlan = {};
    this.linkPlan = {};
    this.linkPlans = [];
    this.activeLinkPlans = 0;
    this.stripePlan = user.stripePlan || {};
    this.teamStripePlan = user.teamStripePlan || {};
    this.team = user.team;
    this.teamInfo = user.teamInfo;
    this.cancelInformation = user.cancelInformation;
    this.convertKitId = user.convertKitId;
    this.deleted = user.deleted;
    this.deletedAt = user.deletedAt;
    this.emailToBilling = user.emailToBilling;
    this.phone = user.phone;
    this.links = [];

    if (user.links) {
      user.links.forEach(link => {
        this.links.push(new Link(link));
        // Sort active link account to top
        this.links.sort((a, b) => {
          if (a.active === b.active) return 0;
          if (a.active) return -1;
          return 1;
        });
      });
    }

    // TODO update for array of links
    // Check for pending link cancelations
    const pendingLinkCancel = user.planChanges
      ? user.planChanges.find(x => x.actionType === 'cancel' && x.status === 'pending' && x.planType === 'link')
      : undefined;
    if (pendingLinkCancel) {
      this.linkPlan.cancelationDate = new Date(pendingLinkCancel.stopDate);
      this.linkPlan.cancelationId = pendingLinkCancel._id;
      this.linkPlan.willCancel = new Date() < this.linkPlan.cancelationDate;
    } else {
      this.linkPlan.willCancel = false;
    }

    // Check for pending video cancelations
    const pendingVideoCancel = user.planChanges
      ? user.planChanges.find(x => x.actionType === 'cancel' && x.status === 'pending' && x.planType === 'video')
      : undefined;
    if (pendingVideoCancel) {
      this.videoPlan.cancelationDate = new Date(pendingVideoCancel.stopDate);
      this.videoPlan.cancelationId = pendingVideoCancel._id;
      this.videoPlan.willCancel = new Date() < this.videoPlan.cancelationDate;
    } else if (user.canceled && Object.keys(user.canceled).length) {
      // LEGACY SUPPORT FOR USER.CANCELED
      this.videoPlan.cancelationDate = new Date(user.canceled.endDate);
      this.videoPlan.willCancel = new Date() < this.videoPlan.cancelationDate;
    } else {
      this.videoPlan.willCancel = false;
    }

    const pendingVideoPause = user.planChanges
      ? user.planChanges.find(x => x.actionType === 'pause' && (x.status === 'pending' || x.status === 'paused'))
      : undefined;

    if (pendingVideoPause) {
      this.videoPlan.resumeDate = new Date(pendingVideoPause.resumeDate);
      this.videoPlan.pauseDate = new Date(pendingVideoPause.stopDate);
      this.videoPlan.pauseId = pendingVideoPause._id;
      this.videoPlan.pauseRollover = pendingVideoPause.rollover;
      this.videoPlan.isPaused = new Date() < this.videoPlan.resumeDate && new Date() > this.videoPlan.pauseDate;
      this.videoPlan.willPause = new Date() < this.videoPlan.pauseDate;
    } else if (user.paused && Object.keys(user.paused).length) {
      // LEGACY SUPPORT FOR USER.PAUSED
      this.videoPlan.resumeDate = new Date(user.paused.resumeDate);
      this.videoPlan.pauseDate = new Date(user.paused.startDate);
      this.videoPlan.pauseRollover = user.paused.rollover;
      this.videoPlan.isPaused = new Date() < this.videoPlan.resumeDate && new Date() > this.videoPlan.pauseDate;
      this.videoPlan.willPause = new Date() < this.videoPlan.pauseDate;
    } else {
      // set pause props to false
      this.videoPlan.isPaused = false;
      this.videoPlan.willPause = false;
    }
    // Populate User videos via Job constructor
    this.jobs = (user.jobs || []).map(job => new Job(job)).sort((a, b) => descending(a.dateAdded, b.dateAdded));

    // Populate User media via Media constructor
    this.media = (user.media || []).map(mediaItem => new Media(mediaItem)).sort((a, b) => descending(a.dateAdded, b.dateAdded));

    // Track last job created date for intercom
    if (this.jobs.length) {
      this.jobLastCreatedAt = moment(this.jobs[0].dateAdded).unix();
    }

    // Populate User designs via Theme constructor
    this.themes = (user.cards || [])
      .map((theme, i, themes) => {
        if (theme.name) return new Theme(theme);
        const date = new Date(theme.dateAdded);
        const monthString = (date.getMonth() + 1).toString().padStart(2, '0'); // getMonth starts from index 0, so +1 gives the correct month
        const dayString = date
          .getDate()
          .toString()
          .padStart(2, '0');
        const yearString = date.getFullYear();

        let index = -1;
        // get designs for same day where no name so that we can display index if multiple designs have same day
        const sameDay = themes.filter(d => {
          const thisDate = new Date(d.dateAdded);
          return date.toDateString() === thisDate.toDateString();
        });

        if (sameDay.length > 1) {
          index = sameDay.findIndex(e => e._id === theme._id);
        }

        theme.name = `Wavve ${monthString}/${dayString}/${yearString}${index > -1 ? ` #${index + 1}` : ''}`;
        return new Theme(theme);
      })
      .filter(theme => !theme.deleted)
      .sort((a, b) => descending(a.dateAdded, b.dateAdded));

    // Populate User caption styles via ZubCaptionStyle constructor
    this.captionStyles = (user.captionStyles || []).map(captionStyle => new ZubCaptionStyle(captionStyle)).filter(captionStyle => !captionStyle.deleted);

    // Determine whether to show new user tips
    if (this.jobs.length === 0) {
      this.noJobs = true;
    }
    if (this.themes.length === 0) {
      this.noThemes = true;
    }

    // reasses if user has video plan
    this.subscribedNoJobs = false;

    // Set billing data if customer has a subscription
    if (subscription.length) {
      this.hasSubscription = true;
      this.stripe.id = user.stripe.id;

      // HANDLE MULTI-PRODUCT SUBSCRIPTIONS
      const videoPlan = subscription.find(x => x.plan.id.indexOf('link') === -1);
      const linkPlan = subscription.find(x => x.plan.id.indexOf('link') !== -1);
      this.linkPlans = subscription.filter(x => x.plan.id.includes('link'));

      // Add billing dates for wavve link plan
      if (linkPlan) {
        this.linkPlan.isDelinquent = linkPlan.status === 'past_due';
        this.linkPlan.planStart = new Date(linkPlan.created * 1000);
        this.linkPlan.currentPeriodStart = new Date(linkPlan.current_period_start * 1000);
        this.linkPlan.currentPeriodEnd = new Date(linkPlan.current_period_end * 1000);
        // Set Start Date Based on Billing Interval
        if (ANNUAL_PLANS.includes(linkPlan.plan.id)) {
          this.linkPlan.currentMonthStartDate = getThisMonthStart(this.linkPlan.currentPeriodStart);
        } else {
          this.linkPlan.currentMonthStartDate = this.linkPlan.currentPeriodStart;
        }
        this.linkPlan.nextBillingDate = this.linkPlan.currentPeriodEnd;
      }

      if (this.linkPlans) {
        this.linkPlans.forEach(plan => {
          plan.isDelinquent = plan.status === 'past_due';
          plan.planStart = new Date(plan.created * 1000);
          plan.currentPeriodStart = new Date(plan.current_period_start * 1000);
          plan.currentPeriodEnd = new Date(plan.current_period_end * 1000);
          // Set Start Date Based on Billing Interval
          if (ANNUAL_PLANS.includes(plan.plan.id)) {
            plan.currentMonthStartDate = getThisMonthStart(plan.currentPeriodStart);
          } else {
            plan.currentMonthStartDate = plan.currentPeriodStart;
          }
          plan.nextBillingDate = plan.currentPeriodEnd;
        });
        this.activeLinkPlans = sum(this.linkPlans, plan => plan.quantity);
      }
      this.activeLinks = this.links.filter(link => link.status === 'FREE' || link.status === 'LIVE').length;

      if (videoPlan) {
        this.videoPlan.isDelinquent = videoPlan.status === 'past_due';
        this.videoPlan.planStart = new Date(videoPlan.created * 1000);
        this.videoPlan.currentPeriodStart = new Date(videoPlan.current_period_start * 1000);
        this.videoPlan.currentPeriodEnd = new Date(videoPlan.current_period_end * 1000);
        this.monthlyAllowance = PLAN_MONTHLY_ALLOWANCE[this.products.video];
        this.rollover = getRolloverTime({
          planStartDate: this.videoPlan.planStart,
          allowance: this.monthlyAllowance,
          rolloverAllowance: this.monthlyAllowance * 2,
          jobs: this.jobs,
        });
        this.subscribedNoJobs = subscribedNoJobs({ planStartDate: this.videoPlan.planStart, jobs: this.jobs });
        // Set Start Date Based on Billing Interval
        if (ANNUAL_PLANS.includes(videoPlan.plan.id)) {
          this.videoPlan.currentMonthStartDate = getThisMonthStart(this.videoPlan.currentPeriodStart);
        } else {
          this.videoPlan.currentMonthStartDate = this.videoPlan.currentPeriodStart;
        }
        this.videoPlan.nextBillingDate = this.videoPlan.currentPeriodEnd;
      }

      // Stripe stores multiple plans differently, so we must first check for this before trying to access plan data
      this.products.link = linkPlan && linkPlan.plan ? linkPlan.plan.id : 'free';
      this.products.video = videoPlan && videoPlan.plan ? videoPlan.plan.id : 'free';
      this.products.links = [];
      if (this.linkPlans) this.linkPlans.forEach(link => this.products.links.push(link.plan.id));
      // Handle Video Specific Plan Calculations
      setBillingDetails(this);
      // Set 'free' & 'paused' users as well as 'orphaned' users (missing stripe acct but have user.plan)
    } else {
      this.hasSubscription = false;
      // always need videoPlan start date for 'free/paused' usage calculation
      this.videoPlan.currentMonthStartDate = getThisMonthStart(this.dateAdded);
      this.rollover = this.pauseRollover || 0;
      if (!this.planIsUnlimited) {
        this.timeLeft = getNoStripeTimeLeft({
          userDateAdded: this.dateAdded,
          jobs: this.jobs,
          allowance: this.monthlyAllowance,
          rollover: 0,
        });
        this.timeLeftString = formatTimeLeft(this.timeLeft);
        this.rewardSeconds = 0;
      } else {
      }
    }
    this.videosThisMonth = getVideosBetween({
      jobs: this.jobs,
      startDate: this.videoPlan.currentMonthStartDate,
    });
    this.videoPlan.nextMonthStartDate = addMonths(this.videoPlan.currentMonthStartDate, 1);
    this.videoPlan.nextMonthStartDateString = timeFormat('%B %d, %Y')(this.videoPlan.nextMonthStartDate);
    this.videoPlan.currentMonthStartDateString = timeFormat('%B %d, %Y')(this.videoPlan.currentMonthStartDate);

    this.videoStorageLimit = PLAN_STORAGE_DAY_LIMIT[user.products.video];
    this.timeLeft = null;
    this.timeLeftString = 'Unlimited plan';
  }
}
export default User;
