<template>
  <nav class="navbar is-spaced rr-block" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <span class="navbar-item" :to="{ name: 'videos' }">
          <img style="cursor:pointer; scale: 1.3; margin-left:15px; margin-top: -4px;" src="@/assets/brand/logo-text.png"
            alt="Wavve Logo" @click="returnToTranscriptios()" />
          <!-- <span class="has-text-primary has-small-margin-left">Wavve</span> -->
        </span>
        <!-- <a id="navbarMenuBurger" role="button" class="navbar-burger burger" aria-label="menu" aria-expanded="false"
          data-target="navbarMenu">
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a> -->
      </div>
      <div class="navbar-menu" id="navbarMenu" @click="hidemenu">
        <div class="navbar-start has-small-margin-top">

          <router-link :to="{ name: 'transcriptions' }" class="navbar-item big">
            <span class="icon">
              <i class="fad fa-copy has-small-margin-right"></i>
            </span>
            <div style="display:inline-block;margin-left:-2px;">Transcriptions</div>
            <div style="margin-left: 0px; margin-top: -8px;  margin-top: -30px; position: absolute;margin-left:155px">
              <el-tag size="mini"
                style="padding:3px; border:none;padding-top:0px;line-height:12px;height:11px;font-size:35%;background-color:#24E0DE;color:white">BETA</el-tag>
            </div>
          </router-link>
          <router-link :to="{ name: 'videos' }" class="navbar-item big">
            <span class="icon">
              <i class="fad fa-play-circle has-small-margin-right"></i>
            </span>
            Videos
          </router-link>
          <router-link :to="{ name: 'link' }" class="navbar-item big">
            <span class="icon">
              <i class="fad fa-link has-small-margin-right"></i>
            </span>
            Wavve Link
          </router-link>

          <small>

            <span class="navbar-item has-margin-top is-uppercase ">Support</span>
          </small>
          <ul>
            <!-- <li>
              <a
                href="https://support.wavve.co"
                analytics-on="click"
                analytics-event="Navigate"
                analytics-category="New Page"
                analytics-label="/support"
                target="_blank"
                class="navbar-item"
                >Help Center</a
              >
            </li> -->
            <li>
              <a href="https://wavve.co/faqs" analytics-on="click" analytics-event="Navigate"
                analytics-category="New Page" analytics-label="/support" target="_blank" class="navbar-item">FAQs</a>
            </li>
            <!-- <li>
              <a
                href="https://www.youtube.com/playlist?list=PLSOcvEjrm8_e9627RGae2UBNvvQd-pHO6"
                analytics-on="click"
                analytics-event="Navigate"
                analytics-category="New Page"
                analytics-label="/tutorials"
                target="_blank"
                class="navbar-item"
                >Video Tutorials</a
              >
            </li> -->
            <!-- <li>
              <a
                href="http://wavve.co/affiliate-program"
                analytics-on="click"
                analytics-event="Navigate"
                analytics-category="New Page"
                analytics-label="/affiliate"
                target="_blank"
                class="navbar-item"
                >Affiliate Program</a
              >
            </li> -->
          </ul>
          <small>
            <span class="navbar-item has-margin-top is-uppercase">Account</span>
          </small>
          <ul>
            <li>
              <router-link :to="{ name: 'settings' }" class="navbar-item">Settings</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'billing' }" class="navbar-item">Billing</router-link>
            </li>
            <li>
              <router-link :to="{ name: 'teams' }" class="navbar-item">Teams</router-link>
            </li>
            <!-- <li>
              <router-link :to="{ name: 'pricing' }" class="navbar-item">Pricing</router-link>
            </li> -->
            <!-- <a @click="logout" class="navbar-item">Logout</a> -->
            <li>
              <!-- <router-link :to="{ name: 'logout' }" class="navbar-item">Logout</router-link> -->

            <li>
              <router-link :to="{ name: 'logout' }" class="navbar-item">Logout</router-link>
            </li>
            <!-- <a href="/logout" onclick="window.location.href='/logout'" class="navbar-item">Logout</a> -->
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TheNavbar',
  computed: {
    user() {
      return this.$store.state.user;
    },
    team() {
      return this.$store.state.team;
    },
    isMobile() {
      return window.innerWidth <= 768;
    },
  },
  mounted() {
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach(el => {
        el.addEventListener('click', () => {
          const { target } = el.dataset;
          const $target = document.getElementById(target);
          // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
        });
      });
    }

    if (this.isMobile) {
      const elements = [document.getElementById('navbarMenu'), document.getElementById('navbarMenuBurger')];

      elements.forEach(el => {
        if (el) {
          el.classList.toggle('is-active');
        }
      });
    }
  },
  methods: {
    returnToTranscriptios() {
      // this.$router.push("/transcriptios");
      location.href = "/transcriptions"
    },
    hidemenu(event) {
      event.target.classList.toggle('is-active');

      document.querySelectorAll('.navbar-burger').forEach(el => {
        el.classList.toggle('is-active');
      })
    }
    ,
    async logout() {
      // localStorage.removeItem('token');
      // await this.$store.dispatch(LOGOUT);
      await this.$router.push('/logout');
    },
  },
  watch: {
    // eslint-disable-next-line
    $route() {
      const elements = [document.getElementById('navbarMenu'), document.getElementById('navbarMenuBurger')];
      elements.forEach(el => {
        if (el) {
          el.classList.remove('is-active');
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>