import Vue from 'vue';
import axios from 'axios';
import * as actions from './action-types';
import * as types from './mutation-types';
import * as getters from './getters';

export default {
  [types.SET_JOB_LOADING](state, payload) {
    state.user.jobs.forEach(function (currentValue, index, arr) {
      if (currentValue.id == payload.id) {
        let currentJob = state.user.jobs[index];
        currentValue.isLoading = payload.value;
        Vue.set(state.user.jobs, index, currentValue);
      }
    });
    // state.user.jobs[payload.index].isLoading=payload.value;
    // Vue.set(state.user.jobs, payload.index, payload.value);
  },

  [types.SET_THEME_LOADING](state, payload) {
    state.user.themes.forEach(function (currentValue, index, arr) {
      if (currentValue.id == payload.id) {
        let currentJob = state.user.themes[index];
        currentValue.isLoading = payload.value;
        Vue.set(state.user.themes, index, currentValue);
      }
    });
  },

  // AUTH MUTATIONS
  [types.SET_AUTH_LOADING](state) {
    state.authStatus = 'loading';
  },
  [types.SET_AUTH_SUCCESS](state, payload) {
    state.authStatus = 'success';
    state.token = `${payload}`;
  },
  [types.SET_USER](state, payload) {
    // console.log('suser',payload);
    if (!payload) {
      Vue.set(state, 'user', null);

      return;
    }

    if (!state.user) {
      state.user = payload;
    } else {
      Object.keys(payload).forEach(key => {
        // console.log(key,payload[key]);
        Vue.set(state.user, key, payload[key]);
      });
    }
    // Vue.set(state, 'user', payload)
    // Vue.set(state.user, 'media', payload.media)
    // Vue.set(state.user, 'links', payload.links)
    // Vue.set(state.user, 'isSubscribed', payload.isSubscribed)
    // Vue.set(state.user, 'monthlyAllowance', payload.monthlyAllowance)
    // Vue.set(state.user, 'planIsUnlimited', payload.planIsUnlimited)
    // Vue.set(state.user, 'hasPremiumLink', payload.hasPremiumLink)
    // Vue.set(state.user, 'stinger', payload.stinger)
  },
  [types.SET_AUTH_ERROR](state) {
    state.authStatus = 'error';
  },
  [types.SET_LOGOUT](state) {
    state.authStatus = '';
    state.token = '';
    state.user = null;
  },

  // CANVAS STATE
  [types.SAVE_CANVAS](state, payload) {
    state.canvasData = payload;
  },
  [types.CLEAR_CANVAS](state) {
    state.canvasData = null;
  },

  // CANVAS HISTORY
  [types.SAVE_HISTORY](state, payload) {
    state.history = payload;
  },

  // AUDIO RECORDING MUTATIONS
  [types.SET_AUDIO_TYPE](state, audioType) {
    state.audioType = audioType;
  },
  [types.SET_AUDIO_START](state, audioStart) {
    state.audioStart = audioStart;
  },
  [types.SET_AUDIO_END](state, audioEnd) {
    state.audioEnd = audioEnd;
  },
  [types.SET_SELECTED_THEME](state, selectedTheme) {
    state.selectedTheme = selectedTheme;
  },
  [types.SET_AUDIO_BUFFER](state, audioBuffer) {
    // state.audioBuffer = audioBuffer;
    window.audioBuffer = audioBuffer;
  },
  [types.SET_AUDIO_BUFFER_DECODED](state, audioBufferDecoded) {
    state.audioBufferDecoded = audioBufferDecoded;
  },
  [types.SET_AUDIO_BUFFER_DECODING](state, audioBufferDecoding) {
    state.audioBufferDecoding = audioBufferDecoding;
  },
  [types.SET_AUDIO_BUFFER_DECODE_ESTIMATE_DATE](state, audioBufferDecodeEstimateDate) {
    state.audioBufferDecodeEstimateDate = audioBufferDecodeEstimateDate;
  },
  [types.SET_AUDIO_DATA_URL](state, audioDataUrl) {
    state.audioDataUrl = audioDataUrl;
  },
  [types.SET_AUDIO_UPLOAD_DATA_URL](state, audioUploadDataUrl) {
    state.audioUploadDataUrl = audioUploadDataUrl;
  },
  [types.SET_UPLOAD_AUDIO_PROCESSING](state, uploadAudioProcessing) {
    state.uploadAudioProcessing = uploadAudioProcessing;
  },
  [types.SET_REMOTE_AUDIO_URL](state, remoteAudioUrl) {
    state.remoteAudioUrl = remoteAudioUrl;
  },
  [types.SET_EPISODE_INFO](state, episodeInfo) {
    state.episodeInfo = episodeInfo;
  },
  [types.SET_REMOTE_AUDIO_DOWNLOADING](state, remoteAudioDownloading) {
    state.remoteAudioDownloading = remoteAudioDownloading;
  },
  [types.SET_SELECTED_EPISODE](state, selectedEpisode) {
    state.selectedEpisode = selectedEpisode;
  },
  [types.SET_REMOTE_AUDIO_DOWNLOAD_PROGRESS](state, remoteAudioDownloadProgress) {
    state.remoteAudioDownloadProgress = remoteAudioDownloadProgress;
  },

  [types.SET_POPOVER_DISMISSED](state, popoverDismissed) {
    state.popoverDismissed = popoverDismissed;
  },
  [types.SET_AUDIO_READY](state, audioReady) {
    state.audioReady = audioReady;
  },

  [types.SET_AUDIO_REGION](state, audioRegion) {
    state.audioRegion = audioRegion;
  },
  [types.SET_AUDIO_READING](state, audioReading) {
    state.audioReading = audioReading;
  },
  [types.SET_EDITING_JOB](state, editingJob) {
    state.editingJob = editingJob;
  },
  [types.SET_DOWNLOAD_REQUEST](state, downloadRequest) {
    state.downloadRequest = downloadRequest;
  },
  [types.CLEAR_AUDIO_DATA](state) {
    state.audioBuffer = null;
    state.audioStart = null;
    state.audioEnd = null;
    state.audioBufferDecoded = null;
    state.audioBufferDecoding = false;
    state.audioDataUrl = null;
    state.audioType = null;
    state.remoteAudioUrl = null;
    state.remoteAudioDownloading = false;
    state.remoteAudioDownloadProgress = 0;
    state.remoteAudioDownloadProgress = null;
    state.selectedEpisode = null;
  },
  [types.SET_JOB_DATA](state, { id, data }) {
    const job = getters.getJobById(state)(id);
    // console.log("------->>>>>>>>>>>>>>>>>",id,job)
    if (job) {
      Object.keys(data).forEach(key => {
        Vue.set(job, key, data[key]);
      });
    }
  },
  [types.SET_USER_DATA](state, data) {
    if (state.user) {
      Object.keys(data).forEach(key => {
        // console.log(key,payload[key]);
        Vue.set(state.user, key, data[key]);
      });
    }
  },
  [types.SET_LINK_DATA](state, { _id, data }) {
    const link = getters.getLinkById(state)(_id);
    if (link) {
      Object.keys(data).forEach(key => {
        Vue.set(link, key, data[key]);
      });
    }
  },
  [types.ADD_LINK_DATA](state, data) {
    state.user.links.push(data);
  },
  [types.SET_CANCELING_LINK_ID](state, id) {
    // console.log(`Mutation: ${id}`);
    state.subscriptionData.cancelingLinkId = id;
  },

  // PODCAST RELATED
  [types.SET_PODCAST_RSS](state, data) {
    state.podcastRss = data;
    Vue.set(state, 'podcastRss', data);
  },

  [types.SET_MEDIA](state, media) {
    // console.log('setselectedmedia', media);
    state.selectedMedia = media;
  },

  // SET DEFLECTOR OUTCOME
  [types.SET_DEFLECTOR_RESULTS](state, data) {
    state.deflector.success = data.success;
    state.deflector.acceptedOffer = data.acceptedOffer;
    state.deflector.offerDetails = data.offerDetails;
  },

  // SET DEFLECTOR SURVEY
  [types.SET_DEFLECTOR_SURVEY](state, data) {
    state.deflectorSurvey.feedback = data.feedback;
    state.deflectorSurvey.cancelReason = data.cancelReason;
  },

  // SET DEFLECTOR SUCCESS STATE
  [types.SET_DEFLECTOR_STATUS](state, status) {
    state.deflector.isRecording = status;
  },

  // SET OAUTH STATE
  [types.OAUTH_STATE](state, status) {
    state.oauthState = status;
  },

  [types.UPDATE_TRANSCRIPTIONS](state, data) {
    state.transcriptions_meta.lastupdate = 0;
    state.transcriptions.forEach(item => {
      if (item.lastStatusUpdate > state.transcriptions_meta.lastupdate) {
        state.transcriptions_meta.lastupdate = item.lastStatusUpdate;
      }
    });
    state.transcriptions = data.docs;
  },

  [types.DELETE_TRANSCRIPTION](state, data) {
    // console.log(state, data);
    const storageIndex = state.transcriptions.findIndex(t2 => {
      return t2._id === data;
    });
    Vue.delete(state.transcriptions, storageIndex);
  },

  // [types.UPDATE_TRANSCRIPTION_USAGE_STATUS](state, data) {
  //     Vue.set(state, 'transcriptionUsageStatus', data)
  // },

  [types.UPDATE_TRANSCRIPTION](state, data) {
    if (data.length) {
      // console.log('UPDATING TRANSCRIPTION', data);
    }
    const storageIndex = state.transcriptions.findIndex(t2 => {
      return t2._id === data._id;
    });
    // console.log('storageIndex', storageIndex, 'data', data);
    // }
    if (storageIndex > -1 && data.transcriptionContent) {
      let updatedItem = state.transcriptions[storageIndex];
      updatedItem.transcriptionContent = data.transcriptionContent;
      Vue.set(state.transcriptions, storageIndex, updatedItem);
    }
    if (storageIndex > -1 && data.title) {
      // console.log('TITLE', storageIndex, data.title);
      let updatedItem = state.transcriptions[storageIndex];
      updatedItem.title = data.title;
      Vue.set(state.transcriptions, storageIndex, updatedItem);
    }
    if (storageIndex > -1 && data.contentWithoutTitle) {
      // console.log('CONTENT', storageIndex, data.contentWithoutTitle);
      let updatedItem = state.transcriptions[storageIndex];
      updatedItem.contentWithoutTitle = data.contentWithoutTitle;
      Vue.set(state.transcriptions, storageIndex, updatedItem);
    }
  },

  [types.UPDATE_PENDING_TRANSCRIPTIONS](state, data) {
    if (data.length) {
      // console.log('UPDATING', data);
    }
    data.docs.forEach(item => {
      if (item.lastStatusUpdate > state.transcriptions_meta.lastupdate) {
        state.transcriptions_meta.lastupdate = item.lastStatusUpdate;
      }
      const storageIndex = state.transcriptions.findIndex(t2 => {
        return t2._id === item._id;
      });
      if (storageIndex > -1) {
        // console.log('ACHEI NO INDICE', storageIndex);
        if (state.transcriptions[storageIndex].lastStatusUpdate < item.lastStatusUpdate) {
          Vue.set(state.transcriptions, storageIndex, item);
        }
      } else {
        // console.log('NAO ACHEI NO INDICE', item);
        state.transcriptions.unshift(item);
      }
    });
    // if (document.myInterval.length) {
    //   clearTimeout(document.myInterval);
    // }
    // let mutations=this
    // document.myInterval = setTimeout(this.dispatch(LOAD_PENDING_TRANSCRIPTIONS)", 5000);

    // Vue.set(state, 'transcriptions', state.transcriptions);
    // state.transcriptions.map(t1 => ({ ...state.transcriptions, ...data.find(t2 => t2.id === t1.id) }));
    // if (!Object.keys(data).length) {
    //   data = [];
    // }
    // const mergedList = data.concat(state.transcriptions).reduce((acc, x) => {
    //   acc[x._id] = Object.assign(acc[x._id] || {}, x);
    //   return acc;
    // }, {});
    // state.transcriptions = Object.values(mergedList);
    // console.log('ML:', mergedList);
  },

  [types.SET_TEAMS](state, teams) {
    if (!teams && !teams.length) {
      Vue.set(state, 'teams', undefined);

      return;
    }

    // const teamsTransformed = teams.map((team, index) => ({
    //   ...team,
    //   seats: team.seats || 3,
    //   name: team.name ? team.name : team.owner.name ? `${team.owner.name}'s team` : `Team ${index + 1}`
    // }));

    const teamsTransformed = teams.map((team, index) => {
      let color;

      if (!team.color) {
        color = getRandomContrastColor();
      }

      return {
        ...team,
        color: team.color || color.color,
        textColor: team.textColor || color.textColor,
        seats: team.seats || 3,
        name: team.name ? team.name : team.owner.name ? `${team.owner.name}'s team` : `Team ${index + 1}`,
      };
    });

    Vue.set(state, 'teams', teamsTransformed);
  },

  [types.SET_TEAMS_INVITES](state, invites) {
    if (!invites || !invites.length) {
      Vue.set(state, 'invites', undefined);
      return;
    }

    // const invitesTransformed = invites.map((invite, index) => ({
    //   ...invite,
    //   name: invite.name ? invite.name : invite.owner.name ? `${invite.owner.name}'s team` : `Invite ${index + 1}`
    // }));

    const invitesTransformed = invites.map((invite, index) => {
      let color;

      if (!invite.color) {
        color = getRandomContrastColor();
      }

      return {
        ...invite,
        color: invite.color || color.color,
        textColor: invite.textColor || color.textColor,
        name: invite.name ? invite.name : invite.owner.name ? `${invite.owner.name}'s team` : `Invite ${index + 1}`,
      };
    });

    Vue.set(state, 'invites', invitesTransformed);
  },
  [types.SET_USER_TEAM](state, teamId) {
    Vue.set(state.user, 'team', teamId);
  },
  [types.SET_IS_CHANGING_TEAM](state, value) {
    Vue.set(state, 'isChangingTeam', value);
  },
};

export function getRandomContrastColor() {
  let color;
  let luminance = 0;

  do {
    const r = Math.floor(Math.random() * 256);
    const g = Math.floor(Math.random() * 256);
    const b = Math.floor(Math.random() * 256);

    luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    color = `#${((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)}`;
  } while (luminance < 0.2 || luminance > 0.8);

  const textColor = luminance > 0.5 ? '#000000' : '#FFFFFF';

  return { color, textColor };
}
