export const SIGNUP = 'SIGNUP';
export const SETUP = 'SETUP';
export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const CLEAR_TOKEN = 'CLEAR_TOKEN';
export const SYNC_USER_TO_DB = 'SYNC_USER_TO_DB';
export const SAVE_CANVAS = 'SAVE_CANVAS';
export const SAVE_HISTORY = 'SAVE_HISTORY';
export const CLEAR_CANVAS = 'CLEAR_CANVAS';
export const SET_AUDIO_FILE = 'SET_AUDIO_FILE';
export const SET_AUDIO_REGION = 'SET_AUDIO_REGION';
export const SET_AUDIO_FILE_FROM_REMOTE_URL = 'SET_AUDIO_FILE_FROM_REMOTE_URL';
export const SET_SELECTED_EPISODE = 'SET_SELECTED_EPISODE';
export const START_AUDIO_PROCESSING = 'START_AUDIO_PROCESSING';
export const STOP_AUDIO_PROCESSING = 'STOP_AUDIO_PROCESSING';
export const SET_EPISODE_INFO = 'SET_EPISODE_INFO';
export const SET_EDITING_JOB = 'SET_EDITING_JOB';
export const DECODE_AUDIO_BUFFER = 'DECODE_AUDIO_BUFFER';
export const CLEAR_AUDIO_FILE = 'CLEAR_AUDIO_FILE';
export const JOB_UPDATE = 'JOB_UPDATE';
export const SET_AUDIO_UPLOAD_FILE = 'SET_AUDIO_UPLOAD_FILE';
export const JOB_CREATE = 'JOB_CREATE';
export const JOB_RECREATE = 'JOB_RECREATE';
export const POLL_VIDEO_CREATION = 'POLL_VIDEO_CREATION';
export const POLL_ZUBTITLE_FINALIZATION = 'POLL_ZUBTITLE_FINALIZATION';
export const POLL_ZUBTITLE_TRANSCRIPTION = 'POLL_ZUBTITLE_TRANSCRIPTION';
export const CREATE_WAVVE_LINK = 'CREATE_WAVVE_LINK';
export const UPDATE_WAVVE_LINK = 'UPDATE_WAVVE_LINK';
export const DELETE_WAVVE_LINK = 'DELETE_WAVVE_LINK';
export const SET_CANCELING_LINK_ID = 'SET_CANCELING_LINK_ID';
export const UPDATE_FEEDS = 'UPDATE_FEEDS';
export const DELETE_THEME = 'DELETE_THEME';
export const SET_SELECTED_THEME = 'SET_SELECTED_THEME';
export const SET_POPOVER_DISMISSED = 'SET_POPOVER_DISMISSED';
export const FETCH_MAILING_LIST_SUBSCRIBERS = 'FETCH_MAILING_LIST_SUBSCRIBERS';
export const SUBSCRIBE_TO_MAILING_LIST = 'SUBSCRIBE_TO_MAILING_LIST';
export const SET_CUSTOM_DOMAIN = 'SET_CUSTOM_DOMAIN';
export const REMOVE_CUSTOM_DOMAIN = 'REMOVE_CUSTOM_DOMAIN';
export const REFRESH_AUTH_TOKEN = 'REFRESH_AUTH_TOKEN';
export const FETCH_PODCAST_RSS = 'FETCH_PODCAST_RSS';
export const SET_DEFLECTOR_RESULTS = 'SET_DEFLECTOR_RESULTS';
export const SET_DEFLECTOR_SURVEY = 'SET_DEFLECTOR_SURVEY';
export const START_DEFLECTOR_RECORDING = 'START_DEFLECTOR_RECORDING';
export const STOP_DEFLECTOR_RECORDING = 'STOP_DEFLECTOR_RECORDING';
export const OAUTH_AUTHORIZE = 'OAUTH_AUTHORIZE';
export const OAUTH_CONFIRM = 'OAUTH_CONFIRM';
export const POLL_MEDIA_PROCESSING = 'POLL_MEDIA_PROCESSING';
export const STOP_POLL_MEDIA_PROCESSING = 'STOP_POLL_MEDIA_PROCESSING';
export const SET_MEDIA = 'SET_MEDIA';
export const LOAD_TRANSCRIPTIONS = 'LOAD_TRANSCRIPTIONS';
export const REMOVE_TRANSCRIPTION = 'REMOVE_TRANSCRIPTION';
export const LOAD_PENDING_TRANSCRIPTIONS = 'LOAD_PENDING_TRANSCRIPTIONS';
export const TRANSCRIBE_VIDEO = 'TRANSCRIBE_VIDEO';
export const LOAD_ONE_TRANSCRIPTION = 'LOAD_ONE_TRANSCRIPTION';
export const SAVE_TRANSCRIPTION_TITLE = 'SAVE_TRANSCRIPTION_TITLE';
export const SAVE_TRANSCRIPTION_CONTENT = 'SAVE_TRANSCRIPTION_CONTENT';
export const CREATE_TRANSCRIPTION_CLONE = 'CREATE_TRANSCRIPTION_CLONE';
export const CREATE_TRANSCRIPTION_LOADER = 'CREATE_TRANSCRIPTION_LOADER';
export const SUMMARY_TRANSCRIBE_VIDEO = 'SUMMARY_TRANSCRIBE_VIDEO';
export const SYNC_TRANSCRIPTION_USAGE_STATUS = 'SYNC_TRANSCRIPTION_USAGE_STATUS';
export const CLONE_VIDEO = 'CLONE_VIDEO';
export const TEAMS_GET_TEAMS_ACTION = 'TEAMS_GET_TEAMS';
export const TEAMS_GET_INVITES_ACTION = 'TEAMS_GET_INVITES';
export const TEAMS_ACCEPT_INVITE_ACTION = 'TEAMS_ACCEPT_INVITE';
export const TEAMS_ADD_USER_ACTION = 'TEAMS_ADD_USER';
export const TEAMS_LEAVE_USER_ACTION = 'TEAMS_LEAVE_USER_ACTION';
export const TEAMS_DELETE_USER_ACTION = 'TEAMS_DELETE_USER';
export const TEAMS_DELETE_ADDITIONAL_SEATS_ACTION = 'TEAMS_DELETE_ADDITIONAL_SEATS_ACTION';
export const TEAMS_DELETE_EMPTY_SEATS_ACTION = 'TEAMS_DELETE_EMPTY_SEATS_ACTION';
export const TEAMS_CHANGE_TEAM = 'TEAMS_CHANGE_TEAM';
export const TEAMS_CHANGE_NAME = 'TEAMS_CHANGE_NAME';
export const TEAMS_CHANGE_COLOR = 'TEAMS_CHANGE_COLOR';
export const TEAMS_CHANGE_ROLE = 'TEAMS_CHANGE_ROLE';
export const TEAMS_REMOVE_SEAT = 'TEAMS_REMOVE_SEAT';
export const TEAMS_ADD_SEATS = 'TEAMS_ADD_SEATS';
export const TEAMS_RESEND_INVITE = 'TEAMS_RESEND_INVITE';
export const TEAMS_DECLINE_INVITE = 'TEAMS_DECLINE_INVITE'
export const STRIPE_CANCEL_PLAN = 'STRIPE_CANCEL_PLAN';
export const STRIPE_PAUSE_PLAN = 'STRIPE_PAUSE_PLAN';
export const STRIPE_CHECKOUT_PAUSE_PLAN = 'STRIPE_CHECKOUT_PAUSE_PLAN';
export const USER_DELETE_ACCOUNT = 'USER_DELETE_ACCOUNT';
export const USER_SCHEDULE_DELETE_ACCOUNT = 'USER_SCHEDULE_DELETE_ACCOUNT';
export const MEDIA_DELETE = 'MEDIA_DELETE';
export const MEDIA_CHANGE = 'MEDIA_CHANGE';
export const CAPTIONS_DONE_EVENT = 'CAPTIONS_DONE_EVENT';